import React, { ReactElement, ReactNode } from 'react';
import styles from './AddEditBase.module.scss';

interface AddEditBaseProps {
  children?: ReactNode;
}
function AddEditBase({ children }: AddEditBaseProps): ReactElement {
  return <form className={styles.form}>{children}</form>;
}

export default AddEditBase;
