import Checkbox from 'components/Base/Checkbox';
import React, { ReactElement } from 'react';
import styles from './AddRemoveCheckboxes.module.scss';

interface AddRemoveCheckboxesProps {
  changeCheckbox: (value: boolean) => void;
  checked: boolean;
  add: boolean;
  title: string;
}

function AddRemoveCheckboxes({ checked, add, changeCheckbox, title }: AddRemoveCheckboxesProps): ReactElement {
  return (
    <div className={styles.checkboxes}>
      <Checkbox
        label={`${add ? 'Add' : 'Remove'} ${title}`}
        checked={checked}
        onClick={(value: boolean) => changeCheckbox(value)}
      />
    </div>
  );
}
export default AddRemoveCheckboxes;
