import React, { ReactElement } from 'react';
import styles from './CheckboxAttribute.module.scss';
import Checkbox from 'components/Base/Checkbox';

interface CheckboxAttributeProps {
  field: string;
  onChange: (value: boolean) => void;
  value: boolean;
  edit: boolean;
}
function CheckboxAttribute({ field, onChange, edit, value }: CheckboxAttributeProps): ReactElement {
  return (
    <div className={styles.item}>
      <div className={styles.field}>{field}</div>
      {edit ? (
        <div className={styles.edit}>
          <Checkbox disabled={!edit} checked={value} onClick={onChange} />
        </div>
      ) : (
        <div className={styles.edit}>
          <div>{value ? 'Enabled' : 'Disabled'}</div>
        </div>
      )}
    </div>
  );
}

export default CheckboxAttribute;
