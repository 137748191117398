import { changeStyleProperty } from 'lib/reactOperations';
import React, { ReactElement, useEffect } from 'react';
import styles from './ColorIndicator.module.scss';

interface ColorIndicatorProps {
  colorHex: string | undefined;
  id: string;
}

function ColorIndicator({ id, colorHex }: ColorIndicatorProps): ReactElement {
  useEffect(() => {
    if (!colorHex) return;
    changeStyleProperty(id, '--colorHex', colorHex);
  }, [colorHex]);

  return <div id={id} className={styles.color} />;
}

export default ColorIndicator;
