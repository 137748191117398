import BaseModal from 'components/Base/BaseModal';
import Checkbox from 'components/Base/Checkbox';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import useJobs from 'hooks/useJobs.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import { formatPrice } from 'lib/formatting';
import React, { ReactElement, useEffect, useState } from 'react';
import { ConfirmCustomChanges, ConfirmEavesChanges, ConfirmWindowChanges, Job, RequestJobChanges } from 'types';
import styles from './HandleChangeRequestsModal.module.scss';

interface HandleChangeRequestsModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  job?: Job;
}
function HandleChangeRequestsModal({ open, onClose, job, onSave }: HandleChangeRequestsModalProps): ReactElement {
  const [changeRequest, setChangeRequest] = useState<RequestJobChanges>();
  const [windowChanges, setWindowChanges] = useState<ConfirmWindowChanges>();
  const [eavesChanges, setEavesChanges] = useState<ConfirmEavesChanges>();
  const [customChanges, setCustomChanges] = useState<ConfirmCustomChanges>();
  const [addIncludeTax, setAddIncludeTax] = useState<boolean>();
  const [removeIncludeTax, setRemoveIncludeTax] = useState<boolean>();

  const { fetchRequestChanges, changeRequests, confirmRequestChanges, jobErrorTitle, jobErrorMessage, resetJobError } =
    useJobs();
  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError }],
  });

  const windowRequest = changeRequest?.windowRequest;
  const eavesRequest = changeRequest?.eavesRequest;
  const customRequest = changeRequest?.customRequest;

  const onLocalSave = async () => {
    if (!changeRequest) return;

    await confirmRequestChanges(changeRequest.id, {
      windowChanges,
      eavesChanges,
      customChanges,
      addIncludeTax,
      removeIncludeTax,
    });
    onSave();
  };

  const setWindowCheckbox = (key: keyof ConfirmWindowChanges, value: boolean) => {
    setWindowChanges((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const setEavesCheckbox = (key: keyof ConfirmEavesChanges, value: boolean) => {
    setEavesChanges((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const setCustomCheckbox = (key: keyof ConfirmCustomChanges, value: boolean) => {
    setCustomChanges((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    if (!job) return;
    fetchRequestChanges(job.id);
  }, [job]);

  useEffect(() => {
    if (!changeRequests.length) return;
    const cr = changeRequests[0];
    setChangeRequest(cr);
  }, [changeRequests]);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title={'Change Request'}
      onConfirm={onLocalSave}
      openAlert={openAlert}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      onAlertClose={closeAlertModal}
    >
      {changeRequests.length > 1 && (
        <Dropdown
          hint={'Select a Request'}
          objectKey={'title'}
          items={changeRequests.map((request) => {
            return (
              <DropdownItem
                key={request.id}
                id={request.id}
                value={request.createdBy}
                onSelect={() => setChangeRequest(request)}
              />
            );
          })}
          selectedValue={changeRequest?.createdBy}
        />
      )}
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.title}>Reason</div>
          <div>{changeRequest?.reason}</div>
        </div>
        {!!changeRequest?.addIncludeTax && (
          <Checkbox checked={!!addIncludeTax} label={'Include Tax'} onClick={(value) => setAddIncludeTax(value)} />
        )}
        {!!changeRequest?.removeIncludeTax && (
          <Checkbox
            checked={!!removeIncludeTax}
            label={'Do Not Include Tax'}
            onClick={(value) => setRemoveIncludeTax(value)}
          />
        )}
        {windowRequest && (
          <div className={styles.section}>
            <div className={styles.title}>Window Changes</div>
            {!!windowRequest.subType && (
              <Checkbox
                checked={!!windowChanges?.subType}
                label={windowRequest.subType.title}
                onClick={(value) => setWindowCheckbox('subType', value)}
              />
            )}
            {!!windowRequest.price && (
              <Checkbox
                checked={!!windowChanges?.price}
                label={`Price: ${formatPrice(windowRequest.price, true)}`}
                onClick={(value) => setWindowCheckbox('price', value)}
              />
            )}
            {!!(windowRequest.addBasement || windowRequest.removeBasement) && (
              <Checkbox
                checked={windowRequest.addBasement ? !!windowChanges?.addBasement : !!windowChanges?.removeBasement}
                label={windowRequest.addBasement ? 'Add Basement' : 'Remove Basement'}
                onClick={(value) =>
                  setWindowCheckbox(windowRequest.addBasement ? 'addBasement' : 'removeBasement', value)
                }
              />
            )}
            {!!(windowRequest.addRailings || windowRequest.removeRailings) && (
              <Checkbox
                checked={windowRequest.addRailings ? !!windowChanges?.addRailings : !!windowChanges?.removeRailings}
                label={windowRequest.addRailings ? 'Add Railings' : 'Remove Railings'}
                onClick={(value) =>
                  setWindowCheckbox(windowRequest.addRailings ? 'addRailings' : 'removeRailings', value)
                }
              />
            )}
            {!!(windowRequest.addSills || windowRequest.removeSills) && (
              <Checkbox
                checked={windowRequest.addSills ? !!windowChanges?.addSills : !!windowChanges?.removeSills}
                label={windowRequest.addSills ? 'Add Sills' : 'Remove Sills'}
                onClick={(value) => setWindowCheckbox(windowRequest.addSills ? 'addSills' : 'removeSills', value)}
              />
            )}
            {!!(windowRequest.addSkylights || windowRequest.removeSkylights) && (
              <Checkbox
                checked={windowRequest.addSkylights ? !!windowChanges?.addSkylights : !!windowChanges?.removeSkylights}
                label={windowRequest.addSkylights ? 'Add Skylights' : 'Remove Skylights'}
                onClick={(value) =>
                  setWindowCheckbox(windowRequest.addSkylights ? 'addSkylights' : 'removeSkylights', value)
                }
              />
            )}
            {!!(windowRequest.addThirdFloor || windowRequest.removeThirdFloor) && (
              <Checkbox
                checked={
                  windowRequest.addThirdFloor ? !!windowChanges?.addThirdFloor : !!windowChanges?.removeThirdFloor
                }
                label={windowRequest.addThirdFloor ? 'Add ThirdFloor' : 'Remove ThirdFloor'}
                onClick={(value) =>
                  setWindowCheckbox(windowRequest.addThirdFloor ? 'addThirdFloor' : 'removeThirdFloor', value)
                }
              />
            )}
            {!!(windowRequest.addCranks || windowRequest.removeCranks) && (
              <Checkbox
                checked={windowRequest.addCranks ? !!windowChanges?.addCranks : !!windowChanges?.removeCranks}
                label={windowRequest.addCranks ? 'Add Cranks' : 'Remove Cranks'}
                onClick={(value) => setWindowCheckbox(windowRequest.addCranks ? 'addCranks' : 'removeCranks', value)}
              />
            )}
            {!!(windowRequest.addTracks || windowRequest.removeTracks) && (
              <Checkbox
                checked={windowRequest.addTracks ? !!windowChanges?.addTracks : !!windowChanges?.removeTracks}
                label={windowRequest.addTracks ? 'Add Tracks' : 'Remove Tracks'}
                onClick={(value) => setWindowCheckbox(windowRequest.addTracks ? 'addTracks' : 'removeTracks', value)}
              />
            )}
            {!!(windowRequest.addDoors || windowRequest.removeDoors) && (
              <Checkbox
                checked={windowRequest.addDoors ? !!windowChanges?.addDoors : !!windowChanges?.removeDoors}
                label={windowRequest.addDoors ? 'Add Doors' : 'Remove Doors'}
                onClick={(value) => setWindowCheckbox(windowRequest.addDoors ? 'addDoors' : 'removeDoors', value)}
              />
            )}
            {!!(windowRequest.addSolarPanels || windowRequest.removeSolarPanels) && (
              <Checkbox
                checked={
                  windowRequest.addSolarPanels ? !!windowChanges?.addSolarPanels : !!windowChanges?.removeSolarPanels
                }
                label={windowRequest.addSolarPanels ? 'Add SolarPanels' : 'Remove SolarPanels'}
                onClick={(value) =>
                  setWindowCheckbox(windowRequest.addSolarPanels ? 'addSolarPanels' : 'removeSolarPanels', value)
                }
              />
            )}
            {!!(windowRequest.addGarage || windowRequest.removeGarage) && (
              <Checkbox
                checked={windowRequest.addGarage ? !!windowChanges?.addGarage : !!windowChanges?.removeGarage}
                label={windowRequest.addGarage ? 'Add Garage' : 'Remove Garage'}
                onClick={(value) => setWindowCheckbox(windowRequest.addGarage ? 'addGarage' : 'removeGarage', value)}
              />
            )}
            {!!(windowRequest.addScreens || windowRequest.removeScreens) && (
              <Checkbox
                checked={windowRequest.addScreens ? !!windowChanges?.addScreens : !!windowChanges?.removeScreens}
                label={windowRequest.addScreens ? 'Add Screens' : 'Remove Screens'}
                onClick={(value) => setWindowCheckbox(windowRequest.addScreens ? 'addScreens' : 'removeScreens', value)}
              />
            )}
          </div>
        )}
        {eavesRequest && (
          <div className={styles.section}>
            <div className={styles.title}>Eavestrough Changes</div>
            {!!eavesRequest.subType && (
              <Checkbox
                checked={!!eavesChanges?.subType}
                label={eavesRequest.subType.title}
                onClick={(value) => setEavesCheckbox('subType', value)}
              />
            )}
            {!!eavesRequest.price && (
              <Checkbox
                checked={!!eavesChanges?.price}
                label={`Price: ${formatPrice(eavesRequest.price, true)}`}
                onClick={(value) => setEavesCheckbox('price', value)}
              />
            )}
            {!!(eavesRequest.addSecondBuilding || eavesRequest.removeSecondBuilding) && (
              <Checkbox
                checked={
                  eavesRequest.addSecondBuilding
                    ? !!eavesChanges?.addSecondBuilding
                    : !!eavesChanges?.removeSecondBuilding
                }
                label={eavesRequest.addSecondBuilding ? 'Add Second Building' : 'Remove Second Building'}
                onClick={(value) =>
                  setEavesCheckbox(eavesRequest.addSecondBuilding ? 'addSecondBuilding' : 'removeSecondBuilding', value)
                }
              />
            )}
            {!!(eavesRequest.addValleys || eavesRequest.removeValleys) && (
              <Checkbox
                checked={eavesRequest.addValleys ? !!eavesChanges?.addValleys : !!eavesChanges?.removeValleys}
                label={eavesRequest.addValleys ? 'Add Valleys' : 'Remove Valleys'}
                onClick={(value) => setEavesCheckbox(eavesRequest.addValleys ? 'addValleys' : 'removeValleys', value)}
              />
            )}
            {!!(eavesRequest.addFlatRoofs || eavesRequest.removeFlatRoofs) && (
              <Checkbox
                checked={eavesRequest.addFlatRoofs ? !!eavesChanges?.addFlatRoofs : !!eavesChanges?.removeFlatRoofs}
                label={eavesRequest.addFlatRoofs ? 'Add Flat Roofs' : 'Remove Flat Roofs'}
                onClick={(value) =>
                  setEavesCheckbox(eavesRequest.addFlatRoofs ? 'addFlatRoofs' : 'removeFlatRoofs', value)
                }
              />
            )}
          </div>
        )}
        {customRequest && (
          <div className={styles.section}>
            <div className={styles.title}>Custom Changes</div>
            {!!customRequest.price && (
              <Checkbox
                checked={!!customChanges?.price}
                label={`Price: $${formatPrice(customRequest.price)}`}
                onClick={(value) => setCustomCheckbox('price', value)}
              />
            )}
          </div>
        )}
      </div>
    </BaseModal>
  );
}

export default HandleChangeRequestsModal;
