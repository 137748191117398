import Layout from 'Base';
import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import JobScheduleSummarySetup from 'components/JobScheduleSummarySetup';
import { SnackBarType } from 'components/SnackBar/SnackBar';

function JobSchedule(): ReactElement {
  const { jobScheduleId } = useParams();
  const [snackBarMessage, setSnackBarMessage] = useState<string>();

  return (
    <Layout
      title={'Job Schedule'}
      snackBarMessage={snackBarMessage}
      showSnackBar={!!snackBarMessage}
      closeSnackBar={() => setSnackBarMessage(undefined)}
      snackBarType={SnackBarType.SUCCESS}
    >
      <JobScheduleSummarySetup
        id={jobScheduleId ? +jobScheduleId : undefined}
        setSnackBarMessage={setSnackBarMessage}
      />
    </Layout>
  );
}

export default JobSchedule;
