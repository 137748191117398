import BaseModal from 'components/Base/BaseModal';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import useJobs from 'hooks/useJobs.hook';
import React, { ReactElement, useEffect, useState } from 'react';
import { ChangeStatusValidation, JobStatus } from 'types';
import useAlertDetails from 'hooks/useAlertDetails.hook';

interface ChangeStatusModalProps {
  jobId?: number;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

function ChangeStatusModal({ open, onClose, onSave, jobId }: ChangeStatusModalProps): ReactElement {
  const [status, setStatus] = useState<JobStatus>();
  const [hasValidated, setHasValidated] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ChangeStatusValidation>();

  const { statuses, fetchStatuses, changeStatus, fetchJob, job, jobErrorMessage, jobErrorTitle, resetJobError } =
    useJobs();
  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError }],
  });

  const validate = (): boolean => {
    const errors: ChangeStatusValidation = {};

    let success = true;

    if (!status) {
      errors.status = 'Select a status';
      success = false;
    }
    setValidationErrors(errors);
    setHasValidated(true);
    return success;
  };

  const localChangeStatus = async () => {
    try {
      if (!validate() || !status || !job) return;
      await changeStatus({ jobId: job.id, statusId: status.id });
      onSave();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!open) return;
    if (!statuses.length) fetchStatuses();
  }, [open]);

  useEffect(() => {
    if (!hasValidated) return;
    validate();
  }, [status]);

  useEffect(() => {
    if (!jobId) return;
    fetchJob(jobId);
  }, [jobId]);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="Change Status"
      onConfirm={() => localChangeStatus()}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
    >
      <Dropdown
        hint={'Select a status'}
        objectKey={'status'}
        error={validationErrors?.status}
        items={statuses.map((type) => {
          return <DropdownItem key={type.id} id={type.id} value={type.status} onSelect={() => setStatus(type)} />;
        })}
        selectedValue={status}
      />
    </BaseModal>
  );
}

export default ChangeStatusModal;
