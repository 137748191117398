import Checkbox from 'components/Base/Checkbox';
import TextArea from 'components/Base/TextArea';
import TextField from 'components/Base/TextField';
import useJobs from 'hooks/useJobs.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import React, { ReactElement, useEffect, useState } from 'react';
import { CreateWindow, CreateEavestrough, CreateOther, JobSubType } from 'types';
import styles from './SubmitQuote.module.scss';
import QuoteItem from './components/QuoteItem';
import { getEavesExtras, getWindowExtras } from 'lib/jobs';
import Button from 'components/Base/Button';
import Layout from 'Base';
import { useParams } from 'react-router-dom';
import useReactOperations from 'hooks/useReactOperations.hook';

function SubmitQuotesModal(): ReactElement {
  const { jobId } = useParams();
  const [windowQuotes, setWindowQuotes] = useState<Array<CreateWindow>>([]);
  const [eavesQuotes, setEavesQuotes] = useState<Array<CreateEavestrough>>([]);
  const [otherQuotes, setOtherQuotes] = useState<Array<CreateOther>>([]);
  const [windowQuoteDetails, setWindowQuoteDetails] = useState<CreateWindow>();
  const [eavesQuoteDetails, setEavesQuoteDetails] = useState<CreateEavestrough>();
  const [otherQuoteDetails, setOtherQuoteDetails] = useState<CreateOther>();

  const { jobErrorMessage, jobErrorTitle, resetJobError, fetchJob, job, submitQuote } = useJobs();
  const { openAlert, closeAlertModal, alertMessage, alertTitle } = useAlertDetails({
    alerts: [{ title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError }],
  });

  const { goBack } = useReactOperations();

  const changeWindowDetails = (key: keyof CreateWindow, value: boolean | string | JobSubType | number | undefined) => {
    const temp = { [key]: value };
    setWindowQuoteDetails((prev) => ({
      ...prev,
      ...temp,
    }));
  };
  const changeEavesDetails = (
    key: keyof CreateEavestrough,
    value: boolean | string | JobSubType | number | undefined
  ) => {
    const temp = { [key]: value };
    setEavesQuoteDetails((prev) => ({
      ...prev,
      ...temp,
    }));
  };
  const changeOtherDetails = (key: keyof CreateOther, value: boolean | string | JobSubType | number | undefined) => {
    const temp = { [key]: value };
    setOtherQuoteDetails((prev) => ({
      ...prev,
      ...temp,
    }));
  };

  const updateWindowQuote = () => {
    const data = [...windowQuotes];
    if (windowQuoteDetails?.index === undefined) return;
    data[windowQuoteDetails.index] = windowQuoteDetails;
    setWindowQuotes(data);
    setWindowQuoteDetails(undefined);
  };
  const updateEavesQuote = () => {
    const data = [...eavesQuotes];
    if (eavesQuoteDetails?.index === undefined) return;
    data[eavesQuoteDetails.index] = eavesQuoteDetails;
    setEavesQuotes(data);
    setEavesQuoteDetails(undefined);
  };
  const updateOtherQuote = () => {
    const data = [...otherQuotes];
    if (otherQuoteDetails?.index === undefined) return;
    data[otherQuoteDetails.index] = otherQuoteDetails;
    setOtherQuotes(data);
    setOtherQuoteDetails(undefined);
  };

  const deleteWindowQuote = (index: number) => {
    const data = [...windowQuotes];
    data.splice(index, 1);
    setWindowQuotes(data);
  };

  const deleteEavesQuote = (index: number) => {
    const data = [...eavesQuotes];
    data.splice(index, 1);
    setEavesQuotes(data);
  };

  const deleteOtherQuote = (index: number) => {
    const data = [...otherQuotes];
    data.splice(index, 1);
    setOtherQuotes(data);
  };

  const onSubmit = async () => {
    if (!job) return;
    const data = {
      jobId: job.id,
      windowQuotes,
      eavesQuotes,
      otherQuotes,
    };
    await submitQuote(data);
    //goBack();
  };

  useEffect(() => {
    if (!job) return;
    const windowQuotes = job.windowQuotes?.map((quote, index) => {
      return {
        id: quote.id,
        index,
        subType: quote.subType,
        notes: quote.notes,
        description: quote.description,
        garage: quote.garage,
        doors: quote.doors,
        railings: quote.railings,
        tracks: quote.tracks,
        cranks: quote.cranks,
        solarPanels: quote.solarPanels,
        basement: quote.basement,
        skylights: quote.skylights,
        thirdFloor: quote.thirdFloor,
        frames: quote.frames,
        screens: quote.screens,
        sills: quote.sills,
      };
    });
    setWindowQuotes(windowQuotes || []);
    const eavesQuotes = job.eavesQuotes?.map((quote, index) => {
      return {
        id: quote.id,
        index,
        subType: quote.subType,
        notes: quote.notes,
        description: quote.description,
        secondBuilding: quote.secondBuilding,
        valleys: quote.valleys,
        flatRoofs: quote.flatRoofs,
      };
    });
    setEavesQuotes(eavesQuotes || []);
    const otherQuotes = job.otherQuotes?.map((quote, index) => {
      return {
        id: quote.id,
        index,
        notes: quote.notes,
        description: quote.description,
      };
    });
    setOtherQuotes(otherQuotes || []);
  }, [job]);

  useEffect(() => {
    if (!jobId) return;
    fetchJob(+jobId);
  }, []);

  return (
    <Layout
      title="Submit Quote"
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
    >
      <div className={styles.container}>
        <div className={styles.details}>
          {windowQuotes &&
            windowQuotes.map((quote, index) => {
              return (
                <div className={styles.centered} key={`window-${index}`}>
                  <QuoteItem
                    extras={getWindowExtras(quote)}
                    price={quote.price}
                    description={quote.subType?.title || ''}
                    onEdit={() => setWindowQuoteDetails({ ...quote })}
                    onDelete={() => deleteWindowQuote(index)}
                  />
                </div>
              );
            })}
          {eavesQuotes &&
            eavesQuotes.map((quote, index) => {
              return (
                <div className={styles.centered} key={`eaves-${index}`}>
                  <QuoteItem
                    extras={getEavesExtras(quote)}
                    price={quote.price}
                    description={quote.subType?.title || ''}
                    onEdit={() => setEavesQuoteDetails({ ...quote })}
                    onDelete={() => deleteEavesQuote(index)}
                  />
                </div>
              );
            })}
          {otherQuotes &&
            otherQuotes.map((quote, index) => {
              return (
                <div className={styles.centered} key={`other-${index}`}>
                  <QuoteItem
                    description={quote.description || ''}
                    price={quote.price}
                    onEdit={() => setOtherQuoteDetails({ ...quote })}
                    onDelete={() => deleteOtherQuote(index)}
                  />
                </div>
              );
            })}

          <div className={styles.centered}>
            <div className={styles.update}>
              {!!windowQuoteDetails && (
                <>
                  <div className={styles.checkboxContainer}>
                    <div className={styles.checkboxes}>
                      <Checkbox
                        label={'Basement'}
                        checked={!!windowQuoteDetails.basement}
                        onClick={(value: boolean) => changeWindowDetails('basement', value)}
                      />
                      <Checkbox
                        label={'Sills'}
                        checked={!!windowQuoteDetails.sills}
                        onClick={(value: boolean) => changeWindowDetails('sills', value)}
                      />
                      <Checkbox
                        label={'Railings'}
                        checked={!!windowQuoteDetails.railings}
                        onClick={(value: boolean) => changeWindowDetails('railings', value)}
                      />
                      <Checkbox
                        label={'Doors'}
                        checked={!!windowQuoteDetails.doors}
                        onClick={(value: boolean) => changeWindowDetails('doors', value)}
                      />
                      <Checkbox
                        label={'Frames'}
                        checked={!!windowQuoteDetails.frames}
                        onClick={(value: boolean) => changeWindowDetails('frames', value)}
                      />
                      <Checkbox
                        label={'Tracks'}
                        checked={!!windowQuoteDetails.tracks}
                        onClick={(value: boolean) => changeWindowDetails('tracks', value)}
                      />
                    </div>
                    <div className={styles.checkboxes}>
                      <Checkbox
                        label={'Garage'}
                        checked={!!windowQuoteDetails.garage}
                        onClick={(value: boolean) => changeWindowDetails('garage', value)}
                      />
                      <Checkbox
                        label={'Cranks'}
                        checked={!!windowQuoteDetails.cranks}
                        onClick={(value: boolean) => changeWindowDetails('cranks', value)}
                      />
                      <Checkbox
                        label={'Solar Panels'}
                        checked={!!windowQuoteDetails.solarPanels}
                        onClick={(value: boolean) => changeWindowDetails('solarPanels', value)}
                      />
                      <Checkbox
                        label={'Third Floor'}
                        checked={!!windowQuoteDetails.thirdFloor}
                        onClick={(value: boolean) => changeWindowDetails('thirdFloor', value)}
                      />
                      <Checkbox
                        label={'Skylights'}
                        checked={!!windowQuoteDetails.skylights}
                        onClick={(value: boolean) => changeWindowDetails('skylights', value)}
                      />
                    </div>
                  </div>
                  <div className={styles.priceContainer}>
                    <div className={styles.title}>Price:</div>
                    <div className={styles.price}>
                      <TextField
                        name={'price'}
                        type={'price'}
                        value={windowQuoteDetails.price}
                        setValue={(value) => changeWindowDetails('price', +value)}
                      />
                    </div>
                  </div>

                  <div className={styles.notes}>
                    <TextArea
                      name="windowNotes"
                      value={windowQuoteDetails.notes}
                      setValue={(value: string) => changeWindowDetails('notes', value)}
                    />
                  </div>
                  <div className={styles.saveBtnContainer}>
                    <div className={styles.addBtn}>
                      <Button title={'Save'} onClick={() => updateWindowQuote()} />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className={styles.centered}>
              {!!eavesQuoteDetails && (
                <>
                  <div className={styles.divisionContent}>
                    <div className={styles.titledRow}>
                      <div className={styles.rowTitle}>{eavesQuoteDetails.subType?.title}</div>
                    </div>
                    <div className={styles.titledRow}>
                      <div className={styles.rowTitle}>{eavesQuoteDetails.description}</div>
                    </div>
                    <div className={styles.checkboxContainer}>
                      <div className={styles.checkboxes}>
                        <Checkbox
                          label={'Second Building'}
                          checked={!!eavesQuoteDetails.secondBuilding}
                          onClick={(value: boolean) => changeEavesDetails('secondBuilding', value)}
                        />
                      </div>
                      <div className={styles.checkboxes}>
                        <Checkbox
                          label={'Flat Roofs'}
                          checked={!!eavesQuoteDetails.flatRoofs}
                          onClick={(value: boolean) => changeEavesDetails('flatRoofs', value)}
                        />
                      </div>
                      <div className={styles.checkboxes}>
                        <Checkbox
                          label={'Valleys'}
                          checked={!!eavesQuoteDetails.valleys}
                          onClick={(value: boolean) => changeEavesDetails('valleys', value)}
                        />
                      </div>
                    </div>
                    <div className={styles.titledRow}>
                      <div className={styles.rowTitle}>Price:</div>
                      <TextField
                        maxLength={60}
                        name={'price'}
                        type={'price'}
                        value={eavesQuoteDetails.price}
                        setValue={(value) => changeEavesDetails('price', +value)}
                      />
                    </div>

                    <div className={styles.notes}>
                      <TextArea
                        name="eavesNotes"
                        value={eavesQuoteDetails.notes}
                        setValue={(value: string) => changeEavesDetails('notes', value)}
                      />
                    </div>
                    <div className={styles.saveBtnContainer}>
                      <div className={styles.addBtn}>
                        <Button title={'Save'} onClick={() => updateEavesQuote()} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className={styles.centered}>
              {!!otherQuoteDetails && (
                <div className={styles.divisionContent}>
                  <div className={styles.titledRow}>
                    <div className={styles.rowTitle}>{otherQuoteDetails.description}</div>
                  </div>
                  <div className={styles.titledRow}>
                    <div className={styles.rowTitle}>Price:</div>
                    <TextField
                      maxLength={60}
                      name={'price'}
                      type={'price'}
                      value={otherQuoteDetails.price}
                      setValue={(value) => changeOtherDetails('price', +value)}
                    />
                  </div>
                  <div className={styles.notes}>
                    <TextArea
                      name="otherNotes"
                      value={otherQuoteDetails.notes}
                      setValue={(value: string) => changeOtherDetails('notes', value)}
                      hint={'Notes'}
                    />
                  </div>
                  <div className={styles.saveBtnContainer}>
                    <div className={styles.addBtn}>
                      <Button title={'Save'} onClick={() => updateOtherQuote()} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.finalButtons}>
          <div className={styles.addBtnContainer}>
            <div className={styles.addBtn}>
              <Button title={'Cancel'} onClick={() => goBack()} border={false} />
            </div>
          </div>
          <div className={styles.addBtnContainer}>
            <div className={styles.addBtn}>
              <Button title={'Submit'} onClick={onSubmit} border={false} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SubmitQuotesModal;
