import React, { ReactElement, useEffect, useState } from 'react';
import styles from './Unsubscribe.module.scss';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { ClientProfile, EmailContact } from 'types';
import useAccounts from 'hooks/useAccounts.hook';
import Button from 'components/Base/Button';
import useClients from 'hooks/useClients.hooks';

function Unsubscribe(): ReactElement {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jwtToken = queryParams.get('jwt') || undefined;
  const clientProfile: ClientProfile = jwtDecode(jwtToken || '');

  let topic = '';

  if (clientProfile.unsubscribeJobReminders) topic = 'upcoming job reminders';

  const [declined, setDeclined] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [allowDesiredAction, setAllowDesiredAction] = useState<boolean>(false);
  const [email, setEmail] = useState<EmailContact>();
  const { fetchCompanySquareLogo, companySquareLogoSignedUrl } = useAccounts();
  const { unsubscribeEmail, getClient, client, fetchEmail } = useClients();

  const getEmail = async () => {
    const email = await fetchEmail(clientProfile.emailId, jwtToken);
    setEmail(email);
  };

  const unsubscribe = async () => {
    setConfirm(true);
    const params = {
      jobReminders: clientProfile.unsubscribeJobReminders,
    };
    await unsubscribeEmail(clientProfile.id, params, jwtToken);
    getClient(clientProfile.id, jwtToken);
  };

  useEffect(() => {
    if (!client) return;
    let allowDesiredAction = false;

    if (clientProfile.unsubscribeJobReminders) allowDesiredAction = !!client?.allowJobReminders;
    setAllowDesiredAction(allowDesiredAction);
    setEmail(client.emails.find((email) => email.main));
  }, [client]);

  useEffect(() => {
    fetchCompanySquareLogo(jwtToken);
    getClient(clientProfile.id, jwtToken);
    getEmail();
  }, []);

  return clientProfile.pages.unsubscribe ? (
    client ? (
      <div className={styles.container}>
        <div className={styles.content}>
          {companySquareLogoSignedUrl && <img src={companySquareLogoSignedUrl} alt="" />}

          {declined && (
            <div>{`You will still receive ${topic} to ${email?.email}.  You can still choose to unsubscribe in the future.`}</div>
          )}
          {!declined && !confirm && !allowDesiredAction && (
            <div>{`You are already unsubscribed from receiving ${topic} to ${email?.email}.`}</div>
          )}
          {confirm && !allowDesiredAction && <div>{`You will no longer receive ${topic} to ${email?.email}.`}</div>}

          {!!(!declined && allowDesiredAction) && (
            <div>
              <div
                className={styles.confirmMessage}
              >{`Are you sure you would like to unsubcribe from receiving ${topic} to ${email?.email}?`}</div>
              <div
                className={styles.warningMessage}
              >{`This will prevent all future communication to ${email?.email} with regards to ${topic}?`}</div>

              <div className={styles.buttons}>
                <Button title="NO" onClick={() => setDeclined(true)} />
                <Button title="YES" onClick={unsubscribe} />
              </div>
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className={styles.info}>Fetching Information...</div>
    )
  ) : (
    <div className={styles.info}>Error Finding Page</div>
  );
}

export default Unsubscribe;
