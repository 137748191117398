import styles from './Team.module.scss';
import Layout from 'Base';
import InfoCard from 'components/InfoCard';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useTeams from 'hooks/useTeams.hook';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { dateFormats, formatDate, sqlToday } from 'lib/date';
import AddEditTeamModal from 'components/Modals/AddEditTeamModal';
import { changeStyleProperty } from 'lib/reactOperations';
import ColorIndicator from 'pages/Teams/components/ColorIndicator';
import classNames from 'classnames';
import JobTypeIndicators from 'components/JobTypeIndicators';
import { formatPrice } from 'lib/formatting';
import useReactOperations from 'hooks/useReactOperations.hook';
import useJobSchedules from 'hooks/useJobSchedules.hook';

function Team(): ReactElement {
  const { teamId } = useParams();
  const id = teamId ? +teamId : undefined;

  const [openEditTeam, setOpenEditTeam] = useState<boolean>(false);

  const { team, fetchTeam, teamErrorMessage, teamErrorTitle, resetTeamError, teamLoading } = useTeams();

  const { navigateToAddressPage, navigateToClientPage, navigateToJobPage } = useReactOperations();

  const { jobSchedulesLoading, fetchJobSchedules, jobSchedules } = useJobSchedules();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: teamErrorTitle, message: teamErrorMessage, reset: resetTeamError }],
  });

  const onTeamUpdate = () => {
    if (!id) return;
    fetchTeam(id);
  };

  useEffect(() => {
    if (!team) return;
    changeStyleProperty('team-colour', '--teamColour', team.hexCode || '');
  }, [team]);

  useEffect(() => {
    if (!id) return;
    fetchJobSchedules({ teamId: id, date: sqlToday() });
    fetchTeam(id);
  }, []);

  return (
    <Layout
      title={team?.name || ''}
      alertMessage={alertMessage}
      openAlert={openAlert}
      alertTitle={alertTitle}
      onAlertClose={closeAlertModal}
    >
      <div className={styles.container}>
        <div className={styles.summaries}>
          <InfoCard title={'Summary'} onEdit={() => setOpenEditTeam(true)} loading={teamLoading}>
            <table>
              <thead>
                <tr>
                  <th>Members</th>
                  <th>Data</th>
                  <th>Office Info</th>
                </tr>
              </thead>
              <tbody>
                {!teamLoading && (
                  <tr>
                    <td className={styles.center}>
                      {team?.members.map((member) => (
                        <div key={member.fullName}>{member.fullName}</div>
                      ))}
                    </td>
                    <td className={styles.center}>
                      <div>
                        <ColorIndicator id={`${team?.id}`} colorHex={team?.hexCode} />
                      </div>

                      <div>Hourly Production: {team?.hourlyProduction}</div>
                    </td>
                    <td className={styles.center}>
                      <div>{`Created By: ${team?.createdBy}`}</div>
                      <div>{`Created At: ${formatDate(team?.createdAt, dateFormats.fullPretty)}`}</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </InfoCard>
          <InfoCard title={"Today's Jobs"} loading={jobSchedulesLoading}>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Schedule Time</th>
                  <th>Actual Time</th>
                  <th>Address</th>
                  <th>Client</th>
                  <th>Types</th>
                  <th>Price</th>
                  <th>Complete</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {jobSchedules.map((schedule) => {
                  return (
                    <tr key={schedule.id}>
                      <td className={styles.date}>{formatDate(schedule.date, dateFormats.abbreviatedPretty)}</td>
                      <td className={styles.date}>
                        {schedule.startTime} - {schedule.endTime}
                      </td>
                      <td className={styles.date}>
                        {schedule.timeStarted} - {schedule.timeFinished}
                      </td>
                      <td
                        className={classNames(styles.center, styles.clickable)}
                        onClick={() => navigateToClientPage(schedule.job.address.id)}
                      >
                        {schedule.job.client.fullName}
                      </td>
                      <td
                        className={classNames(styles.center, styles.clickable)}
                        onClick={() => navigateToAddressPage(schedule.job.address.id)}
                      >
                        {schedule.job.address?.shortAddress}
                      </td>
                      <td className={styles.center}>
                        <JobTypeIndicators job={schedule.job} useFillers={false} />
                      </td>
                      <td className={styles.center}>
                        {schedule.job.hasWork ? formatPrice(schedule.job.subtotal, true) : ''}
                      </td>
                      <td className={styles.center}>{schedule.job.completed ? 'YES' : ''}</td>
                      <td
                        className={classNames(styles.center, styles.clickable)}
                        onClick={() => navigateToJobPage(schedule.job.id)}
                      >
                        View
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </InfoCard>
        </div>
      </div>
      <AddEditTeamModal
        onClose={() => setOpenEditTeam(false)}
        onSave={onTeamUpdate}
        open={openEditTeam}
        teamId={team?.id}
      />
    </Layout>
  );
}

export default Team;
