import { ADDRESSES } from 'lib/routes';
import {
  Address,
  AddressSearch,
  Country,
  CreateAddress,
  CreatedAddress,
  FoundAddress,
  Region,
  VerifiedAddress,
} from 'types';
import { BaseApi } from 'api';

export const fetchAddresses = async (params: AddressSearch): Promise<Array<Address>> => {
  return await BaseApi.get(ADDRESSES, params);
};

export const findAddresses = async (searchAddress: string): Promise<Array<FoundAddress>> => {
  return await BaseApi.get(`${ADDRESSES}/findAddresses`, { searchAddress });
};

export const verify = async (searchAddress: string): Promise<VerifiedAddress> => {
  return await BaseApi.get(`${ADDRESSES}/verify`, { searchAddress });
};

export const fetchCountries = async (): Promise<Array<Country>> => {
  return await BaseApi.get(`${ADDRESSES}/fetchCountries`);
};

export const fetchRegions = async (countryId: number): Promise<Array<Region>> => {
  return await BaseApi.get(`${ADDRESSES}/fetchRegions`, { countryId });
};

export const fetchAddress = async (id: number): Promise<Address> => {
  return await BaseApi.get(`${ADDRESSES}/${id}`);
};

export const create = async (body: CreateAddress): Promise<CreatedAddress> => {
  return await BaseApi.post(`${ADDRESSES}`, {
    ...body,
  });
};

export const update = async (id: number, body: CreateAddress): Promise<Array<Address>> => {
  return await BaseApi.put(`${ADDRESSES}/${id}`, body);
};

export const del = async (id: number): Promise<void> => {
  return await BaseApi.del(`${ADDRESSES}/${id}`);
};
