import { BaseApi } from 'api';
import { ACCOUNTS } from 'lib/routes';
import { Account, CreateAccount } from 'types';

export const fetchCompanySquareLogo = async (token?: string): Promise<string> => {
  return await BaseApi.get(`${ACCOUNTS}/squareLogo`, undefined, token);
};

export const fetchInvoiceQuoteLogoSignedUrl = async (): Promise<string> => {
  //return await BaseApi.get(`${ACCOUNTS}/invoiceQuoteLogo`);
  return '';
};

export const fetchAccount = async (): Promise<Account> => {
  return await BaseApi.get(`${ACCOUNTS}`);
};

export const create = async (data: CreateAccount): Promise<void> => {
  return await BaseApi.post(`${ACCOUNTS}`, data);
};

export const update = async (data: CreateAccount): Promise<void> => {
  return await BaseApi.put(`${ACCOUNTS}`, data);
};
