import AddEditBase from 'components/AddEditSetup/AddEditBase';
import styles from './AddEditJobModal.module.scss';
import Autocomplete from 'components/Base/Autocomplete';
import BaseModal from 'components/Base/BaseModal';
import HorizontalAddEditChild from 'components/AddEditSetup/HorizontalAddEditChild';
import HorizontalAddEditRow from 'components/AddEditSetup/HorizontalAddEditRow';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Address,
  CreateWindow,
  CreateWindowValidation,
  CreateEavesValidation,
  CreateEavestrough,
  CreateOtherValidation,
  CreateOther,
  CreateJobValidation,
  CreatedJob,
  Job,
  CreateJob,
  JobStatus,
} from 'types';
import { MAX_DESCRIPTION_SIZE, MAX_NOTES_SIZE } from 'lib/jobs';
import useDebounce from 'hooks/useDebounce.hooks';
import useJobs from 'hooks/useJobs.hook';
import useAddresses from 'hooks/useAddresses.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import ToggleButton from 'components/Base/ToggleButton';
import AddEditJobSetup from 'components/AddEditSetup/AddEditJobSetup';
import AddEditQuoteSetup from 'components/AddEditSetup/AddEditQuoteSetup';
import TextAreaCountdown from 'components/TextAreaCountdown';
import TextFieldCountdown from 'components/TextFieldCountdown';
import useReactOperations from 'hooks/useReactOperations.hook';
import MultiChoiceModal, { MultiChoiceButton } from '../MultiChoiceModal';
import { dateFormats, formatDate } from 'lib/date';
import JobTypeIndicators from 'components/JobTypeIndicators';
import FindJobScheduleOpeningsModal from '../FindJobScheduleOpeningsModal';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';

interface AddEditJobModalProps {
  open: boolean;
  jobId?: number;
  addressId?: number;
  onClose: () => void;
  onSave: (jobId?: number) => void;
  onDelete?: () => void;
}

const initalValues = { includeTax: true, needsInvoice: true, includeQuoteTax: true };

function AddEditJobModal({ open, onClose, onSave, jobId, addressId, onDelete }: AddEditJobModalProps): ReactElement {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [jobSearchFilter, setJobSearchFilter] = useState<string>('');
  const [selectedAddress, setSelectedAddress] = useState<Address>();
  const [selectedJob, setSelectedJob] = useState<Job>();
  const [createEditJob, setCreateEditJob] = useState<CreateJob>({ ...initalValues });

  const [errors, setErrors] = useState<CreateJobValidation>();
  const [validated, setValidated] = useState<boolean>(false);
  const [showQuotes, setShowQuotes] = useState<boolean>(false);
  const [openCreateJobMultiSelectModal, setOpenCreateJobMultiSelectModal] = useState<boolean>(false);

  const [createdJob, setCreatedJob] = useState<CreatedJob>();
  const [openJobScheduleOpeningsModal, setOPenJobScheduleOpeningsModal] = useState<boolean>(false);

  const debounceSearch = useDebounce(searchFilter, 300);
  const debounceJobSearch = useDebounce(jobSearchFilter, 300);

  const {
    jobErrorMessage,
    jobErrorTitle,
    windowSubTypes,
    eavesSubTypes,
    statuses,
    job,
    jobs,
    fetchJobs,
    fetchStatuses,
    resetJobError,
    fetchJobSubTypes,
    fetchJob,
    createJob,
    updateJob,
    deleteJob,
  } = useJobs();
  const { navigateToJobsPage } = useReactOperations();
  const {
    fetchAddresses,
    fetchAddress,
    address,
    addresses,
    resetAddressError,
    addressErrorMessage,
    addressErrorTitle,
  } = useAddresses();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [
      { title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError },
      { title: addressErrorTitle, message: addressErrorMessage, reset: resetAddressError },
    ],
  });

  const validateWindows = (errors: CreateWindowValidation, details?: CreateWindow, isQuote?: boolean) => {
    if (!details && !isQuote) return true;
    let validated = true;
    if (!details?.description) {
      errors.description = 'Please provide a description';
      validated = false;
    }
    if (!details?.subType) {
      errors.subType = 'Please provide a cleaning type';
      validated = false;
    }
    if (!isQuote && !details?.price) {
      errors.price = 'Please provide a price';
      validated = false;
    }

    return validated;
  };
  const validateEaves = (errors: CreateEavesValidation, details?: CreateEavestrough, isQuote?: boolean) => {
    if (!details && !isQuote) return true;
    let validated = true;
    if (!details?.description) {
      errors.description = 'Please provide a description';
      validated = false;
    }
    if (!details?.subType) {
      errors.subType = 'Please provide a cleaning type';
      validated = false;
    }
    if (!isQuote && !details?.price) {
      errors.price = 'Please provide a price';
      validated = false;
    }
    return validated;
  };
  const validateOther = (errors: CreateOtherValidation, details?: CreateOther, isQuote?: boolean) => {
    if (!details && !isQuote) return true;
    let validated = true;
    if (!details?.description) {
      errors.description = 'Please provide a description';
      validated = false;
    }

    if (!isQuote && !details?.price) {
      errors.price = 'Please provide a price';
      validated = false;
    }
    return validated;
  };

  const validate = () => {
    let success = true;
    const errors: CreateJobValidation = {};
    const windowErrors: CreateWindowValidation = {};
    const eavesErrors: CreateEavesValidation = {};
    const otherErrors: CreateOtherValidation = {};

    setValidated(true);

    if (
      !createEditJob.windowDetails &&
      !createEditJob.eavesDetails &&
      !createEditJob.otherDetails &&
      !createEditJob.windowQuotes &&
      !createEditJob.eavesQuotes &&
      !createEditJob.otherQuotes
    ) {
      success = false;
      errors.missingInfo = 'Please add a quote or a job';
    }

    if (!createEditJob.description) {
      errors.description = 'Please provide a job title';
      success = false;
    }

    if (!selectedAddress) {
      errors.address = 'Please select an address';
      success = false;
    }

    if (!createEditJob.paymentAmount !== !createEditJob.paymentType) {
      errors.payment = 'Please provide type and amount';
      success = false;
    }

    if (!validateWindows(windowErrors, createEditJob.windowDetails)) {
      success = false;
      errors.windowErrors = windowErrors;
    }

    if (!validateEaves(eavesErrors, createEditJob.eavesDetails)) {
      success = false;
      errors.eavesErrors = eavesErrors;
    }

    if (!validateOther(otherErrors, createEditJob.otherDetails)) {
      success = false;
      errors.otherErrors = otherErrors;
    }

    setErrors(success ? undefined : errors);
    return success;
  };

  const onLocalSave = async () => {
    try {
      if (!validate()) return;

      if (jobId) {
        await updateJob(jobId, createEditJob);
        onSave();
      } else {
        const createdJob = await createJob(createEditJob);
        setCreatedJob(createdJob);
        setOpenCreateJobMultiSelectModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetData = () => {
    setCreateEditJob({ ...initalValues });
    setSearchFilter('');
    setJobSearchFilter('');
    setSelectedAddress(undefined);
    setErrors(undefined);
    setValidated(false);
  };

  const onLocalClose = () => {
    resetData();
    onClose();
  };

  const setOfficeNotes = (officeNotes: string) => {
    setCreateEditJob((prev) => ({
      ...prev,
      officeNotes,
    }));
  };

  const setJobDescription = (description: string) => {
    setCreateEditJob((prev) => ({
      ...prev,
      description,
    }));
  };

  const setStatus = (status?: JobStatus) => {
    setCreateEditJob((prev) => ({
      ...prev,
      status,
    }));
  };

  const changeAddress = (address: Address, resetDetails: boolean) => {
    if (resetDetails) {
      setCreateEditJob((prev) => ({
        includeTax: prev.includeTax,
        needsInvoice: prev.needsInvoice,
        addressId: address.id,
        description: address.fullAddress,
        includeQuoteTax: prev.includeQuoteTax,
        status: statuses[0],
      }));
      setJobSearchFilter('');
      setSearchFilter(address.fullAddress);
    } else {
      setCreateEditJob((prev) => ({
        ...prev,
        addressId: address.id,
        description: address.fullAddress,
      }));
    }

    setSelectedAddress(address);
  };

  const onLocalDelete = async () => {
    try {
      if (!jobId) return;
      await deleteJob(jobId);
      if (onDelete) {
        onDelete();
      } else {
        navigateToJobsPage();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const populateFieldsFromJob = (job: Job, includeId: boolean) => {
    if (job.address?.id) fetchAddress(job.address?.id);
    setCreateEditJob({
      id: includeId ? job?.id : undefined,
      addressId: job.address?.id,
      createdAt: job.createdAt,
      needsInvoice: job.needsInvoice,
      windowDetails: job.windowDetails
        ? { ...job.windowDetails, id: includeId ? job.windowDetails.id : undefined }
        : undefined,
      windowQuotes: job.windowQuotes
        ? job.windowQuotes.map((quote, index) => {
            return { ...quote, index };
          })
        : undefined,
      eavesDetails: job.eavesDetails
        ? { ...job.eavesDetails, id: includeId ? job.eavesDetails.id : undefined }
        : undefined,
      eavesQuotes: job.eavesQuotes
        ? job.eavesQuotes.map((quote, index) => {
            return { ...quote, index };
          })
        : undefined,
      otherDetails: job.otherDetails
        ? { ...job.otherDetails, id: includeId ? job.otherDetails.id : undefined }
        : undefined,
      otherQuotes: job.otherQuotes
        ? job.otherQuotes.map((quote, index) => {
            return { ...quote, index };
          })
        : undefined,
      description: job.description,
      officeNotes: job.officeNotes,
      includeTax: job.includeTax,
      includeQuoteTax: job.includeQuoteTax,
    });
  };

  useEffect(() => {
    if (!job) return;
    populateFieldsFromJob(job, true);
  }, [job]);

  useEffect(() => {
    if (!selectedJob) return;
    setSearchFilter('');
    setJobSearchFilter(selectedJob.description || '');
    populateFieldsFromJob(selectedJob, false);
  }, [selectedJob]);

  useEffect(() => {
    if (!address) return;
    if (!jobId) {
      setJobDescription(address.fullAddress);
    }
    setSelectedAddress(address);
    changeAddress(address, false);
  }, [address]);

  useEffect(() => {
    if (!open) return;
    fetchAddresses({
      searchFilter: debounceSearch,
      limit: 50,
      hasClient: true,
    });
  }, [debounceSearch]);

  useEffect(() => {
    if (!statuses.length) return;
    setStatus(statuses[0]);
  }, [statuses]);

  useEffect(() => {
    if (!open) return;

    fetchJobs({
      searchFilter: debounceJobSearch,
      limit: 20,
      completed: true,
    });
  }, [debounceJobSearch]);

  useEffect(() => {
    if (!open) return;
    if (jobId) {
      fetchJob(jobId);
    }
    if (addressId) {
      fetchAddress(addressId);
    }
    fetchJobSubTypes();
    fetchStatuses();
  }, [open]);

  useEffect(() => {
    if (!validated) return;
    validate();
  }, [createEditJob]);

  const getNextStepButtons = (): Array<MultiChoiceButton> => {
    const buttons: Array<MultiChoiceButton> = [];
    if (!createdJob) return buttons;

    if (createdJob) {
      buttons.push({
        label: 'Create Job Schedule',
        onClick: () => {
          setOpenCreateJobMultiSelectModal(false);
          setOPenJobScheduleOpeningsModal(true);
        },
      });
    }

    return buttons;
  };

  return (
    <>
      <BaseModal
        open={open}
        title={createEditJob.isQuote ? 'Create Quote' : 'Create Job'}
        onConfirm={onLocalSave}
        onClose={onLocalClose}
        alertMessage={alertMessage}
        alertTitle={alertTitle}
        openAlert={openAlert}
        confirmTitle={(createEditJob.id ? 'Update' : 'Save ') + (createEditJob.isQuote ? 'Quote' : 'Job')}
        onAlertClose={closeAlertModal}
        onDelete={jobId ? onLocalDelete : undefined}
        deletePopupMessage={`Are you sure you would like to delete job:  ${job?.description}? and associated job schedules?`}
        deletePopupTitle={'Delete Job'}
      >
        <ToggleButton
          leftTitle={'Work'}
          rightTitle={'Quote'}
          value={showQuotes}
          setRightSelected={(isQuote: boolean) => {
            setShowQuotes(isQuote);
          }}
        />
        {<p className={styles.errorMessage}>{errors?.missingInfo}</p>}

        <AddEditBase>
          {!!(!jobId && !addressId) && (
            <HorizontalAddEditRow>
              <HorizontalAddEditChild fullWidth>
                <Autocomplete
                  values={addresses.map((address) => {
                    return {
                      object: address,
                      rowDisplay: (
                        <div className={styles.customDropdown}>
                          <div className={styles.address}>{address.fullAddress}</div>
                          <div>{address.client?.fullName}</div>
                        </div>
                      ),
                    };
                  })}
                  onSelect={(address: Address) => changeAddress(address, true)}
                  searchFilter={searchFilter}
                  setSearchFilter={setSearchFilter}
                  onClearField={() => setSearchFilter('')}
                  hint={'Search Addresses, Clients or Emails'}
                  error={errors?.address}
                />
              </HorizontalAddEditChild>
              <HorizontalAddEditChild fullWidth>
                <Autocomplete
                  values={jobs.map((job) => {
                    return {
                      object: job,
                      rowDisplay: (
                        <div className={styles.customDropdown}>
                          <div>{formatDate(job.startDate, dateFormats.abbreviatedPretty)}</div>
                          <div className={styles.address}>{job.address.fullAddress}</div>
                          <div>
                            <JobTypeIndicators job={job} useFillers={false} />
                          </div>
                        </div>
                      ),
                    };
                  })}
                  onSelect={(job: Job) => setSelectedJob(job)}
                  searchFilter={jobSearchFilter}
                  setSearchFilter={setJobSearchFilter}
                  onClearField={() => setJobSearchFilter('')}
                  hint={'Search Previous Jobs By Addresses, Clients or Emails'}
                />
              </HorizontalAddEditChild>
            </HorizontalAddEditRow>
          )}
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <div className={styles.clientInfo}>
                <div className={styles.client}>{selectedAddress?.client.fullName}</div>
                <div className={styles.address}>{selectedAddress?.fullAddress}</div>
              </div>
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <div className={styles.description}>
                <div className={styles.title}>Description</div>
                <TextFieldCountdown
                  value={createEditJob.description}
                  setValue={setJobDescription}
                  maxLength={MAX_DESCRIPTION_SIZE}
                  hint={'Job Title'}
                  error={errors?.description}
                />
              </div>
            </HorizontalAddEditChild>
            <HorizontalAddEditChild>
              <div className={styles.status}>
                <Dropdown
                  items={statuses.map((status) => {
                    return (
                      <DropdownItem
                        key={status.id}
                        id={status.id}
                        value={status.status}
                        onSelect={() => setStatus(status)}
                      />
                    );
                  })}
                  selectedValue={statuses?.find((status) => status.id === createEditJob.status?.id)}
                  objectKey="status"
                />
              </div>
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <div className={styles.title}>Office Notes</div>
              <div className={styles.officeNotes}>
                <TextAreaCountdown
                  value={createEditJob.officeNotes}
                  maxLength={MAX_NOTES_SIZE}
                  hint={'Office Notes'}
                  setValue={setOfficeNotes}
                />
              </div>
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>

          {showQuotes ? (
            <AddEditQuoteSetup
              createEditJob={createEditJob}
              setCreateEditJob={setCreateEditJob}
              windowSubTypes={windowSubTypes}
              eavesSubTypes={eavesSubTypes}
              job={job}
              validateWindows={validateWindows}
              validateEaves={validateEaves}
              validateOther={validateOther}
            />
          ) : (
            <AddEditJobSetup
              createEditJob={createEditJob}
              setCreateEditJob={setCreateEditJob}
              windowSubTypes={windowSubTypes}
              eavesSubTypes={eavesSubTypes}
              job={job || selectedJob}
              windowErrors={errors?.windowErrors}
              eavesErrors={errors?.eavesErrors}
              otherErrors={errors?.otherErrors}
              paymentError={errors?.payment}
              address={selectedAddress}
              includePreviousWork={!addressId && !job && !selectedJob}
            />
          )}
        </AddEditBase>
      </BaseModal>
      <FindJobScheduleOpeningsModal
        open={openJobScheduleOpeningsModal}
        onClose={() => {
          onSave();
          setOPenJobScheduleOpeningsModal(false);
        }}
        onSave={() => {
          onSave();
          setOPenJobScheduleOpeningsModal(false);
        }}
        jobId={createdJob?.id}
      />
      <MultiChoiceModal
        title={'Next Step'}
        open={openCreateJobMultiSelectModal}
        onClose={() => {
          setOpenCreateJobMultiSelectModal(false);
          onSave();
          onLocalClose();
        }}
        message={'Choose An Action'}
        buttons={getNextStepButtons()}
      />
    </>
  );
}

export default AddEditJobModal;
