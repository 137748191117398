import React, { ReactElement } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import styles from './TextSearch.module.scss';

interface TextSearchProps {
  value: string;
  onChange: (search: string) => void;
}

function TextSearch({ onChange, value }: TextSearchProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <input type="text" placeholder="Search" onChange={(event) => onChange(event.target.value)} value={value} />
        <div className={styles.iconDiv}>
          <SearchIcon className={styles.icon} />
        </div>
      </div>
    </div>
  );
}

export default TextSearch;
