import React, { ReactElement, useEffect } from 'react';
import styles from './ColourDropdownItem.module.scss';
import { changeStyleProperty } from 'lib/reactOperations';
import { Colour } from 'types';

interface DropdownItemProps {
  id: number;
  item: Colour;
  onSelect?: () => void;
}

function DropdownItem({ id, item, onSelect }: DropdownItemProps): ReactElement {
  useEffect(() => {
    changeStyleProperty(item.hexCode, '--colour', item.hexCode);
  }, []);
  return <li id={item.hexCode} key={id} className={styles.item} onClick={onSelect}></li>;
}

export default DropdownItem;
