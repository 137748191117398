import React, { ReactElement, useEffect, useState } from 'react';
import TextField from 'components/Base/TextField';
import { Country, CreateAddress, CreateEditAddressValidation, Region } from 'types';
import styles from './AddEditAddressSetup.module.scss';
import useAddresses from 'hooks/useAddresses.hook';
import useDebounce from 'hooks/useDebounce.hooks';
import NavigationLink from 'components/Base/NavigationLink';
import { formatPostalCode } from 'lib/formatting';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import Autocomplete from 'components/Base/Autocomplete';

interface AddEditAddressSetupProps {
  address?: CreateAddress;
  setAddress: React.Dispatch<React.SetStateAction<CreateAddress | undefined>>;
  validation?: CreateEditAddressValidation;
  countries: Array<Country>;
  regions: Array<Region>;
  setHasExistingAddresses?: (hasExistingAddresses: boolean) => void;
  fetchRegions: (countryId: number) => void;
}

function AddEditAddressSetup({
  address,
  validation,
  countries,
  regions,
  fetchRegions,
  setAddress,
  setHasExistingAddresses,
}: AddEditAddressSetupProps): ReactElement {
  const [country, setCountry] = useState<Country>();
  const [region, setRegion] = useState<Region>();
  const [searchAddress, setSearchAddress] = useState<string>('');

  const serachAddressDebounce = useDebounce(searchAddress);
  const { addresses, fetchAddresses, findAddresses, foundAddresses, verify, verifiedAddress } = useAddresses();

  const changeCreateAddress = (key: keyof CreateAddress, value: number | string | undefined) => {
    setAddress((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    const country = countries.find((country) => country.name.toLowerCase() === verifiedAddress?.country?.toLowerCase());
    setCountry(country);
    setAddress((prev) => {
      return {
        ...prev,
        streetName: verifiedAddress?.streetName,
        streetNumber: verifiedAddress?.streetNumber,
        postalCode: verifiedAddress?.postalCode,
        city: verifiedAddress?.city,
        latitude: verifiedAddress?.latitude,
        longitude: verifiedAddress?.longitude,
        unit: verifiedAddress?.unit,
        countryId: country?.id,
      };
    });

    if (verifiedAddress) setSearchAddress(verifiedAddress.formatted || '');
  }, [verifiedAddress]);
  useEffect(() => {
    if (!setHasExistingAddresses) return;
    setHasExistingAddresses(!!addresses.length);
  }, [addresses]);

  useEffect(() => {
    if (!serachAddressDebounce) return;
    findAddresses(serachAddressDebounce);
  }, [serachAddressDebounce]);

  useEffect(() => {
    fetchAddresses({
      unit: address?.unit,
      streetName: address?.streetName,
      streetNumber: address?.streetNumber,
      postalCode: address?.postalCode,
      excludeId: address?.id,
      searchFilter: verifiedAddress ? undefined : searchAddress,
      limit: 10,
    });
  }, [serachAddressDebounce, address]);

  useEffect(() => {
    if (!country) return;
    fetchRegions(country.id);
  }, [country]);

  useEffect(() => {
    const country = countries.find((country) => country.id === address?.countryId);
    setCountry(country);
  }, [countries, address]);

  useEffect(() => {
    let region;
    if (verifiedAddress) {
      region = regions.find((region) => region.name.toLowerCase() === verifiedAddress.region?.toLowerCase());
      if (!address?.regionId || (address.regionId && address.regionId !== region?.id)) {
        changeCreateAddress('regionId', region?.id);
      }
    } else if (address?.regionId) {
      region = regions.find((region) => region.id === address.regionId);
    }
    setRegion(region);
  }, [regions, address]);

  return (
    <div className={styles.container}>
      <div className={styles.verify}>
        <Autocomplete
          values={foundAddresses.map((address) => {
            return {
              object: address,
              rowDisplay: <div>{address.name}</div>,
            };
          })}
          onSelect={(address) => verify(address.name)}
          searchFilter={searchAddress}
          setSearchFilter={setSearchAddress}
          hint={'Search'}
          onClearField={() => setSearchAddress('')}
        />
      </div>

      <div className={styles.manual}>
        <div className={styles.row}>
          <div className={styles.unit}>
            <TextField
              value={address?.unit}
              setValue={(value) => changeCreateAddress('unit', value)}
              name={'unit'}
              hint={'Unit'}
            />
          </div>
          <div className={styles.number}>
            <TextField
              value={address?.streetNumber}
              setValue={(value) => changeCreateAddress('streetNumber', value)}
              name={'streetNumber'}
              hint={'Number'}
              error={validation?.number}
              disabled={!!verifiedAddress?.streetNumber}
            />
          </div>
          <TextField
            value={address?.streetName}
            setValue={(value) => changeCreateAddress('streetName', value)}
            name={'streetName'}
            hint={'Street Name'}
            error={validation?.name}
            hasError={true}
            disabled={!!verifiedAddress?.streetName}
          />
        </div>

        <div className={styles.row}>
          <TextField
            value={address?.city}
            setValue={(value) => changeCreateAddress('city', value)}
            name={'city'}
            hint={'City'}
            maxLength={50}
            error={validation?.city}
            hasError={true}
            disabled={!!verifiedAddress?.city}
          />
        </div>

        <div className={styles.row}>
          <TextField
            value={formatPostalCode(address?.postalCode)}
            setValue={(value) => changeCreateAddress('postalCode', value)}
            name={'postalCode'}
            hint={'Postal Code'}
            maxLength={7}
            error={validation?.postalCode}
            hasError={true}
            disabled={!!verifiedAddress?.postalCode}
          />
          <Dropdown
            hint={'Country'}
            disabled={!!verifiedAddress?.country}
            items={countries.map((country) => {
              return (
                <DropdownItem
                  key={country.id}
                  id={country.id}
                  value={<div>{country.name}</div>}
                  onSelect={() => setCountry(country)}
                />
              );
            })}
            error={validation?.country}
            selectedValue={country?.name}
          />
          <Dropdown
            hint={'Region'}
            disabled={!!verifiedAddress?.region}
            items={regions.map((region) => {
              return (
                <DropdownItem
                  key={region.id}
                  id={region.id}
                  value={<div>{region.name}</div>}
                  onSelect={() => setRegion(region)}
                />
              );
            })}
            error={validation?.region}
            selectedValue={region?.name}
          />
        </div>
        <div className={styles.row}>
          <TextField
            value={address?.latitude}
            setValue={(value) => changeCreateAddress('latitude', value)}
            name={'latitude'}
            hint={'Longitude'}
            error={validation?.latitude}
            hasError={true}
            disabled={!!verifiedAddress?.latitude}
            label={'Latitude'}
          />
          <TextField
            value={address?.longitude}
            setValue={(value) => changeCreateAddress('longitude', value)}
            name={'longitude'}
            hint={'Longitude'}
            error={validation?.longitude}
            hasError={true}
            disabled={!!verifiedAddress?.longitude}
            label={'Longitude'}
          />
        </div>
      </div>
      <div className={styles.existing}>
        <div className={styles.titleElement}>Existing Addresses</div>
        {addresses.map((address) => {
          return (
            <NavigationLink key={address.id} link={`/address/${address.id}`}>
              <div className={styles.existingClient}>
                <div className={styles.name}>{address.fullAddress}</div>
                <div className={styles.name}>{address.client?.fullName}</div>
              </div>
            </NavigationLink>
          );
        })}
      </div>
    </div>
  );
}

export default AddEditAddressSetup;
