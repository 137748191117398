import React, { ReactElement, ReactNode } from 'react';
import styles from './HorizontalAddEditChild.module.scss';
import classNames from 'classnames';

interface HorizontalAddEditChildProps {
  children?: ReactNode;
  styling?: string;
  fullWidth?: boolean;
}

function HorizontalAddEditChild({ children, styling, fullWidth }: HorizontalAddEditChildProps): ReactElement {
  return <div className={classNames(styles.child, styling, fullWidth && styles.fullWidth)}>{children}</div>;
}

export default HorizontalAddEditChild;
