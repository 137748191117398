import FullWrapper from 'Base/FullWrapper';
import { AuthorizationProvider } from 'context/auth';
import { UserProfileProvider } from 'context/userProfile';
import React, { ReactElement } from 'react';
import './App.css';
import Routing from './routing';

function App(): ReactElement {
  return (
    <AuthorizationProvider>
      <UserProfileProvider>
        <FullWrapper>
          <Routing />
        </FullWrapper>
      </UserProfileProvider>
    </AuthorizationProvider>
  );
}

export default App;
