import classNames from 'classnames';
import JobTypeIndicators from 'components/JobTypeIndicators';
import { dailyRowSettings } from 'lib/calendar';
import { formatPrice } from 'lib/formatting';
import { changeStyleProperty } from 'lib/reactOperations';
import moment from 'moment';
import React, { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { CreateJobScheduleFromDrag, JobSchedule } from 'types';
import styles from './JobDetail.module.scss';
import RequestChange from '@material-ui/icons/Adjust';
import Invoice from '@material-ui/icons/Receipt';
import Money from '@material-ui/icons/AttachMoney';
import RightArrow from '@material-ui/icons/ArrowRight';
import FormatQuote from '@material-ui/icons/FormatQuote';
import Visibility from '@material-ui/icons/Visibility';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { dateFormats } from 'lib/date';
import useReactOperations from 'hooks/useReactOperations.hook';

interface JobDetailProps {
  schedule: JobSchedule;
  setSelectedJobScheduleId: (id: number | undefined) => void;
  startTime: string;
  columnWidth: number;
  onChangeRequestClick: () => void;
  onInvoiceClick: () => void;
  onQuoteClick: () => void;
  openChangeStatusModal: (jobId: number) => void;
  openRecordPaymentModal: (jobId: number) => void;
  refreshPage: () => void;
  onDragStop: (data: CreateJobScheduleFromDrag) => void;
}

function JobDetail({
  schedule,
  setSelectedJobScheduleId,
  startTime,
  columnWidth,
  onChangeRequestClick,
  onInvoiceClick,
  onQuoteClick,
  openChangeStatusModal,
  openRecordPaymentModal,
  refreshPage,
  onDragStop,
}: JobDetailProps): ReactElement {
  const status = schedule?.job?.status?.status || '';
  const statusKey = status.replace(' ', '').toLowerCase();

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [startY, setStartY] = useState<number>(0);
  const [localStartTime, setLocalStartTime] = useState<string>(schedule.startTime);
  const [localEndTime, setLocalEndTime] = useState<string>(schedule.endTime);
  const minDragDistance = 5; // Minimum distance to consider it a drag

  const { navigateToAddressPage, navigateToClientPage, navigateToJobPage } = useReactOperations();

  const getJobProgressColor = (schedule: JobSchedule) => {
    if (schedule.timeFinished) return styles.jobComplete;
    if (schedule.timeStarted) return styles.jobInProgress;
    return styles.jobNotStarted;
  };
  const getJobProgressBackgroundColor = (schedule: JobSchedule) => {
    if (schedule.timeFinished) return styles.jobCompleteBackground;
    if (schedule.timeStarted) return styles.jobInProgressBackground;
    return styles.jobNotStartedBackground;
  };

  const changeStatus = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    openChangeStatusModal(schedule.job.id);
  };

  const setPosition = () => {
    const totalRowHeight = dailyRowSettings.height;
    const height = ((schedule?.duration || 0) / dailyRowSettings.interval) * totalRowHeight;
    const top =
      (moment(schedule.startTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'minutes') / dailyRowSettings.interval) *
      totalRowHeight;

    changeStyleProperty(`${schedule.id}-job`, '--height', `${height}px`);
    changeStyleProperty(`${schedule.id}-job`, '--top', `${top}px`);
  };

  const handleDragStart = (_: DraggableEvent, data: DraggableData) => {
    setStartX(data.x);
    setStartY(data.y);
  };

  const handleDrag = (event: DraggableEvent, data: DraggableData) => {
    if (!isDragging) {
      const distanceX = Math.abs(data.x - startX);
      const distanceY = Math.abs(data.y - startY);

      if (distanceX >= minDragDistance || distanceY >= minDragDistance) {
        setIsDragging(true);
      }
    }
    const mouseEvent = event as MouseEvent;
    const elements = document.elementsFromPoint(mouseEvent.clientX, mouseEvent.clientY);

    for (const element of elements) {
      const id = element.getAttribute('id');

      if (id && id.includes('-blank')) {
        const data = id.split('-');
        const time = data[1];

        const difference = moment(`${schedule.date} ${schedule.endTime}`).diff(
          `${schedule.date} ${schedule.startTime}`,
          'minutes'
        );
        setLocalStartTime(time);
        setLocalEndTime(moment(`${schedule.date} ${time}`).add(difference, 'minutes').format(dateFormats.sqlTimeOnly));

        return;
      }
    }
  };

  const handleDragStop = (event: DraggableEvent) => {
    const mouseEvent = event as MouseEvent;
    const elements = document.elementsFromPoint(mouseEvent.clientX, mouseEvent.clientY);
    if (!isDragging) return;

    for (const element of elements) {
      const id = element.getAttribute('id');

      if (id && id.includes('-blank')) {
        const data = id.split('-');
        const teamId = data[0];
        const time = data[1];

        const difference = moment(`${schedule.date} ${schedule.endTime}`).diff(
          `${schedule.date} ${schedule.startTime}`,
          'minutes'
        );

        const params = {
          startTime: time,
          jobId: schedule.job.id,
          endTime: moment(`${schedule.date} ${time}`).add(difference, 'minutes').format(dateFormats.sqlTimeOnly),
          date: schedule.date,
          teamId: +teamId,
          typeId: schedule.type.id,
        };

        onDragStop({ id: schedule.id, createJobSchedule: params });
        return;
      }
    }
    setLocalEndTime(schedule.endTime);
    setLocalStartTime(schedule.startTime);
    setPosition();
    refreshPage();
  };

  useEffect(() => {
    setPosition();
    setIsDragging(false);
  }, [schedule, startTime]);

  return (
    <Draggable onStop={handleDragStop} onDrag={handleDrag} onStart={handleDragStart} grid={[columnWidth, 1]}>
      <div id={`${schedule.id}-job`} className={classNames(styles.job, getJobProgressColor(schedule))}>
        <div className={classNames(styles.mainInfo, getJobProgressBackgroundColor(schedule))}>
          <div>
            <div className={styles.firstLine}>
              <div className={styles.time}>
                {localStartTime}-{localEndTime}
              </div>

              <JobTypeIndicators job={schedule.job} useFillers={false} />
              <div className={styles.actionIcons}>
                {!!schedule?.job && (
                  <RightArrow
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!isDragging) navigateToJobPage(schedule.job.id);
                    }}
                  />
                )}
                {!!(!schedule.job.fullyPaid && schedule.job.completed) && (
                  <Money
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!isDragging) openRecordPaymentModal(schedule.job.id);
                    }}
                    className={styles.action}
                  />
                )}

                {schedule.job.changeRequests && (
                  <RequestChange
                    className={styles.action}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!isDragging) onChangeRequestClick();
                    }}
                  />
                )}
                {!!schedule.job.completed && (
                  <>
                    {!!(schedule.job.hasWork && schedule.job.needsInvoice) && (
                      <Invoice
                        className={schedule.job.invoices.length ? styles.completedAction : styles.action}
                        onClick={(event) => {
                          event.stopPropagation();
                          if (!isDragging) onInvoiceClick();
                        }}
                      />
                    )}
                    {schedule.job.hasQuotes && (
                      <FormatQuote
                        className={schedule.job.quotes.length ? styles.completedAction : styles.action}
                        onClick={(event) => {
                          event.stopPropagation();
                          if (!isDragging) onQuoteClick();
                        }}
                      />
                    )}
                  </>
                )}
                <Visibility
                  onClick={() => {
                    if (!isDragging) setSelectedJobScheduleId(schedule.id);
                  }}
                />
              </div>
            </div>
            {status && (
              <div
                onClick={(event) => {
                  if (!isDragging) changeStatus(event);
                }}
                className={classNames(styles.status, styles[statusKey])}
              >
                <div>{status}</div>
              </div>
            )}
            <div>Type: {schedule?.type.type || ''}</div>
            {!!(schedule.manditoryArriveAfter || schedule.manditoryArriveBefore) && (
              <div>
                Manditory Arrival: {schedule?.manditoryArriveAfter || 'ANY'} -{' '}
                {schedule?.manditoryArriveBefore || 'ANY'}
              </div>
            )}
            {!!(schedule.preferredArriveAfter || schedule.preferredArriveBefore) && (
              <div>
                Preferred Arrival: {schedule?.preferredArriveAfter || 'ANY'} -{' '}
                {schedule?.preferredArriveBefore || 'ANY'}
              </div>
            )}
            {!!(schedule.job.completed && schedule.job.hasWork) && (
              <>
                {schedule.job.fullyPaid ? (
                  <div className={styles.paid}>PAID</div>
                ) : (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!isDragging) openRecordPaymentModal(schedule.job.id);
                    }}
                    className={styles.notPaid}
                  >
                    NEEDS PAYMENT<span>{`:  ${formatPrice(schedule.job.paymentRemaining, true)}`}</span>
                  </div>
                )}
              </>
            )}
            <div>
              <div
                className={styles.client}
                onClick={() => {
                  if (!isDragging) navigateToClientPage(schedule.job.client.id);
                }}
              >
                {schedule.job.client?.fullName}
              </div>
              <div
                className={styles.address}
                onClick={() => {
                  if (!isDragging) navigateToAddressPage(schedule.job.address.id);
                }}
              >
                {schedule.job.address?.shortAddress}
              </div>
              {schedule.job.hasWork && (
                <div>
                  <span className={styles.field}>Total:</span> {formatPrice(schedule.job.subtotal, true)}
                </div>
              )}
              {schedule.job.hasQuotes && (
                <div>
                  <span className={styles.field}>Quote Total:</span> {formatPrice(schedule.job.quoteSubtotal, true)}
                </div>
              )}
              <div>
                {schedule.job.windowDetails && (
                  <div>
                    <span className={styles.field}>Window Details</span> -{' '}
                    {formatPrice(schedule.job.windowDetails?.price, true)}
                    <div className={styles.subField}>{schedule.job.windowDetails?.description}</div>
                  </div>
                )}
                {schedule.job.eavesDetails && (
                  <div>
                    <span className={styles.field}>Eaves Details</span> -{' '}
                    {formatPrice(schedule.job.eavesDetails?.price, true)}
                    <div className={styles.subField}>{schedule.job.eavesDetails?.description}</div>
                  </div>
                )}
                {schedule.job.otherDetails && (
                  <div>
                    <span className={styles.field}>Custom</span> - {formatPrice(schedule.job.otherDetails?.price, true)}
                    <div className={styles.subField}>{schedule.job.otherDetails?.description}</div>
                  </div>
                )}
                {schedule.job.windowQuotes && (
                  <div>
                    Window Quotes
                    {schedule.job.windowQuotes.map((quote) => {
                      return <div key={quote.id}>{quote.description}</div>;
                    })}
                  </div>
                )}
                {schedule.job.eavesQuotes && (
                  <div>
                    Eaves Quotes
                    {schedule.job.eavesQuotes.map((quote) => {
                      return <div key={quote.id}>{quote.description}</div>;
                    })}
                  </div>
                )}
                {schedule.job.otherQuotes && (
                  <div>
                    Custom Quotes
                    {schedule.job.otherQuotes.map((quote) => {
                      return <div key={quote.id}>{quote.description}</div>;
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.notes}>
            <div className={styles.title}>Office Notes:</div>
            <div>{schedule.job.officeNotes}</div>
          </div>
          <div className={styles.notes}>
            <div className={styles.title}>Public Notes:</div>
            <div>{schedule.job.publicNotes}</div>
          </div>
        </div>
      </div>
    </Draggable>
  );
}

export default JobDetail;
