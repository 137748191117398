import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Job } from 'types';
import styles from './JobTypeIndicators.module.scss';

interface JobTypeIndicatorsProps {
  job: Job;
  useFillers?: boolean;
}

function JobTypeIndicators({ job, useFillers = true }: JobTypeIndicatorsProps): ReactElement {
  return (
    <div className={styles.container}>
      <div
        className={classNames(job?.windowDetails && styles.window, !job?.windowDetails && useFillers && styles.filler)}
      />
      <div
        className={classNames(
          job?.eavesDetails && styles.eavestrough,
          !job?.eavesDetails && useFillers && styles.filler
        )}
      />
      <div className={classNames(job.otherDetails && styles.other, !job.otherDetails && useFillers && styles.filler)} />
      <div
        className={classNames(
          job?.windowQuotes && styles.windowQuote,
          !job?.windowQuotes && useFillers && styles.filler
        )}
      />
      <div
        className={classNames(
          job?.eavesQuotes && styles.eavestroughQuote,
          !job?.eavesQuotes && useFillers && styles.filler
        )}
      />
      <div
        className={classNames(job?.otherQuotes && styles.otherQuote, !job?.otherQuotes && useFillers && styles.filler)}
      />
    </div>
  );
}

export default JobTypeIndicators;
