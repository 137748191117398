import React, { ReactElement, ReactNode, useState } from 'react';
import styles from './Autocomplete.module.scss';
import classNames from 'classnames';
import TextField from '../TextField';

export type AutoCompleteItem = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any;
  rowDisplay: ReactNode;
};
interface AutocompleteProps {
  values: Array<AutoCompleteItem>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: (item: any) => void;
  searchFilter: string;
  setSearchFilter: (search: string) => void;
  onClearField?: () => void;
  hint?: string;
  error?: string;
  hasError?: boolean;
  disabled?: boolean;
}
function Autocomplete({
  values,
  onSelect,
  setSearchFilter,
  onClearField,
  searchFilter,
  hint,
  error,
  hasError,
  disabled,
}: AutocompleteProps): ReactElement {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <div className={styles.menuContainer}>
      <TextField
        value={searchFilter}
        name={'item'}
        setValue={setSearchFilter}
        error={error}
        type={'text'}
        hint={hint}
        onFocusLost={() => setIsActive(false)}
        onFocus={() => setIsActive(true)}
        hasError={!!error && (!values.length || !isActive)}
        disabled={disabled}
      />

      {!disabled && (
        <div className={classNames(styles.menu, isActive ? styles.active : styles.inActive)}>
          <ul>
            {values &&
              values.map((value, index) => {
                return (
                  <div key={index} onClick={() => onSelect(value.object)}>
                    <div className={styles.item}>{value.rowDisplay}</div>
                  </div>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Autocomplete;
