import BaseModal from 'components/Base/BaseModal';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import TextField from 'components/Base/TextField';
import { UserContext } from 'context/userProfile';
import useJobs from 'hooks/useJobs.hook';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { CreateRecordPaymentValidation, EmailContact, PaymentType, RecordPayment } from 'types';
import styles from './RecordPaymentModal.module.scss';

interface RecordPaymentProps {
  open: boolean;
  jobId?: number;
  onSave: () => void;
  onClose: () => void;
  setSnackBarMessage?: (value: string) => void;
}

function RecordPaymentModal({ onClose, onSave, jobId, open, setSnackBarMessage }: RecordPaymentProps): ReactElement {
  const [paymentType, setPaymentType] = useState<PaymentType>();
  const [amount, setAmount] = useState<number>();
  const [tipAmount, setTipAmount] = useState<number>();
  const [email, setEmail] = useState<EmailContact>();
  const [hasValidated, setHasValidated] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<CreateRecordPaymentValidation>();
  const { fetchPaymentTypes, paymentTypes, recordPayment, fetchJob, job, clearJob } = useJobs();

  const { user } = useContext(UserContext);

  const validate = (): boolean => {
    const errors: CreateRecordPaymentValidation = {};

    let success = true;

    if (!amount) {
      errors.amount = 'Select an amount';
      success = false;
    }

    if (!paymentType) {
      errors.paymentType = 'Select a payment type';
      success = false;
    }

    if (user?.permissions.isCleaner && !email) {
      errors.email = 'Email needed to send to client';
      success = false;
    }

    setValidationErrors(errors);
    setHasValidated(true);
    return success;
  };
  const resetData = () => {
    clearJob();
    setAmount(undefined);
    setHasValidated(false);
    setPaymentType(undefined);
    setValidationErrors(undefined);
    setEmail(undefined);
    setTipAmount(undefined);
  };

  const onLocalClose = () => {
    resetData();
    onClose();
  };

  const onLocalSave = async () => {
    try {
      if (!validate() || !jobId || !amount || !paymentType) return;
      const data: RecordPayment = {
        jobId,
        amount,
        typeId: paymentType?.id,
        email,
        tipAmount,
      };
      await recordPayment(data);
      resetData();
      setSnackBarMessage?.(`Message sent to ${email?.email} to confirm payment`);
      onSave();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!open || !job) return;
    setAmount(job?.paymentRemaining);
  }, [job]);

  useEffect(() => {
    if (!hasValidated) return;
    validate();
  }, [paymentType, amount]);

  useEffect(() => {
    if (!open || !jobId) return;
    fetchJob(jobId);

    if (!paymentTypes.length) fetchPaymentTypes();
  }, [open, jobId]);

  return (
    <BaseModal title="Record Payment" open={open} onClose={onLocalClose} onConfirm={onLocalSave}>
      <div className={styles.container}>
        <Dropdown
          error={validationErrors?.paymentType}
          hint={'Select a Payment Method'}
          objectKey={'type'}
          items={paymentTypes.map((type) => {
            return <DropdownItem key={type.id} id={type.id} value={type.type} onSelect={() => setPaymentType(type)} />;
          })}
          selectedValue={paymentType}
        />

        {job && (
          <Dropdown
            hint={'Select an email'}
            items={job.client.emails.map((email) => {
              return <DropdownItem key={email.id} id={email.id} value={email.email} onSelect={() => setEmail(email)} />;
            })}
            onRemove={() => setEmail(undefined)}
            selectedValue={email?.email}
            error={validationErrors?.email}
          />
        )}
        {!!email && <p className={styles.emailMessage}>An email will be sent to confirm payment</p>}

        <TextField
          name={'amount'}
          value={amount}
          setValue={setAmount}
          type={'price'}
          label={'Amount'}
          hasError={true}
          error={validationErrors?.amount}
          hint={'Amount'}
        />
        <TextField
          name={'tipAmount'}
          value={tipAmount}
          setValue={setTipAmount}
          type={'price'}
          label={'Tip Amount'}
          hint="Tip Amount"
        />
      </div>
    </BaseModal>
  );
}

export default RecordPaymentModal;
