import { BaseApi } from 'api';
import { CLIENTS } from 'lib/routes';
import {
  Client,
  ClientSearch,
  CreateClient,
  CreatedClient,
  EmailContact,
  PhoneType,
  SubscribeEmail,
  Unsubscribe,
} from 'types';

export const fetchPhoneTypes = async (): Promise<Array<PhoneType>> => {
  return await BaseApi.get(`${CLIENTS}/phoneTypes`);
};
export const fetchClients = async (params?: ClientSearch): Promise<Array<Client>> => {
  return await BaseApi.get(CLIENTS, params);
};

export const fetchEmail = async (id: number, token?: string): Promise<EmailContact | undefined> => {
  return await BaseApi.get(`${CLIENTS}/email/${id}`, undefined, token);
};

export const unsubscribeEmail = async (clientId: number, data: Unsubscribe, token?: string): Promise<void> => {
  return await BaseApi.put(`${CLIENTS}/unsubscribeEmail/${clientId}`, data, token);
};

export const subscribeEmail = async (clientId: number, data: SubscribeEmail, token?: string): Promise<void> => {
  return await BaseApi.put(`${CLIENTS}/subscribeEmail/${clientId}`, data, token);
};

export const getClient = async (id: number, token?: string): Promise<Client> => {
  return await BaseApi.get(`${CLIENTS}/${id}`, undefined, token);
};
export const createClient = async (body: CreateClient): Promise<CreatedClient> => {
  return await BaseApi.post(`${CLIENTS}`, body);
};

export const updateClient = async (id: number, body: CreateClient): Promise<Array<Client>> => {
  return await BaseApi.put(`${CLIENTS}/${id}`, body);
};

export const del = async (id: number): Promise<void> => {
  return await BaseApi.del(`${CLIENTS}/${id}`);
};
