import BaseModal from 'components/Base/BaseModal';
import React, { ReactElement, useEffect, useState } from 'react';
import styles from './AddEditUserModal.module.scss';
import TextField from 'components/Base/TextField';
import { CreateUser, CreateUserValidation, UserType } from 'types';
import HorizontalAddEditRow from 'components/AddEditSetup/HorizontalAddEditRow';
import HorizontalAddEditChild from 'components/AddEditSetup/HorizontalAddEditChild';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useUsersApi from 'hooks/useUsers.hooks';
import { formatPhone, numbersOnly } from 'lib/formatting';
import {
  lowercaseLetters,
  noSpaces,
  numbers,
  specialCharacters,
  totalSize,
  uppercaseLetters,
  validatePassword,
} from 'lib/users';
import Invalid from '@material-ui/icons/Close';
import Valid from '@material-ui/icons/Check';
import DateSelector from 'components/Base/DateSelector';
import { sqlToday } from 'lib/date';

interface AddEditUserModalProps {
  id?: number;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

function AddEditUserModal({ id, open, onClose, onSave }: AddEditUserModalProps): ReactElement {
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [cellPhone, setCellPhone] = useState<string>();
  const [homePhone, setHomePhone] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [startDate, setStartDate] = useState<string>(sqlToday());
  const [emergencyContactName, setEmergencyContactName] = useState<string>();
  const [emergencyContactEmail, setEmergencyContactEmail] = useState<string>();
  const [emergencyContactNumber, setEmergencyContactNumber] = useState<string>();
  const [userType, setUserType] = useState<UserType>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [errors, setErrors] = useState<CreateUserValidation>();
  const [validated, setValidated] = useState<boolean>(false);

  const {
    userTypes,
    userErrorMessage,
    userErrorTitle,
    user,
    resetUserError,
    resetUsersHook,
    fetchUserTypes,
    create,
    update,
    fetchUser,
  } = useUsersApi();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: userErrorTitle, message: userErrorMessage, reset: resetUserError }],
  });

  const validate = () => {
    const errors: CreateUserValidation = {};
    let validated = true;
    if (!firstName) {
      validated = false;
      errors.firstName = 'Provide A First Name';
    }
    if (!lastName) {
      validated = false;
      errors.lastName = 'Provide A Last Name';
    }
    if (!email) {
      validated = false;
      errors.email = 'Provide An Email';
    }
    if (!userType?.id) {
      validated = false;
      errors.userType = 'Please Select A Staff Type';
    }
    if (!startDate) {
      validated = false;
      errors.startDate = 'Please Select A Start Date';
    }

    if (!id && !validatePassword(password)) {
      validated = false;
      errors.password = 'Please provide a valid password';
    }

    if (!id && confirmPassword !== password) {
      validated = false;
      errors.confirmPassword = 'Passwords do not match';
    }
    setErrors(errors);
    setValidated(true);
    return validated;
  };

  const getTitle = () => {
    return id ? 'Edit Staff' : 'New Staff';
  };
  const resetModal = () => {
    setFirstName(undefined);
    setLastName(undefined);
    setEmail(undefined);
    setCellPhone(undefined);
    setHomePhone(undefined);
    setUserType(undefined);
    setPassword(undefined);
    setEmergencyContactEmail(undefined);
    setEmergencyContactName(undefined);
    setEmergencyContactNumber(undefined);
    setStartDate(sqlToday());
    setConfirmPassword(undefined);
    setErrors(undefined);
    setValidated(false);
    resetUsersHook();
  };

  const onLocalSave = async () => {
    if (!validate()) return;
    const data: CreateUser = {
      firstName,
      lastName,
      email,
      address,
      cellPhone,
      homePhone,
      userTypeId: userType?.id,
      password,
      confirmPassword,
      startDate,
      emergencyContactEmail,
      emergencyContactName,
      emergencyContactNumber,
    };

    try {
      if (id) {
        await update(id, data);
      } else {
        await create(data);
      }
      onSave();
    } catch (error) {
      console.error(error);
    }
  };

  const localFetchUser = async () => {
    fetchUser(id);
  };

  const passwordCondition = (test: boolean, condition: string) => {
    return (
      <div className={styles.condition}>
        <div className={styles.icon}>
          {test ? <Valid className={styles.valid} /> : <Invalid className={styles.invalid} />}
        </div>
        <div className={styles.info}>{condition}</div>
      </div>
    );
  };

  const setUserTypeFromUser = () => {
    if (!user || !userTypes.length) return;
    const userType = userTypes.find((userType) => userType.id === user.userType?.id);
    setUserType(userType);
  };

  useEffect(() => {
    if (!user) return;
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setCellPhone(user.cellPhone);
    setHomePhone(user.homePhone);
    setAddress(user.address);
    setStartDate(user.startDate || '');
    setEmergencyContactName(user.emergencyContactName);
    setEmergencyContactNumber(user.emergencyContactNumber);
    setEmergencyContactEmail(user.emergencyContactEmail);
    setUserTypeFromUser();
  }, [user]);

  useEffect(() => {
    setUserTypeFromUser();
  }, [userTypes]);

  useEffect(() => {
    if (!open) {
      resetModal();
      return;
    }

    if (!userTypes.length) fetchUserTypes();
    localFetchUser();
  }, [open]);

  useEffect(() => {
    if (!validated) return;
    validate();
  }, [firstName, lastName, email, userType, password, confirmPassword, startDate]);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title={getTitle()}
      onConfirm={onLocalSave}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
      alertTitle={alertTitle}
      alertMessage={alertMessage}
    >
      <div className={styles.form}>
        <div className={styles.column}>
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <DateSelector
                date={startDate}
                setDate={setStartDate}
                title={'Start Date'}
                error={errors?.startDate}
                hasError={true}
              />
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <div className={styles.dropdown}>
                <Dropdown
                  hint={'Select a user type'}
                  error={errors?.userType}
                  items={userTypes.map((type) => (
                    <DropdownItem key={type.id} id={type.id} value={type.type} onSelect={() => setUserType(type)} />
                  ))}
                  selectedValue={userType ? userType.type : ''}
                />
              </div>
              <p className={styles.description}>{userType?.description}</p>
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <TextField
                value={firstName}
                setValue={setFirstName}
                name={'firstName'}
                hint={'First Name'}
                hasError={true}
                error={errors?.firstName}
              />
            </HorizontalAddEditChild>
            <HorizontalAddEditChild fullWidth>
              <TextField
                value={lastName}
                setValue={setLastName}
                name={'lastName'}
                hint={'Last Name'}
                hasError={true}
                error={errors?.lastName}
              />
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <TextField
                value={email}
                setValue={setEmail}
                name={'email'}
                hint={'Email'}
                hasError={true}
                error={errors?.email}
              />
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <TextField
                value={formatPhone(cellPhone)}
                setValue={(value) => setCellPhone(numbersOnly(value))}
                name={'cellPhone'}
                hint={'Cell Phone'}
                label={'Cell Phone'}
              />
            </HorizontalAddEditChild>
            <HorizontalAddEditChild fullWidth>
              <TextField
                value={formatPhone(homePhone)}
                setValue={(value) => setHomePhone(numbersOnly(value))}
                name={'homePhone'}
                hint={'Home Phone'}
                label={'Home Phone'}
              />
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <TextField value={address} setValue={setAddress} name={'address'} hint={'Address'} />
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
          <div className={styles.titleElement}>Emergency Contact Information</div>
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <TextField
                value={emergencyContactName}
                setValue={setEmergencyContactName}
                name={'ecName'}
                hint={'Name'}
              />
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <TextField
                value={emergencyContactEmail}
                setValue={setEmergencyContactEmail}
                name={'ecEmail'}
                hint={'Email'}
              />
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
          <HorizontalAddEditRow>
            <HorizontalAddEditChild>
              <TextField
                value={formatPhone(emergencyContactNumber)}
                setValue={(value) => setEmergencyContactNumber(numbersOnly(value))}
                name={'ecPhone'}
                hint={'Phone'}
              />
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
          {!id && (
            <div className={styles.password}>
              <div className={styles.titleElement}>Password</div>

              <HorizontalAddEditRow>
                <HorizontalAddEditChild fullWidth>
                  <div className={styles.passwordContent}>
                    <div className={styles.passwords}>
                      <TextField
                        value={password}
                        setValue={setPassword}
                        name={'password'}
                        hint={'Password'}
                        hasError={true}
                        error={errors?.password}
                      />
                      <TextField
                        value={confirmPassword}
                        setValue={setConfirmPassword}
                        name={'confirmPassword'}
                        hint={'Confirm Password'}
                        hasError={true}
                        error={errors?.confirmPassword}
                      />
                    </div>

                    <div className={styles.passwordCheck}>
                      {passwordCondition(totalSize(password), 'At least 8 characters')}
                      {passwordCondition(noSpaces(password), 'No spaces')}
                      {passwordCondition(uppercaseLetters(password), 'At least one uppercase letter')}
                      {passwordCondition(lowercaseLetters(password), 'At least one lowercase letter')}
                      {passwordCondition(numbers(password), 'At least one number')}
                      {passwordCondition(specialCharacters(password), 'At least one special character')}
                    </div>
                  </div>
                </HorizontalAddEditChild>
              </HorizontalAddEditRow>
            </div>
          )}
        </div>
      </div>
    </BaseModal>
  );
}

export default AddEditUserModal;
