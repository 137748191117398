import { TeamsApi } from 'api';
import { handleApiError } from 'lib/errors';
import { useState } from 'react';
import {
  Colour,
  CreateTeam,
  CreateTeamSchedule,
  DailyAllTeamsSchedule,
  DailyTeamSchedule,
  Team,
  TeamSchedule,
  FetchTeamsParams,
  DailyTeamScheduleSearch,
  TeamScheduleSearch,
  DeleteTeamSchedule,
  FetchMonthlyTeamSchedulesParams,
} from 'types';
import useErrorsDetails from './useErrors.hook';

interface TeamsInfo {
  teams: Array<Team>;
  teamLoading: boolean;
  teamSchedules: Array<TeamSchedule>;
  dailyTeamSchedules: Array<DailyTeamSchedule>;
  monthTeamsSchedules: Array<DailyAllTeamsSchedule>;
  colours: Array<Colour>;
  teamErrorMessage: string;
  teamErrorTitle: string;
  team: Team | undefined;
  teamSchedule: TeamSchedule | undefined;
  fetchTeam: (id: number) => void;
  fetchTeamSchedule: (id: number) => void;
  resetTeamError: () => void;
  getTeams: (params?: FetchTeamsParams) => void;
  fetchDailyTeamSchedules: (params: DailyTeamScheduleSearch) => void;
  fetchTeamSchedules: (params: TeamScheduleSearch) => void;
  fetchMonthlyTeamSchedules: ({ date }: FetchMonthlyTeamSchedulesParams) => void;
  onNewTeamSave: (team: CreateTeam) => Promise<void>;
  update: (id: number, team: CreateTeam) => Promise<void>;
  createTeamSchedule: (schedule: CreateTeamSchedule) => Promise<void>;
  updateTeamSchedule: (id: number, schedule: CreateTeamSchedule) => Promise<void>;
  fetchColours: () => Promise<void>;
  deleteTeamSchedule: (id: number, data: DeleteTeamSchedule) => Promise<void>;
  deleteTeam: (id: number) => Promise<void>;
  clearTeamSchedule: () => void;
}

function useTeams(): TeamsInfo {
  const [team, setTeam] = useState<Team>();
  const [teams, setTeams] = useState<Array<Team>>([]);
  const [colours, setColours] = useState<Array<Colour>>([]);
  const [dailyTeamSchedules, setDailyTeamSchedules] = useState<Array<DailyTeamSchedule>>([]);
  const [teamSchedules, setTeamSchedules] = useState<Array<TeamSchedule>>([]);
  const [monthTeamsSchedules, setMonthTeamsSchedule] = useState<Array<DailyAllTeamsSchedule>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [teamSchedule, setTeamSchedule] = useState<TeamSchedule>();

  const { handleError, errorMessage, errorTitle, resetError } = useErrorsDetails();

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const fetchTeam = async (id: number) => {
    startLoading();
    try {
      const team = await TeamsApi.fetchTeam(id);

      setTeam(team);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }

    stopLoading();
  };

  const fetchTeamSchedule = async (id: number) => {
    startLoading();
    try {
      const schedule = await TeamsApi.fetchTeamSchedule(id);
      setTeamSchedule(schedule);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }

    stopLoading();
  };

  const fetchDailyTeamSchedules = async (params: DailyTeamScheduleSearch) => {
    startLoading();
    try {
      const dailyTeamScheduleList = await TeamsApi.fetchDailyTeamSchedules(params);
      setDailyTeamSchedules(dailyTeamScheduleList);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }

    stopLoading();
  };

  const fetchTeamSchedules = async (params: TeamScheduleSearch) => {
    startLoading();
    try {
      const teamScheduleList = await TeamsApi.fetchTeamSchedules(params);
      setTeamSchedules(teamScheduleList);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }

    stopLoading();
  };

  const fetchMonthlyTeamSchedules = async ({ date }: FetchMonthlyTeamSchedulesParams) => {
    startLoading();
    try {
      const monthTeamScheduleList = await TeamsApi.fetchMonthTeamsSchedules({
        date,
      });
      setMonthTeamsSchedule(monthTeamScheduleList);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    stopLoading();
  };

  const fetchColours = async () => {
    try {
      const colours = await TeamsApi.fetchColours();
      setColours(colours);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };
  const getTeams = async (params?: FetchTeamsParams) => {
    try {
      const teamList = await TeamsApi.fetch(params);
      setTeams(teamList);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };

  const onNewTeamSave = async (data: CreateTeam): Promise<void> => {
    try {
      await TeamsApi.createTeam(data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const update = async (id: number, data: CreateTeam): Promise<void> => {
    try {
      await TeamsApi.update(id, data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const createTeamSchedule = async (schedule: CreateTeamSchedule): Promise<void> => {
    try {
      await TeamsApi.createTeamSchedule(schedule);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const updateTeamSchedule = async (id: number, schedule: CreateTeamSchedule): Promise<void> => {
    try {
      await TeamsApi.updateTeamSchedule(id, schedule);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const deleteTeam = async (id: number) => {
    try {
      await TeamsApi.del(id);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const deleteTeamSchedule = async (id: number, data: DeleteTeamSchedule) => {
    try {
      await TeamsApi.delSchedule(id, data);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const clearTeamSchedule = () => {
    setTeamSchedule(undefined);
  };

  return {
    team,
    teams,
    colours,
    teamLoading: loading,
    dailyTeamSchedules,
    monthTeamsSchedules,
    teamErrorMessage: errorMessage,
    teamErrorTitle: errorTitle,
    teamSchedule,
    teamSchedules,
    fetchTeamSchedules,
    deleteTeam,
    deleteTeamSchedule,
    update,
    fetchTeam,
    fetchTeamSchedule,
    resetTeamError: resetError,
    fetchColours,
    getTeams,
    fetchDailyTeamSchedules,
    fetchMonthlyTeamSchedules,
    updateTeamSchedule,
    onNewTeamSave,
    createTeamSchedule,
    clearTeamSchedule,
  };
}

export default useTeams;
