import { useNavigate } from 'react-router-dom';
import * as PATHS from 'lib/pagesPaths';
import { sqlToday } from 'lib/date';
import { MonthlyCalendarProps } from 'types';
import { JobsProps } from 'pages/Jobs/Jobs';

interface UseReactOperationsProps {
  navigateToPage: (path: string) => void;
  navigateToLoginPage: () => void;
  navigateToAddressesPage: () => void;
  navigateToClientsPage: () => void;
  navigateToClientPage: (id: number) => void;
  navigateToJobPage: (id: number) => void;
  navigateToJobsPage: (params?: JobsProps) => void;
  navigateToUsersPage: () => void;
  navigateToDailyJobsPage: (date?: string) => void;
  navigateToMonthCalendarPage: (date?: string, props?: MonthlyCalendarProps, reload?: boolean) => void;
  navigateToDailyCalendarPage: (date?: string) => void;
  navigateToSummaryOverviewPage: (startDate?: string, endDate?: string) => void;
  navigateToSummaryTeamsPage: (startDate?: string, endDate?: string) => void;
  navigateToSummaryEmployeesPage: (startDate?: string, endDate?: string) => void;
  navigateToTeamsCalendarPage: () => void;
  navigateToTeamsPage: () => void;
  navigateToClockInOutPage: (date?: string) => void;
  navigateToSubmitQuotePage: (jobId: number) => void;
  navigateToAddressPage: (id: number) => void;
  navigateToSettingsPage: () => void;
  navigateToSettingsGeneralPage: () => void;
  navigateToSettingsInvoiceQuotePage: () => void;
  navigateToSettingsCommunicationPage: () => void;
  navigateToCreateAccountPage: () => void;
  replaceState: () => void;
  goBack: () => void;
}

function useReactOperations(): UseReactOperationsProps {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const localNavigate = (path: string, props?: any, reload?: boolean) => {
    navigate(path, { state: props });
    if (reload) window.location.reload();
  };

  const navigateToPage = (path: string) => {
    localNavigate(path);
  };

  const navigateToLoginPage = () => {
    localNavigate(PATHS.LOGIN);
  };

  const navigateToCreateAccountPage = () => {
    localNavigate(PATHS.CREATE_ACCOUNT);
  };

  const navigateToAddressesPage = () => {
    localNavigate(PATHS.ADDRESSES);
  };

  const navigateToClientsPage = () => {
    localNavigate(PATHS.CLIENTS);
  };

  const navigateToTeamsPage = () => {
    localNavigate(PATHS.TEAMS);
  };

  const navigateToUsersPage = () => {
    localNavigate(PATHS.USERS);
  };

  const navigateToSettingsPage = () => {
    localNavigate(PATHS.SETTINGS);
  };

  const navigateToSettingsGeneralPage = () => {
    localNavigate(PATHS.SETTINGS_GENERAL);
  };
  const navigateToSettingsInvoiceQuotePage = () => {
    localNavigate(PATHS.SETTINGS_INVOICE_QUOTE);
  };
  const navigateToSettingsCommunicationPage = () => {
    localNavigate(PATHS.SETTINGS_COMMUNICATION);
  };

  const navigateToDailyCalendarPage = (date?: string) => {
    localNavigate(PATHS.DAILY_CALENDAR(date || sqlToday()));
  };

  const navigateToMonthCalendarPage = (date?: string, props?: MonthlyCalendarProps, reload?: boolean) => {
    localNavigate(PATHS.MONTH_CALENDAR(date), props, reload);
  };

  const navigateToTeamsCalendarPage = () => {
    localNavigate(PATHS.TEAMS_CALENDAR());
  };

  const navigateToJobsPage = (params?: JobsProps) => {
    localNavigate(PATHS.JOBS, params);
  };

  const navigateToDailyJobsPage = (date?: string) => {
    localNavigate(PATHS.DAILY_JOBS(date));
  };

  const navigateToClockInOutPage = (date?: string) => {
    localNavigate(PATHS.CLOCK_IN_OUT(date));
  };

  const navigateToSubmitQuotePage = (jobId: number) => {
    localNavigate(PATHS.SUBMIT_QUOTE(jobId));
  };

  const navigateToClientPage = (id: number) => {
    localNavigate(PATHS.CLIENT(id));
  };

  const navigateToJobPage = (id: number) => {
    localNavigate(PATHS.JOB(id));
  };

  const navigateToAddressPage = (id: number) => {
    localNavigate(PATHS.ADDRESS(id));
  };

  const navigateToSummaryOverviewPage = (startDate?: string, endDate?: string) => {
    localNavigate(PATHS.SUMMARY_OVERVIEW(startDate, endDate));
  };

  const navigateToSummaryTeamsPage = (startDate?: string, endDate?: string) => {
    localNavigate(PATHS.SUMMARY_TEAMS(startDate, endDate));
  };

  const navigateToSummaryEmployeesPage = (startDate?: string, endDate?: string) => {
    localNavigate(PATHS.SUMMARY_EMPLOYEES(startDate, endDate));
  };

  const replaceState = () => {
    window.history.replaceState(null, document.title, 'url');
  };

  const goBack = () => {
    navigate(-1);
  };

  return {
    navigateToPage,
    navigateToLoginPage,
    navigateToCreateAccountPage,
    navigateToClientPage,
    navigateToJobPage,
    navigateToAddressesPage,
    navigateToClientsPage,
    navigateToJobsPage,
    navigateToDailyJobsPage,
    navigateToDailyCalendarPage,
    navigateToTeamsCalendarPage,
    navigateToTeamsPage,
    navigateToUsersPage,
    navigateToClockInOutPage,
    navigateToSubmitQuotePage,
    navigateToAddressPage,
    navigateToMonthCalendarPage,
    navigateToSummaryOverviewPage,
    navigateToSummaryTeamsPage,
    navigateToSummaryEmployeesPage,
    navigateToSettingsPage,
    navigateToSettingsGeneralPage,
    navigateToSettingsCommunicationPage,
    navigateToSettingsInvoiceQuotePage,
    replaceState,
    goBack,
  };
}

export default useReactOperations;
