import styles from './QuoteItem.module.scss';
import React, { ReactElement } from 'react';
import { IconButton } from '@material-ui/core';
import EditButton from '@material-ui/icons/Edit';
import { formatPrice } from 'lib/formatting';

interface QutoeItemProps {
  onClick?: (value: boolean) => void;
  description: string;
  price?: number;
  extras?: string;
  onEdit: () => void;
  onDelete: () => void;
}

function QuoteItem({ onEdit, description, extras, price }: QutoeItemProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.quotes}>
        <div className={styles.info}>
          <div className={styles.description}>{description} </div>
          <div className={styles.extras}>
            {extras} <span>{formatPrice(price, true)}</span>
          </div>
        </div>
        <div className={styles.icon}>
          <IconButton onClick={onEdit}>
            <EditButton />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default QuoteItem;
