import { IconButton, Icon } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import styles from './Header.module.scss';
import DehazeIcon from '@material-ui/icons/Dehaze';
import Navigation from '../Navigation';

interface HeaderProps {
  title: string;
}

const Header = ({ title }: HeaderProps) => {
  const [navigationOpen, setNavigationOpen] = useState<boolean>(false);

  const onNavigationCLick = () => {
    setNavigationOpen(!navigationOpen);
  };
  const onCloseNavigation = () => {
    setNavigationOpen(false);
  };

  return (
    <Fragment>
      <header className={styles.container}>
        <Navigation open={navigationOpen} onClose={onCloseNavigation} />
        <IconButton onClick={onNavigationCLick}>
          <Icon>
            <DehazeIcon />
          </Icon>
        </IconButton>

        <div className={styles.title}>{title}</div>
      </header>
    </Fragment>
  );
};

export default Header;
