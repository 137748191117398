import { largestScreenSizePixels } from 'lib/deviceDetection';
import { useEffect, useState } from 'react';

type WindowSize = {
  width: number;
  height: number;
};

interface UseScreenOperationsProps {
  isPhone: boolean;
}

function useScreenOperations(): UseScreenOperationsProps {
  const [screenSize, setScreenSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isPhone, setIsPhone] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setScreenSize({ width, height });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsPhone(screenSize.width < largestScreenSizePixels || screenSize.height < largestScreenSizePixels);
  }, [screenSize]);

  return {
    isPhone,
  };
}

export default useScreenOperations;
