import { SnackBarType } from 'components/SnackBar/SnackBar';
import React, { FC } from 'react';
import Presentation from './Presentation';

interface LayoutPresentationProps {
  children: React.ReactNode;
  title: string;
  openAlert?: boolean;
  alertTitle?: string;
  alertMessage?: string;
  onAlertClose?: () => void;
  showSnackBar?: boolean;
  snackBarType?: SnackBarType;
  snackBarMessage?: string;
  closeSnackBar?: () => void;
}

const Layout: FC<LayoutPresentationProps> = (props) => {
  return <Presentation {...props} />;
};

export default Layout;
