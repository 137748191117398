import TextField from 'components/Base/TextField';
import React, { ReactElement } from 'react';
import styles from './TextFieldCountdown.module.scss';

interface TextFieldCountdownProps {
  value?: string;
  setValue: (value: string) => void;
  hint?: string;
  maxLength: number;
  error?: string;
}

function TextFieldCountdown({ value, setValue, hint, maxLength, error }: TextFieldCountdownProps): ReactElement {
  return (
    <div className={styles.description}>
      <div className={styles.text}>
        <TextField
          hint={hint}
          maxLength={maxLength}
          name={'textFieldCountdown'}
          value={value}
          error={error}
          hasError={true}
          setValue={setValue}
        />
      </div>
      <div className={styles.countdown}>{maxLength - (value?.length || 0)}</div>
    </div>
  );
}

export default TextFieldCountdown;
