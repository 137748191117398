import TextField from 'components/Base/TextField';
import React, { ReactElement } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { CreateEmailContact } from 'types';
import styles from './EmailSetup.module.scss';
import CloseIcon from '@material-ui/icons/Close';
interface EmailSetupProps {
  label?: string;
  index: number;
  email: CreateEmailContact;
  allowDelete: boolean;
  allowAdd: boolean;
  setEmail: (value: string, index?: number) => void;
  remove?: () => void;
  add?: () => void;
}

function EmailSetup({
  email,
  label,
  index,
  allowDelete,
  allowAdd,
  setEmail,
  remove,
  add,
}: EmailSetupProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.fullWidth}>
        <TextField
          value={email.email}
          setValue={(value) => setEmail(value)}
          name={'email'}
          hint={!index ? 'Default' : undefined}
          label={!index ? 'Default' : undefined}
        />
      </div>
      <div>
        <i className={styles.removeButton} onClick={remove}>
          {allowDelete && <CloseIcon />}
        </i>

        <div className={styles.addButton} onClick={add}>
          {allowAdd && <AddIcon className={styles.button} />}
        </div>
      </div>
    </div>
  );
}

export default EmailSetup;
