import { dailyRowSettings } from 'lib/calendar';
import { changeStyleProperty } from 'lib/reactOperations';
import React, { ReactElement, useEffect } from 'react';
import styles from './BlankRow.module.scss';

interface BlankRowProps {
  inTeamSchedule: boolean;
  onClick: () => void;
  time: string;
  id: string;
}
function BlankRow({ inTeamSchedule, time, onClick, id }: BlankRowProps): ReactElement {
  useEffect(() => {
    changeStyleProperty(id, '--height', `${dailyRowSettings.height}px`);
  }, []);
  return (
    <div id={id} className={styles.body} onClick={onClick}>
      <div className={inTeamSchedule ? styles.schedule : styles.space} />
    </div>
  );
}

export default BlankRow;
