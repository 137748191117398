import React, { ReactElement, useEffect, useState } from 'react';
import styles from './Subscribe.module.scss';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { ClientProfile, EmailContact, SubscribeEmail } from 'types';
import useAccounts from 'hooks/useAccounts.hook';
import useClients from 'hooks/useClients.hooks';

function Subscribe(): ReactElement {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jwtToken = queryParams.get('jwt') || undefined;
  const clientProfile: ClientProfile = jwtDecode(jwtToken || '');

  let topic = '';

  if (clientProfile.subscribeJobReminders) topic = 'upcoming job reminders';
  if (clientProfile.subscribeSolicitations) topic = 'future promotions and offers';

  const [email, setEmail] = useState<EmailContact>();
  const { fetchCompanySquareLogo, companySquareLogoSignedUrl } = useAccounts();
  const { subscribeEmail, getClient, client } = useClients();

  useEffect(() => {
    if (!client) return;

    setEmail(client.emails.find((email) => email.main));
  }, [client]);

  useEffect(() => {
    fetchCompanySquareLogo(jwtToken);
    getClient(clientProfile.id, jwtToken);
    const params: SubscribeEmail = {
      solicitations: !!clientProfile.subscribeSolicitations,
      jobReminders: !!clientProfile.subscribeJobReminders,
    };
    subscribeEmail(clientProfile.id, params, jwtToken);
  }, []);

  return clientProfile.pages.subscribe ? (
    client ? (
      <div className={styles.container}>
        <div className={styles.content}>
          {companySquareLogoSignedUrl && <img src={companySquareLogoSignedUrl} alt="" />}
          <div>{`You will receive ${topic} to ${email?.email}.  You can still choose to unsubscribe in the future.`}</div>
        </div>
      </div>
    ) : (
      <div className={styles.info}>Fetching Information...</div>
    )
  ) : (
    <div className={styles.info}>Error Finding Page</div>
  );
}

export default Subscribe;
