import React, { ReactElement, useEffect, useState } from 'react';
import styles from '../Components.module.scss';
import Button from 'components/Base/Button';
import { UpdateInvoiceSettings } from 'types';
import TextareaAttribute from '../../components/TextareaAttribute';
import useInvoices from 'hooks/useInvoices.hook';
import useQuotes from 'hooks/useQuotes.hook';

function InvoiceQuote(): ReactElement {
  const [edit, setEdit] = useState<boolean>(false);
  const [invoiceIndividualFooter, setInvoiceIndividualFooter] = useState<string>();
  const [invoiceCorperateFooter, setInvoiceCorperateFooter] = useState<string>();
  const [quoteIndividualFooter, setQuoteIndividualFooter] = useState<string>();
  const [quoteCorperateFooter, setQuoteCorperateFooter] = useState<string>();
  const { previewInvoice, fetchInvoiceSettings, invoiceSettings, updateInvoiceSettings } = useInvoices();
  const { fetchQuoteSettings, quoteSettings, updateQuoteSettings, previewQuote } = useQuotes();

  const onSave = async () => {
    try {
      const invoiceData: UpdateInvoiceSettings = {
        coorperateFooterNote: invoiceCorperateFooter ? invoiceCorperateFooter : null,
        individualFooterNote: invoiceIndividualFooter ? invoiceIndividualFooter : null,
      };

      const quoteData: UpdateInvoiceSettings = {
        coorperateFooterNote: quoteCorperateFooter ? quoteCorperateFooter : null,
        individualFooterNote: quoteIndividualFooter ? quoteIndividualFooter : null,
      };

      await updateInvoiceSettings(invoiceData);
      await updateQuoteSettings(quoteData);
      fetchQuoteSettings();
      fetchInvoiceSettings();
      setEdit(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setInvoiceIndividualFooter(invoiceSettings?.individualFooterNote);
    setInvoiceCorperateFooter(invoiceSettings?.coorperateFooterNote);
    setQuoteIndividualFooter(quoteSettings?.individualFooterNote);
    setQuoteCorperateFooter(quoteSettings?.coorperateFooterNote);
  }, [invoiceSettings, quoteSettings]);

  useEffect(() => {
    fetchInvoiceSettings();
    fetchQuoteSettings();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>General</div>
      <div className={styles.buttons}>
        <Button title={edit ? 'Cancel' : 'Edit'} onClick={() => setEdit((prev) => !prev)} />
        {edit && <Button title={'Save'} onClick={() => onSave()} />}
      </div>

      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.title}>Invoice</div>
          <div className={styles.buttons}>{!edit && <Button title={'Preview'} onClick={() => previewInvoice()} />}</div>
          <TextareaAttribute
            value={invoiceIndividualFooter}
            onChange={setInvoiceIndividualFooter}
            field={'Individual Footer'}
            edit={edit}
          />
          <TextareaAttribute
            value={invoiceCorperateFooter}
            onChange={setInvoiceCorperateFooter}
            field={'Corperate Footer'}
            edit={edit}
          />
        </div>
        <div className={styles.section}>
          <div className={styles.title}>Quote</div>
          <div className={styles.buttons}>{!edit && <Button title={'Preview'} onClick={() => previewQuote()} />}</div>
          <TextareaAttribute
            value={quoteIndividualFooter}
            onChange={setQuoteIndividualFooter}
            field={'Individual Footer'}
            edit={edit}
          />
          <TextareaAttribute
            value={quoteCorperateFooter}
            onChange={setQuoteCorperateFooter}
            field={'Corperate Footer'}
            edit={edit}
          />
        </div>
      </div>
    </div>
  );
}

export default InvoiceQuote;
