import Layout from 'Base';
import classNames from 'classnames';
import InfoCard from 'components/InfoCard';
import AddEditAddressModal from 'components/Modals/AddEditAddressModal';
import useAddresses from 'hooks/useAddresses.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useJobs from 'hooks/useJobs.hook';
import { dateFormats, formatDate } from 'lib/date';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Address.module.scss';
import useReactOperations from 'hooks/useReactOperations.hook';
import JobTypeIndicators from 'components/JobTypeIndicators';
import { formatPrice } from 'lib/formatting';
import Invoice from '@material-ui/icons/Receipt';
import FormatQuote from '@material-ui/icons/FormatQuote';
import useInvoices from 'hooks/useInvoices.hook';
import useQuotes from 'hooks/useQuotes.hook';

function Address(): ReactElement {
  const { addressId } = useParams();
  const id = addressId ? +addressId : undefined;
  const [openEditAddress, setOpenEditAddress] = useState<boolean>(false);
  const { address, addressErrorMessage, addressErrorTitle, resetAddressError, fetchAddress, addressLoading } =
    useAddresses();

  const { jobs, fetchJobs, jobErrorMessage, jobErrorTitle, resetJobError, jobsLoading } = useJobs();

  const { viewInvoice } = useInvoices();
  const { viewQuote } = useQuotes();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [
      { title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError },
      { title: addressErrorTitle, message: addressErrorMessage, reset: resetAddressError },
    ],
  });
  const { navigateToJobPage, navigateToClientPage } = useReactOperations();

  const getAllFields = () => {
    if (!id) return;
    fetchJobs({ addressId: id });
    fetchAddress(id);
  };
  const onSave = () => {
    getAllFields();
    setOpenEditAddress(false);
  };

  useEffect(() => {
    getAllFields();
  }, [id]);

  return (
    <Layout
      title={address?.fullAddress || ''}
      alertMessage={alertMessage}
      openAlert={openAlert}
      alertTitle={alertTitle}
      onAlertClose={closeAlertModal}
    >
      <div className={styles.container}>
        <div className={styles.summaries}>
          <InfoCard title={'Address Details'} onEdit={() => setOpenEditAddress(true)}>
            <table>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Office Info</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                {!addressLoading && (
                  <tr>
                    <td className={styles.center}>
                      <div>{`Total Jobs: ${address?.jobCount}`}</div>
                    </td>
                    <td className={styles.center}>
                      <div>{`Created By: ${address?.createdBy}`}</div>
                      <div>{`Created At: ${formatDate(address?.createdAt, dateFormats.fullPretty)}`}</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </InfoCard>

          <InfoCard title={'Jobs Summary'} loading={jobsLoading}>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Client</th>
                  <th>Types</th>
                  <th>Price</th>
                  <th>Paid</th>
                  <th>Documents</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {jobs.map((job) => {
                  return (
                    <tr key={job.id}>
                      <td className={styles.date}>
                        {!job.scheduledStartDate && 'Not Scheduled'}
                        {job.scheduledStartDate &&
                          formatDate(job.scheduledStartDate || '', dateFormats.abbreviatedPretty)}
                        {!!(job.scheduledStartDate && job.scheduledEndDate !== job.scheduledStartDate) &&
                          ` - ${formatDate(job.scheduledEndDate || '', dateFormats.abbreviatedPretty)}`}
                      </td>
                      <td
                        className={classNames(styles.center, styles.clickable)}
                        onClick={() => navigateToClientPage(job.client.id)}
                      >
                        {job.client.fullName}
                      </td>
                      <td className={styles.center}>
                        <JobTypeIndicators job={job} />
                      </td>
                      <td className={styles.center}>{job.hasWork ? formatPrice(job.subtotal, true) : ''}</td>
                      <td className={styles.center}>{!!(job.completed && job.fullyPaid) && 'PAID'}</td>
                      <td className={styles.center}>
                        {job.invoices.map((invoice) => {
                          return <Invoice key={invoice.id} onClick={() => viewInvoice(invoice.id)} />;
                        })}
                        {job.quotes.map((quote) => {
                          return <FormatQuote key={quote.id} onClick={() => viewQuote(quote.id)} />;
                        })}
                      </td>
                      <td
                        className={classNames(styles.center, styles.clickable)}
                        onClick={() => navigateToJobPage(job.id)}
                      >
                        View
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </InfoCard>

          <InfoCard title={'Documents'} />
        </div>
      </div>
      <AddEditAddressModal
        open={openEditAddress}
        onClose={() => setOpenEditAddress(false)}
        onSave={onSave}
        id={address?.id}
      />
    </Layout>
  );
}

export default Address;
