import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Navigation.module.scss';
import { IconButton, Icon } from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';
import NavigationItem from './components/NavigationItem';
import { UserContext } from 'context/userProfile';
import useLogin from 'action/useLogin';
import useReactOperations from 'hooks/useReactOperations.hook';
import useScreenOperations from 'hooks/useScreenOperations.hook';
import classNames from 'classnames';
import useAccounts from 'hooks/useAccounts.hook';

interface NavigationProps {
  onClose: () => void;
  open: boolean;
}

const Navigation = ({ onClose, open }: NavigationProps) => {
  const { user } = useContext(UserContext);
  const { logout } = useLogin();
  const navigate = useNavigate();

  const changePage = (page: string) => {
    navigate(page);
  };

  const {
    navigateToDailyCalendarPage,
    navigateToClientsPage,
    navigateToAddressesPage,
    navigateToTeamsPage,
    navigateToJobsPage,
    navigateToUsersPage,
    navigateToDailyJobsPage,
    navigateToTeamsCalendarPage,
    navigateToClockInOutPage,
    navigateToSummaryOverviewPage,
    navigateToSettingsPage,
  } = useReactOperations();

  const { isPhone } = useScreenOperations();
  const { fetchCompanySquareLogo, companySquareLogoSignedUrl } = useAccounts();

  useEffect(() => {
    fetchCompanySquareLogo();
  }, []);

  return (
    <div className={classNames(styles.container, open ? styles.visible : styles.gone)} onClick={onClose}>
      <div className={styles.drawer}>
        <div className={styles.icon}>
          <IconButton onClick={onClose}>
            <Icon>
              <DehazeIcon />
            </Icon>
          </IconButton>
          {companySquareLogoSignedUrl && <img src={companySquareLogoSignedUrl} alt="" />}
        </div>
        <div className={styles.items}>
          {user?.permissions.pages.summary && (
            <NavigationItem title={'Overview'} onClick={() => navigateToSummaryOverviewPage()} />
          )}
          {user?.permissions.pages.dailyCalendar && !isPhone && (
            <NavigationItem title={'Calender'} onClick={() => navigateToDailyCalendarPage()} />
          )}
          {user?.permissions.pages.clients && !isPhone && (
            <NavigationItem title={'Clients'} onClick={navigateToClientsPage} />
          )}
          {user?.permissions.pages.addresses && !isPhone && (
            <NavigationItem title={'Addresses'} onClick={navigateToAddressesPage} />
          )}
          {user?.permissions.pages.teams && !isPhone && (
            <NavigationItem title={'Teams'} onClick={navigateToTeamsPage} />
          )}
          {user?.permissions.pages.teamsCalendar && !isPhone && (
            <NavigationItem title={'Teams Calendar'} onClick={navigateToTeamsCalendarPage} />
          )}
          {user?.permissions.pages.jobs && !isPhone && <NavigationItem title={'Jobs'} onClick={navigateToJobsPage} />}
          {user?.permissions.pages.users && !isPhone && (
            <NavigationItem title={'Staff'} onClick={navigateToUsersPage} />
          )}
          {user?.permissions.pages.dailyJobs && (
            <NavigationItem title={'Daily Jobs'} onClick={() => navigateToDailyJobsPage()} />
          )}
          {!!user && (
            <NavigationItem
              title={user?.clockedIn ? 'Clock Out' : 'Clock In'}
              onClick={() => navigateToClockInOutPage()}
            />
          )}

          {user?.permissions.pages.settings && !isPhone && (
            <NavigationItem title={'Settings'} onClick={() => navigateToSettingsPage()} />
          )}
          {!!user && (
            <NavigationItem
              title={'Logout'}
              onClick={() => {
                logout();
                changePage('/login');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
