import React, { ReactElement } from 'react';
import { CreateEavestrough, CreateOther, CreateWindow } from 'types';
import QuoteItem from '../QuoteItem';
import { getEavesExtras, getWindowExtras } from 'lib/jobs';
import styles from './QuoteSummaryTable.module.scss';

interface QuoteSummaryTableProps {
  windowQuotes?: Array<CreateWindow>;
  eavesQuotes?: Array<CreateEavestrough>;
  otherQuotes?: Array<CreateOther>;
  deleteQuote: (type: string, index: number) => void;
  setWindowQuoteDetails: (deatails: CreateWindow) => void;
  setEavesQuoteDetails: (deatails: CreateEavestrough) => void;
  setOtherQuoteDetails: (deatails: CreateOther) => void;
}

function QuoteSummaryTable({
  windowQuotes,
  eavesQuotes,
  otherQuotes,
  deleteQuote,
  setWindowQuoteDetails,
  setEavesQuoteDetails,
  setOtherQuoteDetails,
}: QuoteSummaryTableProps): ReactElement {
  return (
    <div className={styles.container}>
      <table>
        <tr>
          <th className={styles.firstColumn}>Include in Quote</th>
          <th>Description</th>
          <th>Extras</th>
          <th>Price</th>
          <th></th>
        </tr>
        {windowQuotes &&
          windowQuotes.map((quote, index) => {
            return (
              <QuoteItem
                includeInQuote={!!quote.showToClient}
                key={`window-${index}`}
                extras={getWindowExtras(quote)}
                description={quote.description || ''}
                onEdit={() => setWindowQuoteDetails({ ...quote })}
                onDelete={() => deleteQuote('window', index)}
                price={quote.price}
              />
            );
          })}
        {eavesQuotes &&
          eavesQuotes.map((quote, index) => {
            return (
              <QuoteItem
                includeInQuote={!!quote.showToClient}
                key={`eaves-${index}`}
                extras={getEavesExtras(quote)}
                description={quote.description || ''}
                onEdit={() => setEavesQuoteDetails({ ...quote })}
                onDelete={() => deleteQuote('eaves', index)}
                price={quote.price}
              />
            );
          })}
        {otherQuotes &&
          otherQuotes.map((quote, index) => {
            return (
              <QuoteItem
                includeInQuote={!!quote.showToClient}
                key={`window-${index}`}
                description={quote.description || ''}
                onEdit={() => setOtherQuoteDetails({ ...quote })}
                onDelete={() => deleteQuote('other', index)}
                price={quote.price}
              />
            );
          })}
      </table>
    </div>
  );
}

export default QuoteSummaryTable;
