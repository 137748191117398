import Layout from 'Base';
import styles from './Addresses.module.scss';
import useAddresses from 'hooks/useAddresses.hook';
import React, { ReactElement, useEffect, useState } from 'react';
import { Address } from 'types';
import TableData from 'components/Base/Table/components/TableData';
import Table from 'components/Base/Table';
import TableRow from 'components/Base/Table/components/TableRow';
import AddEditAddressModal from 'components/Modals/AddEditAddressModal';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useReactOperations from 'hooks/useReactOperations.hook';
import useDebounce from 'hooks/useDebounce.hooks';
import { dateFormats, formatDate } from 'lib/date';
import TextSearch from 'components/TextSearch';
import Button from 'components/Base/Button';
import { SnackBarType } from 'components/SnackBar/SnackBar';
import MultiChoiceModal, { MultiChoiceButton } from 'components/Modals/MultiChoiceModal';
import AddEditJobModal from 'components/Modals/AddEditJobModal';

function Addresses(): ReactElement {
  const getHeader = () => {
    return [
      <TableData key={1} data={'Address'} id={'address-header'} widthPercent={30} />,
      <TableData key={2} data={'Client'} id={'client-header'} widthPercent={30} />,
      <TableData key={3} data={'Last Job'} id={'last-job-header'} widthPercent={20} />,
      <TableData key={4} data={'Next Job'} id={'next-job-header'} widthPercent={20} />,
    ];
  };

  const [searchFilter, setSearchFilter] = useState<string>('');
  const [openAddEditAddressModal, setOpenAddEditAddressModel] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>();
  const [openCreateJobModal, setOpenCreateJobModal] = useState<boolean>(false);
  const [openMultiSelectModal, setOpenMultiSelectModal] = useState<boolean>(false);
  const [newAddressId, setNewAddressId] = useState<number>();
  const { addresses, addressErrorMessage, addressErrorTitle, resetAddressError, fetchAddresses } = useAddresses();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: addressErrorTitle, message: addressErrorMessage, reset: resetAddressError }],
  });

  const { navigateToAddressPage } = useReactOperations();

  const debounceSearch = useDebounce(searchFilter, 300);

  const selectAddress = (address: Address) => {
    navigateToAddressPage(address.id);
  };

  const onSuccess = async (newAddressId?: number) => {
    if (newAddressId) {
      setOpenMultiSelectModal(true);
      setNewAddressId(newAddressId);
    }
    setOpenAddEditAddressModel(false);
    setSnackBarMessage('Address Created');
    fetchAddresses({ searchFilter: debounceSearch });
  };

  const getNextStepButtons = (): Array<MultiChoiceButton> => {
    const buttons: Array<MultiChoiceButton> = [];
    if (!newAddressId) return buttons;
    buttons.push({
      label: 'Go To Address Page',
      onClick: () => navigateToAddressPage(newAddressId),
    });
    if (newAddressId) {
      buttons.push({
        label: 'Create Job',
        onClick: () => {
          setOpenCreateJobModal(true);
          setOpenMultiSelectModal(false);
        },
      });
    }
    return buttons;
  };

  useEffect(() => {
    fetchAddresses({ searchFilter: debounceSearch });
  }, [debounceSearch]);

  return (
    <Layout
      title={'Addresses'}
      openAlert={!!openAlert}
      alertTitle={alertTitle}
      alertMessage={alertMessage}
      snackBarMessage={snackBarMessage}
      showSnackBar={!!snackBarMessage}
      closeSnackBar={() => setSnackBarMessage(undefined)}
      snackBarType={SnackBarType.SUCCESS}
      onAlertClose={closeAlertModal}
    >
      <div className={styles.container}>
        <section id="toolbar" className={styles.toolbar}>
          <TextSearch value={searchFilter} onChange={setSearchFilter} />
          <Button title="New Address" onClick={() => setOpenAddEditAddressModel(true)} />
        </section>
        <div className={styles.listContainer}>
          <Table header={getHeader()}>
            {addresses.map((address, index) => {
              return (
                <TableRow key={index} onClick={() => selectAddress(address)}>
                  <TableData data={address.fullAddress} id={`address-${address.id}`} widthPercent={30} />
                  <TableData data={address.client.fullName} id={`client-${address.id}`} widthPercent={30} />
                  <TableData
                    data={formatDate(address.latestCompletedJob, dateFormats.abbreviatedPretty)}
                    id={`last-job-${address.id}`}
                    widthPercent={20}
                  />
                  <TableData
                    data={formatDate(address.nextJob, dateFormats.abbreviatedPretty)}
                    id={`next-job-${address.id}`}
                    widthPercent={20}
                  />
                </TableRow>
              );
            })}
          </Table>
        </div>
      </div>
      <AddEditAddressModal
        open={openAddEditAddressModal}
        onClose={() => setOpenAddEditAddressModel(false)}
        onSave={onSuccess}
      />
      <MultiChoiceModal
        title={'Next Step'}
        open={openMultiSelectModal}
        onClose={() => setOpenMultiSelectModal(false)}
        message={'Choose An Action'}
        buttons={getNextStepButtons()}
      />
      <AddEditJobModal
        open={openCreateJobModal}
        onClose={() => setOpenCreateJobModal(false)}
        addressId={newAddressId}
        onSave={() => {
          setOpenCreateJobModal(false);
        }}
      />
    </Layout>
  );
}

export default Addresses;
