import React, { ReactElement, useEffect } from 'react';
import styles from './ConfirmJob.module.scss';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { ClientProfile } from 'types';
import useAccounts from 'hooks/useAccounts.hook';
import useJobs from 'hooks/useJobs.hook';
import { JOB_STATUSES } from 'lib/jobs';

function ConfirmJob(): ReactElement {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jwtToken = queryParams.get('jwt') || undefined;
  const clientProfile: ClientProfile = jwtDecode(jwtToken || '');

  const { fetchCompanySquareLogo, companySquareLogoSignedUrl } = useAccounts();

  const { changeStatus, fetchJob, job, jobErrorMessage } = useJobs();

  const updateStatus = async () => {
    await changeStatus({ jobId: clientProfile.jobId, statusId: JOB_STATUSES.CONFIRMED }, jwtToken);
    fetchJob(clientProfile.jobId, jwtToken);
  };

  useEffect(() => {
    if (!job) return;
  }, [job]);

  useEffect(() => {
    fetchCompanySquareLogo(jwtToken);
    updateStatus();
  }, []);

  return clientProfile.pages.confirm ? (
    (job && job.status.id === 2) || jobErrorMessage ? (
      <div className={styles.container}>
        <div className={styles.content}>
          {companySquareLogoSignedUrl && <img src={companySquareLogoSignedUrl} alt="" />}
          {!jobErrorMessage ? (
            <div>{`You have confirmed work to be completed at ${job?.address.fullAddress}`}</div>
          ) : (
            <div>{`Job status already changed`}</div>
          )}
          <div>{`If you have to cancel your appontment please contact the office`}</div>
        </div>
      </div>
    ) : (
      <div className={styles.info}>Confirming ...</div>
    )
  ) : (
    <div className={styles.info}>Error Finding Page</div>
  );
}

export default ConfirmJob;
