import { useState } from 'react';
import {
  Client,
  CreateClient,
  PhoneType,
  ClientSearch,
  CreatedClient,
  EmailContact,
  Unsubscribe,
  SubscribeEmail,
} from 'types';
import { ClientsApi } from 'api';
import useErrorsDetails from './useErrors.hook';
import { handleApiError } from 'lib/errors';

export interface ClientsInfo {
  clientsLoading: boolean;
  clients: Array<Client>;
  phoneTypes: Array<PhoneType>;
  client: Client | undefined;
  clientsErrorMessage: string;
  clientsErrorTitle: string;
  resetClientError: () => void;
  fetchEmail: (id: number, token?: string) => Promise<EmailContact | undefined>;
  getClient: (id: number | undefined, token?: string) => void;
  fetchClients: (params?: ClientSearch) => void;
  fetchPhoneTypes: () => void;
  createClient: (data: CreateClient) => Promise<CreatedClient>;
  updateClient: (id: number, data: CreateClient) => Promise<void>;
  setClients: (clients: Array<Client>) => void;
  deleteClient: (id: number) => Promise<void>;
  clearClients: () => void;
  unsubscribeEmail: (clientId: number, data: Unsubscribe, token?: string) => Promise<void>;
  subscribeEmail: (clientId: number, data: SubscribeEmail, token?: string) => Promise<void>;
}

function useClients(): ClientsInfo {
  const [clients, setClients] = useState<Array<Client>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneTypes, setPhoneTypes] = useState<Array<PhoneType>>([]);
  const [client, setClient] = useState<Client>();

  const { handleError, errorMessage, errorTitle, resetError } = useErrorsDetails();

  const fetchClients = async (params?: ClientSearch) => {
    try {
      setLoading(true);
      const clientsList = await ClientsApi.fetchClients(params);
      setClients(clientsList);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
    }
    setLoading(false);
  };

  const fetchEmail = async (id: number, token?: string): Promise<EmailContact | undefined> => {
    try {
      setLoading(true);
      return await ClientsApi.fetchEmail(id, token);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
    }
    setLoading(false);
    return;
  };

  const createClient = async (data: CreateClient): Promise<CreatedClient> => {
    try {
      setLoading(true);
      const clientData = await ClientsApi.createClient(data);
      setLoading(false);
      return clientData;
    } catch (error) {
      handleError('Error', handleApiError(error));
      setLoading(false);
      throw error;
    }
  };

  const updateClient = async (id: number, data: CreateClient): Promise<void> => {
    try {
      await ClientsApi.updateClient(id, data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      setLoading(false);
      throw error;
    }
  };

  const fetchPhoneTypes = async (): Promise<void> => {
    try {
      setLoading(true);
      const phoneTypesList = await ClientsApi.fetchPhoneTypes();
      setPhoneTypes(phoneTypesList);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    setLoading(false);
  };

  const getClient = async (clientId: number | undefined, token?: string) => {
    try {
      if (!clientId) return;
      setLoading(true);
      const selectedClient = await ClientsApi.getClient(clientId, token);
      setClient(selectedClient);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    setLoading(false);
  };

  const deleteClient = async (id: number) => {
    try {
      await ClientsApi.del(id);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const unsubscribeEmail = async (clientId: number, data: Unsubscribe, token?: string) => {
    try {
      await ClientsApi.unsubscribeEmail(clientId, data, token);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const subscribeEmail = async (clientId: number, data: SubscribeEmail, token?: string) => {
    try {
      await ClientsApi.subscribeEmail(clientId, data, token);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const clearClients = () => {
    setClients([]);
  };

  return {
    clientsLoading: loading,
    clients,
    phoneTypes,
    client,
    clientsErrorMessage: errorMessage,
    clientsErrorTitle: errorTitle,
    unsubscribeEmail,
    subscribeEmail,
    fetchEmail,
    resetClientError: resetError,
    getClient,
    fetchClients,
    fetchPhoneTypes,
    createClient,
    updateClient,
    setClients,
    clearClients,
    deleteClient,
  };
}

export default useClients;
