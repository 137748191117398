import React, { ReactElement, ReactNode } from 'react';
import styles from './NavigationItem.module.scss';

interface NavigationItemProps {
  title: ReactNode;
  onClick: () => void;
}
function NavigationItem({ title, onClick }: NavigationItemProps): ReactElement {
  return (
    <div className={styles.item} onClick={onClick}>
      {title}
    </div>
  );
}

export default NavigationItem;
