import React, { ReactElement } from 'react';
import EditButton from '@material-ui/icons/Edit';
import DeleteButton from '@material-ui/icons/Delete';

interface QutoeItemProps {
  includeInQuote: boolean;
  description: string;
  extras?: string;
  price?: number;
  onEdit: () => void;
  onDelete: () => void;
}

function QuoteItem({ onDelete, onEdit, description, extras, price, includeInQuote }: QutoeItemProps): ReactElement {
  return (
    <tr>
      <td>{includeInQuote ? 'Yes' : 'No'}</td>
      <td>{description}</td>
      <td>{extras}</td>
      <td>{price ? `$${price}` : undefined}</td>
      <td>
        <EditButton onClick={onEdit} />
        <DeleteButton onClick={onDelete} />
      </td>
    </tr>
  );
}

export default QuoteItem;
