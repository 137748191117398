import useReactOperations from 'hooks/useReactOperations.hook';
import React, { ReactElement, ReactNode } from 'react';
import styles from './NavigationLink.module.scss';

interface NavigationLinkProps {
  link: string;
  children?: ReactNode;
}
function NavigationLink({ link, children }: NavigationLinkProps): ReactElement {
  const { navigateToPage } = useReactOperations();

  return (
    <div className={styles.nav} onClick={() => navigateToPage(link)}>
      {children}
    </div>
  );
}

export default NavigationLink;
