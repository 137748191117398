import React, { ReactElement, useEffect, useState } from 'react';
import styles from './NewAccount.module.scss';
import TextField from 'components/Base/TextField';
import { Country, CreateAccount, CreateAccountValidation, CreateFile, Region } from 'types';
import { formatPostalCode } from 'lib/formatting';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import Dropdown from 'components/Base/Dropdown';
import useAddresses from 'hooks/useAddresses.hook';
import ImageUpload from 'components/ImageUpload';
import GoogleMapView, { GoogleMarker } from 'components/GoogleMap/GoogleMapView';
import useAccounts from 'hooks/useAccounts.hook';
import Button from 'components/Base/Button';

function NewAccount(): ReactElement {
  const [unit, setUnit] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [city, setCity] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [country, setCountry] = useState<Country>();
  const [region, setRegion] = useState<Region>();
  const [companyName, setCompanyName] = useState<string>();
  const [hstNumber, setHstNumber] = useState<string>();

  const [hasValidated, setHasValidated] = useState<boolean>(false);
  const [validation, setValidation] = useState<CreateAccountValidation>();
  const [squareLogoFile, setSquareLogoFile] = useState<CreateFile | null>();
  const [centerMarker, setCenterMarker] = useState<Array<GoogleMarker>>([]);

  const [outboundEmail, setOutboundEmail] = useState<string>();

  const { fetchCountries, fetchRegions, countries, regions } = useAddresses();
  const { create } = useAccounts();

  const onSave = async () => {
    const data: CreateAccount = {
      companyName,
      unit,
      address,
      city,
      postalCode,
      regionId: region?.id,
      countryId: country?.id,
      outboundEmail,
      hstNumber,
      squareLogoFile,
    };

    await create(data);
  };

  const validate = (): boolean => {
    const errors: CreateAccountValidation = {};

    let success = true;

    if (address) {
      errors.address = 'Provide a street name';
      success = false;
    }

    if (postalCode) {
      errors.postalCode = 'Provide a postal code';
      success = false;
    }

    if (city) {
      errors.city = 'Provide a city';
      success = false;
    }
    if (country) {
      errors.country = 'Provide a country';
      success = false;
    }

    if (region) {
      errors.region = 'Provide a region';
      success = false;
    }

    setValidation(errors);
    setHasValidated(true);
    return success;
  };

  useEffect(() => {
    if (!country) return;
    fetchRegions(country.id);
  }, [country]);

  useEffect(() => {
    if (!hasValidated) return;
    validate();
  }, [companyName, country, region, address, postalCode, city]);

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <div className={styles.row}>
          <TextField value={companyName} setValue={setCompanyName} name={'companyName'} hint={'Company Name'} />
        </div>
        <div className={styles.row}>
          <TextField value={hstNumber} setValue={setHstNumber} name={'hst'} hint={'HST Number'} />
        </div>
        <TextField value={outboundEmail} setValue={setOutboundEmail} name={'email'} hint={'Invoice / Quote Email'} />
        <div className={styles.row}>
          <div className={styles.unit}>
            <TextField value={unit} setValue={setUnit} name={'unit'} hint={'Unit'} />
          </div>
          <div className={styles.number}>
            <TextField
              value={address}
              setValue={setAddress}
              name={'address'}
              hint={'Address'}
              error={validation?.address}
            />
          </div>
        </div>

        <div className={styles.row}>
          <TextField
            value={city}
            setValue={setCity}
            name={'city'}
            hint={'City'}
            maxLength={50}
            error={validation?.city}
            hasError={true}
          />
        </div>

        <div className={styles.row}>
          <TextField
            value={formatPostalCode(postalCode)}
            setValue={setPostalCode}
            name={'postalCode'}
            hint={'Postal Code'}
            maxLength={7}
            error={validation?.postalCode}
            hasError={true}
          />
          <Dropdown
            hint={'Country'}
            items={countries.map((country) => {
              return (
                <DropdownItem
                  key={country.id}
                  id={country.id}
                  value={<div>{country.name}</div>}
                  onSelect={() => setCountry(country)}
                />
              );
            })}
            error={validation?.country}
            selectedValue={country?.name}
          />
          <Dropdown
            hint={'Region'}
            items={regions.map((region) => {
              return (
                <DropdownItem
                  key={region.id}
                  id={region.id}
                  value={<div>{region.name}</div>}
                  onSelect={() => setRegion(region)}
                />
              );
            })}
            error={validation?.region}
            selectedValue={region?.name}
          />
          <div></div>
          <ImageUpload onChange={setSquareLogoFile} edit={true} field={'Square Logo:'} />
        </div>
        <GoogleMapView
          width={700}
          height={300}
          markers={centerMarker}
          setMarker={(marker: GoogleMarker) => setCenterMarker([marker])}
        />
        <Button onClick={onSave} />
      </div>
    </div>
  );
}

export default NewAccount;
