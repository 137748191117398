import Layout from 'Base';
import Table from 'components/Base/Table';
import TableData from 'components/Base/Table/components/TableData';
import TableRow from 'components/Base/Table/components/TableRow';
import React, { ReactElement, useEffect, useState } from 'react';
import styles from './Teams.module.scss';
import useTeams from 'hooks/useTeams.hook';
import { Team } from 'types';
import AddEditTeamModal from 'components/Modals/AddEditTeamModal';
import ColorIndicator from './components/ColorIndicator';
import AlertModal from 'components/AlertModal';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useDebounce from 'hooks/useDebounce.hooks';
import useReactOperations from 'hooks/useReactOperations.hook';
import Button from 'components/Base/Button';
import TextSearch from 'components/TextSearch';
import { SnackBarType } from 'components/SnackBar/SnackBar';

function Teams(): ReactElement {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [openAddEditTeamModal, setOpenAddEditTeamModal] = useState<boolean>(false);

  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarType, setSnackBarType] = useState<SnackBarType>();
  const [snackBarMessage, setSnackBarMessage] = useState<string>();

  const { navigateToPage } = useReactOperations();

  const getHeader = () => {
    return [
      <TableData key={1} data={'Name'} id={'name-header'} widthPercent={25} />,
      <TableData key={2} data={'Members'} id={'members-header'} widthPercent={47} />,
      <TableData key={3} data={'Production Rate'} id={'production-rate-header'} widthPercent={15} />,
      <TableData key={4} data={'Colour'} id={'colour-header'} widthPercent={13} />,
    ];
  };

  const { teams, teamErrorMessage, teamErrorTitle, resetTeamError, getTeams } = useTeams();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: teamErrorTitle, message: teamErrorMessage, reset: resetTeamError }],
  });

  const debounceSearch = useDebounce(searchFilter, 300);

  const selectTeam = (team: Team) => {
    navigateToPage(`/team/${team.id}`);
  };

  const onSave = () => {
    setSnackBarMessage('Team Created');
    setSnackBarType(SnackBarType.SUCCESS);
    setShowSnackBar(true);
    getTeams();
    setOpenAddEditTeamModal(false);
  };

  useEffect(() => {
    getTeams(debounceSearch);
  }, [debounceSearch]);

  return (
    <Layout
      title="Teams"
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
      showSnackBar={showSnackBar}
      snackBarType={snackBarType}
      snackBarMessage={snackBarMessage}
      closeSnackBar={() => setShowSnackBar(false)}
    >
      <div className={styles.container}>
        <section id="toolbar" className={styles.toolbar}>
          <TextSearch value={searchFilter} onChange={setSearchFilter} />
          <Button title="New Team" onClick={() => setOpenAddEditTeamModal(true)} />
        </section>
        <div className={styles.listContainer}>
          <Table header={getHeader()}>
            {teams.map((team, index) => {
              return (
                <TableRow key={index} onClick={() => selectTeam(team)}>
                  <TableData data={team.name} id={`name-${team.id}`} widthPercent={25} />
                  <TableData
                    data={team.members.map((member) => member.fullName).join(', ')}
                    id={`member-${team.id}`}
                    widthPercent={47}
                  />
                  <TableData
                    data={team.hourlyProduction ? `$${team.hourlyProduction}/hour` : '---'}
                    id={`production-${team.id}`}
                    widthPercent={15}
                  />
                  <TableData
                    data={<ColorIndicator id={`${team.id}`} colorHex={team.hexCode} />}
                    id={`colour-${team.id}`}
                    widthPercent={13}
                  />
                </TableRow>
              );
            })}
          </Table>
        </div>
      </div>
      <AddEditTeamModal open={openAddEditTeamModal} onClose={() => setOpenAddEditTeamModal(false)} onSave={onSave} />

      <AlertModal
        open={!!openAlert}
        title={alertTitle}
        message={alertMessage}
        onClose={() => {
          resetTeamError();
        }}
      />
    </Layout>
  );
}
export default Teams;
