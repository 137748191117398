import classNames from 'classnames';
import moment from 'moment';
import React, { MouseEvent, ReactElement, useEffect, useState } from 'react';
import styles from './TimeSelector.module.scss';

type HighlightRanges = {
  start: string;
  end: string;
};

interface TimeSelectorProps {
  time?: string;
  setTime: (newDate: string) => void;
  title?: string;
  error?: string;
  hasError?: boolean;
  step?: number;
  clockType?: string;
  name?: string;
  min?: string;
  max?: string;
  highlightRanges?: Array<HighlightRanges>;
}

export enum CLOCK_TYPE {
  TWELVE_HOUR = '12h',
  TWENTY_FOUR_HOUR = '24h',
}

function TimeSelector({
  time,
  setTime,
  title,
  error,
  hasError,
  step = 1,
  name = 'time',
  min,
  max,
  highlightRanges,
  clockType = CLOCK_TYPE.TWENTY_FOUR_HOUR,
}: TimeSelectorProps): ReactElement {
  const getMoment = (time?: string) => {
    return moment(time, 'HH:mm');
  };

  const menuId = `${name}-menu`;
  const amPm = ['AM', 'PM'];
  const hours: Array<string> = [];
  const minutes: Array<string> = [];
  const savedTime = time ? getMoment(time) : undefined;
  const savedMinute = savedTime?.get('minute');
  const savedHour = savedTime?.get('hour');

  const [timeOfDay, setTimeOfDay] = useState<string>(amPm[0]);
  const [isActive, setIsActive] = useState<boolean>(false);

  const selectedHour = time
    ? clockType === CLOCK_TYPE.TWELVE_HOUR
      ? getMoment(time).format('h')
      : getMoment(time).format('HH')
    : undefined;
  const selectedMinute = time ? getMoment(time).format('mm') : undefined;
  const minMoment = getMoment(min);
  const minHour = minMoment.get('hour');
  const minMinute = minMoment.get('minute');
  const maxMoment = getMoment(max);
  const maxHour = maxMoment.get('hour');
  const maxMinute = maxMoment.get('minute');
  for (let i = 0; i < 24; i++) {
    if (clockType === CLOCK_TYPE.TWELVE_HOUR && i === 0) continue;
    if (clockType === CLOCK_TYPE.TWELVE_HOUR && i > 12) break;
    let value = `${i}`;
    if (i < 10 && clockType === CLOCK_TYPE.TWENTY_FOUR_HOUR) value = `0${i}`;

    if (min) {
      const hourEqualToMin = i === minHour;
      if (i < minHour || (hourEqualToMin && minMinute === 60 - step)) continue;

      if (savedTime && savedMinute !== undefined) {
        if (savedTime && hourEqualToMin && minMinute >= savedMinute && i === savedHour) {
          setTime(savedTime.set('minute', minMinute + step).format('HH:mm'));
        }
      }
    }

    if (max) {
      const hourEqualToMax = i === maxHour;
      if (i > maxHour || (hourEqualToMax && maxMinute === 0)) break;

      if (savedTime && savedMinute !== undefined) {
        if (savedTime && hourEqualToMax && maxMinute <= savedMinute && i === savedHour) {
          const newTime = savedTime.set('minute', maxMinute - step).format('HH:mm');
          setTime(newTime);
        }
      }
    }
    hours.push(value);
  }

  for (let i = 0; i < 60; i += step) {
    let value = `${i}`;
    if (i < 10) value = `0${i}`;
    if (savedTime && min && time) {
      if (i <= minMinute && minHour === savedHour) continue;
    }

    if (savedTime && max && time) {
      if (i >= maxMinute && maxHour === savedHour) break;
    }
    minutes.push(value);
  }

  const setHour = (hour: string) => {
    const newTime = time ? getMoment(time) : moment();
    let hourInt = +hour;
    if (clockType === CLOCK_TYPE.TWELVE_HOUR && timeOfDay === amPm[0]) hourInt %= 12;
    if (clockType === CLOCK_TYPE.TWELVE_HOUR && timeOfDay === amPm[1]) hourInt += 12;

    newTime.set('hour', hourInt);
    if (!savedTime) {
      newTime.set('minute', 0);
    }
    setTime(newTime.format('HH:mm'));
  };

  const setMinute = (minute: string) => {
    const newTime = time ? getMoment(time) : moment();
    newTime.set('minute', +minute);
    setTime(newTime.format('HH:mm'));
  };

  const setAmPm = (timeOfDay: string) => {
    if (!time || !selectedHour) return;
    const newTime = getMoment(time);
    const hour = newTime.get('hour');
    if (timeOfDay === amPm[0]) {
      if (hour >= 12) newTime.set('hour', hour - 12);
    } else {
      if (hour < 12) newTime.set('hour', hour + 12);
    }
    setTimeOfDay(timeOfDay);
    setTime(newTime.format('HH:mm'));
  };

  const getTime = () => {
    if (!selectedHour || !selectedMinute) return;
    return `${selectedHour}:${selectedMinute}${clockType === CLOCK_TYPE.TWELVE_HOUR ? ' ' + timeOfDay : ''}`;
  };

  const isHourHighlighted = (hour: string) => {
    if (!highlightRanges) return true;
    for (const range of highlightRanges) {
      const startHour = moment(range.start, 'HH:mm').get('hour');
      const endHour = moment(range.end, 'HH:mm').get('hour');
      if (+hour <= endHour && +hour >= startHour) return true;
    }
    return false;
  };

  const isMinuteHighlighted = (minute: string) => {
    if (!highlightRanges || !selectedHour) return true;
    for (const range of highlightRanges) {
      const startHour = moment(range.start, 'HH:mm');
      const endHour = moment(range.end, 'HH:mm');
      const current = moment().set('hour', +selectedHour).set('minute', +minute);

      if (current.isBetween(startHour, endHour, 'minutes', '[]')) return true;
    }
    return false;
  };

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsActive((prev) => !prev);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pageClickEvent = (e: any) => {
      const target = e.target as Element;
      if (
        !(
          [menuId, 'timeOfDay'].includes(target.id) ||
          hours.map((hour) => name + 'hour-' + hour).includes(target.id) ||
          minutes.map((minute) => name + 'minute-' + minute).includes(target.id)
        )
      ) {
        setIsActive(false);
      }
    };

    if (isActive) {
      window.addEventListener('click', pageClickEvent);
      const minuteId = selectedMinute ? name + 'minute-' + selectedMinute : name + 'minute-00';
      const hourId = selectedHour
        ? name + 'hour-' + selectedHour
        : name + 'hour-' + (clockType === CLOCK_TYPE.TWELVE_HOUR ? '8' : '08');
      document.getElementById(minuteId)?.scrollIntoView();
      document.getElementById(hourId)?.scrollIntoView();
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  return (
    <>
      <div>{title}</div>
      <div className={styles.menuContainer}>
        <button onClick={onClick} className={classNames(styles.menuTrigger, !!error && styles.btnError)} id={menuId}>
          <span id={menuId}>{getTime()}</span>
        </button>
        <div className={classNames(styles.menu, styles.time, isActive && styles.active, error && styles.error)}>
          <div className={styles.hours} id={'hours'}>
            {hours.map((hour) => (
              <div key={hour} className={styles.item}>
                <div
                  id={name + 'hour-' + hour}
                  className={classNames(
                    hour === selectedHour && styles.selected,
                    !isHourHighlighted(hour) && styles.shade
                  )}
                  onClick={() => setHour(hour)}
                >
                  {hour}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.minutes} id={'minutes'}>
            {minutes.map((minute) => (
              <div key={minute} className={styles.item}>
                <div
                  id={name + 'minute-' + minute}
                  key={minute}
                  className={classNames(
                    minute === selectedMinute && styles.selected,
                    !isMinuteHighlighted(minute) && styles.shade
                  )}
                  onClick={() => setMinute(minute)}
                >
                  {minute}
                </div>
              </div>
            ))}
          </div>
          {clockType === CLOCK_TYPE.TWELVE_HOUR && (
            <div className={styles.timeOfDay} id={'timeOfDay'}>
              {amPm.map((amPm) => (
                <div key={amPm} className={styles.item}>
                  <div
                    id={'timeOfDay'}
                    key={amPm}
                    className={classNames(amPm === timeOfDay && styles.selected)}
                    onClick={() => setAmPm(amPm)}
                  >
                    {amPm}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {hasError && <p className={styles.errorMessage}>{error}</p>}
    </>
  );
}

export default TimeSelector;
