import Button from 'components/Base/Button/Button.view';
import React, { ReactElement } from 'react';
import styles from './ConfirmationModal.module.scss';

interface ConfirmationModalProps {
  open: boolean;
  title?: string;
  message?: string;
  onClose?: () => void;
  onAccept?: () => void;
}
function ConfirmationModal({ open, title, message, onClose, onAccept }: ConfirmationModalProps): ReactElement {
  return (
    <>
      {open && (
        <div className={styles.background}>
          <div className={styles.container}>
            <label>{title}</label>
            <div className={styles.titleDivider} />
            <p>{message}</p>
            <div className={styles.buttons}>
              <div className={styles.button}>
                <Button onClick={onClose} title={'CANCEL'} border={false} />
              </div>
              <div className={styles.button}>
                <Button onClick={onAccept} title={'OK'} border={false} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ConfirmationModal;
