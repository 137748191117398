import { JobsApi } from 'api';
import { sqlToday } from 'lib/date';
import { handleApiError } from 'lib/errors';
import { PRESET_TYPE_EAVESTROUGH, PRESET_TYPE_WINDOW } from 'lib/jobs';
import { useState } from 'react';
import {
  JobType,
  Job,
  JobSubType,
  TaskType,
  Quote,
  CreateJob,
  CreateRequestJobChanges,
  RequestJobChanges,
  ConfirmRequestChanges,
  JobStatus,
  SubmitQuote,
  PaymentType,
  RecordPayment,
  WindowDetails,
  EavestroughDetails,
  OtherDetails,
  FetchJobsParams,
  FetchQuotesParams,
  CreatedJob,
  ChangeStatusParams,
  Communication,
} from 'types';
import useErrorsDetails from './useErrors.hook';

interface JobsInfo {
  jobs: Array<Job>;
  quotes: Array<Quote>;
  job: Job | undefined;
  jobsLoading: boolean;
  jobTypes: Array<JobType>;
  jobSubTypes: Array<JobSubType>;
  windowSubTypes: Array<JobSubType>;
  eavesSubTypes: Array<JobSubType>;
  jobErrorMessage: string;
  jobErrorTitle: string;
  taskTypes: Array<TaskType>;
  changeRequests: Array<RequestJobChanges>;
  statuses: Array<JobStatus>;
  paymentTypes: Array<PaymentType>;
  previousWindowJobs: Array<WindowDetails>;
  previousEavesJobs: Array<EavestroughDetails>;
  previousOtherJobs: Array<OtherDetails>;
  previousWindowQuotes: Array<WindowDetails>;
  previousEavesQuotes: Array<EavestroughDetails>;
  previousOtherQuotes: Array<OtherDetails>;
  communications: Array<Communication>;
  fetchStatuses: () => void;
  fetchTaskTypes: () => void;
  resetJobError: () => void;
  fetchJobSubTypes: () => void;
  fetchJobTypes: () => void;
  fetchJobs: (params: FetchJobsParams) => void;
  fetchQuotes: ({ searchFilter, clientId, taskTypeFilterIds, cleaningTypeFilterIds }: FetchQuotesParams) => void;
  fetchJob: (id: number, token?: string) => void;
  createJob: (job: CreateJob) => Promise<CreatedJob>;
  updateJob: (id: number, job: CreateJob) => Promise<void>;
  completeJob: (id: number) => Promise<void>;
  clearJob: () => void;
  requestChanges: (data: CreateRequestJobChanges) => Promise<void>;
  submitQuote: (data: SubmitQuote) => Promise<void>;
  fetchRequestChanges: (jobId: number) => void;
  confirmRequestChanges: (id: number, changes: ConfirmRequestChanges) => Promise<void>;
  deleteJob: (id: number) => Promise<void>;
  changeStatus: (params: ChangeStatusParams, token?: string) => Promise<void>;
  fetchPaymentTypes: () => void;
  recordPayment: (data: RecordPayment) => Promise<void>;
  fetchAddressJobs: (addressId: number) => Promise<void>;
  fetchCommunication: (jobId: number) => void;
}

function useJobs(): JobsInfo {
  const [jobs, setJobs] = useState<Array<Job>>([]);
  const [quotes, setQuotes] = useState<Array<Quote>>([]);
  const [job, setJob] = useState<Job>();
  const [loading, setLoading] = useState<boolean>(false);
  const [jobTypes, setJobTypes] = useState<Array<JobType>>([]);
  const [jobSubTypes, setJobSubTypes] = useState<Array<JobSubType>>([]);
  const [windowSubTypes, setWindowSubTypes] = useState<Array<JobSubType>>([]);
  const [eavesSubTypes, setEavesSubTypes] = useState<Array<JobSubType>>([]);
  const [changeRequests, setChangeRequests] = useState<Array<RequestJobChanges>>([]);
  const [taskTypes, setTaskType] = useState<Array<TaskType>>([]);
  const [statuses, setStatuses] = useState<Array<JobStatus>>([]);
  const [paymentTypes, setPaymentTypes] = useState<Array<PaymentType>>([]);
  const [previousWindowJobs, setPreviousWindowJobs] = useState<Array<WindowDetails>>([]);
  const [previousEavesJobs, setPreviousEavesJobs] = useState<Array<EavestroughDetails>>([]);
  const [previousOtherJobs, setPreviousOtherJobs] = useState<Array<OtherDetails>>([]);
  const [previousWindowQuotes, setPreviousWindowQuotes] = useState<Array<WindowDetails>>([]);
  const [previousEavesQuotes, setPreviousEavesQuotes] = useState<Array<EavestroughDetails>>([]);
  const [previousOtherQuotes, setPreviousOtherQuotes] = useState<Array<OtherDetails>>([]);
  const [communications, setCommunications] = useState<Array<Communication>>([]);

  const { handleError, errorMessage, errorTitle, resetError } = useErrorsDetails();

  const fetchStatuses = async () => {
    try {
      setLoading(true);
      const statuses = await JobsApi.fetchStatuses();
      setStatuses(statuses);
      setLoading(false);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };

  const fetchJobTypes = async () => {
    try {
      const types = await JobsApi.fetchJobTypes();
      setJobTypes(types);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };

  const fetchPaymentTypes = async () => {
    try {
      const types = await JobsApi.fetchPaymentTypes();
      setPaymentTypes(types);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };

  const fetchCommunication = async (jobId: number) => {
    const communications = await JobsApi.fetchCommunications(jobId);
    setCommunications(communications);
  };

  const fetchAddressJobs = async (addressId: number) => {
    try {
      const addressJobDetails = await JobsApi.fetchAddressJobDetails({ onBeforeDate: sqlToday(), addressId });
      setPreviousWindowJobs(addressJobDetails.windowDetails);
      setPreviousEavesJobs(addressJobDetails.eavesDetails);
      setPreviousOtherJobs(addressJobDetails.otherDetails);
      setPreviousWindowQuotes(addressJobDetails.windowQuotes);
      setPreviousEavesQuotes(addressJobDetails.eavesQuotes);
      setPreviousOtherQuotes(addressJobDetails.otherQuotes);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };

  const fetchJobSubTypes = async () => {
    try {
      const types = await JobsApi.fetchJobSubTypes();
      const windowSubTypes = types ? types.filter((type) => type.jobTypeId === PRESET_TYPE_WINDOW) : [];
      const eavesSubTypes = types ? types.filter((type) => type.jobTypeId === PRESET_TYPE_EAVESTROUGH) : [];
      setWindowSubTypes(windowSubTypes);
      setEavesSubTypes(eavesSubTypes);
      setJobSubTypes(types);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };

  const fetchJobs = async (params: FetchJobsParams) => {
    try {
      setLoading(true);
      const jobList = await JobsApi.fetchJobs(params);

      setJobs(jobList);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    setLoading(false);
  };

  const fetchJob = async (id: number, token?: string) => {
    try {
      const job = await JobsApi.fetchJob(id);
      setJob(job);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };

  const fetchQuotes = async ({ searchFilter, clientId }: FetchQuotesParams) => {
    try {
      const jobList = await JobsApi.fetchQuotes({ searchFilter, clientId });
      setQuotes(jobList);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };

  const submitQuote = async (data: SubmitQuote): Promise<void> => {
    try {
      await JobsApi.submitQuote(data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const fetchTaskTypes = async () => {
    try {
      const taskTypesList = await JobsApi.fetchTaskTypes();
      setTaskType(taskTypesList);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
    }
  };

  const fetchRequestChanges = async (jobId: number) => {
    try {
      const requests = await JobsApi.fetchChangeRequests({ jobId });
      setChangeRequests(requests);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
    }
  };

  const confirmRequestChanges = async (id: number, changes: ConfirmRequestChanges) => {
    try {
      await JobsApi.confirmRequestChanges(id, changes);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
    }
  };

  const recordPayment = async (data: RecordPayment) => {
    try {
      await JobsApi.recordPayment(data);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
    }
  };

  const changeStatus = async (params: ChangeStatusParams, token?: string) => {
    try {
      await JobsApi.changeStatus(params, token);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const createJob = async (data: CreateJob): Promise<CreatedJob> => {
    try {
      return await JobsApi.createJob(data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const updateJob = async (id: number, data: CreateJob): Promise<void> => {
    try {
      await JobsApi.updateJob(id, data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const completeJob = async (id: number): Promise<void> => {
    try {
      await JobsApi.completeJob(id);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const requestChanges = async (data: CreateRequestJobChanges): Promise<void> => {
    try {
      await JobsApi.requestChanges(data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const deleteJob = async (id: number): Promise<void> => {
    try {
      await JobsApi.del(id);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const clearJob = () => {
    setJob(undefined);
  };

  return {
    jobs,
    job,
    jobsLoading: loading,
    jobTypes,
    jobSubTypes,
    windowSubTypes,
    eavesSubTypes,
    jobErrorMessage: errorMessage,
    jobErrorTitle: errorTitle,
    taskTypes,
    changeRequests,
    quotes,
    statuses,
    paymentTypes,
    previousEavesJobs,
    previousEavesQuotes,
    previousOtherJobs,
    previousOtherQuotes,
    previousWindowJobs,
    previousWindowQuotes,
    communications,
    fetchCommunication,
    submitQuote,
    recordPayment,
    fetchPaymentTypes,
    fetchAddressJobs,
    changeStatus,
    fetchStatuses,
    updateJob,
    deleteJob,
    fetchQuotes,
    fetchRequestChanges,
    fetchTaskTypes,
    resetJobError: resetError,
    fetchJobSubTypes,
    fetchJobTypes,
    fetchJobs,
    fetchJob,
    createJob,
    clearJob,
    completeJob,
    requestChanges,
    confirmRequestChanges,
  };
}

export default useJobs;
