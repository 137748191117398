import { useState } from 'react';
import { TimeCard, User, UserType, CreateUser, CreateClockIn, CreateClockOut } from 'types';
import { UsersApi } from 'api';
import { handleApiError } from 'lib/errors';
import useErrorsDetails from './useErrors.hook';
import useLogin from 'action/useLogin';

export interface UsersInfo {
  userLoading: boolean;
  users: Array<User>;
  user: User | undefined;
  userTypes: Array<UserType>;
  userErrorMessage: string;
  userErrorTitle: string;
  timeCard: TimeCard | undefined;
  timeCards: Array<TimeCard>;
  fetchTimeCards: (date: string, userId?: number) => void;
  clockIn: (data: CreateClockIn) => Promise<void>;
  clockOut: (data: CreateClockOut) => Promise<void>;
  fetchTimeCard: (date?: string, userId?: number) => void;
  resetUserError: () => void;
  resetUsersHook: () => void;
  fetchUserTypes: () => void;
  fetchUser: (id: number | undefined) => void;
  fetchUsers: (searchFilter?: string) => void;
  create: (data: CreateUser) => Promise<void>;
  update: (id: number, data: CreateUser) => Promise<void>;
}

function useUsers(): UsersInfo {
  const [users, setUsers] = useState<Array<User>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [userTypes, setUserTypes] = useState<Array<UserType>>([]);
  const [timeCard, setTimeCard] = useState<TimeCard>();
  const [timeCards, setTimeCards] = useState<Array<TimeCard>>([]);

  const { handleError, errorMessage, errorTitle, resetError } = useErrorsDetails();

  const { getRefreshToken } = useLogin();

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const fetchUsers = async (searchFilter?: string) => {
    startLoading();
    try {
      const usersList = await UsersApi.fetchUsers(searchFilter);
      setUsers(usersList);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    stopLoading();
  };

  const fetchUserTypes = async () => {
    startLoading();
    try {
      const userTypesList = await UsersApi.fetchUserTypes();
      setUserTypes(userTypesList);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    stopLoading();
  };

  const create = async (data: CreateUser): Promise<void> => {
    startLoading();

    try {
      await UsersApi.createUser(data);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
      throw error;
    }
    stopLoading();
  };

  const update = async (id: number, data: CreateUser): Promise<void> => {
    startLoading();
    try {
      await UsersApi.updateUser(id, data);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    stopLoading();
  };

  const fetchUser = async (userId: number | undefined) => {
    if (!userId) return;
    startLoading();
    try {
      const selectedUser = await UsersApi.fetchUser(userId);
      setUser(selectedUser);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    stopLoading();
  };

  const fetchTimeCard = async (date?: string, userId?: number) => {
    startLoading();
    try {
      const timeCard = await UsersApi.fetchTimeCard(date, userId);
      setTimeCard(timeCard);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    stopLoading();
  };

  const fetchTimeCards = async (date: string, userId?: number) => {
    startLoading();
    try {
      const timeCards = await UsersApi.fetchTimeCards(date, userId);
      setTimeCards(timeCards);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    stopLoading();
  };

  const clockIn = async (data: CreateClockIn): Promise<void> => {
    startLoading();
    try {
      await UsersApi.clockIn(data);
      await getRefreshToken();
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    stopLoading();
  };

  const clockOut = async (data: CreateClockOut): Promise<void> => {
    startLoading();
    try {
      await UsersApi.clockOut(data);
      await getRefreshToken();
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    stopLoading();
  };

  const resetUsersHook = () => {
    setUser(undefined);
    setUsers([]);
    setUserTypes([]);
    resetError();
  };

  return {
    userLoading: loading,
    users,
    user,
    userTypes,
    userErrorMessage: errorMessage,
    userErrorTitle: errorTitle,
    timeCard,
    timeCards,
    fetchTimeCards,
    clockIn,
    clockOut,
    fetchTimeCard,
    resetUserError: resetError,
    resetUsersHook,
    fetchUserTypes,
    fetchUser,
    fetchUsers,
    create,
    update,
  };
}

export default useUsers;
