import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';

interface CheckboxProps {
  checked: boolean;
  label?: string;
  onClick: (checked: boolean) => void;
  styling?: string;
  disabled?: boolean;
}
function Checkbox({ checked, label, onClick, styling, disabled }: CheckboxProps): ReactElement {
  return (
    <div className={classNames(styles.checkbox, styling, disabled && styles.disabled)}>
      <div className={styles.border} onClick={() => onClick(!checked)}>
        <div className={classNames(styles.indicator, checked && styles.checked)} />
      </div>
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
}

export default Checkbox;
