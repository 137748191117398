import classNames from 'classnames';
import React, { ReactElement, useEffect } from 'react';
import styles from './SnackBar.module.scss';

export enum SnackBarType {
  ERROR = 'error',
  SUCCESS = 'success',
}
interface SnackBarProps {
  open?: boolean;
  message?: string;
  type?: SnackBarType;
  close?: () => void;
}

function SnackBar({ open, message, type = SnackBarType.SUCCESS, close }: SnackBarProps): ReactElement {
  useEffect(() => {
    if (!open || !close) return;
    setTimeout(close, 3000);
  }, [open]);
  return open ? (
    <div className={styles.container}>
      <div className={classNames(styles.message, type === SnackBarType.SUCCESS ? styles.success : styles.error)}>
        {message}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default SnackBar;
