import React, { ReactElement } from 'react';
import ExpandedIcon from '@material-ui/icons/ExpandMore';
import CollaspedIcon from '@material-ui/icons/ChevronRight';
import styles from './TypeTitleBar.module.scss';

interface TypeTitleBarProps {
  onClear?: () => void;
  title: string;
  onExpandedCollaspe: () => void;
  expanded: boolean;
}

function TypeTitleBar({ onClear, onExpandedCollaspe, title, expanded }: TypeTitleBarProps): ReactElement {
  return (
    <div className={styles.mainDivision}>
      <div className={styles.arrowControl} onClick={onExpandedCollaspe}>
        {expanded ? <ExpandedIcon /> : <CollaspedIcon />}
      </div>
      <div className={styles.sectionTitle}>{title}</div>

      <div className={styles.clearButton} onClick={onClear}>
        Clear
      </div>
    </div>
  );
}

export default TypeTitleBar;
