import React, { ReactElement, useState } from 'react';
import styles from './EmailVerification.module.scss';
import TextField from 'components/Base/TextField';
import Button from 'components/Base/Button';
import ConfirmationModal from 'components/ConfirmationModal';

interface EmailVerificationProps {
  field: string;
  onChange: (value: string) => void;
  value?: string;
  onSave: () => void;
}
function EmailVerification({ field, onChange, value, onSave }: EmailVerificationProps): ReactElement {
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [originalValue, setOriginalValue] = useState<string>();
  const [edit, setEdit] = useState<boolean>(false);

  const localSave = () => {
    onSave();
    setEdit(false);
    setOriginalValue(undefined);
    setOpenConfirmation(false);
  };

  const localCancel = () => {
    setEdit(false);
    onChange(originalValue || '');
    setOriginalValue(undefined);
  };

  const localEdit = () => {
    setEdit(true);
    setOriginalValue(value);
  };

  return (
    <div className={styles.item}>
      <div className={styles.field}>{field}</div>
      {edit ? (
        <div className={styles.edit}>
          <TextField value={value} setValue={onChange} name={field} />

          <Button title={'Save'} onClick={() => setOpenConfirmation(true)} />
          <Button title={'Cancel'} onClick={localCancel} />
        </div>
      ) : (
        <div className={styles.edit}>
          <div>{value}</div>
          <Button title={'Edit'} onClick={localEdit} />
        </div>
      )}
      <ConfirmationModal
        open={openConfirmation}
        message={`This will remove permissions from current email and change to ${value}`}
        title={`Email Change`}
        onAccept={localSave}
        onClose={() => setOpenConfirmation(false)}
      />
    </div>
  );
}

export default EmailVerification;
