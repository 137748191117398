import React, { MouseEvent, ReactElement, ReactNode, useEffect, useState } from 'react';
import styles from './ColourDropdown.module.scss';
import classNames from 'classnames';
import { changeStyleProperty } from 'lib/reactOperations';

interface ColourDropdownProps {
  label: string;
  items: Array<ReactNode>;
  selectedValue: string;
  noSelectValue?: ReactNode;
  error?: string;
  description?: string;
}

function ColourDropdown({
  items,
  label,
  selectedValue,
  noSelectValue,
  error,
  description,
}: ColourDropdownProps): ReactElement {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [values, setValues] = useState<Array<ReactNode>>([]);
  const onClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsActive(true);
  };

  useEffect(() => {
    const pageClickEvent = () => {
      setIsActive(false);
    };

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  useEffect(() => {
    let localValues = [...items];
    if (noSelectValue) localValues = [noSelectValue, ...items];
    setValues(localValues);
  }, [items]);

  useEffect(() => {
    changeStyleProperty('Selector', '--colour', selectedValue);
  }, [selectedValue]);

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <div className={styles.menuContainer}>
          <button id={'Selector'} onClick={onClick} className={styles.menuTrigger}>
            {!selectedValue ? label : ''}
          </button>
          <div className={classNames(styles.menu, isActive && styles.active, error && styles.error)}>
            <ul>{values}</ul>
          </div>
        </div>
        <p className={styles.description}>{description}</p>
      </div>

      <p className={styles.errorMessage}>{error}</p>
    </div>
  );
}

export default ColourDropdown;
