import Layout from 'Base';
import { UserContext } from 'context/userProfile';
import useJobs from 'hooks/useJobs.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import { dateFormats, formatDate, sqlToday } from 'lib/date';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styles from './DailyJobs.module.scss';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import moment from 'moment';
import useDebounce from 'hooks/useDebounce.hooks';
import classNames from 'classnames';
import { FetchTeamsParams, JobSchedule, Team } from 'types';
import useReactOperations from 'hooks/useReactOperations.hook';
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import useTeams from 'hooks/useTeams.hook';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import useJobSchedules from 'hooks/useJobSchedules.hook';
import JobTypeIndicators from 'components/JobTypeIndicators';
import InfoModal from 'components/InfoModal';

function DailyJobs(): ReactElement {
  const { date } = useParams();

  const { user } = useContext(UserContext);
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [snackBarMessage, setSnackBarMessage] = useState<string>();
  const [selectedSchedule, setSelectedSchedule] = useState<JobSchedule>();

  const { navigateToPage, navigateToDailyJobsPage } = useReactOperations();
  let currentDate: string = sqlToday();
  if (date && moment(date, 'YYYY-MM-DD').isValid()) {
    currentDate = date;
  } else {
    navigateToDailyJobsPage(currentDate);
  }

  const debounceDate = useDebounce(currentDate, 300);

  const { jobErrorMessage, jobErrorTitle, resetJobError, jobsLoading } = useJobs();
  const { fetchJobSchedules, jobSchedules } = useJobSchedules();
  const { getTeams, teams } = useTeams();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError }],
  });

  const dateRight = () => {
    const newDate = moment(currentDate).add(1, 'day').format(dateFormats.sqlDateOnly);
    navigateToDailyJobsPage(newDate);
  };

  const dateLeft = () => {
    const newDate = moment(currentDate).subtract(1, 'day').format(dateFormats.sqlDateOnly);
    navigateToDailyJobsPage(newDate);
  };

  const getJobProgressColor = (schedule: JobSchedule) => {
    if (schedule.timeFinished) return styles.jobComplete;
    if (schedule.timeStarted) return styles.jobInProgress;
    return styles.jobNotStarted;
  };

  const refreshScreen = (teamId: number) => {
    fetchJobSchedules({ date: currentDate, teamId });
  };
  const selectSchedule = (schedule: JobSchedule) => {
    if (
      (!user?.clockedIn || !moment(user?.clockedIn).isSameOrAfter(schedule.date, 'days')) &&
      !user?.permissions.isAdmin &&
      !user?.permissions.isReceptionist
    ) {
      setSnackBarMessage('Please Sign In To View Job Details');
      setSelectedSchedule(schedule);
    } else {
      navigateToPage(`/jobSchedule/${schedule.id}`);
    }
  };

  useEffect(() => {
    const team = teams.find((team) => !!team.members.find((member) => member.id === user?.id));

    setSelectedTeam(team);
  }, [teams]);

  useEffect(() => {
    if (!selectedTeam) return;
    refreshScreen(selectedTeam.id);
  }, [debounceDate, selectedTeam]);

  useEffect(() => {
    const params: FetchTeamsParams = {};
    if (!user?.permissions.isAdmin && !user?.permissions.isReceptionist) {
      params.userId = user?.id;
    }
    getTeams(params);
  }, []);

  return (
    <Layout
      title="Daily Jobs"
      openAlert={openAlert}
      alertTitle={alertTitle}
      alertMessage={alertMessage}
      onAlertClose={closeAlertModal}
      snackBarMessage={snackBarMessage}
      closeSnackBar={() => setSnackBarMessage(undefined)}
      showSnackBar={!!snackBarMessage}
    >
      <div className={styles.container}>
        <div className={styles.navigation}>
          <div className={styles.dateChangeBtn} onClick={dateLeft}>
            <ArrowLeft className={styles.dateChangeIcon} />
          </div>
          <div className={styles.date}>{formatDate(currentDate, dateFormats.abbreviatedPretty)}</div>
          <div className={styles.dateChangeBtn} onClick={dateRight}>
            <ArrowRight className={styles.dateChangeIcon} />
          </div>
        </div>
        {teams.length > 1 && (
          <div className={styles.dropdownContainer}>
            <Dropdown
              items={teams.map((team) => {
                return (
                  <DropdownItem
                    key={team.id}
                    id={team.id}
                    value={<div>{team.name}</div>}
                    onSelect={() => setSelectedTeam(team)}
                  />
                );
              })}
              selectedValue={selectedTeam?.name}
            />
          </div>
        )}

        <div>
          {jobSchedules.map((schedule) => {
            return (
              <div
                className={classNames(styles.job, getJobProgressColor(schedule))}
                key={schedule.id}
                onClick={() => selectSchedule(schedule)}
              >
                <div className={styles.time}>{schedule.startTime}</div>
                <div className={styles.address}>{schedule.job.address?.fullAddress}</div>
                <JobTypeIndicators job={schedule.job} useFillers={false} />
              </div>
            );
          })}
          {!!(!jobSchedules.length && !jobsLoading) && <div className={styles.noJobs}>No Jobs Scheduled For Today</div>}
          {jobsLoading && (
            <div className={styles.noJobs}>
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
      <InfoModal
        open={!!selectedSchedule}
        title={'Address'}
        message={selectedSchedule?.job.address.fullAddress}
        onClose={() => setSelectedSchedule(undefined)}
      />
    </Layout>
  );
}

export default DailyJobs;
