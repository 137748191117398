import React, { ReactElement, ReactNode, useState } from 'react';
import styles from './BaseModal.module.scss';
import Button from '../Button/Button.view';
import AlertModal from 'components/AlertModal';
import classNames from 'classnames';
import ConfirmationModal from 'components/ConfirmationModal';
import LoadingSpinner from 'components/LoadingSpinner';

interface BaseModalProps {
  open: boolean;
  openAlert?: boolean;
  alertTitle?: string;
  alertMessage?: string;
  onAlertClose?: () => void;
  onClose: () => void;
  title: string;
  confirmTitle?: string;
  cancelTitle?: string;
  onConfirm?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
  include?: boolean;
  fullScreen?: boolean;
  deleteTitle?: string;
  deletePopupTitle?: string;
  deletePopupMessage?: string;
  loading?: boolean;
}

function BaseModal({
  open,
  openAlert,
  alertTitle,
  alertMessage,
  confirmTitle = 'Save',
  cancelTitle = 'Cancel',
  deleteTitle = 'Delete',
  onClose,
  onConfirm,
  onDelete,
  onCancel,
  onAlertClose,
  children,
  title,
  fullScreen = false,
  deletePopupMessage = 'Are you sure you would like to delete?',
  deletePopupTitle = 'Delete',
  loading,
}: BaseModalProps): ReactElement {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);

  return (
    <>
      {open && (
        <div className={styles.background}>
          <div className={classNames(styles.container, fullScreen && styles.fullScreen)}>
            {loading && (
              <div className={styles.loading}>
                <LoadingSpinner />
              </div>
            )}

            <div className={styles.info}>
              <div className={styles.header}>
                <div className={styles.titleBar}>
                  <div className={styles.title}>{title}</div>
                </div>
              </div>
              <div className={styles.children}>{children}</div>
            </div>

            <div className={styles.buttons}>
              <div>
                <Button onClick={onCancel ? onCancel : onClose} title={cancelTitle} border={false} />
              </div>
              {onDelete && (
                <div className={styles.confirmBtn}>
                  <Button onClick={() => setOpenDeleteConfirmation(true)} title={deleteTitle} border={false} />
                </div>
              )}
              {onConfirm && (
                <div className={styles.confirmBtn}>
                  <Button onClick={onConfirm} title={confirmTitle} border={false} />
                </div>
              )}
            </div>
          </div>
          <AlertModal open={!!openAlert} title={alertTitle} message={alertMessage} onClose={onAlertClose} />
          {!!onDelete && (
            <ConfirmationModal
              open={openDeleteConfirmation}
              title={deletePopupTitle}
              message={deletePopupMessage}
              onClose={() => setOpenDeleteConfirmation(false)}
              onAccept={() => {
                onDelete();
                setOpenDeleteConfirmation(false);
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default BaseModal;
