import { JobSchedulesApi } from 'api';
import { handleApiError } from 'lib/errors';
import { useState } from 'react';
import {
  JobSchedule,
  JobDailySummary,
  CreateJobSchedule,
  FetchJobSchedulesParams,
  FetchMonthlyJobSchedulesParams,
  OpenDay,
  OpenSlot,
  FetchOpeningParams,
  JobScheduleType,
  StartJobScheduleParams,
} from 'types';
import useErrorsDetails from './useErrors.hook';

interface JobSchedulesInfo {
  jobSchedulesLoading: boolean;
  jobSchedules: Array<JobSchedule>;
  monthJobSchedules: Array<JobDailySummary>;
  jobSchedule: JobSchedule | undefined;
  jobScheduleErrorMessage: string;
  jobScheduleErrorTitle: string;
  openDays: Array<OpenDay>;
  openSlots: Array<OpenSlot>;
  jobScheduleTypes: Array<JobScheduleType>;
  resetJobSchedulesError: () => void;
  fetchJobSchedule: (id: number) => void;
  fetchJobSchedules: (params: FetchJobSchedulesParams) => void;
  fetchMonthJobSchedules: ({ date }: FetchMonthlyJobSchedulesParams) => void;
  createJobSchedule: (JobSchedule: CreateJobSchedule) => Promise<void>;
  updateJobSchedule: (id: number, params: CreateJobSchedule) => Promise<void>;
  startJobSchedule: (id: number, params: StartJobScheduleParams) => Promise<void>;
  completeJobSchedule: (id: number, params: StartJobScheduleParams) => Promise<void>;
  deleteJobSchedule: (id: number) => Promise<void>;
  fetchAvailability: (params: FetchOpeningParams) => void;
  resetAvailableDays: () => void;
  fetchJobScheduleTypes: () => void;
  reset: (id: number, staffIds: Array<number>, jobCompleted: boolean) => Promise<void>;
}

function useJobSchedules(): JobSchedulesInfo {
  const [jobSchedules, setJobSchedules] = useState<Array<JobSchedule>>([]);
  const [jobScheduleTypes, setJobScheduleTypes] = useState<Array<JobScheduleType>>([]);
  const [jobSchedule, setJobSchedule] = useState<JobSchedule>();
  const [loading, setLoading] = useState<boolean>(false);
  const [monthJobSchedules, setMonthJobSchedules] = useState<Array<JobDailySummary>>([]);
  const [openDays, setOpenDays] = useState<Array<OpenDay>>([]);
  const [openSlots, setOpenSlots] = useState<Array<OpenSlot>>([]);

  const { handleError, errorMessage, errorTitle, resetError } = useErrorsDetails();

  const fetchAvailability = async (params: FetchOpeningParams) => {
    setLoading(true);
    try {
      const openings = await JobSchedulesApi.fetchAvailability(params);
      setOpenDays(openings.openDays);
      setOpenSlots(openings.openSlots);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    setLoading(false);
  };

  const fetchJobSchedule = async (id: number) => {
    try {
      const jobSchedule = await JobSchedulesApi.fetchJobSchedule(id);
      setJobSchedule(jobSchedule);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };

  const fetchJobSchedules = async (params: FetchJobSchedulesParams) => {
    try {
      setLoading(true);
      setJobSchedules([]);
      const jobSchedulesList = await JobSchedulesApi.fetchJobSchedules(params);
      setJobSchedules(jobSchedulesList);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
    setLoading(false);
  };

  const fetchMonthJobSchedules = async ({ date }: FetchMonthlyJobSchedulesParams) => {
    try {
      const jobSchedulesList = await JobSchedulesApi.fetchMonthJobSchedules({ date });
      setMonthJobSchedules(jobSchedulesList);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };

  const fetchJobScheduleTypes = async () => {
    try {
      const types = await JobSchedulesApi.fetchJobScheduleTypes();
      setJobScheduleTypes(types);
    } catch (error) {
      handleError('Error', handleApiError(error));
    }
  };

  const updateJobSchedule = async (id: number, data: CreateJobSchedule): Promise<void> => {
    try {
      await JobSchedulesApi.updateJobSchedule(id, data);
      fetchJobSchedule(id);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const createJobSchedule = async (data: CreateJobSchedule): Promise<void> => {
    try {
      await JobSchedulesApi.createJobSchedule(data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const startJobSchedule = async (id: number, params: StartJobScheduleParams) => {
    try {
      await JobSchedulesApi.startJobSchedule(id, params);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const completeJobSchedule = async (id: number, params: StartJobScheduleParams): Promise<void> => {
    try {
      await JobSchedulesApi.completeJobSchedule(id, params);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const reset = async (id: number, staffIds: Array<number>, jobCompleted: boolean) => {
    try {
      await JobSchedulesApi.reset(id, staffIds, jobCompleted);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const deleteJobSchedule = async (id: number) => {
    try {
      await JobSchedulesApi.del(id);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const resetAvailableDays = () => {
    setOpenDays([]);
    setOpenSlots([]);
  };

  return {
    jobSchedulesLoading: loading,
    jobSchedule,
    jobSchedules,
    monthJobSchedules,
    jobScheduleErrorMessage: errorMessage,
    jobScheduleErrorTitle: errorTitle,
    openDays,
    openSlots,
    jobScheduleTypes,
    reset,
    fetchJobScheduleTypes,
    fetchAvailability,
    deleteJobSchedule,
    updateJobSchedule,
    resetJobSchedulesError: resetError,
    fetchJobSchedules,
    fetchJobSchedule,
    fetchMonthJobSchedules,
    createJobSchedule,
    startJobSchedule,
    completeJobSchedule,
    resetAvailableDays,
  };
}

export default useJobSchedules;
