import React, { ReactElement, useEffect, useState } from 'react';
import styles from './Users.module.scss';
import Layout from 'Base';

import useUsersApi from 'hooks/useUsers.hooks';
import { User } from 'types';
import useDebounce from 'hooks/useDebounce.hooks';
import AddEditUserModal from 'components/Modals/AddEditUserModal';
import Table from 'components/Base/Table';
import TableRow from 'components/Base/Table/components/TableRow';
import TableData from 'components/Base/Table/components/TableData';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import AlertModal from 'components/AlertModal';
import { formatPhone } from 'lib/formatting';
import useReactOperations from 'hooks/useReactOperations.hook';
import Button from 'components/Base/Button';
import TextSearch from 'components/TextSearch';
import { SnackBarType } from 'components/SnackBar/SnackBar';

function Users(): ReactElement {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [openNewUserModal, setOpenNewUserModal] = useState<boolean>(false);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarType, setSnackBarType] = useState<SnackBarType>();
  const [snackBarMessage, setSnackBarMessage] = useState<string>();

  const debounceSearch = useDebounce(searchFilter, 300);

  const getHeader = () => {
    return [
      <TableData key={1} data={'Name'} id={'name-header'} widthPercent={20} />,
      <TableData key={2} data={'Email'} id={'email-header'} widthPercent={40} />,
      <TableData key={3} data={'Cell Phone'} id={'cell-header'} widthPercent={15} />,
      <TableData key={4} data={'Home Phone'} id={'home-header'} widthPercent={15} />,
      <TableData key={5} data={'Type'} id={'type-header'} widthPercent={10} />,
    ];
  };
  const { navigateToPage } = useReactOperations();

  const { userLoading, users, fetchUsers, userErrorMessage, userErrorTitle, resetUserError } = useUsersApi();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: userErrorTitle, message: userErrorMessage, reset: resetUserError }],
  });

  const selectUser = (user: User) => {
    navigateToPage(`/User/${user.id}/`);
  };

  const onSave = async () => {
    setSnackBarMessage('Staff Created');
    setSnackBarType(SnackBarType.SUCCESS);
    setShowSnackBar(true);
    setOpenNewUserModal(false);
    fetchUsers(debounceSearch);
  };

  const getTableInformation = (): ReactElement => {
    const message = userLoading ? '...Loading' : 'No Information';
    return (
      <TableRow fullHeight={true}>
        <TableData data={message} noData={true} />
      </TableRow>
    );
  };

  useEffect(() => {
    fetchUsers(debounceSearch);
  }, [debounceSearch]);

  return (
    <Layout
      title={'Users'}
      showSnackBar={showSnackBar}
      snackBarType={snackBarType}
      snackBarMessage={snackBarMessage}
      closeSnackBar={() => setShowSnackBar(false)}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
    >
      <div className={styles.container}>
        <section id="toolbar" className={styles.toolbar}>
          <TextSearch value={searchFilter} onChange={setSearchFilter} />
          <Button title="New Staff" onClick={() => setOpenNewUserModal(true)} />
        </section>

        <div className={styles.listContainer}>
          <Table header={getHeader()}>
            {users.length === 0 && getTableInformation()}
            {users.map((user: User, index: number) => {
              return (
                <TableRow key={index} onClick={() => selectUser(user)}>
                  <TableData data={user.fullName} id={`name-${user.id}`} widthPercent={20} />
                  <TableData data={user.email} id={`email-${user.id}`} widthPercent={40} />
                  <TableData data={formatPhone(user.homePhone)} id={`home-${user.id}`} widthPercent={15} />
                  <TableData data={formatPhone(user.cellPhone)} id={`cell-${user.id}`} widthPercent={15} />
                  <TableData data={user.userType?.type} id={`type-${user.id}`} widthPercent={10} />
                </TableRow>
              );
            })}
          </Table>
        </div>
      </div>
      <AlertModal open={!!openAlert} title={alertTitle} message={alertMessage} onClose={closeAlertModal} />
      <AddEditUserModal open={openNewUserModal} onClose={() => setOpenNewUserModal(false)} onSave={onSave} />
    </Layout>
  );
}

export default Users;
