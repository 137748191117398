import React, { ReactElement } from 'react';
import styles from './TextAttribute.module.scss';
import TextField from 'components/Base/TextField';

interface TextAttributeProps {
  field: string;
  onChange: (value: string) => void;
  value?: string | number;
  edit: boolean;
}
function TextAttribute({ field, onChange, edit, value }: TextAttributeProps): ReactElement {
  return (
    <div className={styles.item}>
      <div className={styles.field}>{field}</div>
      {edit ? (
        <div className={styles.edit}>
          <TextField value={value} setValue={onChange} name={field} />
        </div>
      ) : (
        <div className={styles.edit}>
          <div>{value}</div>
        </div>
      )}
    </div>
  );
}

export default TextAttribute;
