import React, { ReactElement } from 'react';
import styles from './AddressAttribute.module.scss';
import TextField from 'components/Base/TextField';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import { Country, Region } from 'types';
import { formatPostalCode } from 'lib/formatting';

interface AddressAttributeProps {
  field: string;
  setPostalCode: (postalCode: string) => void;
  setUnit: (unit: string) => void;
  setCity: (city: string) => void;
  setCountry: (country: Country) => void;
  setRegion: (region: Region) => void;
  setAddress: (address: string) => void;
  postalCode?: string;
  city?: string;
  unit?: string;
  address?: string;
  region?: Region;
  country?: Country;
  value?: string | number;
  edit: boolean;
  countries: Array<Country>;
  regions: Array<Region>;
}
function AddressAttribute({
  field,
  setPostalCode,
  setCity,
  setAddress,
  city,
  address,
  unit,
  setUnit,
  postalCode,
  edit,
  value,
  countries,
  regions,
  setCountry,
  country,
  setRegion,
  region,
}: AddressAttributeProps): ReactElement {
  return (
    <div className={styles.item}>
      {edit ? (
        <div className={styles.edit}>
          <div className={styles.field}>{field}</div>
          <div>
            <div className={styles.addressFields}>
              <TextField value={unit} setValue={setUnit} name={'Unit'} hint="Unit" />
              <TextField value={address} setValue={setAddress} name={'Address'} hint="Address" />
              <TextField value={city} setValue={setCity} name={'City'} hint="City" />
            </div>
            <div className={styles.addressFields}>
              <Dropdown
                hint={'Country'}
                items={countries.map((country) => {
                  return (
                    <DropdownItem
                      key={country.id}
                      id={country.id}
                      value={<div>{country.name}</div>}
                      onSelect={() => setCountry(country)}
                    />
                  );
                })}
                selectedValue={country?.name}
              />
              <Dropdown
                hint={'Region'}
                items={regions.map((region) => {
                  return (
                    <DropdownItem
                      key={region.id}
                      id={region.id}
                      value={<div>{region.name}</div>}
                      onSelect={() => setRegion(region)}
                    />
                  );
                })}
                selectedValue={region?.name}
              />

              <TextField
                value={postalCode}
                setValue={(value) => setPostalCode(formatPostalCode(value))}
                name={'Postal Code'}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.edit}>
          <div className={styles.field}>{field}</div>
          <div>{value}</div>
        </div>
      )}
    </div>
  );
}

export default AddressAttribute;
