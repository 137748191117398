import React, { ReactElement, ReactNode } from 'react';
import styles from './Table.module.scss';

interface TableProps {
  header: Array<ReactNode>;
  children?: ReactNode;
}

function Table({ header, children }: TableProps): ReactElement {
  return (
    <div className={styles.table}>
      <table>
        <thead>
          <tr>{header}</tr>
        </thead>
        <tbody className={styles.body}>{children}</tbody>
      </table>
    </div>
  );
}

export default Table;
