import React, { ReactElement, useEffect, useRef } from 'react';
import { CreateJobScheduleFromDrag, DailyTeamSchedule, Job, JobSchedule } from 'types';
import JobDetail from '../JobDetail';
import BlankRow from './components/BlankRow';
import styles from './TeamColumn.module.scss';

interface TeamColumnProps {
  times: Array<string>;
  teamSchedule: DailyTeamSchedule;
  jobSchedules: Array<JobSchedule>;
  startTime: string;
  onInvoiceClick: (job: Job) => void;
  onQuoteClick: (job: Job) => void;
  onTimeSelectionClick: (time: string, teamId: number) => void;
  setSelectedJobScheduleId: (id: number | undefined) => void;
  onChangeRequestClick: (job: Job) => void;
  openChangeStatusModal: (scheduleId: number) => void;
  openRecordPaymentModal: (jobId: number) => void;
  onDragStop: (data: CreateJobScheduleFromDrag) => void;
  refreshPage: () => void;
}

function TeamColumn({
  times,
  teamSchedule,
  jobSchedules,
  startTime,
  onChangeRequestClick,
  onInvoiceClick,
  onQuoteClick,
  onTimeSelectionClick,
  setSelectedJobScheduleId,
  openChangeStatusModal,
  openRecordPaymentModal,
  onDragStop,
  refreshPage,
}: TeamColumnProps): ReactElement {
  const componentRef = useRef<HTMLElement | null>(null);

  const getWidth = (): number => {
    if (componentRef.current) {
      const width = componentRef.current.offsetWidth;
      return width;
    }
    return 0;
  };

  const isInTeamSchedule = (time: string) => {
    const schedules = teamSchedule.schedules;
    const passed = schedules.some((schedule) => {
      return schedule.startTime <= time && schedule.endTime > time;
    });
    return passed;
  };

  useEffect(() => {
    getWidth();
  }, []);

  return (
    <div className={styles.team} ref={componentRef as React.LegacyRef<HTMLDivElement>}>
      <div className={styles.title}>{teamSchedule.teamName}</div>
      <div id={`${teamSchedule.teamId}-team-column`} className={styles.body}>
        {times.map((time, index) => {
          return (
            <BlankRow
              key={`${index}-${teamSchedule.teamId}`}
              inTeamSchedule={isInTeamSchedule(time)}
              onClick={() => onTimeSelectionClick(time, teamSchedule.teamId)}
              time={time}
              id={`${teamSchedule.teamId}-${time}-blank`}
            />
          );
        })}
        {jobSchedules.map((schedule) => {
          return (
            <JobDetail
              key={schedule.id}
              schedule={schedule}
              setSelectedJobScheduleId={setSelectedJobScheduleId}
              startTime={startTime}
              onChangeRequestClick={() => onChangeRequestClick(schedule.job)}
              onInvoiceClick={() => onInvoiceClick(schedule.job)}
              onQuoteClick={() => onQuoteClick(schedule.job)}
              openChangeStatusModal={openChangeStatusModal}
              openRecordPaymentModal={openRecordPaymentModal}
              columnWidth={getWidth()}
              onDragStop={onDragStop}
              refreshPage={refreshPage}
            />
          );
        })}
      </div>
    </div>
  );
}

export default TeamColumn;
