import { BaseApi } from 'api';
import { USERS } from 'lib/routes';
import { TimeCard, CreateClockIn, CreateClockOut, User, UserType, CreateUser } from 'types';

export const fetchUsers = async (searchFilter?: string): Promise<Array<User>> => {
  return await BaseApi.get(USERS, { searchFilter });
};
export const fetchUserTypes = async (): Promise<Array<UserType>> => {
  return await BaseApi.get(`${USERS}/userTypes`);
};
export const fetchUser = async (userId: number, date?: string): Promise<User> => {
  return await BaseApi.get(`${USERS}/${userId}`, { date });
};
export const fetchTimeCard = async (date?: string, userId?: number): Promise<TimeCard> => {
  return await BaseApi.get(`${USERS}/fetchTimeCard`, { date, userId });
};
export const fetchTimeCards = async (date: string, userId?: number): Promise<Array<TimeCard>> => {
  return await BaseApi.get(`${USERS}/fetchTimeCards`, { date, userId });
};
export const createUser = async (body: CreateUser): Promise<void> => {
  return await BaseApi.post(`${USERS}`, body);
};
export const updateUser = async (id: number, body: CreateUser): Promise<void> => {
  return await BaseApi.put(`${USERS}/${id}`, body);
};
export const clockIn = async (body: CreateClockIn): Promise<void> => {
  return await BaseApi.put(`${USERS}/clockIn`, body);
};
export const clockOut = async (body: CreateClockOut): Promise<void> => {
  return await BaseApi.post(`${USERS}/clockOut`, body);
};
