import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import styles from './ImageUpload.module.scss';
import Button from 'components/Base/Button';
import { CreateFile } from 'types';

interface ImageUploadProps {
  field: string;
  onChange: (value?: CreateFile | null) => void;
  value?: string;
  edit: boolean;
}
function ImageUpload({ field, onChange, edit, value }: ImageUploadProps): ReactElement {
  const [previewURL, setPreviewURL] = useState<string>();
  const [isValidImage, setIsValidImage] = useState<boolean>(true);
  const [replaceImage, setReplaceImage] = useState<boolean>(false);

  const handleImageChange = async (event?: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewURL(reader.result as string);
      };
      reader.readAsDataURL(file);

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async () => {
        if (img.width !== img.height) {
          setIsValidImage(false);
          onChange(undefined);
        } else {
          setIsValidImage(true);
          const fileData = file.name.split('.');
          onChange({ file: reader.result, mimeType: file.type, extension: fileData[fileData.length - 1] });
        }
        URL.revokeObjectURL(img.src);
      };
    } else {
      setPreviewURL(undefined);
      setIsValidImage(true);
      onChange(undefined);
    }
  };

  useEffect(() => {
    if (replaceImage) onChange(null);
  }, [replaceImage]);

  useEffect(() => {
    if (!edit) setPreviewURL(undefined);
  }, [edit]);

  return (
    <div className={styles.item}>
      <div className={styles.field}>{field}</div>

      {edit ? (
        <div>
          {replaceImage ? (
            <>
              <div className={styles.uploadContainer}>
                {!isValidImage && <p className={styles.errorMessage}>Please upload a square image.</p>}
                {previewURL && isValidImage && <img className={styles.uploadedImage} src={previewURL} alt="Preview" />}
              </div>
              <input id={'imageUpload'} type="file" accept="image/*" onChange={handleImageChange} />
              <Button
                border={true}
                title="Clear"
                onClick={() => {
                  handleImageChange(undefined);
                  setReplaceImage(false);
                }}
              />
            </>
          ) : (
            <div className={styles.replace}>
              {value && <img src={value} alt={''} />}
              <Button border={true} title={value ? 'Replace' : 'Add Image'} onClick={() => setReplaceImage(true)} />
            </div>
          )}
        </div>
      ) : value ? (
        <img src={value} alt={''} />
      ) : (
        <></>
      )}
    </div>
  );
}

export default ImageUpload;
