import React, { ReactElement, useEffect, useState } from 'react';
import styles from '../Components.module.scss';
import useAccounts from 'hooks/useAccounts.hook';
import TextAttribute from '../../components/TextAttribute';
import { Country, CreateAccount, CreateFile, Region } from 'types';
import AddressAttribute from '../../components/AddressAttribute';
import useAddresses from 'hooks/useAddresses.hook';
import Button from 'components/Base/Button';
import ImageUpload from '../../../../components/ImageUpload';
import CheckboxAttribute from '../../components/CheckboxAttribute';

function General(): ReactElement {
  const [companyName, setCompanyName] = useState<string>();
  const [hstNumber, setHstNumber] = useState<string>();
  const [unit, setUnit] = useState<string>();
  const [country, setCountry] = useState<Country>();
  const [region, setRegion] = useState<Region>();
  const [address, setAddress] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [city, setCity] = useState<string>();
  const [edit, setEdit] = useState<boolean>(false);
  const [sendPaymentReminders, setSendPaymentReminders] = useState<boolean>(false);
  const [sendJobReminders, setSendJobReminders] = useState<boolean>(false);

  const [squareLogoFile, setSquareLogoFile] = useState<CreateFile | null>();

  const { fetchAccount, account, fetchCompanySquareLogo, companySquareLogoSignedUrl, update } = useAccounts();
  const { countries, regions, fetchCountries, fetchRegions } = useAddresses();

  const onSave = async () => {
    const data: CreateAccount = {
      companyName,
      hstNumber: hstNumber || null,
      unit: unit || null,
      countryId: country?.id,
      regionId: region?.id,
      address,
      postalCode,
      city,
      squareLogoFile,
      sendPaymentReminders,
      sendJobReminders,
    };

    await update(data);
    fetchAccount();
    setEdit(false);
  };

  useEffect(() => {
    setCompanyName(account?.companyName);
    setHstNumber(account?.hstNumber);
    setUnit(account?.address.unit);
    setCity(account?.address.city);
    setCountry(account?.address.country);
    setRegion(account?.address.region);
    setAddress(account?.address.address);
    setPostalCode(account?.address.postalCode);
    setSendPaymentReminders(!!account?.sendPaymentReminders);
    setSendJobReminders(!!account?.sendJobReminders);
  }, [account, countries, regions, companySquareLogoSignedUrl]);

  useEffect(() => {
    if (!country) return;
    fetchRegions(country.id);
  }, [country]);

  useEffect(() => {
    fetchAccount();
    fetchCompanySquareLogo();
    fetchCountries();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>General</div>
      <div className={styles.buttons}>
        <Button title={edit ? 'Cancel' : 'Edit'} onClick={() => setEdit((prev) => !prev)} />
        {edit && <Button title={'Save'} onClick={() => onSave()} />}
      </div>

      <div className={styles.content}>
        <TextAttribute value={companyName} onChange={setCompanyName} field={'Company Name:'} edit={edit} />
        <TextAttribute value={hstNumber} onChange={setHstNumber} field={'HST Number:'} edit={edit} />
        <AddressAttribute
          unit={unit}
          address={address}
          field={'Address:'}
          setPostalCode={setPostalCode}
          setUnit={setUnit}
          setCity={setCity}
          setCountry={setCountry}
          setRegion={setRegion}
          setAddress={setAddress}
          postalCode={postalCode}
          city={city}
          region={region}
          country={country}
          edit={edit}
          countries={countries}
          regions={regions}
          value={account?.address.fullAddress}
        />
        <div className={styles.image}>
          <ImageUpload
            onChange={setSquareLogoFile}
            value={companySquareLogoSignedUrl}
            edit={edit}
            field={'Square Logo:'}
          />
        </div>
        <CheckboxAttribute
          field={'Send Payment Reminders'}
          onChange={setSendPaymentReminders}
          value={sendPaymentReminders}
          edit={edit}
        />
        <CheckboxAttribute
          field={'Send Job Reminders'}
          onChange={setSendJobReminders}
          value={sendJobReminders}
          edit={edit}
        />
      </div>
    </div>
  );
}

export default General;
