import { sqlToday } from './date';

export const ADDRESSES = '/addresses';
export const CLIENTS = '/clients';
export const TEAMS = '/teams';
export const USERS = '/users';
export const JOBS = '/jobs';
export const LOGIN = '/login';
export const CREATE_ACCOUNT = '/createAccount';
export const SETTINGS = '/settings';
export const SETTINGS_GENERAL = '/settings/general';
export const SETTINGS_INVOICE_QUOTE = '/settings/invoiceQuote';
export const SETTINGS_COMMUNICATION = '/settings/communication';

export const DAILY_JOBS = (date?: string) => {
  return `/dailyjobs/${date || sqlToday()}`;
};

export const DAILY_CALENDAR = (date?: string) => {
  return `/dayCalendar/${date || sqlToday()}`;
};

export const MONTH_CALENDAR = (date?: string) => {
  return `/monthCalendar/${date || sqlToday()}`;
};

export const TEAMS_CALENDAR = (date?: string) => {
  return `/teamsCalendar/${date || sqlToday()}`;
};

export const CLOCK_IN_OUT = (date?: string) => {
  return `/clockInOut/${date || sqlToday()}`;
};

export const SUMMARY_OVERVIEW = (startDate?: string, endDate?: string) => {
  let dates = '';
  if (startDate) {
    dates = startDate;
  }
  if (endDate) {
    dates += `-${endDate}`;
  }

  return `/summary/overview/${dates}`;
};

export const SUMMARY_TEAMS = (startDate?: string, endDate?: string) => {
  let dates = '';
  if (startDate) {
    dates = startDate;
  }
  if (endDate) {
    dates += `-${endDate}`;
  }

  return `/summary/teams/${dates}`;
};

export const SUMMARY_EMPLOYEES = (startDate?: string, endDate?: string) => {
  let dates = '';
  if (startDate) {
    dates = startDate;
  }
  if (endDate) {
    dates += `-${endDate}`;
  }

  return `/summary/employees/${dates}`;
};

export const SUBMIT_QUOTE = (jobId: number) => {
  return `/submitQuote/${jobId}`;
};

export const CLIENT = (id: number) => {
  return `/client/${id}`;
};

export const ADDRESS = (id: number) => {
  return `/address/${id}`;
};

export const JOB = (id: number) => {
  return `/job/${id}`;
};
