import React, { ReactElement } from 'react';
import styles from './Home.module.scss';
import useReactOperations from 'hooks/useReactOperations.hook';

function Home(): ReactElement {
  const { navigateToLoginPage } = useReactOperations();
  return (
    <div className={styles.container}>
      <div className={styles.login} onClick={navigateToLoginPage}>
        Login
      </div>

      <header className={styles.header}>
        <h1>Welcome to Encompass CRM</h1>
        <p>Your Solution for Effortless Job Planning</p>
      </header>
      <section className={styles.aboutUs}>
        <p>
          Encompass CRM is dedicated to simplifying job planning for businesses of all sizes. Our user-friendly platform
          helps you streamline your operations and boost productivity.
        </p>
      </section>
      <footer className={styles.footer}>
        <p>2023 Encompass CRM. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
