import { BaseApi } from 'api';
import { JOB_SCHEDULES } from 'lib/routes';
import {
  JobSchedule,
  CreateJobSchedule,
  JobDailySummary,
  FetchJobSchedulesParams,
  FetchMonthlyJobSchedulesParams,
  FetchOpeningParams,
  FetchOpeningResults,
  JobScheduleType,
  StartJobScheduleParams,
} from 'types';

export const fetchMonthJobSchedules = async (
  params: FetchMonthlyJobSchedulesParams
): Promise<Array<JobDailySummary>> => {
  return await BaseApi.get(`${JOB_SCHEDULES}/month`, params);
};

export const fetchJobSchedule = async (id: number): Promise<JobSchedule> => {
  return await BaseApi.get(`${JOB_SCHEDULES}/${id}`);
};

export const fetchJobSchedules = async (params: FetchJobSchedulesParams): Promise<Array<JobSchedule>> => {
  return await BaseApi.get(`${JOB_SCHEDULES}`, params);
};

export const fetchJobScheduleTypes = async (): Promise<Array<JobScheduleType>> => {
  return await BaseApi.get(`${JOB_SCHEDULES}/types`);
};

export const createJobSchedule = async (jobSchedule: CreateJobSchedule): Promise<void> => {
  return await BaseApi.post(`${JOB_SCHEDULES}`, jobSchedule);
};

export const updateJobSchedule = async (id: number, data: CreateJobSchedule): Promise<void> => {
  return await BaseApi.put(`${JOB_SCHEDULES}/${id}`, data);
};

export const startJobSchedule = async (id: number, params: StartJobScheduleParams): Promise<void> => {
  return await BaseApi.put(`${JOB_SCHEDULES}/start/${id}`, params);
};

export const fetchAvailability = async (params: FetchOpeningParams): Promise<FetchOpeningResults> => {
  return await BaseApi.get(`${JOB_SCHEDULES}/fetchAvailability`, params);
};

export const reset = async (id: number, staffIds: Array<number>, jobCompleted: boolean): Promise<void> => {
  return await BaseApi.put(`${JOB_SCHEDULES}/reset/${id}`, {
    staffIds,
    jobCompleted,
  });
};

export const completeJobSchedule = async (id: number, params: StartJobScheduleParams): Promise<void> => {
  return await BaseApi.put(`${JOB_SCHEDULES}/complete/${id}`, params);
};

export const del = async (id: number): Promise<void> => {
  return await BaseApi.del(`${JOB_SCHEDULES}/${id}`);
};
