import React, { ReactElement, ReactNode } from 'react';
import styles from './TableRow.module.scss';

interface TableRowProps {
  children?: ReactNode;
  fullHeight?: boolean;
  onClick?: () => void;
}
function TableRow({ children, onClick, fullHeight }: TableRowProps): ReactElement {
  const getStyles = () => {
    return fullHeight ? styles.fullHeight : styles.row;
  };
  return (
    <tr className={getStyles()} onClick={onClick}>
      {children}
    </tr>
  );
}

export default TableRow;
