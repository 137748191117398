import { InvoicesApi } from 'api';
import { handleApiError } from 'lib/errors';
import { useState } from 'react';
import { CreateInvoice, InvoiceSettings, UpdateInvoiceSettings } from 'types';
import useErrorsDetails from './useErrors.hook';
import { createPDFUrlFromByte, openNewTab } from 'lib/reactOperations';

interface InvoicesInfo {
  invoiceErrorMessage: string;
  invoiceErrorTitle: string;
  invoicesLoading: boolean;
  invoiceSettings?: InvoiceSettings;
  previewInvoice: (data?: CreateInvoice) => void;
  createInvoice: (data: CreateInvoice) => Promise<void>;
  updateInvoiceSettings: (data: UpdateInvoiceSettings) => Promise<void>;
  resetInvoiceError: () => void;
  fetchInvoiceSettings: () => void;
  viewInvoice: (id: number) => void;
  sendInvoice: (id: number, emailIds: Array<number>) => void;
}

function useInvoices(): InvoicesInfo {
  const [loading, setLoading] = useState<boolean>(false);
  const [settings, setSettings] = useState<InvoiceSettings>();

  const { handleError, errorMessage, errorTitle, resetError } = useErrorsDetails();

  const viewInvoice = async (id: number) => {
    try {
      setLoading(true);
      const signedUrl = await InvoicesApi.fetchInvoiceSignedUrl(id);
      openNewTab(signedUrl);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      handleError('Error', handleApiError(error));
    }
  };

  const fetchInvoiceSettings = async () => {
    try {
      const settings = await InvoicesApi.fetchSettings();
      setSettings(settings);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const createInvoice = async (data: CreateInvoice): Promise<void> => {
    try {
      await InvoicesApi.create(data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const updateInvoiceSettings = async (data: UpdateInvoiceSettings): Promise<void> => {
    try {
      await InvoicesApi.updateSettings(data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const preview = async (data?: CreateInvoice) => {
    try {
      const pdfData: ArrayBuffer = await InvoicesApi.preview(data);
      const url = createPDFUrlFromByte(pdfData);
      openNewTab(url);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const sendInvoice = async (id: number, emailIds: Array<number>) => {
    try {
      await InvoicesApi.sendInvoice(id, emailIds);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  return {
    invoicesLoading: loading,
    invoiceErrorMessage: errorMessage,
    invoiceErrorTitle: errorTitle,
    invoiceSettings: settings,
    updateInvoiceSettings,
    previewInvoice: preview,
    fetchInvoiceSettings,
    viewInvoice,
    resetInvoiceError: resetError,
    createInvoice,
    sendInvoice,
  };
}

export default useInvoices;
