import React, { ReactElement } from 'react';
import styles from './TextArea.module.scss';
interface TextFieldProps {
  name: string;
  value?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue?: (value: any) => void;
  hint?: string;
  maxLength?: number;
  label?: string;
  setFocused?: (focused: boolean) => void;
  error?: string;
  hasError?: boolean;
}

function TextField({
  value = '',
  hint,
  name,
  label,
  maxLength,
  error,
  hasError,
  setValue,
  setFocused,
}: TextFieldProps): ReactElement {
  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{value ? label : ''}</label>}
      <textarea
        className={styles.info}
        id={name}
        maxLength={maxLength}
        onFocus={() => (setFocused ? setFocused(true) : undefined)}
        onBlur={() => (setFocused ? setFocused(false) : undefined)}
        placeholder={hint}
        onChange={(event) => (setValue ? setValue(event.target.value) : null)}
        value={value || ''}
      />
      {hasError && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
}

export default TextField;
