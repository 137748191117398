import moment from 'moment';

export enum DaysOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}
export enum DaysOfWeekNumber {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export const daysOfWeek = [
  DaysOfWeek.Monday,
  DaysOfWeek.Tuesday,
  DaysOfWeek.Wednesday,
  DaysOfWeek.Thursday,
  DaysOfWeek.Friday,
  DaysOfWeek.Saturday,
  DaysOfWeek.Sunday,
];

export const daysOfWeekFormat = {
  [DaysOfWeek.Monday]: DaysOfWeekNumber.Monday,
  [DaysOfWeek.Tuesday]: DaysOfWeekNumber.Tuesday,
  [DaysOfWeek.Wednesday]: DaysOfWeekNumber.Wednesday,
  [DaysOfWeek.Thursday]: DaysOfWeekNumber.Thursday,
  [DaysOfWeek.Friday]: DaysOfWeekNumber.Friday,
  [DaysOfWeek.Saturday]: DaysOfWeekNumber.Saturday,
  [DaysOfWeek.Sunday]: DaysOfWeekNumber.Sunday,
};

export const dateFormats = {
  sqlDateOnly: 'YYYY-MM-DD',
  sqlDateTime: 'YYYY-MM-DD HH:mm',
  sqlDateTimeFull: 'YYYY-MM-DD HH:mm:ss',
  abbreviatedPretty: 'DD MMM YYYY',
  sqlTimeOnly: 'HH:mm',
  prettyTime: 'HH:mm',
  fullPretty: 'DD MMM YYYY HH:mm',
  monthFullYearFull: 'MMMM YYYY',
  monthNumber: 'M',
  dayOnly: 'D',
};

export const compareFormats = {
  month: 'month',
  day: 'day',
  year: 'year',
};

export const sqlToday = () => {
  return moment().format(dateFormats.sqlDateOnly);
};

export const sqlTime = () => {
  return moment().format(dateFormats.sqlTimeOnly);
};

export const sqlTodayWithTime = () => {
  return moment().format(dateFormats.sqlDateTime);
};

export const isSameDay = (date?: string) => {
  if (!date) return;
  return moment().isSame(date, 'day');
};

export const isSameOrBeforeDay = (date?: string) => {
  if (!date) return;
  return moment().isSameOrAfter(date, 'day');
};

export const isPast = (date: string) => {
  return moment(date).isBefore(sqlToday(), 'day');
};

export const momentToday = () => {
  return moment();
};

export const formatFromMonth = (month: number, year: number) => {
  return moment().month(month).year(year).format(dateFormats.sqlDateOnly);
};

export const currentMonthBase0 = (): number => {
  return moment().month();
};

export const getMonthBase0 = (date: string): number => {
  return moment(date).month();
};

export const formatTime = (time: string | undefined, format: string) => {
  if (!time) return '';
  return moment(time, 'HH:mm').format(format);
};

export const formatDate = (date: string | undefined, format: string) => {
  if (!date) return '';
  return moment(date).format(format);
};

export const compareEqualDate = (date1: string, date2: string, compareFormat: string) => {
  if (compareFormat === compareFormats.month) {
    return moment(date1).isSame(date2, 'month');
  }
};

export const getFirstOfMonth = (date?: string) => {
  return moment(date).startOf('month').format(dateFormats.sqlDateOnly);
};
