import React, { ReactElement, useEffect, useState } from 'react';
import {
  CreateEavestrough,
  CreateEavesValidation,
  CreateJob,
  CreateOther,
  CreateOtherValidation,
  CreateWindow,
  CreateWindowValidation,
  Job,
  JobSubType,
} from 'types';
import CustomSetup from '../components/CustomSetup';
import EavesSetup from '../components/EavesSetup';
import TypeTitleBar from '../components/TypeTitleBar';
import WindowSetup from '../components/WindowSetup';
import styles from './AddEditQuoteSetup.module.scss';
import QuoteSummaryTable from './components/QuoteSummaryTable';
import Checkbox from 'components/Base/Checkbox';

interface AddEditQuoteSetupProps {
  createEditJob: CreateJob;
  job?: Job;
  windowSubTypes: Array<JobSubType>;
  eavesSubTypes: Array<JobSubType>;
  setCreateEditJob: (createEditJob: CreateJob) => void;
  validateWindows: (errors: CreateWindowValidation, details?: CreateWindow, isQuote?: boolean) => boolean;
  validateEaves: (errors: CreateEavesValidation, details?: CreateEavestrough, isQuote?: boolean) => boolean;
  validateOther: (errors: CreateOtherValidation, details?: CreateOther, isQuote?: boolean) => boolean;
}

function AddEditQuoteSetup({
  createEditJob,
  job,
  setCreateEditJob,
  windowSubTypes,
  eavesSubTypes,
  validateEaves,
  validateOther,
  validateWindows,
}: AddEditQuoteSetupProps): ReactElement {
  const [showEavesDetails, setShowEavesDetails] = useState<boolean>(false);
  const [showWindowDetails, setShowWindowsDetails] = useState<boolean>(false);
  const [showOtherDetails, setShowOtherDetails] = useState<boolean>(false);
  const [windowsValidated, setWindowsValidated] = useState<boolean>(false);
  const [eavesValidated, setEavesValidated] = useState<boolean>(false);
  const [otherValidated, setOtherValidated] = useState<boolean>(false);
  const [windowErrors, setWindowErrors] = useState<CreateWindowValidation>();
  const [eavesErrors, setEavesErrors] = useState<CreateEavesValidation>();
  const [otherErrors, setOtherErrors] = useState<CreateOtherValidation>();
  const [windowQuoteDetails, setWindowQuoteDetails] = useState<CreateWindow>({});
  const [eavesQuoteDetails, setEavesQuoteDetails] = useState<CreateEavestrough>({});
  const [otherQuoteDetails, setOtherQuoteDetails] = useState<CreateOther>({});

  const changeWindowDetails = (key: keyof CreateWindow, value: boolean | JobSubType | number | string | undefined) => {
    const newValue = {
      [key]: value,
    };
    setWindowQuoteDetails((prev) => ({
      ...prev,
      ...newValue,
    }));
  };
  const changeEavesDetails = (
    key: keyof CreateEavestrough,
    value: boolean | JobSubType | number | string | undefined
  ) => {
    const newValue = { [key]: value };
    setEavesQuoteDetails((prev) => ({
      ...prev,
      ...newValue,
    }));
  };
  const changeOtherDetails = (key: keyof CreateOther, value: boolean | JobSubType | number | string | undefined) => {
    const newValue = { [key]: value };
    setOtherQuoteDetails((prev) => ({
      ...prev,
      ...newValue,
    }));
  };

  const updateWindow = (details: CreateWindow) => {
    setWindowQuoteDetails(details);
    setShowWindowsDetails(true);
    setShowEavesDetails(false);
    setShowOtherDetails(false);
    setWindowErrors(undefined);
  };

  const updateEaves = (details: CreateEavestrough) => {
    setEavesQuoteDetails(details);
    setShowWindowsDetails(false);
    setShowEavesDetails(true);
    setShowOtherDetails(false);
    setEavesErrors(undefined);
  };
  const updateOther = (details: CreateOther) => {
    setOtherQuoteDetails(details);
    setShowWindowsDetails(false);
    setShowEavesDetails(false);
    setShowOtherDetails(true);
    setOtherErrors(undefined);
  };
  const resetWindowValidation = () => {
    setWindowErrors(undefined);
    setWindowsValidated(false);
  };

  const resetEavesValidation = () => {
    setEavesErrors(undefined);
    setEavesValidated(false);
  };

  const resetOtherValidation = () => {
    setOtherErrors(undefined);
    setOtherValidated(false);
  };

  const localValidateWindows = () => {
    const windowErrors: CreateWindowValidation = {};
    if (!validateWindows(windowErrors, windowQuoteDetails, true)) {
      setWindowErrors(windowErrors);
      setWindowsValidated(true);
      return false;
    }
    return true;
  };

  const localValidateEaves = () => {
    const eavesErrors: CreateEavesValidation = {};
    if (!validateEaves(eavesErrors, eavesQuoteDetails, true)) {
      setEavesErrors(windowErrors);
      setEavesValidated(true);
      return false;
    }
    return true;
  };

  const localValidateOther = () => {
    const otherErrors: CreateOtherValidation = {};
    if (!validateOther(otherErrors, otherQuoteDetails, true)) {
      setOtherErrors(otherErrors);
      setOtherValidated(true);
      return false;
    }
    return true;
  };
  const setTaxPercent = (checked: boolean) => {
    setCreateEditJob({
      ...createEditJob,
      includeQuoteTax: checked,
    });
  };

  const addWindowQuote = () => {
    if (!localValidateWindows()) return;
    resetWindowValidation();
    const details = { ...createEditJob };
    const quotes = details.windowQuotes ? details.windowQuotes : [];
    if (windowQuoteDetails.index !== undefined) {
      quotes[windowQuoteDetails.index] = { ...windowQuoteDetails };
    } else {
      quotes.push({ ...windowQuoteDetails, index: quotes.length });
    }
    details.windowQuotes = quotes;
    setCreateEditJob(details);
    setWindowQuoteDetails({});
  };
  const addEavesQuote = () => {
    if (!localValidateEaves()) return;
    resetEavesValidation();

    const details = { ...createEditJob };
    const quotes = details.eavesQuotes ? details.eavesQuotes : [];
    if (eavesQuoteDetails.index !== undefined) {
      quotes[eavesQuoteDetails.index] = { ...eavesQuoteDetails };
    } else {
      quotes.push({ ...eavesQuoteDetails, index: quotes.length });
    }
    details.eavesQuotes = quotes;
    setCreateEditJob(details);
    setEavesQuoteDetails({});
  };
  const addOtherQuote = () => {
    if (!localValidateOther()) return;
    resetOtherValidation();

    const details = { ...createEditJob };
    const quotes = details.otherQuotes ? details.otherQuotes : [];
    if (otherQuoteDetails.index !== undefined) {
      quotes[otherQuoteDetails.index] = { ...otherQuoteDetails };
    } else {
      quotes.push({ ...otherQuoteDetails, index: quotes.length });
    }
    details.otherQuotes = quotes;
    setCreateEditJob(details);
    setOtherQuoteDetails({});
  };

  const setSelectedWindowType = (type: JobSubType) => {
    if (type.id === windowQuoteDetails?.subType?.id) return;
    changeWindowDetails('description', type.description);
    changeWindowDetails('subType', type);
  };

  const setSelectedEavesType = (type: JobSubType) => {
    if (type.id === eavesQuoteDetails?.subType?.id) return;
    changeEavesDetails('subType', type);
    changeEavesDetails('description', type.description);
  };

  const deleteQuote = (type: string, index: number) => {
    if (!createEditJob) return;
    const temp: CreateJob = { ...createEditJob };
    switch (type) {
      case 'window':
        if (temp.windowQuotes) temp.windowQuotes.splice(index, 1);
        break;
      case 'eaves':
        if (temp.eavesQuotes) temp.eavesQuotes.splice(index, 1);
        break;
      case 'other':
        if (temp.otherQuotes) temp.otherQuotes.splice(index, 1);
        break;
    }

    setCreateEditJob(temp);
  };

  useEffect(() => {
    if (!windowsValidated && !eavesValidated && !otherValidated) return;
    localValidateWindows();
  }, [windowQuoteDetails]);

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <Checkbox checked={!!createEditJob.includeQuoteTax} onClick={setTaxPercent} label={'Include Tax'} />
        <div className={styles.table}>
          <QuoteSummaryTable
            windowQuotes={createEditJob.windowQuotes}
            eavesQuotes={createEditJob.eavesQuotes}
            otherQuotes={createEditJob.otherQuotes}
            deleteQuote={deleteQuote}
            setWindowQuoteDetails={updateWindow}
            setEavesQuoteDetails={updateEaves}
            setOtherQuoteDetails={updateOther}
          />
        </div>
        <div className={styles.clientDetails}>
          <TypeTitleBar
            title="Window Quote"
            onClear={() => {
              setWindowQuoteDetails({});
              resetWindowValidation();
            }}
            onExpandedCollaspe={() => setShowWindowsDetails((prev) => !prev)}
            expanded={showWindowDetails}
          />
          {showWindowDetails && (
            <WindowSetup
              subTypes={windowSubTypes}
              details={windowQuoteDetails}
              changeDetails={changeWindowDetails}
              setSubType={setSelectedWindowType}
              addTitle={windowQuoteDetails.index !== undefined ? 'Update' : 'Add'}
              onAdd={addWindowQuote}
              isQuote={true}
              errors={windowErrors}
            />
          )}
        </div>

        <div className={styles.clientDetails}>
          <TypeTitleBar
            title="Eavestrough Quote"
            onClear={() => {
              setEavesQuoteDetails({});
              resetEavesValidation();
            }}
            onExpandedCollaspe={() => setShowEavesDetails((prev) => !prev)}
            expanded={showEavesDetails}
          />
          {showEavesDetails && (
            <EavesSetup
              subTypes={eavesSubTypes}
              details={eavesQuoteDetails}
              changeDetails={changeEavesDetails}
              setSubType={setSelectedEavesType}
              addTitle={eavesQuoteDetails.index !== undefined ? 'Update' : 'Add'}
              onAdd={addEavesQuote}
              isQuote={true}
              errors={eavesErrors}
            />
          )}
        </div>

        <div className={styles.clientDetails}>
          <TypeTitleBar
            title="Custom Quote"
            onClear={() => {
              setOtherQuoteDetails({});
              resetOtherValidation();
            }}
            onExpandedCollaspe={() => setShowOtherDetails((prev) => !prev)}
            expanded={showOtherDetails}
          />
          {showOtherDetails && (
            <CustomSetup
              details={otherQuoteDetails}
              changeDetails={changeOtherDetails}
              addTitle={otherQuoteDetails.index !== undefined ? 'Update' : 'Add'}
              onAdd={addOtherQuote}
              isQuote={true}
              errors={otherErrors}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AddEditQuoteSetup;
