import BaseModal from 'components/Base/BaseModal';
import Checkbox from 'components/Base/Checkbox';
import { UserContext } from 'context/userProfile';
import useJobSchedules from 'hooks/useJobSchedules.hook';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styles from './ConfirmJobScheduleMembersStartModal.module.scss';
import { sqlTime } from 'lib/date';
import DateSelector from 'components/Base/DateSelector';
import moment from 'moment';
import TextArea from 'components/Base/TextArea';
import { CreateStartStopValidation } from 'types';

interface ConfirmJobScheduleMembersStartModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  id?: number;
}

function ConfirmJobScheduleMembersStartModal({
  open,
  id,
  onClose,
  onSave,
}: ConfirmJobScheduleMembersStartModalProps): ReactElement {
  const [selectedMemberIds, setSelectedMemberIds] = useState<Array<number>>([]);
  const { user } = useContext(UserContext);

  const [useCurrentTime, setUseCurrentTime] = useState<boolean>(false);
  const [reason, setReason] = useState<string>();
  const [time, setTime] = useState<string>(sqlTime());
  const [hasValidated, setHasValidated] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<CreateStartStopValidation>();

  const { fetchJobSchedule, jobSchedule, startJobSchedule } = useJobSchedules();

  const isToday = moment().isSame(jobSchedule?.date, 'days');

  const validate = (): boolean => {
    const errors: CreateStartStopValidation = {};

    let success = true;

    if (!reason && !useCurrentTime) {
      errors.reason = 'Please provide a reason for the altered time';
      success = false;
    }

    setValidationErrors(errors);
    setHasValidated(true);
    return success;
  };

  const onClick = (id: number, value: boolean) => {
    const members = [...selectedMemberIds];
    if (value) {
      members.push(id);
    } else {
      const membersIndex = members.findIndex((memberId) => memberId === id);
      members.splice(membersIndex, 1);
    }
    setSelectedMemberIds(members);
  };

  const joinJobSchedule = async () => {
    if (!id || !validate()) return;
    const params = {
      staffIds: selectedMemberIds,
      time,
      reason,
    };
    await startJobSchedule(id, params);
    onSave();
  };

  useEffect(() => {
    if (!jobSchedule) return;
    const memberIds = jobSchedule?.team.members.map((member) => member.id);
    setSelectedMemberIds(memberIds);
  }, [jobSchedule]);

  useEffect(() => {
    if (!id || !open) return;
    fetchJobSchedule(id);
  }, [open]);

  useEffect(() => {
    if (!hasValidated) return;
    validate();
  }, [reason, time]);

  return (
    <BaseModal title={'Join'} open={open} onClose={onClose} onConfirm={joinJobSchedule}>
      <div className={styles.container}>
        {jobSchedule?.needsStarting.map((member) => {
          return (
            <Checkbox
              key={member.id}
              label={member.fullName}
              onClick={(value) => onClick(member.id, value)}
              checked={selectedMemberIds.includes(member.id)}
              disabled={member.id === user?.id}
            />
          );
        })}

        {isToday && (
          <div className={styles.checkbox}>
            <Checkbox
              checked={useCurrentTime}
              onClick={(useCurrentTime) => {
                setUseCurrentTime(useCurrentTime);
                setReason(undefined);
                if (useCurrentTime) setTime(sqlTime());
              }}
              label={'Use Current Time'}
            />
          </div>
        )}

        {!useCurrentTime && (
          <>
            <DateSelector date={time} setDate={setTime} title="Time" type="time" error={validationErrors?.time} />
            <div className={styles.reason}>
              <TextArea
                value={reason}
                setValue={setReason}
                name={'Reason'}
                hint={'Reason for adjusted time'}
                error={validationErrors?.reason}
                hasError={true}
              />
            </div>
          </>
        )}
      </div>
    </BaseModal>
  );
}

export default ConfirmJobScheduleMembersStartModal;
