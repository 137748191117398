import React, { ReactElement } from 'react';
import styles from './InfoRow.module.scss';

interface InfoProps {
  title: string;
  info?: string | number;
}
function InfoRow({ title, info }: InfoProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{`${title}: `}</div>
      <div className={styles.info}>{info}</div>
    </div>
  );
}

export default InfoRow;
