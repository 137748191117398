import React, { ReactElement } from 'react';
import styles from './TextField.module.scss';
import classNames from 'classnames';
interface TextFieldProps {
  name: string;
  value: string | number | undefined;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: (value: any) => void;
  onFocus?: () => void;
  onFocusLost?: () => void;
  hint?: string;
  label?: string;
  type?: string;
  error?: string;
  hasError?: boolean;
  maxLength?: number;
}

function TextField({
  value = '',
  hint,
  disabled,
  name,
  label,
  type,
  onFocus,
  onFocusLost,
  error,
  hasError,
  maxLength,
  setValue,
}: TextFieldProps): ReactElement {
  const getType = () => {
    switch (type) {
      case 'number':
      case 'price':
        return 'number';
      case 'password':
        return 'password';
      case 'text':
      default:
        return 'text';
    }
  };

  return (
    <div className={styles.container}>
      <input
        className={classNames(styles.info, error && styles.error)}
        autoComplete={'off'}
        id={name}
        type={getType()}
        onFocus={onFocus}
        onBlur={onFocusLost}
        placeholder={hint}
        onChange={(event) => (setValue ? setValue(event.target.value) : null)}
        value={value}
        step={type === 'price' ? 0.01 : undefined}
        min={type === 'price' ? 0 : undefined}
        maxLength={maxLength}
        disabled={disabled}
      />
      {label && value && <label className={styles.label}>{value ? label : ''}</label>}
      {!!hasError && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
}

export default TextField;
