import Checkbox from 'components/Base/Checkbox';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import TextField from 'components/Base/TextField';
import { MAX_DESCRIPTION_SIZE, MAX_NOTES_SIZE } from 'lib/jobs';
import React, { ReactElement, useEffect, useState } from 'react';
import { CreateWindow, CreateWindowValidation, JobSubType, WindowDetails } from 'types';
import TextFieldCountdown from 'components/TextFieldCountdown';
import styles from '../TypeSetup.module.scss';
import TextAreaCountdown from 'components/TextAreaCountdown';
import Button from 'components/Base/Button';
import { dateFormats, formatDate } from 'lib/date';
import { formatPrice } from 'lib/formatting';

interface WindowSetupProps {
  subTypes: Array<JobSubType>;
  details?: CreateWindow;
  addTitle?: string;
  changeDetails: (key: keyof CreateWindow, value: number | JobSubType | string | boolean | undefined) => void;
  setSubType: (type: JobSubType) => void;
  onAdd?: () => void;
  isQuote?: boolean;
  previousJobs?: Array<WindowDetails>;
  previousQuotes?: Array<WindowDetails>;
  errors?: CreateWindowValidation;
  includePreviousWork?: boolean;
}

function WindowSetup({
  subTypes,
  details,
  onAdd,
  changeDetails,
  setSubType,
  addTitle,
  isQuote,
  errors,
  previousJobs,
  previousQuotes,
  includePreviousWork,
}: WindowSetupProps): ReactElement {
  const [selectedPreviousJob, setSelectedPreviousJob] = useState<WindowDetails>();
  const [selectedQuote, setSelectedQuote] = useState<WindowDetails>();

  const previousSummary = (data?: WindowDetails) => {
    return (
      data && (
        <div>
          {`${formatDate(data?.createdAt, dateFormats.abbreviatedPretty)}: ${data?.subType.title} - ${formatPrice(
            data?.price,
            true
          )}`}
        </div>
      )
    );
  };

  const getQuoteValue = () => {
    if (previousQuotes && !previousQuotes.length) return 'No Quotes Found';
    return previousSummary(selectedQuote);
  };

  const getPreviousJobValue = () => {
    if (previousJobs && !previousJobs.length) return 'No Previous Jobs Found';
    return previousSummary(selectedPreviousJob);
  };

  useEffect(() => {
    if (!includePreviousWork) return;
    const quote = selectedPreviousJob || selectedQuote;

    changeDetails('subType', quote?.subType);
    changeDetails('description', quote?.description);
    changeDetails('basement', quote?.basement);
    changeDetails('sills', quote?.sills);
    changeDetails('railings', quote?.railings);
    changeDetails('skylights', quote?.skylights);
    changeDetails('frames', quote?.frames);
    changeDetails('tracks', quote?.tracks);
    changeDetails('garage', quote?.garage);
    changeDetails('cranks', quote?.cranks);
    changeDetails('doors', quote?.doors);
    changeDetails('solarPanels', quote?.solarPanels);
    changeDetails('thirdFloor', quote?.thirdFloor);
    changeDetails('notes', quote?.notes);
    changeDetails('price', quote?.price);
  }, [selectedPreviousJob, selectedQuote]);

  return (
    <div className={styles.divisionContent}>
      {!!(!isQuote && includePreviousWork) && (
        <div className={styles.preset}>
          {previousJobs && (
            <Dropdown
              hint={'Previous Window Jobs'}
              items={previousJobs.map((job) => {
                return (
                  <DropdownItem
                    key={job.id}
                    id={job.id}
                    value={<div>{previousSummary(job)}</div>}
                    onSelect={() => {
                      setSelectedPreviousJob(job);
                      setSelectedQuote(undefined);
                    }}
                  />
                );
              })}
              selectedValue={getPreviousJobValue()}
            />
          )}
          {previousQuotes && (
            <Dropdown
              hint={'Quotes'}
              items={previousQuotes.map((job) => {
                return (
                  <DropdownItem
                    key={job.id}
                    id={job.id}
                    value={<div>{previousSummary(job)}</div>}
                    onSelect={() => {
                      setSelectedPreviousJob(undefined);
                      setSelectedQuote(job);
                    }}
                  />
                );
              })}
              selectedValue={getQuoteValue()}
            />
          )}
        </div>
      )}

      <div className={styles.subTypes}>
        <Dropdown
          hint={'Select a Cleaning Type'}
          objectKey={'title'}
          error={errors?.subType}
          items={subTypes.map((type) => {
            return <DropdownItem key={type.id} id={type.id} value={type.title} onSelect={() => setSubType(type)} />;
          })}
          selectedValue={details?.subType}
        />
        {isQuote && (
          <div className={styles.includeInQuote}>
            <Checkbox
              label={'Include In Quote'}
              checked={!!details?.showToClient}
              onClick={(value: boolean) => changeDetails('showToClient', value)}
            />
          </div>
        )}
      </div>
      <div className={styles.description}>
        <TextFieldCountdown
          value={details?.description}
          hint={'Description'}
          error={errors?.description}
          setValue={(value: string) => changeDetails('description', value)}
          maxLength={MAX_DESCRIPTION_SIZE}
        />
      </div>

      <div className={styles.checkboxContainer}>
        <div>
          <Checkbox
            label={'Basement'}
            checked={!!details?.basement}
            onClick={(value: boolean) => changeDetails('basement', value)}
          />
          <Checkbox
            label={'Sills'}
            checked={!!details?.sills}
            onClick={(value: boolean) => changeDetails('sills', value)}
          />
          <Checkbox
            label={'Railings'}
            checked={!!details?.railings}
            onClick={(value: boolean) => changeDetails('railings', value)}
          />
          <Checkbox
            label={'Skylights'}
            checked={!!details?.skylights}
            onClick={(value: boolean) => changeDetails('skylights', value)}
          />
        </div>
        <div>
          <Checkbox
            label={'Frames'}
            checked={!!details?.frames}
            onClick={(value: boolean) => changeDetails('frames', value)}
          />
          <Checkbox
            label={'Tracks'}
            checked={!!details?.tracks}
            onClick={(value: boolean) => changeDetails('tracks', value)}
          />
          <Checkbox
            label={'Garage'}
            checked={!!details?.garage}
            onClick={(value: boolean) => changeDetails('garage', value)}
          />
          <Checkbox
            label={'Cranks'}
            checked={!!details?.cranks}
            onClick={(value: boolean) => changeDetails('cranks', value)}
          />
        </div>
        <div>
          <Checkbox
            label={'Doors'}
            checked={!!details?.doors}
            onClick={(value: boolean) => changeDetails('doors', value)}
          />
          <Checkbox
            label={'Solar Panels'}
            checked={!!details?.solarPanels}
            onClick={(value: boolean) => changeDetails('solarPanels', value)}
          />
          <Checkbox
            label={'Third Floor'}
            checked={!!details?.thirdFloor}
            onClick={(value: boolean) => changeDetails('thirdFloor', value)}
          />
        </div>
      </div>

      <div className={styles.notes}>
        <TextAreaCountdown
          hint="Notes"
          maxLength={MAX_NOTES_SIZE}
          value={details?.notes}
          setValue={(value: string) => changeDetails('notes', value)}
        />
      </div>
      <div className={styles.priceContainer}>
        <div className={styles.price}>
          <TextField
            type={'price'}
            hint={'Price'}
            name={'windowPrice'}
            error={errors?.price}
            hasError={true}
            value={details?.price}
            setValue={(value) => {
              if (value) value = parseFloat(value);
              changeDetails('price', value === '' ? undefined : +value);
            }}
          />
        </div>
      </div>
      {addTitle && (
        <div className={styles.addBtnContainer}>
          <div className={styles.addBtn}>
            <Button title={addTitle} onClick={onAdd} />
          </div>
        </div>
      )}
    </div>
  );
}

export default WindowSetup;
