import Autocomplete from 'components/Base/Autocomplete';
import BaseModal from 'components/Base/BaseModal';
import TextField from 'components/Base/TextField';
import HorizontalAddEditChild from 'components/AddEditSetup/HorizontalAddEditChild';
import HorizontalAddEditRow from 'components/AddEditSetup/HorizontalAddEditRow';
import React, { ReactElement, useEffect, useState } from 'react';
import { User, Colour, CreateEditTeamValidation, CreateTeam } from 'types';
import useUsers from 'hooks/useUsers.hooks';
import useTeams from 'hooks/useTeams.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import ColourDropdown from 'components/ColourDropdown';
import ColourDropdownItem from 'components/ColourDropdown/components/ColourDropdownItem';
import styles from './AddEditTeamModal.module.scss';
import useDebounce from 'hooks/useDebounce.hooks';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationModal from 'components/ConfirmationModal';

interface AddEditTeamModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  teamId?: number;
}
function AddEditTeamModal({ open, teamId, onClose, onSave }: AddEditTeamModalProps): ReactElement {
  const [name, setName] = useState<string>();
  const [hourlyProduction, setHourlyProduction] = useState<number>();
  const [colour, setColour] = useState<Colour>();
  const [members, setMembers] = useState<Array<User>>([]);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [validation, setValidation] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<CreateEditTeamValidation>();
  const [confirmHourlyProduction, setConfirmHourlyProduction] = useState<boolean>(false);

  const {
    onNewTeamSave,
    fetchColours,
    resetTeamError,
    fetchTeam,
    update,
    colours,
    teamErrorMessage,
    teamErrorTitle,
    team,
  } = useTeams();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: teamErrorTitle, message: teamErrorMessage, reset: resetTeamError }],
  });
  const { fetchUsers, users } = useUsers();

  const debounceSearch = useDebounce(searchFilter, 300);

  const validate = (showErrors = false) => {
    const errors: CreateEditTeamValidation = {};
    let validated = true;
    if (!name) {
      errors.teamName = 'Please create a team name';
      validated = false;
    }

    if (!colour) {
      errors.colour = 'Please select a team colour';
      validated = false;
    }
    if (!members.length) {
      errors.members = 'Please select at least one team member';
      validated = false;
    }

    if (showErrors || validation) {
      setValidationErrors(errors);
      setValidation(true);
    }

    return validated;
  };

  const resetValues = () => {
    setMembers([]);
    setSearchFilter('');
    setColour(undefined);
    setHourlyProduction(undefined);
    setName(undefined);
    setValidation(false);
    setValidationErrors(undefined);
  };

  const onLocalClose = () => {
    onClose();
    resetValues();
  };

  const saveChanges = async () => {
    try {
      const newTeam: CreateTeam = {
        name,
        hourlyProduction,
        members,
        colourId: colour?.id,
      };
      if (teamId) {
        await update(teamId, newTeam);
      } else {
        await onNewTeamSave(newTeam);
      }

      onSave();
      resetValues();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const onLocalSave = () => {
    if (!validate(true)) return;
    if (!hourlyProduction) {
      setConfirmHourlyProduction(true);
      return;
    }
    saveChanges();
  };

  const onItemSelect = (user: User) => {
    const member = members.find((member) => member.id === user.id);
    if (member) return;

    const selected = [...members];
    selected.push(user);
    setMembers(selected);
  };

  const removeTeamMember = (index: number) => {
    if (index >= members.length) return;
    const selected = [...members];

    selected.splice(index, 1);
    setMembers(selected);
  };
  const localFetchTeam = () => {
    if (!teamId) return;
    fetchTeam(teamId);
  };

  useEffect(() => {
    validate();
  }, [members, name, colour]);

  useEffect(() => {
    if (!open) return;
    fetchUsers(debounceSearch);
  }, [debounceSearch]);

  useEffect(() => {
    if (!team) return;
    setName(team.name);
    setHourlyProduction(team.hourlyProduction);
    setMembers(team.members);
    const colour = colours.find((colour) => colour.id === team.colourId);
    setColour(colour);
  }, [team]);

  useEffect(() => {
    if (!open) return;
    fetchColours();
    fetchUsers();
    localFetchTeam();
  }, [open]);

  return (
    <BaseModal
      title={teamId ? 'Edit Team' : 'Add Team'}
      open={open}
      onClose={onLocalClose}
      onConfirm={onLocalSave}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
    >
      <form>
        <HorizontalAddEditRow>
          <HorizontalAddEditChild fullWidth>
            <TextField
              value={name}
              label={'Team Name'}
              setValue={setName}
              name={'name'}
              hint={'Team Name'}
              error={validationErrors?.teamName}
              hasError={true}
              maxLength={50}
            />
          </HorizontalAddEditChild>
        </HorizontalAddEditRow>
        <HorizontalAddEditRow>
          <HorizontalAddEditChild>
            <ColourDropdown
              label={'Team Colour'}
              error={validationErrors?.colour}
              items={colours.map((type) => (
                <ColourDropdownItem key={type.id} id={type.id} item={type} onSelect={() => setColour(type)} />
              ))}
              selectedValue={colour?.hexCode || ''}
            />
          </HorizontalAddEditChild>
          <HorizontalAddEditChild fullWidth>
            <TextField
              label={'Hourly Production'}
              value={hourlyProduction}
              setValue={setHourlyProduction}
              name={'hourlyProduction'}
              hint={'Hourly Production'}
              hasError={true}
              disabled={!!teamId}
              type={'price'}
            />
          </HorizontalAddEditChild>
        </HorizontalAddEditRow>
        <HorizontalAddEditRow>
          {!teamId && (
            <div>
              <HorizontalAddEditChild fullWidth>
                <Autocomplete
                  values={users
                    .filter((user) => !members.find((member) => member.id === user.id))
                    .map((user) => {
                      return {
                        object: user,
                        rowDisplay: <div>{user.fullName}</div>,
                      };
                    })}
                  onSelect={onItemSelect}
                  error={validationErrors?.members}
                  searchFilter={searchFilter}
                  setSearchFilter={setSearchFilter}
                  hint={'Search Staff'}
                  hasError={true}
                  onClearField={() => setSearchFilter('')}
                />
              </HorizontalAddEditChild>
            </div>
          )}
        </HorizontalAddEditRow>
        <HorizontalAddEditRow>
          <HorizontalAddEditChild fullWidth>
            <div className={styles.membersTitle}>Members</div>
            <div className={styles.membersList}>
              {members.map((member, index) => {
                return (
                  <div key={member.id} className={styles.member}>
                    <div>{member.fullName}</div>
                    {!teamId && (
                      <i className={styles.removeButton} onClick={() => removeTeamMember(index)}>
                        <CloseIcon className={styles.icon} />
                      </i>
                    )}
                  </div>
                );
              })}
            </div>
          </HorizontalAddEditChild>
        </HorizontalAddEditRow>
      </form>
      <ConfirmationModal
        open={confirmHourlyProduction}
        title={'Missing Hourly Production'}
        message={
          'Are you sure you would not add an hourly production.  Hourly production is used to help schedule jobs for your teams'
        }
        onAccept={saveChanges}
        onClose={() => setConfirmHourlyProduction(false)}
      />
    </BaseModal>
  );
}

export default AddEditTeamModal;
