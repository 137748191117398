import { changeStyleProperty } from 'lib/reactOperations';
import React, { MouseEvent, ReactElement, useEffect } from 'react';
import styles from './TableData.module.scss';
import classNames from 'classnames';

interface TableDataProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  onClick?: () => void;
  noData?: boolean;
  widthPercent?: number;
  id?: string;
  header?: boolean;
  hover?: boolean;
}
function TableData({ data, onClick, noData, id, widthPercent, header, hover }: TableDataProps): ReactElement {
  const getStyles = () => {
    return noData ? styles.message : styles.data;
  };
  const localOnClick = (event: MouseEvent<HTMLElement>) => {
    if (onClick) {
      event.stopPropagation();
      onClick();
    }
  };

  useEffect(() => {
    if (id && widthPercent) changeStyleProperty(id, '--width', `${widthPercent}%`);
  }, []);

  return (
    <td id={id} onClick={localOnClick} className={classNames(getStyles(), styles.width, header && styles.header)}>
      <div className={classNames(hover && styles.hover)}>{data}</div>
    </td>
  );
}

export default TableData;
