import { QuotesApi } from 'api';
import { handleApiError } from 'lib/errors';
import { useState } from 'react';
import { CreateQuote, QuoteSettings, UpdateQuoteSettings } from 'types';
import useErrorsDetails from './useErrors.hook';
import { createPDFUrlFromByte, openNewTab } from 'lib/reactOperations';

interface QuotesInfo {
  quotesErrorMessage: string;
  quotesErrorTitle: string;
  quotesLoading: boolean;
  quoteSettings?: QuoteSettings;
  createQuote: (data: CreateQuote) => Promise<void>;
  updateQuoteSettings: (data: UpdateQuoteSettings) => Promise<void>;
  resetQuoteError: () => void;
  fetchQuoteSettings: () => void;
  viewQuote: (id: number) => void;
  previewQuote: (data?: CreateQuote) => void;
  sendQuote: (id: number, emailIds: Array<number>) => void;
}

function useQuotes(): QuotesInfo {
  const [loading, setLoading] = useState<boolean>(false);
  const [settings, setSettings] = useState<QuoteSettings>();

  const { handleError, errorMessage, errorTitle, resetError } = useErrorsDetails();

  const viewQuote = async (id: number) => {
    try {
      setLoading(true);
      const signedUrl = await QuotesApi.fetchQuoteSignedUrl(id);
      openNewTab(signedUrl);
      setLoading(false);
    } catch (error) {
      console.error(error);
      handleError('Error', handleApiError(error));
      setLoading(false);
    }
  };

  const fetchQuoteSettings = async () => {
    try {
      const settings = await QuotesApi.fetchSettings();
      setSettings(settings);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const createQuote = async (data: CreateQuote): Promise<void> => {
    try {
      await QuotesApi.createQuote(data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const updateQuoteSettings = async (data: UpdateQuoteSettings): Promise<void> => {
    try {
      await QuotesApi.updateSettings(data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const preview = async (data?: CreateQuote) => {
    try {
      const pdfData: ArrayBuffer = await QuotesApi.preview(data);
      const url = createPDFUrlFromByte(pdfData);
      openNewTab(url);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const sendQuote = async (id: number, emailIds: Array<number>) => {
    try {
      await QuotesApi.sendQuote(id, emailIds);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  return {
    quotesLoading: loading,
    quotesErrorMessage: errorMessage,
    quotesErrorTitle: errorTitle,
    quoteSettings: settings,
    updateQuoteSettings,
    resetQuoteError: resetError,
    fetchQuoteSettings,
    createQuote,
    viewQuote,
    previewQuote: preview,
    sendQuote,
  };
}

export default useQuotes;
