import { useState } from 'react';

interface useErrorsDetailsInfo {
  errorMessage: string;
  errorTitle: string;
  resetError: () => void;
  handleError: (title: string, message: string) => void;
}

function useErrors(): useErrorsDetailsInfo {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorTitle, setErrorTitle] = useState<string>('');

  const handleError = (title: string, message: string) => {
    setErrorTitle(title);
    setErrorMessage(message);
  };

  const resetError = () => {
    setErrorMessage('');
    setErrorTitle('');
  };

  return {
    handleError,
    errorMessage,
    errorTitle,
    resetError,
  };
}

export default useErrors;
