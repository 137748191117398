import { formatPrice } from 'lib/formatting';
import { changeStyleProperty } from 'lib/reactOperations';
import React, { ReactElement, useEffect } from 'react';
import { TeamSchedule } from 'types';
import styles from './TeamScheduleSummary.module.scss';

interface TeamScheduleSummaryProps {
  schedule: TeamSchedule;
  editSchedule: () => void;
}
function TeamScheduleSummary({ schedule, editSchedule }: TeamScheduleSummaryProps): ReactElement {
  const scheduleId = `${schedule.id}-${schedule.day}`;
  useEffect(() => {
    changeStyleProperty(scheduleId, '--colorHex', schedule.team.hexCode);
  }, [schedule]);
  return (
    <>
      <div onClick={editSchedule} id={scheduleId} className={styles.summary}>
        {`${schedule.startTime} - ${schedule.endTime}`}
        <div className={styles.details}>
          <div>{schedule.team.name}</div>
          {schedule.team.hourlyProduction && <div>{formatPrice(schedule.team.hourlyProduction, true)}/h</div>}
        </div>
      </div>
    </>
  );
}

export default TeamScheduleSummary;
