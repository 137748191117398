import Autocomplete from 'components/Base/Autocomplete';
import BaseModal from 'components/Base/BaseModal';
import DateSelector from 'components/Base/DateSelector';
import TextField from 'components/Base/TextField';
import useDebounce from 'hooks/useDebounce.hooks';
import useJobs from 'hooks/useJobs.hook';
import { formatDate, sqlToday } from 'lib/date';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { FetchOpeningParams, Job, JobScheduleType, OpenDay, OpenSlot } from 'types';
import styles from './FindJobScheduleOpeningsModal.module.scss';
import { dateFormats } from 'lib/date';
import MultiChoiceModal, { MultiChoiceButton } from '../MultiChoiceModal';
import useReactOperations from 'hooks/useReactOperations.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import JobTypeIndicators from 'components/JobTypeIndicators';
import useJobSchedules from 'hooks/useJobSchedules.hook';
import Checkbox from 'components/Base/Checkbox';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';

interface FindJobScheduleOpeningsProps {
  open: boolean;
  onClose: () => void;
  onSave: (date?: string) => void;
  jobId?: number;
}

function FindJobScheduleOpeningsModal({ open, onClose, onSave, jobId }: FindJobScheduleOpeningsProps): ReactElement {
  const [jobSearchFilter, setJobSearchFilter] = useState<string>('');
  const [range, setRange] = useState<number>(2);
  const [selectedJob, setSelectedJob] = useState<Job>();
  const [minDate, setMinDate] = useState<string>(sqlToday());
  const [maxDate, setMaxDate] = useState<string>(moment().add(1, 'months').format(dateFormats.sqlDateOnly));
  const [priceEstimate, setPriceEstimate] = useState<number>();
  const [timeEstimate, setTimeEstimate] = useState<number>();
  const [selectedOpenDay, setSelectedOpenDay] = useState<OpenDay>();
  const [selectedOpenSlot, setSelectedOpenSlot] = useState<OpenSlot>();
  const [openDayIndex, setOpenDayIndex] = useState<number>();
  const [openMultiSelect, setOpenMultiSelect] = useState<boolean>(false);
  const [includeJobsWithSchedules, setIncludeJobsWithSchedules] = useState<boolean>(false);

  const { jobs, fetchJobs, fetchJob, job, jobErrorMessage, jobErrorTitle, resetJobError } = useJobs();

  const {
    fetchAvailability,
    openDays,
    openSlots,
    createJobSchedule,
    resetAvailableDays,
    fetchJobScheduleTypes,
    jobScheduleTypes,
  } = useJobSchedules();
  const [jobScheduleType, setJobScheduleType] = useState<JobScheduleType>();
  const { navigateToDailyCalendarPage } = useReactOperations();

  const jobSearchFilterDebounce = useDebounce(jobSearchFilter);

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError }],
  });

  const resetData = () => {
    setRange(2);
    setJobSearchFilter('');
    setSelectedJob(undefined);
    setMinDate(sqlToday());
    setMaxDate(moment().add(1, 'months').format(dateFormats.sqlDateOnly));
    setPriceEstimate(undefined);
    setTimeEstimate(undefined);
    setSelectedOpenDay(undefined);
    setSelectedOpenSlot(undefined);
    setOpenDayIndex(undefined);
    setOpenMultiSelect(false);
    resetAvailableDays();
  };

  const searchOpenings = () => {
    if (!selectedJob) return;

    const params: FetchOpeningParams = {
      addressId: selectedJob?.address.id,
      minDate,
      maxDate,
      price: priceEstimate,
      time: timeEstimate,
      range: Math.floor(range * 1000),
    };
    fetchAvailability(params);
  };

  const createNewSchedule = async () => {
    if (!selectedJob || openDayIndex === undefined) return;

    let teamId;
    let date;
    if (selectedOpenDay) {
      teamId = selectedOpenDay.teams[openDayIndex].teamId;
      date = selectedOpenDay.date;
    } else if (selectedOpenSlot) {
      teamId = selectedOpenSlot.schedules[openDayIndex].teamId;
      date = selectedOpenSlot.date;
    }
    setSelectedOpenDay(undefined);
    setSelectedOpenSlot(undefined);
    if (!teamId || !date || !jobScheduleType) return;

    await createJobSchedule({
      jobId: selectedJob?.id,
      teamId,
      date,
      freePlacement: true,
      time: timeEstimate,
      price: priceEstimate,
      typeId: jobScheduleType.id,
    });
    onSave(date);
    setOpenMultiSelect(false);
  };

  const getMultiButtons = (): Array<MultiChoiceButton> => {
    return [
      { label: 'Create', onClick: () => createNewSchedule() },
      {
        label: 'Create -> Calendar Page',
        onClick: async () => {
          const date = selectedOpenDay ? selectedOpenDay?.date : selectedOpenSlot?.date;
          await createNewSchedule();
          setOpenMultiSelect(false);
          navigateToDailyCalendarPage(date);
          onClose();
        },
      },
    ];
  };

  useEffect(() => {
    setPriceEstimate(job?.subtotal);
    setSelectedJob(job);
    setJobSearchFilter(job?.description || '');
  }, [job]);

  useEffect(() => {
    if (!jobId) return;
    fetchJob(jobId);
  }, [jobId]);

  useEffect(() => {
    if (!jobScheduleTypes.length) return;
    setJobScheduleType(jobScheduleTypes[0]);
  }, [jobScheduleTypes]);

  useEffect(() => {
    if (!open) {
      resetData();
    }
    fetchJobScheduleTypes();
  }, [open]);

  useEffect(() => {
    if (!open) return;
    fetchJobs({
      searchFilter: jobSearchFilterDebounce,
      notCompleted: true,
      jobsWithoutSchedules: !includeJobsWithSchedules,
    });
  }, [jobSearchFilterDebounce, includeJobsWithSchedules]);

  return (
    <>
      <BaseModal
        open={open}
        title={'Find Openings'}
        onClose={onClose}
        onConfirm={searchOpenings}
        confirmTitle={'Search'}
        cancelTitle={'Close'}
        alertMessage={alertMessage}
        alertTitle={alertTitle}
        openAlert={openAlert}
        onAlertClose={closeAlertModal}
      >
        <div className={styles.container}>
          <div className={styles.searchFields}>
            <Autocomplete
              values={jobs.map((job) => {
                return {
                  object: job,
                  rowDisplay: (
                    <div className={styles.customDropdown}>
                      <div>{formatDate(job.startDate, dateFormats.abbreviatedPretty)}</div>
                      <div className={styles.address}>{job.address.fullAddress}</div>
                      <div>
                        <JobTypeIndicators job={job} useFillers={false} />
                      </div>
                    </div>
                  ),
                };
              })}
              onSelect={(job: Job) => {
                setSelectedJob(job);
                setJobSearchFilter(job.description || '');
                setPriceEstimate(job.subtotal);
              }}
              disabled={!!jobId}
              searchFilter={jobSearchFilter}
              setSearchFilter={setJobSearchFilter}
              hint={'Search Jobs'}
              onClearField={() => setJobSearchFilter('')}
            />
          </div>

          <Checkbox
            label="Include Jobs With Schedules"
            checked={includeJobsWithSchedules}
            onClick={setIncludeJobsWithSchedules}
          />
          <div className={styles.scheduleTypes}>
            <Dropdown
              items={jobScheduleTypes.map((type) => {
                return (
                  <DropdownItem
                    key={type.id}
                    id={type.id}
                    value={type.type}
                    onSelect={() => setJobScheduleType(type)}
                  />
                );
              })}
              selectedValue={jobScheduleTypes?.find((t) => t.id === jobScheduleType?.id) ?? jobScheduleTypes[0]}
              objectKey="type"
            />
          </div>
          <div className={styles.filterOptions}>
            <div className={styles.row}>
              <TextField
                label="Max Distance (km)"
                hint="Max Distance (km)"
                value={range}
                type={'number'}
                setValue={setRange}
                name={'range'}
              />
              <TextField
                label="Price Estimate"
                hint="Price Estimate"
                value={priceEstimate}
                type={'number'}
                setValue={(value) => {
                  setTimeEstimate(undefined);
                  setPriceEstimate(value);
                }}
                name={'priceEstimate'}
              />
              <TextField
                label="Time"
                hint="Time"
                value={timeEstimate}
                type={'number'}
                setValue={(value) => {
                  setTimeEstimate(value);
                  setPriceEstimate(undefined);
                }}
                name={'time'}
              />
            </div>
            <div className={styles.dateSelector}>
              <DateSelector date={minDate} setDate={setMinDate} min={sqlToday()} title={'From'} />
            </div>
            <div className={styles.dateSelector}>
              <DateSelector
                date={maxDate}
                setDate={setMaxDate}
                min={minDate}
                max={moment(minDate).add(2, 'months').format(dateFormats.sqlDateOnly)}
                title={'To'}
              />
            </div>
          </div>

          <div className={styles.results}>
            <div className={styles.openDays}>
              <div className={styles.title}>Open Slots</div>
              {openSlots.map((day) => {
                return (
                  <div key={day.date} className={styles.dateSummary}>
                    <div className={styles.date}>{day.date}</div>
                    <div>
                      {day.schedules.map((schedule, index) => {
                        return (
                          <div key={schedule.teamId} className={styles.teamItem}>
                            <div className={styles.data}>
                              <div className={styles.sub}>
                                <div className={styles.itemTitle}>Distance Away:</div>
                                <div>{`${schedule.distance / 1000} km`}</div>
                              </div>
                              <div className={styles.sub}>
                                <div className={styles.itemTitle}>Team:</div>
                                <div>{schedule.teamName}</div>
                              </div>
                            </div>
                            <button
                              className={styles.btn}
                              onClick={() => {
                                setOpenMultiSelect(true);
                                setOpenDayIndex(index);
                                setSelectedOpenSlot(day);
                              }}
                            >
                              Schedule
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={styles.openDays}>
              <div className={styles.title}>Open Days</div>
              {openDays.map((day) => {
                return (
                  <div key={day.date} className={styles.dateSummary}>
                    <div className={styles.date}>{day.date}</div>
                    <div>
                      {day.teams.map((team, index) => {
                        return (
                          <div key={team.teamId} className={styles.teamItem}>
                            <div className={styles.data}>
                              <div className={styles.sub}>
                                <div>{team.teamName}</div>
                              </div>
                            </div>
                            <button
                              className={styles.btn}
                              onClick={() => {
                                setOpenMultiSelect(true);
                                setOpenDayIndex(index);
                                setSelectedOpenDay(day);
                              }}
                            >
                              Schedule
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <MultiChoiceModal
          open={openMultiSelect}
          title={''}
          message={'Pick an action'}
          onClose={() => setOpenMultiSelect(false)}
          buttons={getMultiButtons()}
        />
      </BaseModal>
    </>
  );
}

export default FindJobScheduleOpeningsModal;
