import { BaseApi } from 'api';
import { CreateInvoice, InvoiceSettings, UpdateInvoiceSettings } from 'types';

import { INVOICES } from 'lib/routes';

export const create = async (data: CreateInvoice): Promise<void> => {
  return await BaseApi.post(`${INVOICES}`, data);
};

export const updateSettings = async (data: UpdateInvoiceSettings): Promise<void> => {
  return await BaseApi.put(`${INVOICES}/settings`, data);
};

export const fetchSettings = async (): Promise<InvoiceSettings> => {
  return await BaseApi.get(`${INVOICES}/settings`);
};

export const fetchInvoiceSignedUrl = async (id: number): Promise<string> => {
  return await BaseApi.get(`${INVOICES}/signedUrl/${id}`);
};

export const preview = async (data?: CreateInvoice): Promise<ArrayBuffer> => {
  return await BaseApi.getPDF(`${INVOICES}/preview`, data);
};

export const sendInvoice = async (id: number, emailIds: Array<number>): Promise<void> => {
  return await BaseApi.post(`${INVOICES}/send/${id}`, { emailIds });
};
