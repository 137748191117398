import Button from 'components/Base/Button';
import React, { ReactElement } from 'react';
import styles from './MultiChoiceModal.module.scss';

export type MultiChoiceButton = {
  label: string;
  onClick: () => void;
};

interface MultiChoiceModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  message?: string;
  buttons: Array<MultiChoiceButton>;
}

function MultiChoiceModal({ open, title, onClose, message, buttons }: MultiChoiceModalProps): ReactElement {
  return (
    <>
      {open && (
        <div className={styles.background}>
          <div className={styles.container}>
            <label>{title}</label>
            <div className={styles.titleDivider} />
            <p>{message}</p>
            <div className={styles.buttons}>
              {buttons.map((button, index) => {
                return (
                  <div key={index} className={styles.button}>
                    <Button onClick={button.onClick} title={button.label} border={false} />
                  </div>
                );
              })}
              <Button onClick={onClose} title={'Close'} border={false} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MultiChoiceModal;
