import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import TextField from 'components/Base/TextField';
import React, { ReactElement } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { CreatePhoneContact, PhoneType } from 'types';
import styles from './PhoneSetUp.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import { formatPhone } from 'lib/formatting';

interface PhoneSetUpProps {
  label?: string;
  index: number;
  phone: CreatePhoneContact;
  phoneTypes: Array<PhoneType>;
  allowDelete: boolean;
  allowAdd: boolean;
  setNumber: (value: string, index?: number) => void;
  setType: (type: PhoneType) => void;
  remove?: () => void;
  add?: () => void;
}

function PhoneSetUp({
  phone,
  index,
  phoneTypes,
  allowDelete,
  allowAdd,
  setNumber,
  setType,
  remove,
  add,
}: PhoneSetUpProps): ReactElement {
  const getPhoneType = (phone: CreatePhoneContact) => {
    if (!phoneTypes.length) return '';
    if (phone) return phoneTypes.find((type) => type.id === phone.typeId)?.type || '';
    return phoneTypes[0].type;
  };

  return (
    <div className={styles.container}>
      <div>
        <TextField
          value={phone.number}
          setValue={(value) => setNumber(formatPhone(value))}
          name={'number'}
          hint={!index ? 'Default' : undefined}
          maxLength={14}
        />
      </div>
      <div className={styles.type}>
        <Dropdown
          items={phoneTypes.map((type) => (
            <DropdownItem key={type.id} id={type.id} value={type.type} onSelect={() => setType(type)} />
          ))}
          selectedValue={getPhoneType(phone)}
        />
      </div>

      <div>
        <i className={styles.removeButton} onClick={remove}>
          {allowDelete && <CloseIcon />}
        </i>

        <div className={styles.addButton} onClick={add}>
          {allowAdd && <AddIcon className={styles.button} />}
        </div>
      </div>
    </div>
  );
}

export default PhoneSetUp;
