import Layout from 'Base';
import InfoCard from 'components/InfoCard';
import AddEditUserModal from 'components/Modals/AddEditUserModal';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useUsers from 'hooks/useUsers.hooks';
import { dateFormats, formatDate } from 'lib/date';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './User.module.scss';

function User() {
  const { userId } = useParams();
  const id = userId ? +userId : undefined;

  const [openEditUser, setOpenEditUser] = useState<boolean>(false);

  const { fetchUser, userErrorMessage, userErrorTitle, resetUserError, user, userLoading } = useUsers();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: userErrorTitle, message: userErrorMessage, reset: resetUserError }],
  });

  const onUpdate = () => {
    if (!id) return;
    fetchUser(id);
    setOpenEditUser(false);
  };

  useEffect(() => {
    if (!id) return;
    fetchUser(id);
  }, [userId]);

  return (
    <Layout
      title={user?.fullName || ''}
      alertMessage={alertMessage}
      openAlert={openAlert}
      alertTitle={alertTitle}
      onAlertClose={closeAlertModal}
    >
      <div className={styles.container}>
        <div className={styles.summaries}>
          <InfoCard title={'Summary'} onEdit={() => setOpenEditUser(true)}>
            <table>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Office Info</th>
                </tr>
              </thead>
              <tbody>
                {!userLoading && (
                  <tr>
                    <td>
                      <div className={styles.center}>{`Address: ${user?.address}`}</div>
                      <div className={styles.center}>{`Cell Phone: ${user?.cellPhone}`}</div>
                      <div className={styles.center}>{`Email: ${user?.email}`}</div>
                      <div className={styles.center}>{`Permissions: ${user?.userType.type}`}</div>
                    </td>
                    <td className={styles.center}>
                      <div className={styles.center}>{`Created By: ${user?.createdBy}`}</div>
                      <div className={styles.center}>{`Created At: ${formatDate(
                        user?.createdAt,
                        dateFormats.fullPretty
                      )}`}</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </InfoCard>
        </div>
      </div>
      <AddEditUserModal open={openEditUser} id={id} onClose={() => setOpenEditUser(false)} onSave={onUpdate} />
    </Layout>
  );
}

export default User;
