import React, { ReactElement } from 'react';
import styles from './TextareaAttribute.module.scss';
import TextAreaCountdown from 'components/TextAreaCountdown';

interface TextAttributeProps {
  field: string;
  onChange: (value: string) => void;
  value?: string;
  edit: boolean;
}
function TextAttribute({ field, onChange, edit, value }: TextAttributeProps): ReactElement {
  return (
    <div className={styles.item}>
      <div className={styles.field}>{field}</div>
      {edit ? (
        <div className={styles.edit}>
          <TextAreaCountdown value={value} setValue={onChange} maxLength={300} />
        </div>
      ) : (
        <div>
          <div>{value}</div>
        </div>
      )}
    </div>
  );
}

export default TextAttribute;
