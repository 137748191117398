import BaseModal from 'components/Base/BaseModal';
import React, { ReactElement, useEffect, useState } from 'react';
import styles from './AddEditClientModal.module.scss';
import TextField from 'components/Base/TextField';
import {
  CreateAddress,
  CreateClient,
  CreatedClient,
  CreateEditClientValidation,
  CreateEmailContact,
  CreatePhoneContact,
  EmailContact,
  PhoneContact,
  PhoneType,
} from 'types';
import PhoneSetUp from 'components/PhoneSetUp';
import HorizontalAddEditRow from 'components/AddEditSetup/HorizontalAddEditRow';
import HorizontalAddEditChild from 'components/AddEditSetup/HorizontalAddEditChild';
import AddEditAddressSetup from 'components/AddEditSetup/AddEditAddressSetup';
import AddEditBase from 'components/AddEditSetup/AddEditBase';
import ColumnAddEdit from 'components/AddEditSetup/ColumnAddEdit';
import useClients from 'hooks/useClients.hooks';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import EmailSetup from 'components/EmailSetup';
import ConfirmationModal from 'components/ConfirmationModal';
import useDebounce from 'hooks/useDebounce.hooks';
import { cleanString, formatPhone, numbersOnly, cleanPhone } from 'lib/formatting';
import NavigationLink from 'components/Base/NavigationLink';
import useReactOperations from 'hooks/useReactOperations.hook';
import useAddresses from 'hooks/useAddresses.hook';
import MultiChoiceModal, { MultiChoiceButton } from '../MultiChoiceModal';
import AddEditJobModal from '../AddEditJobModal';
import Checkbox from 'components/Base/Checkbox';
interface AddEditClientModalProps {
  id?: number;
  open: boolean;
  onClose: () => void;
  onSave: (createdClient?: CreatedClient) => void;
}

function AddEditClientModal({ id, open, onClose, onSave }: AddEditClientModalProps): ReactElement {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [emails, setEmails] = useState<Array<CreateEmailContact>>([{ email: '', main: true }]);
  const [isCoorperate, setIsCoorperate] = useState<boolean>(false);
  const [phones, setPhones] = useState<Array<CreatePhoneContact>>([]);
  const [address, setAddress] = useState<CreateAddress>();
  const [validations, setValidations] = useState<CreateEditClientValidation>();
  const [confirmationMessage, setConfirmationMessage] = useState<string>();
  const [openCreateJobModal, setOpenCreateJobModal] = useState<boolean>(false);
  const [openCreateClientMultiSelectModal, setOpenCreateClientMultiSelectModal] = useState<boolean>(false);

  const [createdClient, setCreatedClient] = useState<CreatedClient>();

  const {
    client,
    clients,
    phoneTypes,
    clientsErrorMessage,
    clientsErrorTitle,
    clientsLoading,
    resetClientError,
    fetchPhoneTypes,
    createClient,
    updateClient,
    getClient,
    fetchClients,
    clearClients,
    deleteClient,
  } = useClients();
  const { countries, fetchCountries, regions, fetchRegions } = useAddresses();

  const { navigateToClientsPage, navigateToClientPage } = useReactOperations();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: clientsErrorTitle, message: clientsErrorMessage, reset: resetClientError }],
  });

  const firstNameDebouce = useDebounce(firstName);
  const lastNameDebounce = useDebounce(lastName);
  const emailsDebounce: Array<EmailContact> = useDebounce(emails);
  const phonesDebounce: Array<PhoneContact> = useDebounce(phones);

  const validate = (showErrors = false): boolean => {
    const errors: CreateEditClientValidation = { address: {} };
    let success = true;
    if (!firstName) {
      errors.firstName = 'Provide a first name';
      success = false;
    }
    if (!lastName) {
      errors.lastName = 'Provide a last name';
      success = false;
    }
    if (address?.unit || address?.streetName || address?.postalCode || address?.streetNumber) {
      if (!address.streetName) {
        errors.address.name = 'Provide a street name';
        success = false;
      }
      if (!address.postalCode) {
        errors.address.postalCode = 'Provide a postal code';
        success = false;
      }
      if (!address.streetNumber) {
        errors.address.number = 'Provide a street number';
        success = false;
      }
      if (!address.city) {
        errors.address.city = 'Provide a city';
        success = false;
      }
      if (!address.regionId) {
        errors.address.region = 'Provide a region';
        success = false;
      }
      if (!address.countryId) {
        errors.address.country = 'Provide a country';
        success = false;
      }
    }
    if (showErrors || validations) setValidations(errors);
    return success;
  };

  const getTitle = () => {
    return id ? 'Edit Client' : 'New Client';
  };
  const replaceEmail = (index: number, email: string) => {
    const alteredEmails = [...emails];
    alteredEmails[index].email = email;
    setEmails(alteredEmails);
  };

  const replacePhoneNumber = (index: number, number: string) => {
    const alteredPhones = [...phones];
    alteredPhones[index].number = number;
    setPhones(alteredPhones);
  };

  const replacePhoneType = (index: number, type: PhoneType) => {
    const alteredPhones = [...phones];
    alteredPhones[index].typeId = type.id;
    alteredPhones[index].type = type.type;
    setPhones(alteredPhones);
  };

  const removeEmail = (index: number) => {
    const alteredEmails = [...emails];
    alteredEmails.splice(index, 1);
    alteredEmails[0].main = true;
    setEmails(alteredEmails);
  };

  const removePhone = (index: number) => {
    const alteredPhones = [...phones];
    alteredPhones.splice(index, 1);
    alteredPhones[0].main = true;
    setPhones(alteredPhones);
  };

  const getConfirmation = (): boolean => {
    let message = '';
    for (const email of emails) {
      if (!email.email) {
        message += ' an email';
        break;
      }
    }
    for (const phone of phones) {
      if (!phone.number) {
        if (!address) {
          message += message ? ',' : ' a';
        } else {
          message += message ? ' or a' : ' a';
        }
        message += ' phone number';
      }
    }
    if (!address) {
      message += message ? ' or an' : ' an';
      message += ' address';
    }

    setConfirmationMessage(message ? `Are you sure you want to proceed without ${message}?` : undefined);
    return !!message;
  };

  const onLocalClose = () => {
    setFirstName('');
    setLastName('');
    setEmails([{ email: '', main: true }]);
    setPhones([{ number: '', typeId: phoneTypes[0].id, type: phoneTypes[0].type, main: true }]);
    setAddress(undefined);
    setIsCoorperate(false);
    setConfirmationMessage(undefined);
    setValidations(undefined);
    onClose();
  };

  const onLocalSave = async (proceedWithMissingInfo = false, proceedWithExistingClients = false) => {
    setConfirmationMessage(undefined);
    if (!validate(true)) return;
    if (!proceedWithMissingInfo && getConfirmation()) return;

    const data: CreateClient = {
      firstName,
      lastName,
      coorperate: isCoorperate,
      emails: emails.filter((email) => !!email.email).length ? emails : undefined,
      phones: phones.filter((phone) => !!phone.number).length
        ? phones.map((phone) => {
            return {
              ...phone,
              number: cleanPhone(phone.number),
            };
          })
        : undefined,
      address: !id && address ? address : undefined,
    };
    try {
      let createdClient;
      if (id) {
        await updateClient(id, data);
        onSave();
        onLocalClose();
      } else {
        createdClient = await createClient(data);
        setCreatedClient(createdClient);
        setOpenCreateClientMultiSelectModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async () => {
    try {
      if (!id) return;
      await deleteClient(id);
      navigateToClientsPage();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!id || !open) return;
    if (address?.countryId) {
      fetchRegions(address?.countryId);
    }
  }, [address]);

  useEffect(() => {
    if (!open) return;
    getClient(id);
  }, [id, open]);

  useEffect(() => {
    if (!client) return;
    setFirstName(client?.firstName || '');
    setLastName(client?.lastName || '');
    setIsCoorperate(!!client.coorperate);
    setEmails(client?.emails && !!client.emails.length ? client?.emails : [{ email: '', main: true }]);
    const phones = client?.phones?.map((phone) => {
      return {
        ...phone,
        number: formatPhone(phone.number),
      };
    });
    setPhones(
      phones && !!phones.length
        ? phones
        : [
            {
              number: '',
              typeId: phoneTypes[0]?.id || 1,
              type: phoneTypes[0]?.type || '',
              main: true,
            },
          ]
    );
  }, [client]);

  useEffect(() => {
    if (!phoneTypes.length) return;
    if (phones.length === 0)
      setPhones([{ number: '', typeId: phoneTypes[0].id, type: phoneTypes[0].type, main: true }]);
  }, [phoneTypes]);

  useEffect(() => {
    validate();
  }, [firstName, lastName, emails, phones, address]);

  useEffect(() => {
    if (!open) return;
    const filteredEmails = emailsDebounce.map((email) => cleanString(email.email)).filter((email) => !!email);
    const filteredPhones = phonesDebounce.map((phone) => numbersOnly(phone.number)).filter((phone) => !!phone);
    if (!firstNameDebouce && !lastNameDebounce && !filteredEmails.length && !filteredPhones.length) {
      clearClients();
      return;
    }
    const clientName = firstNameDebouce || lastNameDebounce ? firstNameDebouce + ' ' + lastNameDebounce : undefined;

    const handler = setTimeout(() => {
      fetchClients({
        clientName: cleanString(clientName),
        emails: filteredEmails,
        phones: filteredPhones,
        limit: 10,
        excludeId: id,
      });
    }, 100);

    return () => {
      clearTimeout(handler);
    };
  }, [firstNameDebouce, lastNameDebounce, emailsDebounce, phonesDebounce]);

  useEffect(() => {
    if (!open) return;
    if (!phoneTypes.length) {
      fetchPhoneTypes();
    }
    fetchCountries();
  }, [open]);

  const getNextStepButtons = (): Array<MultiChoiceButton> => {
    const buttons: Array<MultiChoiceButton> = [];
    if (!createdClient) return buttons;
    buttons.push({
      label: 'Go To Client Page',
      onClick: () => navigateToClientPage(createdClient.clientId),
    });
    if (createdClient.addressId) {
      buttons.push({
        label: 'Create Job',
        onClick: () => {
          setOpenCreateJobModal(true);
          setOpenCreateClientMultiSelectModal(false);
        },
      });
    }

    return buttons;
  };

  return (
    <>
      <BaseModal
        open={open}
        onClose={onLocalClose}
        title={getTitle()}
        onConfirm={() => onLocalSave(!!id)}
        alertMessage={alertMessage}
        alertTitle={alertTitle}
        openAlert={openAlert}
        loading={clientsLoading}
        confirmTitle={id ? 'Update' : 'Create'}
        onAlertClose={closeAlertModal}
        onDelete={id ? onDelete : undefined}
        deletePopupMessage={`Are you sure you would like to delete ${client?.fullName} and associated addresses?`}
        deletePopupTitle={'Delete Client'}
      >
        <AddEditBase>
          <ColumnAddEdit>
            <HorizontalAddEditRow>
              <HorizontalAddEditChild>
                <TextField
                  value={firstName}
                  setValue={setFirstName}
                  name={'firstName'}
                  hint={'First Name'}
                  error={validations?.firstName}
                  hasError={true}
                />
              </HorizontalAddEditChild>
              <HorizontalAddEditChild>
                <TextField
                  value={lastName}
                  setValue={setLastName}
                  name={'lastName'}
                  hint={'Last Name'}
                  error={validations?.lastName}
                  hasError={true}
                />
              </HorizontalAddEditChild>
            </HorizontalAddEditRow>
            <Checkbox checked={isCoorperate} onClick={setIsCoorperate} label={'Coorperate'} />
            <div className={styles.titleElement}>Emails</div>
            {emails.map((email, index) => {
              return (
                <div key={index}>
                  <div>
                    <EmailSetup
                      index={index}
                      key={index}
                      email={email}
                      allowDelete={emails.length > 1}
                      allowAdd={index === emails.length - 1 && emails.length < 3}
                      setEmail={(value) => replaceEmail(index, value)}
                      remove={() => removeEmail(index)}
                      add={() => setEmails([...emails, { email: '', main: false }])}
                    />
                  </div>
                </div>
              );
            })}
            <div className={styles.titleElement}>Phone Numbers</div>
            {phones.map((phone, index) => {
              return (
                <PhoneSetUp
                  index={index}
                  key={index}
                  label={index === 0 ? 'Phone' : undefined}
                  phone={phone}
                  phoneTypes={phoneTypes}
                  allowDelete={phones.length > 1}
                  allowAdd={index === phones.length - 1 && phones.length < 3}
                  setNumber={(value) => replacePhoneNumber(index, value)}
                  setType={(type) => replacePhoneType(index, type)}
                  remove={() => removePhone(index)}
                  add={() =>
                    setPhones([
                      ...phones,
                      {
                        number: '',
                        typeId: phoneTypes[0].id,
                        type: phoneTypes[0].type,
                        main: false,
                      },
                    ])
                  }
                />
              );
            })}

            <HorizontalAddEditRow>
              <HorizontalAddEditChild fullWidth>
                {!!clients.length && (
                  <>
                    <div className={styles.titleElement}>Existing Clients</div>
                    {clients.map((client) => {
                      return (
                        <NavigationLink key={client.id} link={`/client/${client.id}`}>
                          <div className={styles.existingClient}>
                            <div className={styles.name}>{client.fullName}</div>
                            {client.emails && !!client.emails.length && <div>{client.emails[0].email}</div>}
                            {client.phones && !!client.phones.length ? (
                              <div className={styles.phone}>{formatPhone(client.phones[0].number)}</div>
                            ) : (
                              <div className={styles.filler} />
                            )}
                          </div>
                        </NavigationLink>
                      );
                    })}
                  </>
                )}
              </HorizontalAddEditChild>
            </HorizontalAddEditRow>

            {!id && (
              <HorizontalAddEditRow>
                <HorizontalAddEditChild fullWidth>
                  <div className={styles.titleElement}>Address</div>
                  <AddEditAddressSetup
                    address={address}
                    countries={countries}
                    regions={regions}
                    setAddress={setAddress}
                    validation={validations?.address}
                    fetchRegions={fetchRegions}
                  />
                </HorizontalAddEditChild>
              </HorizontalAddEditRow>
            )}
          </ColumnAddEdit>
        </AddEditBase>
      </BaseModal>
      <ConfirmationModal
        open={!!confirmationMessage}
        message={confirmationMessage}
        title={'Missing Information'}
        onClose={() => setConfirmationMessage(undefined)}
        onAccept={() => onLocalSave(true)}
      />
      <MultiChoiceModal
        title={'Next Step'}
        open={openCreateClientMultiSelectModal}
        onClose={() => {
          setOpenCreateClientMultiSelectModal(false);
          onSave();
          onLocalClose();
        }}
        message={'Choose An Action'}
        buttons={getNextStepButtons()}
      />
      <AddEditJobModal
        open={openCreateJobModal}
        onClose={() => setOpenCreateJobModal(false)}
        addressId={createdClient?.addressId}
        onSave={() => {
          setOpenCreateJobModal(false);
        }}
      />
    </>
  );
}

export default AddEditClientModal;
