import { useState } from 'react';
import { Address, AddressSearch, Country, CreateAddress, FoundAddress, Region, VerifiedAddress } from 'types';
import useErrorsDetails from './useErrors.hook';
import { AddressesApi } from 'api';
import { handleApiError } from 'lib/errors';

interface AddressInfo {
  address?: Address;
  addresses: Array<Address>;
  addressLoading: boolean;
  addressErrorMessage: string;
  addressErrorTitle: string;
  regions: Array<Region>;
  countries: Array<Country>;
  foundAddresses: Array<FoundAddress>;
  verifiedAddress?: VerifiedAddress;
  fetchCountries: () => void;
  fetchRegions: (countryId: number) => void;
  resetAddressError: () => void;
  fetchAddresses: (params: AddressSearch) => void;
  createAddress: (address: CreateAddress) => Promise<number>;
  updateAddress: (id: number, address: CreateAddress) => Promise<void>;
  fetchAddress: (addressId: number) => Promise<void>;
  deleteAddress: (id: number) => Promise<void>;
  clearAddresses: () => void;
  findAddresses: (searchAddress: string) => Promise<void>;
  verify: (searchAddress: string) => Promise<void>;
}
function useAddresses(): AddressInfo {
  const [addresses, setAddresses] = useState<Array<Address>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<Address>();
  const [countries, setCountries] = useState<Array<Country>>([]);
  const [regions, setRegions] = useState<Array<Region>>([]);
  const [foundAddresses, setFoundAddresses] = useState<Array<FoundAddress>>([]);
  const [verifiedAddress, setVerifiedAddress] = useState<VerifiedAddress>();

  const { handleError, errorMessage, errorTitle, resetError } = useErrorsDetails();

  const fetchAddresses = async (params: AddressSearch) => {
    setLoading(true);
    try {
      const addresses = await AddressesApi.fetchAddresses(params);
      setAddresses(addresses);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
    setLoading(false);
  };

  const fetchCountries = async () => {
    setLoading(true);
    try {
      const countries = await AddressesApi.fetchCountries();
      setCountries(countries);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
    setLoading(false);
  };

  const fetchRegions = async (countryId: number) => {
    setLoading(true);
    try {
      const regions = await AddressesApi.fetchRegions(countryId);
      setRegions(regions);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
    setLoading(false);
  };

  const verify = async (searchAddress: string): Promise<void> => {
    try {
      const address = await AddressesApi.verify(searchAddress);
      setVerifiedAddress(address);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const findAddresses = async (searchAddress: string): Promise<void> => {
    try {
      setFoundAddresses([]);

      const addresses = await AddressesApi.findAddresses(searchAddress);
      setFoundAddresses(addresses);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const fetchAddress = async (addressId: number) => {
    try {
      const address = await AddressesApi.fetchAddress(addressId);
      setAddress(address);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const createAddress = async (address: CreateAddress): Promise<number> => {
    try {
      const newAddressData = await AddressesApi.create(address);
      return newAddressData.id;
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const updateAddress = async (id: number, address: CreateAddress) => {
    try {
      await AddressesApi.update(id, address);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const deleteAddress = async (id: number) => {
    try {
      await AddressesApi.del(id);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const clearAddresses = () => {
    setAddresses([]);
  };

  return {
    address,
    addresses,
    addressLoading: loading,
    regions,
    countries,
    foundAddresses,
    verifiedAddress,
    verify,
    fetchCountries,
    fetchRegions,
    fetchAddresses,
    createAddress,
    updateAddress,
    clearAddresses,
    addressErrorMessage: errorMessage,
    addressErrorTitle: errorTitle,
    resetAddressError: resetError,
    fetchAddress,
    deleteAddress,
    findAddresses,
  };
}

export default useAddresses;
