import React, { ReactElement, ReactNode } from 'react';
import styles from './HorizontalAddEditRow.module.scss';
import classNames from 'classnames';

interface HorizontalCreateAddRowProps {
  children?: ReactNode;
  keyName?: number;
  styling?: string;
}
function HorizontalAddEditRow({ children, keyName, styling }: HorizontalCreateAddRowProps): ReactElement {
  return (
    <div className={classNames(styles.row, styling)} key={`key-${keyName}`}>
      {children}
    </div>
  );
}

export default HorizontalAddEditRow;
