import React, { ReactElement, useEffect, useState } from 'react';
import styles from './Clients.module.scss';
import Layout from 'Base';

import { EmailContact, PhoneContact } from 'types';
import useDebounce from 'hooks/useDebounce.hooks';
import AddEditClientModal from 'components/Modals/AddEditClientModal';
import Table from 'components/Base/Table';
import TableRow from 'components/Base/Table/components/TableRow';
import TableData from 'components/Base/Table/components/TableData';
import TextSearch from 'components/TextSearch';
import Button from 'components/Base/Button';
import useClients from 'hooks/useClients.hooks';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useReactOperations from 'hooks/useReactOperations.hook';
import { formatPhone } from 'lib/formatting';
import { SnackBarType } from 'components/SnackBar/SnackBar';
import LoadingSpinner from 'components/LoadingSpinner';

function Clients(): ReactElement {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [openNewClientModal, setOpenNewClientModal] = useState<boolean>(false);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarType, setSnackBarType] = useState<SnackBarType>();
  const [snackBarMessage, setSnackBarMessage] = useState<string>();
  const debounceSearch = useDebounce(searchFilter, 300);

  const { navigateToClientPage } = useReactOperations();

  const { clients, clientsErrorMessage, clientsErrorTitle, resetClientError, fetchClients, clientsLoading } =
    useClients();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: clientsErrorTitle, message: clientsErrorMessage, reset: resetClientError }],
  });

  const localFetchClients = () => {
    fetchClients({ searchFilter: debounceSearch, limit: 30 });
  };

  const onSave = async () => {
    localFetchClients();
    setSnackBarMessage('Client Created');
    setSnackBarType(SnackBarType.SUCCESS);
    setShowSnackBar(true);
    setOpenNewClientModal(false);
  };

  const getEmail = (emails?: Array<EmailContact>) => {
    if (!emails || !emails.length) return '---';
    let email = emails[0].email;
    if (emails.length > 1) email += ` ...[${emails.length} more]`;
    return email;
  };

  const getPhone = (phones?: Array<PhoneContact>) => {
    if (!phones || !phones.length) return '---';
    let phone = formatPhone(phones.find((phone) => phone.main)?.number);
    if (phones.length > 1) phone += ` ...[${phones.length} more]`;
    return phone;
  };

  const getHeader = () => {
    return [
      <TableData key={1} data={'Name'} id={'name-header'} widthPercent={50} />,
      <TableData key={2} data={'Email'} id={'email-header'} widthPercent={30} />,
      <TableData key={3} data={'Phone'} id={'phone-header'} widthPercent={20} />,
    ];
  };

  const handleNewClientClick = () => setOpenNewClientModal(() => true);

  useEffect(() => {
    localFetchClients();
  }, [debounceSearch]);

  return (
    <Layout
      title={'Clients'}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
      showSnackBar={showSnackBar}
      snackBarType={snackBarType}
      snackBarMessage={snackBarMessage}
      closeSnackBar={() => setShowSnackBar(false)}
    >
      <div className={styles.container}>
        <section id="toolbar" className={styles.toolbar}>
          <div className={styles.left}>
            <TextSearch value={searchFilter} onChange={setSearchFilter} />
            {clientsLoading && <LoadingSpinner />}
          </div>

          <Button title="New Client" onClick={handleNewClientClick} />
        </section>

        <div className={styles.listContainer}>
          {
            <Table header={getHeader()}>
              {clients.map((client, index) => {
                return (
                  <TableRow key={index} onClick={() => navigateToClientPage(client.id)}>
                    <TableData data={client.fullName} id={`name-${client.id}`} widthPercent={50} />
                    <TableData data={getEmail(client.emails)} id={`email-${client.id}`} widthPercent={30} />
                    <TableData data={getPhone(client.phones)} id={`phone-${client.id}`} widthPercent={20} />
                  </TableRow>
                );
              })}
            </Table>
          }
        </div>
      </div>
      <AddEditClientModal open={openNewClientModal} onClose={() => setOpenNewClientModal(false)} onSave={onSave} />
    </Layout>
  );
}

export default Clients;
