import BaseModal from 'components/Base/BaseModal';
import React, { ReactElement } from 'react';
import styles from './JobScheduleSummaryModal.module.scss';
import JobScheduleSummarySetup from 'components/JobScheduleSummarySetup';

interface JobSummaryModalProps {
  open: boolean;
  jobScheduleId?: number;
  onEditJobScheduleSave?: () => void;
  onClose: () => void;
}
function JobSummaryModal({ open, jobScheduleId, onClose, onEditJobScheduleSave }: JobSummaryModalProps): ReactElement {
  return (
    <BaseModal title="Job Schedule Summary" open={open} onClose={onClose} cancelTitle={'Close'}>
      <div className={styles.body}>
        <JobScheduleSummarySetup
          id={jobScheduleId}
          onSave={onEditJobScheduleSave}
          onDelete={() => {
            onEditJobScheduleSave?.();
            onClose();
          }}
        />
      </div>
    </BaseModal>
  );
}

export default JobSummaryModal;
