import TextArea from 'components/Base/TextArea';
import React, { ReactElement } from 'react';
import styles from './TextAreaCountdown.module.scss';

interface TextAreaCountdownProps {
  value?: string;
  setValue: (value: string) => void;
  hint?: string;
  maxLength: number;
}

function TextFieldCountdown({ value, setValue, hint, maxLength }: TextAreaCountdownProps): ReactElement {
  return (
    <div className={styles.description}>
      <div className={styles.text}>
        <TextArea hint={hint} maxLength={maxLength} name={'textAreaCountdown'} value={value} setValue={setValue} />
      </div>
      <div className={styles.countdown}>{maxLength - (value?.length || 0)}</div>
    </div>
  );
}

export default TextFieldCountdown;
