import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import Layout from 'Base';
import Button from 'components/Base/Button/Button.view';
import Checkbox from 'components/Base/Checkbox';
import DateSelector from 'components/Base/DateSelector';
import TextArea from 'components/Base/TextArea';
import { UserContext } from 'context/userProfile';
import useUsers from 'hooks/useUsers.hooks';
import { dateFormats, formatDate, sqlTime, sqlToday } from 'lib/date';
import { formatNumber } from 'lib/formatting';
import moment from 'moment';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CreateClockIn, CreateClockInOutValidation, CreateClockOut } from 'types';
import styles from './ClockInOut.module.scss';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useReactOperations from 'hooks/useReactOperations.hook';

function TrackTime(): ReactElement {
  const params = useParams();
  const [useCurrentTime, setUseCurrentTime] = useState<boolean>(true);
  const [date, setDate] = useState<string>(params.date || sqlToday());
  const [time, setTime] = useState<string>(sqlTime());
  const [reason, setReason] = useState<string>();
  const [hasValidated, setHasValidated] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<CreateClockInOutValidation>();

  const {
    timeCards,
    timeCard,
    fetchTimeCard,
    fetchTimeCards,
    clockIn,
    clockOut,
    userErrorMessage,
    userErrorTitle,
    resetUserError,
  } = useUsers();
  const { navigateToClockInOutPage } = useReactOperations();
  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: userErrorTitle, message: userErrorMessage, reset: resetUserError }],
  });

  const { user } = useContext(UserContext);

  const validate = (): boolean => {
    const errors: CreateClockInOutValidation = {};

    let success = true;

    if (!reason && !useCurrentTime) {
      errors.reason = 'Please provide a reason for the altered time';
      success = false;
    }

    if (!useCurrentTime && (!date || !time)) {
      errors.dateTime = 'Please provide a valid time';
      success = false;
    }

    if (!useCurrentTime && timeCard && moment(timeCard.clockIn).isAfter(`${date} ${time}`, 'seconds')) {
      errors.beforeTime = 'Please provide a time after clock in';
      success = false;
    }

    setValidationErrors(errors);
    setHasValidated(true);
    return success;
  };

  const clockOutLocal = async () => {
    if (!validate()) return;
    if (!timeCard) return;
    const data: CreateClockOut = {
      id: timeCard?.id,
      dateTime: useCurrentTime ? undefined : `${date} ${time}`,
      useCurrentTime,
      lateClockOutReason: reason,
    };

    await clockOut(data);
    fetchTimeCards(date);
  };

  const clockInLocal = async () => {
    if (!validate()) return;
    const data: CreateClockIn = {
      dateTime: useCurrentTime ? undefined : `${date} ${time}`,
      useCurrentTime,
      lateClockInReason: reason,
    };

    await clockIn(data);
    fetchTimeCards(date);
  };

  const dateRight = () => {
    setDate((prev) => moment(prev).add(1, 'day').format(dateFormats.sqlDateOnly));
  };

  const dateLeft = () => {
    setDate((prev) => moment(prev).subtract(1, 'day').format(dateFormats.sqlDateOnly));
  };

  const totalHours = () => {
    const minutes = timeCards.reduce((currentValue, card) => {
      if (card.clockOut) {
        return moment(card.clockOut).diff(card.clockIn, 'minutes') + currentValue;
      }
      return currentValue;
    }, 0);

    return formatNumber(minutes / 60, 2);
  };

  useEffect(() => {
    if (moment().isBefore(date, 'days')) {
      setDate(sqlToday());
      return;
    }
    if (moment().isAfter(date, 'days')) {
      setUseCurrentTime(false);
    } else {
      setUseCurrentTime(true);
    }

    navigateToClockInOutPage(date);
    fetchTimeCards(date);
    setValidationErrors(undefined);
    setHasValidated(false);
  }, [date]);

  useEffect(() => {
    fetchTimeCard();
  }, [user]);

  useEffect(() => {
    if (!hasValidated) return;
    validate();
  }, [reason, date, time]);

  return (
    <Layout
      title={'Time Card'}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
    >
      <div className={styles.container}>
        <div className={styles.details}>
          <div className={styles.navigation}>
            <div className={styles.dateChangeBtn} onClick={dateLeft}>
              <ArrowLeft className={styles.dateChangeIcon} />
            </div>
            <div className={styles.date}>{formatDate(date, dateFormats.abbreviatedPretty)}</div>
            {moment().isAfter(date, 'day') && (
              <div className={styles.dateChangeBtn} onClick={dateRight}>
                <ArrowRight className={styles.dateChangeIcon} />
              </div>
            )}
          </div>
          {!user?.clockedIn || moment(user.clockedIn).isSame(date) ? (
            <>
              {moment().isSame(date, 'days') && (
                <div className={styles.checkbox}>
                  <Checkbox
                    checked={useCurrentTime}
                    onClick={(useCurrentTime) => {
                      setUseCurrentTime(useCurrentTime);
                      setReason(undefined);
                      if (useCurrentTime) setTime(sqlTime());
                    }}
                    label={'Use Current Time'}
                  />
                </div>
              )}

              {!useCurrentTime && (
                <>
                  <DateSelector
                    date={time}
                    setDate={setTime}
                    title="Time"
                    type="time"
                    disabled={timeCard && moment(date).isAfter(timeCard?.clockIn)}
                    error={validationErrors?.dateTime}
                    min={timeCard?.clockIn}
                  />
                  <div className={styles.reason}>
                    <TextArea
                      value={reason}
                      setValue={setReason}
                      name={'Reason'}
                      hint={'Reason for adjusted time'}
                      error={validationErrors?.reason}
                      hasError={true}
                    />
                  </div>
                </>
              )}
              {(!user?.clockedIn || (moment(user.clockedIn).isSame(date) && moment().isSameOrAfter(date, 'days'))) && (
                <div className={styles.btn}>
                  <Button
                    title={timeCard?.clockIn ? 'Clock Out' : 'Clock In'}
                    onClick={timeCard?.clockIn ? clockOutLocal : clockInLocal}
                  />
                </div>
              )}
            </>
          ) : (
            <div>
              {'Please sign out of '}
              <span onClick={() => setDate(user?.clockedIn || '')}>
                {formatDate(user?.clockedIn, dateFormats.abbreviatedPretty)}
              </span>
            </div>
          )}

          {validationErrors?.beforeTime && <div className={styles.error}>{validationErrors.beforeTime}</div>}

          <div className={styles.summary}>
            <div className={styles.title}>Time Cards</div>
            <div>
              <span>Total Hours: </span>
              {totalHours()}
            </div>
            {timeCards.map((card, index) => {
              return (
                <div key={index} className={styles.item}>
                  <span>{formatDate(card.clockIn, dateFormats.prettyTime)}</span> -{' '}
                  <span>{formatDate(card.clockOut, dateFormats.prettyTime)}</span>
                  {card.lateClockOutReason && {}}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TrackTime;
