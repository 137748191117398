import Checkbox from 'components/Base/Checkbox';
import BaseModal from 'components/Base/BaseModal';
import HorizontalAddEditChild from 'components/AddEditSetup/HorizontalAddEditChild';
import HorizontalAddEditRow from 'components/AddEditSetup/HorizontalAddEditRow';
import React, { ReactElement, useEffect, useState } from 'react';
import { CreateEditTeamScheduleValidation, CreateTeamSchedule, Team, TeamSchedule } from 'types';
import useTeams from 'hooks/useTeams.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import Autocomplete from 'components/Base/Autocomplete';
import useDebounce from 'hooks/useDebounce.hooks';
import TimeSelector from 'components/Base/TimeSelector';
import DateSelector from 'components/Base/DateSelector';
import styles from './AddEditTeamSchedule.module.scss';
import { CLOCK_TYPE } from 'components/Base/TimeSelector/TimeSelector';
import TextAreaCountdown from 'components/TextAreaCountdown';
import MultiChoiceModal from '../MultiChoiceModal';
import { SCHEDULE_UPDATE_TYPES } from 'lib/teams';
import { MultiChoiceButton } from '../MultiChoiceModal/MultiChoiceModal';

interface AddEditTeamScheduleProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  schedule?: TeamSchedule;
  id?: number;
  date?: string;
  onDelete: () => void;
}

function AddEditTeamSchedule({ id, open, onClose, onSave, date, onDelete }: AddEditTeamScheduleProps): ReactElement {
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [errors, setErrors] = useState<CreateEditTeamScheduleValidation>();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [monday, setMonday] = useState<boolean>(false);
  const [tuesday, setTuesday] = useState<boolean>(false);
  const [wednesday, setWednesday] = useState<boolean>(false);
  const [thursday, setThursday] = useState<boolean>(false);
  const [friday, setFriday] = useState<boolean>(false);
  const [saturday, setSaturday] = useState<boolean>(false);
  const [sunday, setSunday] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>();
  const [deleteSchedule, setDeleteSchedule] = useState<boolean>(false);

  const [openEditChoices, setOpenEditChoices] = useState<boolean>(false);

  const onItemSelect = (team: Team) => {
    setSelectedTeam(team);
  };

  const {
    team,
    teams,
    teamErrorMessage,
    teamErrorTitle,
    teamSchedule,
    resetTeamError,
    getTeams,
    fetchTeamSchedule,
    createTeamSchedule,
    updateTeamSchedule,
    deleteTeamSchedule,
  } = useTeams();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: teamErrorTitle, message: teamErrorMessage, reset: resetTeamError }],
  });
  const debounceSearch = useDebounce(searchFilter, 300);

  const validate = () => {
    const errors: CreateEditTeamScheduleValidation = {};
    let validated = true;

    if (!selectedTeam) {
      validated = false;
      errors.team = 'Select a team';
    }
    if (!startDate) {
      validated = false;
      errors.startDate = 'Select a start date';
    }
    if (!endDate) {
      validated = false;
      errors.endDate = 'Select an end date';
    }
    if (!startTime) {
      validated = false;
      errors.startTime = 'Select a start time';
    }
    if (!endTime) {
      validated = false;
      errors.endTime = 'Select an end time';
    }
    if (!monday && !tuesday && !wednesday && !thursday && !friday && !saturday && !sunday) {
      validated = false;
      errors.daySelected = 'Select days of the week';
    }

    setValidated(true);
    setErrors(errors);
    return validated;
  };

  const resetValues = () => {
    setStartDate('');
    setEndDate('');
    setStartTime('');
    setEndTime('');
    setMonday(false);
    setTuesday(false);
    setWednesday(false);
    setThursday(false);
    setFriday(false);
    setSaturday(false);
    setSunday(false);
    setNotes(undefined);
    setOpenEditChoices(false);
  };

  const onLocalSave = async () => {
    if (!validate()) return;

    const teamSchedule: CreateTeamSchedule = {
      startDate,
      endDate,
      startTime,
      endTime,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      teamId: selectedTeam?.id,
    };

    try {
      if (id) {
        setOpenEditChoices(true);
        return;
      }
      await createTeamSchedule(teamSchedule);
      onSave();
    } catch (error) {
      console.error(error);
    }
  };

  const updateSchedule = async (updateType?: number) => {
    if (!id) return;
    const teamSchedule: CreateTeamSchedule = {
      startDate,
      endDate,
      startTime,
      endTime,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      teamId: selectedTeam?.id,
      notes,
      updateType,
      date,
    };

    try {
      if (deleteSchedule) {
        await deleteTeamSchedule(id, { date, updateType });
        onDelete();
      } else {
        await updateTeamSchedule(id, teamSchedule);
        onSave();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const scheduleUpdateOptions: Array<MultiChoiceButton> = [
    {
      label: 'This date only',
      onClick: () => updateSchedule(SCHEDULE_UPDATE_TYPES.EXACT_DATE),
    },
    {
      label: 'Include all future dates',
      onClick: () => updateSchedule(SCHEDULE_UPDATE_TYPES.ALL_FUTURE_DATES),
    },
    {
      label: 'All dates',
      onClick: () => updateSchedule(SCHEDULE_UPDATE_TYPES.ALL_DATES),
    },
  ];

  useEffect(() => {
    if (!id || !open) {
      resetValues();
      return;
    }
    fetchTeamSchedule(id);
  }, [open]);

  useEffect(() => {
    if (!validated) return;
    validate();
  }, [startTime, endTime, startDate, endDate, monday, tuesday, wednesday, thursday, friday, saturday, sunday, team]);

  useEffect(() => {
    if (!teamSchedule) return;
    setSelectedTeam(teamSchedule.team);
    setStartDate(teamSchedule.startDate || '');
    setEndDate(teamSchedule.endDate || '');
    setStartTime(teamSchedule.startTime || '');
    setEndTime(teamSchedule.endTime || '');
    setMonday(teamSchedule.monday);
    setTuesday(teamSchedule.tuesday);
    setWednesday(teamSchedule.wednesday);
    setThursday(teamSchedule.thursday);
    setFriday(teamSchedule.friday);
    setSaturday(teamSchedule.saturday);
    setSunday(teamSchedule.sunday);
  }, [teamSchedule]);

  useEffect(() => {
    if (!open) return;
    getTeams(debounceSearch);
  }, [debounceSearch]);

  useEffect(() => {
    getTeams();
  }, []);

  return (
    <BaseModal
      open={open}
      title={'Create Team Schedule'}
      onClose={onClose}
      onConfirm={onLocalSave}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
      onDelete={id ? () => setDeleteSchedule(true) : undefined}
      deletePopupMessage={'Are you sure you would like to delete team schedule'}
      deletePopupTitle={'Delete Job Schedule'}
    >
      <HorizontalAddEditRow>
        <HorizontalAddEditChild>
          <Autocomplete
            values={teams.map((team) => {
              return {
                object: team,
                rowDisplay: <div>{team.name}</div>,
              };
            })}
            onSelect={onItemSelect}
            error={errors?.team}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            hint={'Search Teams'}
            onClearField={() => setSearchFilter('')}
          />
        </HorizontalAddEditChild>
      </HorizontalAddEditRow>
      <HorizontalAddEditRow>
        <HorizontalAddEditChild fullWidth>
          <div className={styles.teamInfo}>
            <div className={styles.team}>{selectedTeam?.name}</div>
            <div className={styles.members}>{selectedTeam?.members.map((member) => member.fullName).join(' / ')}</div>
          </div>
        </HorizontalAddEditChild>
      </HorizontalAddEditRow>
      <HorizontalAddEditRow>
        <HorizontalAddEditChild>
          <DateSelector
            title={'Start Date'}
            date={startDate}
            setDate={setStartDate}
            error={errors?.startDate}
            hasError={true}
            max={teamSchedule?.startDate || endDate}
          />
        </HorizontalAddEditChild>
        <HorizontalAddEditChild>
          <DateSelector
            title={'End Date'}
            date={endDate}
            setDate={setEndDate}
            error={errors?.endDate}
            hasError={true}
            min={startDate || teamSchedule?.endDate}
          />
        </HorizontalAddEditChild>
      </HorizontalAddEditRow>
      <HorizontalAddEditRow>
        <HorizontalAddEditChild>
          <TimeSelector
            time={startTime}
            setTime={setStartTime}
            error={errors?.startTime}
            hasError={true}
            step={5}
            clockType={CLOCK_TYPE.TWENTY_FOUR_HOUR}
            max={endTime}
            name={'startTime'}
          />
        </HorizontalAddEditChild>
        <HorizontalAddEditChild>
          <TimeSelector
            time={endTime}
            setTime={setEndTime}
            error={errors?.endTime}
            hasError={true}
            step={5}
            clockType={CLOCK_TYPE.TWENTY_FOUR_HOUR}
            min={startTime}
            name={'endTime'}
          />
        </HorizontalAddEditChild>
      </HorizontalAddEditRow>
      <HorizontalAddEditRow>
        <HorizontalAddEditChild>
          <Checkbox checked={monday} label={'Monday'} onClick={setMonday} />
        </HorizontalAddEditChild>
        <HorizontalAddEditChild>
          <Checkbox checked={tuesday} label={'Tuesday'} onClick={setTuesday} />
        </HorizontalAddEditChild>
        <HorizontalAddEditChild>
          <Checkbox checked={wednesday} label={'Wednesday'} onClick={setWednesday} />
        </HorizontalAddEditChild>
        <HorizontalAddEditChild>
          <Checkbox checked={thursday} label={'Thursday'} onClick={setThursday} />
        </HorizontalAddEditChild>
        <HorizontalAddEditChild>
          <Checkbox checked={friday} label={'Friday'} onClick={setFriday} />
        </HorizontalAddEditChild>
        <HorizontalAddEditChild>
          <Checkbox checked={saturday} label={'Saturday'} onClick={setSaturday} />
        </HorizontalAddEditChild>
        <HorizontalAddEditChild>
          <Checkbox checked={sunday} label={'Sunday'} onClick={setSunday} />
        </HorizontalAddEditChild>
      </HorizontalAddEditRow>
      <div className={styles.errorMessage}>{errors?.daySelected}</div>

      <HorizontalAddEditRow>
        <HorizontalAddEditChild>
          <TextAreaCountdown value={notes} setValue={setNotes} maxLength={200} hint={'Notes'} />
        </HorizontalAddEditChild>
      </HorizontalAddEditRow>

      <MultiChoiceModal
        open={openEditChoices || deleteSchedule}
        title={`${deleteSchedule ? 'Delete' : 'Edit'} Options`}
        message={`What would you like to ${deleteSchedule ? 'delete' : 'edit'}?`}
        buttons={scheduleUpdateOptions}
        onClose={() => setOpenEditChoices(false)}
      />
    </BaseModal>
  );
}

export default AddEditTeamSchedule;
