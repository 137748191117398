import Button from 'components/Base/Button';
import InfoRow from 'components/InfoRow';
import AddEditJobScheduleModal from 'components/Modals/AddEditJobScheduleModal';
import RequestJobChangeModal from 'components/Modals/RequestJobChangeModal';
import { formatPrice } from 'lib/formatting';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styles from './JobScheduleSummarySetup.module.scss';
import { getEavesExtras, getWindowExtras } from 'lib/jobs';
import { UserContext } from 'context/userProfile';
import { dateFormats, formatDate, isSameOrBeforeDay, momentToday } from 'lib/date';
import AddEditJobModal from 'components/Modals/AddEditJobModal';
import useScreenOperations from 'hooks/useScreenOperations.hook';
import useReactOperations from 'hooks/useReactOperations.hook';
import useJobSchedules from 'hooks/useJobSchedules.hook';
import RecordPaymentModal from 'components/Modals/RecordPayment';
import ConfirmJobScheduleMembersStartModal from 'components/Modals/ConfirmJobScheduleMembersStartModal';
import ConfirmJobScheduleMembersFinishModal from 'components/Modals/ConfirmJobScheduleMembersFinishModal';
import ConfirmJobScheduleMembersResetModal from 'components/Modals/ConfirmJobScheduleMembersResetModal';

interface JobScheduleSummarySetupProps {
  id?: number;
  onSave?: () => void;
  onDelete?: () => void;
  setSnackBarMessage?: (message: string) => void;
}

function JobScheduleSummarySetup({
  id,
  onSave,
  onDelete,
  setSnackBarMessage,
}: JobScheduleSummarySetupProps): ReactElement {
  const { user } = useContext(UserContext);

  const [openAddEditJobScheduleModal, setOpenAddEditJobScheduleModal] = useState<boolean>(false);
  const [openStartJobScheduleModal, setOpenStartJobScheduleModal] = useState<boolean>(false);
  const [openStopJobScheduleModal, setOpenStopJobScheduleModal] = useState<boolean>(false);
  const [openResetJobScheduleModal, setOpenResetJobScheduleModal] = useState<boolean>(false);
  const [openAddEditJobModal, setOpenAddEditJobModal] = useState<boolean>(false);
  const [openRequestJobChange, setOpenRequestJobChange] = useState<boolean>(false);
  const [openRecordPayment, setOpenRecordPayment] = useState<boolean>(false);

  const { navigateToSubmitQuotePage } = useReactOperations();
  const { isPhone } = useScreenOperations();

  const { jobSchedule, fetchJobSchedule } = useJobSchedules();

  const getSchedule = () => {
    if (!id) return;
    fetchJobSchedule(id);
  };

  const refreshPage = async () => {
    getSchedule();
    setOpenStartJobScheduleModal(false);
    setOpenStopJobScheduleModal(false);
    setOpenResetJobScheduleModal(false);
    if (onSave) onSave();
  };

  const manditoryArriveTimeCheck = () => {
    if (jobSchedule?.manditoryArriveAfter) {
      if (momentToday().diff(`${jobSchedule.date} ${jobSchedule?.manditoryArriveAfter}`) < 0) return false;
    }
    if (jobSchedule?.manditoryArriveBefore) {
      if (momentToday().diff(`${jobSchedule.date} ${jobSchedule?.manditoryArriveBefore}`) > 0) return false;
    }
    return true;
  };

  const getStartActionButtons = () => {
    if (!jobSchedule?.needsStarting.length || (!manditoryArriveTimeCheck() && !jobSchedule?.timeStarted)) return <></>;
    if (!jobSchedule?.timeStarted && isSameOrBeforeDay(jobSchedule.date)) {
      return <Button title={'Start'} onClick={() => setOpenStartJobScheduleModal(true)} />;
    }
    if (
      !jobSchedule?.userTimeStarted &&
      !jobSchedule.timeFinished &&
      user?.permissions.isCleaner &&
      isSameOrBeforeDay(jobSchedule.startTime)
    )
      return <Button title={'Join'} onClick={() => setOpenStartJobScheduleModal(true)} />;
    return <></>;
  };

  useEffect(() => {
    getSchedule();
  }, [id]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.details}>
          <div className={styles.address}>{jobSchedule?.job?.client.fullName}</div>
          <div className={styles.address}>{jobSchedule?.job?.address?.fullAddress}</div>

          <div className={styles.section}>
            <div className={styles.title}>Schedule Details</div>
            <div className={styles.info}>
              <InfoRow title={'Status'} info={jobSchedule?.job?.status?.status || '---'} />
              <InfoRow title={'Date'} info={formatDate(jobSchedule?.date, dateFormats.abbreviatedPretty)} />
              <InfoRow title={'Time'} info={`${jobSchedule?.startTime} - ${jobSchedule?.endTime}`} />
              <InfoRow title={'Type'} info={jobSchedule?.type.type} />

              <InfoRow
                title={'Manditory Arrive Time'}
                info={`${jobSchedule?.manditoryArriveAfter || 'ANY'} - ${jobSchedule?.manditoryArriveBefore || 'ANY'}`}
              />

              <InfoRow
                title={'Preferred Arrive Time'}
                info={`${jobSchedule?.preferredArriveAfter || 'ANY'} - ${jobSchedule?.preferredArriveBefore || 'ANY'}`}
              />

              <InfoRow title={'Created By'} info={jobSchedule?.createdBy} />
              <InfoRow title={'Created At'} info={jobSchedule?.createdAt} />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>Team Details</div>
            <div className={styles.info}>
              <div>{jobSchedule?.team.name}</div>
              <div>{jobSchedule?.team.members.map((member) => member.fullName).join(', ')}</div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>Tracking</div>
            <div className={styles.info}>
              <InfoRow title={'Time Started'} info={jobSchedule?.timeStarted || '---'} />
              <InfoRow title={'Time Finished'} info={jobSchedule?.timeFinished || '---'} />
            </div>
            <div className={styles.info}>
              <InfoRow title={'User Time Started'} info={jobSchedule?.userTimeStarted || '---'} />
              <InfoRow title={'User Time Finished'} info={jobSchedule?.userTimeFinished || '---'} />
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Job Details</div>
            <div className={styles.info}>
              <InfoRow
                title={'Started'}
                info={formatDate(jobSchedule?.job?.startDate, dateFormats.abbreviatedPretty) || '---'}
              />
              <InfoRow
                title={'Finished'}
                info={formatDate(jobSchedule?.job?.endDate, dateFormats.abbreviatedPretty) || '---'}
              />
              <InfoRow title={'Total'} info={formatPrice(jobSchedule?.job.subtotal, true)} />
              <InfoRow title={' Office Notes'} info={jobSchedule?.job.officeNotes} />
            </div>
          </div>
          {jobSchedule &&
          ((jobSchedule?.userTimeStarted && manditoryArriveTimeCheck()) ||
            user?.permissions.isAdmin ||
            user?.permissions.isReceptionist) ? (
            <>
              {jobSchedule?.job.windowDetails && (
                <div className={styles.section}>
                  <div className={styles.title}>Window Details</div>
                  <div className={styles.info}>
                    <InfoRow title={'Description'} info={jobSchedule?.job.windowDetails.description} />
                    <InfoRow title={'Sub Type'} info={jobSchedule?.job?.windowDetails.subType.title} />
                    <InfoRow title={'Extras'} info={getWindowExtras(jobSchedule?.job?.windowDetails) || '---'} />
                    <InfoRow title={'Price'} info={formatPrice(jobSchedule?.job.windowDetails.price, true)} />
                    <div className={styles.notes}>
                      <div>Notes:</div>
                      <div className={styles.notesInfo}>
                        {jobSchedule?.job.windowDetails.notes ? jobSchedule?.job.windowDetails.notes : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {jobSchedule?.job.eavesDetails && (
                <div className={styles.section}>
                  <div className={styles.title}>Eaves Details</div>
                  <div className={styles.info}>
                    <InfoRow title={'Description'} info={jobSchedule?.job.eavesDetails.description} />
                    <InfoRow title={'Sub Type'} info={jobSchedule?.job?.eavesDetails.subType.title} />
                    <InfoRow title={'Extras'} info={getEavesExtras(jobSchedule?.job?.eavesDetails) || '---'} />
                    <InfoRow title={'Price'} info={formatPrice(jobSchedule?.job.eavesDetails.price, true)} />
                    <div className={styles.notes}>
                      <div>Notes:</div>
                      <div className={styles.notesInfo}>
                        {jobSchedule?.job.eavesDetails.notes ? jobSchedule?.job.eavesDetails.notes : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {jobSchedule?.job.otherDetails && (
                <div className={styles.section}>
                  <div className={styles.title}>Custom Details</div>
                  <div className={styles.info}>
                    <InfoRow title={'Description'} info={jobSchedule?.job.otherDetails.description} />
                    <InfoRow title={'Price'} info={formatPrice(jobSchedule?.job.otherDetails.price, true)} />
                    <div className={styles.notes}>
                      <div>Notes:</div>
                      <div className={styles.notesInfo}>
                        {jobSchedule?.job.otherDetails.notes ? jobSchedule?.job.otherDetails.notes : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {jobSchedule.job.hasQuotes && (
                <div className={styles.section}>
                  <div className={styles.title}>Quotes</div>

                  {jobSchedule?.job.windowQuotes?.map((quote) => {
                    return (
                      <div key={quote.id} className={styles.info}>
                        <InfoRow title={'Description'} info={quote.description} />
                        <InfoRow title={'Extras'} info={getWindowExtras(quote) || '---'} />
                        <div className={styles.notes}>
                          <div>Notes:</div>
                          <div className={styles.notesInfo}>{quote.notes ? quote.notes : 'N/A'}</div>
                        </div>
                      </div>
                    );
                  })}

                  {jobSchedule?.job.eavesQuotes?.map((quote) => {
                    return (
                      <div key={quote.id} className={styles.info}>
                        <InfoRow title={'Description'} info={quote.description} />
                        <InfoRow title={'Extras'} info={getEavesExtras(quote) || '---'} />
                        <div className={styles.notes}>
                          <div>Notes:</div>
                          <div className={styles.notesInfo}>{quote.notes ? quote.notes : 'N/A'}</div>
                        </div>
                      </div>
                    );
                  })}

                  {jobSchedule?.job.otherQuotes?.map((quote) => {
                    return (
                      <div key={quote.id} className={styles.info}>
                        <InfoRow title={'Description'} info={quote.description} />
                        <div className={styles.notes}>
                          <div>Notes:</div>
                          <div className={styles.notesInfo}>{quote.notes ? quote.notes : 'N/A'}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          ) : (
            <div className={styles.section}>Start job schedule to see full details</div>
          )}
        </div>
        <div className={styles.buttons}>
          {!!(jobSchedule?.timeStarted && !jobSchedule.timeFinished && jobSchedule.job.hasQuotes) && (
            <div className={styles.button}>
              <Button title={'Submit Quote'} onClick={() => navigateToSubmitQuotePage(jobSchedule?.job.id)} />
            </div>
          )}
          {!!((user?.permissions.isCleaner || isPhone) && jobSchedule?.timeStarted && !jobSchedule.timeFinished) && (
            <div className={styles.button}>
              <Button title={'Request Job Change'} onClick={() => setOpenRequestJobChange(true)} />
            </div>
          )}
          {getStartActionButtons()}
          {!!(
            (!jobSchedule?.userTimeFinished && jobSchedule?.userTimeStarted) ||
            (!jobSchedule?.timeFinished && jobSchedule?.timeStarted)
          ) && (
            <div className={styles.button}>
              <Button title={'Finish'} onClick={() => setOpenStopJobScheduleModal(true)} />
            </div>
          )}
          {!!((user?.permissions.isAdmin || user?.permissions.isReceptionist) && jobSchedule?.timeStarted) && (
            <>
              <div className={styles.button}>
                <Button title={'Reset'} onClick={() => setOpenResetJobScheduleModal(true)} />
              </div>
              <div className={styles.button}>
                <Button title={'Money Collected'} onClick={() => setOpenRecordPayment(true)} />
              </div>
            </>
          )}

          {(user?.permissions.isAdmin || user?.permissions.isReceptionist) && !isPhone && (
            <>
              <div className={styles.button}>
                <Button title={'Edit Job'} onClick={() => setOpenAddEditJobModal(true)} />
              </div>
              <div className={styles.button}>
                <Button title={'Edit Job Schedule'} onClick={() => setOpenAddEditJobScheduleModal(true)} />
              </div>
            </>
          )}
        </div>
      </div>

      <ConfirmJobScheduleMembersStartModal
        open={openStartJobScheduleModal}
        onClose={() => setOpenStartJobScheduleModal(false)}
        onSave={refreshPage}
        id={id}
      />
      <ConfirmJobScheduleMembersFinishModal
        open={openStopJobScheduleModal}
        onClose={() => setOpenStopJobScheduleModal(false)}
        onSave={refreshPage}
        id={id}
      />
      <ConfirmJobScheduleMembersResetModal
        open={openResetJobScheduleModal}
        onClose={() => setOpenResetJobScheduleModal(false)}
        onSave={refreshPage}
        id={id}
      />
      <RecordPaymentModal
        open={openRecordPayment}
        onClose={() => setOpenRecordPayment(false)}
        jobId={jobSchedule?.job.id}
        onSave={() => {
          getSchedule();
          setOpenRecordPayment(false);
        }}
        setSnackBarMessage={setSnackBarMessage}
      />
      {jobSchedule?.job && (
        <RequestJobChangeModal
          open={openRequestJobChange}
          onClose={() => setOpenRequestJobChange(false)}
          job={jobSchedule.job}
          onSave={() => {
            getSchedule();
            setOpenRequestJobChange(false);
          }}
        />
      )}

      {!isPhone && jobSchedule?.job && (
        <AddEditJobModal
          open={openAddEditJobModal}
          onClose={() => setOpenAddEditJobModal(false)}
          jobId={jobSchedule.job.id}
          onSave={() => {
            getSchedule();
            setOpenAddEditJobModal(false);
            if (onSave) onSave();
          }}
          onDelete={() => {
            getSchedule();
            setOpenAddEditJobScheduleModal(false);
            if (onDelete) onDelete();
          }}
        />
      )}

      {!isPhone && (
        <AddEditJobScheduleModal
          open={openAddEditJobScheduleModal}
          id={jobSchedule?.id}
          onClose={() => setOpenAddEditJobScheduleModal(false)}
          onSave={() => {
            getSchedule();
            setOpenAddEditJobScheduleModal(false);
            if (onSave) onSave();
          }}
          onDelete={() => {
            getSchedule();
            setOpenAddEditJobScheduleModal(false);
            if (onDelete) onDelete();
          }}
        />
      )}
    </>
  );
}

export default JobScheduleSummarySetup;
