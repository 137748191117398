import React, { ReactElement, useEffect, useState } from 'react';
import {
  Address,
  CreateEavestrough,
  CreateEavesValidation,
  CreateJob,
  CreateOther,
  CreateOtherValidation,
  CreateWindow,
  CreateWindowValidation,
  Job,
  JobSubType,
  PaymentType,
} from 'types';

import styles from './AddEditJobSetup.module.scss';
import TypeTitleBar from '../components/TypeTitleBar';
import WindowSetup from '../components/WindowSetup';
import EavesSetup from '../components/EavesSetup';
import CustomSetup from '../components/CustomSetup';
import useJobs from 'hooks/useJobs.hook';
import Checkbox from 'components/Base/Checkbox';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import TextField from 'components/Base/TextField';

interface AddEditJobSetupProps {
  createEditJob: CreateJob;
  windowSubTypes: Array<JobSubType>;
  eavesSubTypes: Array<JobSubType>;
  job?: Job;
  setCreateEditJob: React.Dispatch<React.SetStateAction<CreateJob>>;
  paymentError?: string;
  windowErrors?: CreateWindowValidation;
  eavesErrors?: CreateEavesValidation;
  otherErrors?: CreateOtherValidation;
  address?: Address;
  includePreviousWork?: boolean;
}

function AddEditJobSetup({
  createEditJob,
  job,
  setCreateEditJob,
  windowSubTypes,
  eavesSubTypes,
  windowErrors,
  eavesErrors,
  otherErrors,
  paymentError,
  address,
  includePreviousWork,
}: AddEditJobSetupProps): ReactElement {
  const [showEavesDetails, setShowEavesDetails] = useState<boolean>(false);
  const [showWindowDetails, setShowWindowsDetails] = useState<boolean>(false);
  const [showOtherDetails, setShowOtherDetails] = useState<boolean>(false);

  const {
    fetchAddressJobs,
    previousEavesJobs,
    previousEavesQuotes,
    previousOtherJobs,
    previousOtherQuotes,
    previousWindowJobs,
    previousWindowQuotes,
    fetchPaymentTypes,
    paymentTypes,
  } = useJobs();

  const paymentList: Array<PaymentType> = [{ id: 0, type: '-- Type --' }, ...paymentTypes];

  const clearWindowDetails = () => {
    const newEditJob = { ...createEditJob };
    newEditJob.windowDetails = undefined;
    setCreateEditJob(newEditJob);
  };
  const clearEavesDetails = () => {
    const newEditJob = { ...createEditJob };
    newEditJob.eavesDetails = undefined;
    setCreateEditJob(newEditJob);
  };
  const clearOtherDetails = () => {
    const newEditJob = { ...createEditJob };
    newEditJob.otherDetails = undefined;
    setCreateEditJob(newEditJob);
  };

  const changeWindowDetails = (key: keyof CreateWindow, value: boolean | JobSubType | number | string | undefined) => {
    setCreateEditJob((prev) => ({
      ...prev,
      windowDetails: {
        ...prev.windowDetails,
        [key]: value,
      },
    }));
  };
  const changeEavesDetails = (
    key: keyof CreateEavestrough,
    value: boolean | JobSubType | number | string | undefined
  ) => {
    setCreateEditJob((prev) => ({
      ...prev,
      eavesDetails: {
        ...prev.eavesDetails,
        [key]: value,
      },
    }));
  };
  const changeOtherDetails = (key: keyof CreateOther, value: boolean | JobSubType | number | string | undefined) => {
    setCreateEditJob((prev) => ({
      ...prev,
      otherDetails: {
        ...prev.otherDetails,
        [key]: value,
      },
    }));
  };

  const setSelectedWindowType = (type: JobSubType) => {
    if (type.id === createEditJob.windowDetails?.subType?.id) return;
    changeWindowDetails('description', type.description);
    changeWindowDetails('subType', type);
  };

  const setSelectedEavesType = (type: JobSubType) => {
    if (type.id === createEditJob.eavesDetails?.subType?.id) return;
    changeEavesDetails('description', type.description);
    changeEavesDetails('subType', type);
  };

  const setTaxPercent = (checked: boolean) => {
    setCreateEditJob({
      ...createEditJob,
      includeTax: checked,
    });
  };

  const setNeedsInvoiceCheckbox = (value: boolean) => {
    setCreateEditJob({
      ...createEditJob,
      needsInvoice: value,
    });
  };

  const setPaymentType = (value: PaymentType | undefined) => {
    let type = value;
    let amount = createEditJob.paymentAmount;
    if (!value?.id) {
      type = undefined;
      amount = undefined;
    }

    setCreateEditJob({
      ...createEditJob,
      paymentType: type,
      paymentAmount: amount,
    });
  };

  const setPaymentAmount = (value: number) => {
    setCreateEditJob({
      ...createEditJob,
      paymentAmount: value,
    });
  };

  useEffect(() => {
    if (!job) return;
    setShowWindowsDetails(!!job.windowDetails);
    setShowEavesDetails(!!job.eavesDetails);
    setShowOtherDetails(!!job.otherDetails);
  }, [job]);

  useEffect(() => {
    if (address) fetchAddressJobs(address.id);
  }, [address]);

  useEffect(() => {
    fetchPaymentTypes();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <Checkbox checked={!!createEditJob.needsInvoice} onClick={setNeedsInvoiceCheckbox} label={'Require Invoice'} />
        <Checkbox checked={!!createEditJob.includeTax} onClick={setTaxPercent} label={'Include Tax'} />
        {!job && (
          <div className={styles.pContainer}>
            <div className={styles.title}>Payment</div>
            <div className={styles.error}>{paymentError}</div>
            <div className={styles.payment}>
              <div className={styles.type}>
                <Dropdown
                  items={paymentList.map((type) => {
                    return (
                      <DropdownItem
                        key={type.id}
                        id={type.id}
                        value={type.type}
                        onSelect={() => setPaymentType(type)}
                      />
                    );
                  })}
                  selectedValue={
                    paymentList?.find((type) => type.id === createEditJob.paymentType?.id) ?? paymentList[0]
                  }
                  objectKey="type"
                />
              </div>
              <div className={styles.amount}>
                <TextField
                  hint="Amount"
                  value={createEditJob.paymentAmount}
                  setValue={setPaymentAmount}
                  name={'paymentAmount'}
                />
              </div>
            </div>
          </div>
        )}

        <div className={styles.clientDetails}>
          <TypeTitleBar
            onExpandedCollaspe={() => setShowWindowsDetails((prev) => !prev)}
            expanded={showWindowDetails}
            title={'Window Job'}
            onClear={clearWindowDetails}
          />
          {showWindowDetails && (
            <WindowSetup
              changeDetails={changeWindowDetails}
              setSubType={setSelectedWindowType}
              details={createEditJob.windowDetails}
              subTypes={windowSubTypes}
              previousJobs={previousWindowJobs}
              previousQuotes={previousWindowQuotes}
              errors={windowErrors}
              includePreviousWork={includePreviousWork}
            />
          )}
        </div>

        <div className={styles.clientDetails}>
          <TypeTitleBar
            onExpandedCollaspe={() => setShowEavesDetails((prev) => !prev)}
            expanded={showEavesDetails}
            title={'Eavestrough Job'}
            onClear={clearEavesDetails}
          />
          {showEavesDetails && (
            <EavesSetup
              changeDetails={changeEavesDetails}
              setSubType={setSelectedEavesType}
              details={createEditJob.eavesDetails}
              subTypes={eavesSubTypes}
              previousJobs={previousEavesJobs}
              previousQuotes={previousEavesQuotes}
              errors={eavesErrors}
              includePreviousWork={includePreviousWork}
            />
          )}
        </div>

        <div className={styles.clientDetails}>
          <TypeTitleBar
            onExpandedCollaspe={() => setShowOtherDetails((prev) => !prev)}
            expanded={showOtherDetails}
            title={'Other Job'}
            onClear={clearOtherDetails}
          />
          {showOtherDetails && (
            <CustomSetup
              changeDetails={changeOtherDetails}
              details={createEditJob.otherDetails}
              errors={otherErrors}
              previousJobs={previousOtherJobs}
              previousQuotes={previousOtherQuotes}
              includePreviousWork={includePreviousWork}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AddEditJobSetup;
