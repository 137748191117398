import BaseModal from 'components/Base/BaseModal';
import Checkbox from 'components/Base/Checkbox';
import React, { ReactElement, useState } from 'react';
import { EmailContact } from 'types';

interface EmailSelectionModalProps {
  open: boolean;
  onClose: () => void;
  emails?: Array<EmailContact>;
  onSave: (emailIds: Array<number>) => void;
}
function EmailSelectionModal({ open, onClose, emails, onSave }: EmailSelectionModalProps): ReactElement {
  const [selectedEmailIds, setSelectedEmails] = useState<Array<number>>([]);

  const handleCheckboxClick = (id: number) => {
    const ids = [...selectedEmailIds];
    const index = selectedEmailIds.findIndex((i) => i === id);

    if (index >= 0) {
      ids.splice(index, 1);
    } else {
      ids.push(id);
    }
    setSelectedEmails(ids);
  };
  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title={'Email Selection'}
      onConfirm={() => onSave(selectedEmailIds)}
      confirmTitle="DONE"
    >
      <div>
        {emails?.map((email) => {
          return (
            <Checkbox
              key={email.id}
              label={email.email}
              checked={!!selectedEmailIds.find((id) => (id = email.id))}
              onClick={() => handleCheckboxClick(email.id)}
            />
          );
        })}
      </div>
    </BaseModal>
  );
}

export default EmailSelectionModal;
