import classNames from 'classnames';
import React, { ReactElement } from 'react';
import styles from './ToggleButton.module.scss';

interface ToggleButtonProps {
  leftTitle: string;
  rightTitle: string;
  setRightSelected: (checked: boolean) => void;
  value: boolean;
}

function ToggleButton({ leftTitle, rightTitle, setRightSelected, value }: ToggleButtonProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{leftTitle}</div>
      <label className={styles.switch}>
        <input
          type="checkbox"
          checked={value}
          onChange={(e) => {
            setRightSelected(e.target.checked);
          }}
        />
        <span className={classNames(styles.slider, styles.sliderRound)}></span>
      </label>
      <div className={styles.title}>{rightTitle}</div>
    </div>
  );
}

export default ToggleButton;
