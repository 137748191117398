import Autocomplete from 'components/Base/Autocomplete';
import DateRange from 'components/Base/DateRange';
import InfoCard from 'components/InfoCard';
import useDebounce from 'hooks/useDebounce.hooks';
import useSummary from 'hooks/useSummary.hook';
import useUsers from 'hooks/useUsers.hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { User } from 'types';
import styles from '../Components.module.scss';

function Employees(): ReactElement {
  const [minDate, setMinDate] = useState<string>();
  const [maxDate, setMaxDate] = useState<string>();
  const [selectedEmployee, setSelectedEmployee] = useState<User>();
  const [searchUsers, setSearchUsers] = useState<string>('');

  const { fetchEmployeesSummary, employeesSummary } = useSummary();
  const { users, fetchUsers } = useUsers();
  const debounceSearchEmployees: string = useDebounce(searchUsers);

  useEffect(() => {
    if (!selectedEmployee) return;
    const params = {
      minDate: minDate ? minDate : undefined,
      maxDate: maxDate ? maxDate : undefined,
      userId: selectedEmployee.id,
    };
    fetchEmployeesSummary(params);
  }, [minDate, maxDate, selectedEmployee]);

  useEffect(() => {
    fetchUsers(debounceSearchEmployees);
  }, [debounceSearchEmployees]);

  return (
    <div className={styles.container}>
      <div className={styles.itemSelection}>
        <Autocomplete
          values={users.map((user) => {
            return {
              object: user,
              rowDisplay: <div>{user.fullName}</div>,
            };
          })}
          onSelect={(user: User) => {
            setSelectedEmployee(user);
            setSearchUsers('');
          }}
          searchFilter={searchUsers}
          setSearchFilter={setSearchUsers}
          hint={'Search Employees'}
          onClearField={() => setSearchUsers('')}
        />
      </div>
      <DateRange
        minDate={minDate}
        setMinDate={setMinDate}
        maxDate={maxDate}
        setMaxDate={setMaxDate}
        highestMinDate={maxDate}
        lowestMaxDate={minDate}
      />

      {!!selectedEmployee && (
        <div className={styles.data}>
          <div className={styles.selectedItem}>{selectedEmployee?.fullName}</div>
          <div className={styles.summaries}>
            <InfoCard title={'Needs Attention'}>
              <table>
                <tbody>
                  <tr>
                    <td>Total Hours</td>
                    <td>{employeesSummary?.totalWorkedHours}</td>
                  </tr>
                </tbody>
              </table>
            </InfoCard>
          </div>
        </div>
      )}
    </div>
  );
}

export default Employees;
