import { useState } from 'react';
import {
  FetchEmployeesSummaryParams,
  FetchOverviewParams,
  FetchTeamsSummaryParams,
  SummaryEmployees,
  SummaryOverview,
  SummaryTeam,
} from 'types';
import { SummariesApi } from 'api';

interface useSummmaryItems {
  fetchOverview: (params?: FetchOverviewParams) => void;
  fetchTeamsSummary: (params?: FetchTeamsSummaryParams) => void;
  fetchEmployeesSummary: (params?: FetchEmployeesSummaryParams) => void;
  overview?: SummaryOverview;
  teamsSummary?: SummaryTeam;
  employeesSummary?: SummaryEmployees;
}

function useSummary(): useSummmaryItems {
  const [overview, setOverview] = useState<SummaryOverview>();
  const [teamsSummary, setTeamsSummary] = useState<SummaryTeam>();
  const [employeesSummary, setEmployeesSummary] = useState<SummaryEmployees>();

  const fetchOverview = async (params?: FetchOverviewParams) => {
    const overview = await SummariesApi.fetchOverview(params);
    setOverview(overview);
  };

  const fetchTeamsSummary = async (params?: FetchTeamsSummaryParams) => {
    const teamsSummary = await SummariesApi.fetchTeamsSummary(params);
    setTeamsSummary(teamsSummary);
  };

  const fetchEmployeesSummary = async (params?: FetchEmployeesSummaryParams) => {
    const employeesSummary = await SummariesApi.fetchEmployeesSummary(params);
    setEmployeesSummary(employeesSummary);
  };

  return {
    fetchOverview,
    fetchTeamsSummary,
    fetchEmployeesSummary,
    overview,
    teamsSummary,
    employeesSummary,
  };
}

export default useSummary;
