import { BaseApi } from 'api';
import { TEAMS } from 'lib/routes';
import {
  Colour,
  CreateTeam,
  CreateTeamSchedule,
  DailyAllTeamsSchedule,
  DailyTeamSchedule,
  DailyTeamScheduleSearch,
  DeleteTeamSchedule,
  FetchMonthlyTeamSchedulesParams,
  FetchTeamsParams,
  Team,
  TeamSchedule,
  TeamScheduleSearch,
} from 'types';

export const fetchColours = async (): Promise<Array<Colour>> => {
  return await BaseApi.get(`${TEAMS}/colours`);
};

export const fetchTeam = async (id: number): Promise<Team> => {
  return await BaseApi.get(`${TEAMS}/${id}`);
};

export const fetchTeamSchedule = async (id: number): Promise<TeamSchedule> => {
  return await BaseApi.get(`${TEAMS}/schedules/${id}`);
};

export const fetchDailyTeamSchedules = async (params: DailyTeamScheduleSearch): Promise<Array<DailyTeamSchedule>> => {
  return await BaseApi.get(`${TEAMS}/dailySchedules`, params);
};

export const fetchTeamSchedules = async (params: TeamScheduleSearch): Promise<Array<TeamSchedule>> => {
  return await BaseApi.get(`${TEAMS}/schedules`, params);
};

export const fetchMonthTeamsSchedules = async (
  params: FetchMonthlyTeamSchedulesParams
): Promise<Array<DailyAllTeamsSchedule>> => {
  return await BaseApi.get(`${TEAMS}/monthSchedules`, params);
};
export const fetch = async (params?: FetchTeamsParams): Promise<Array<Team>> => {
  return await BaseApi.get(TEAMS, params);
};

export const createTeam = async (body: CreateTeam): Promise<void> => {
  return await BaseApi.post(`${TEAMS}/create`, body);
};

export const update = async (id: number, body: CreateTeam): Promise<void> => {
  return await BaseApi.put(`${TEAMS}/${id}`, body);
};

export const createTeamSchedule = async (schedule: CreateTeamSchedule): Promise<void> => {
  return await BaseApi.post(`${TEAMS}/schedules`, schedule);
};

export const updateTeamSchedule = async (id: number, schedule: CreateTeamSchedule): Promise<void> => {
  return await BaseApi.put(`${TEAMS}/schedules/${id}`, schedule);
};

export const del = async (id: number): Promise<void> => {
  return await BaseApi.del(`${TEAMS}/${id}`);
};

export const delSchedule = async (id: number, data: DeleteTeamSchedule): Promise<void> => {
  return await BaseApi.del(`${TEAMS}/schedules/${id}`, data);
};
