import DateRange from 'components/Base/DateRange';
import InfoCard from 'components/InfoCard';
import useSummary from 'hooks/useSummary.hook';
import { formatPrice } from 'lib/formatting';
import React, { ReactElement, useEffect, useState } from 'react';
import styles from '../Components.module.scss';

function Overview(): ReactElement {
  const [minDate, setMinDate] = useState<string>();
  const [maxDate, setMaxDate] = useState<string>();

  const { fetchOverview, overview } = useSummary();

  useEffect(() => {
    const params = {
      minDate: minDate ? minDate : undefined,
      maxDate: maxDate ? maxDate : undefined,
    };
    fetchOverview(params);
  }, [minDate, maxDate]);

  return (
    <div className={styles.container}>
      <DateRange
        minDate={minDate}
        setMinDate={setMinDate}
        maxDate={maxDate}
        setMaxDate={setMaxDate}
        highestMinDate={maxDate}
        lowestMaxDate={minDate}
      />
      <div className={styles.data}>
        <div className={styles.summaries}>
          <InfoCard title={'Needs Attention'}>
            <table>
              <tbody>
                <tr>
                  <td>Past Incomplete Jobs</td>
                  <td>{overview?.notCompletedJobsCount}</td>
                </tr>
                <tr>
                  <td>Outstanding Invoices</td>
                  <td>{overview?.outstandingInvoicesCount}</td>
                </tr>
                <tr>
                  <td>Past Incomplete Quote Jobs</td>
                  <td>{overview?.notCompletedQuotesCount}</td>
                </tr>
                <tr>
                  <td>Outstanding Quotes</td>
                  <td>{overview?.outstandingQuotesCount}</td>
                </tr>
              </tbody>
            </table>
          </InfoCard>
          <InfoCard title={'Summary'}>
            <table>
              <tbody>
                <tr>
                  <td>Total Jobs And Quotes</td>
                  <td>{overview?.totalJobsCount}</td>
                </tr>
                <tr>
                  <td>Complete Jobs</td>
                  <td>{overview?.completedJobsCount}</td>
                </tr>
                <tr>
                  <td>Total Production</td>
                  <td>{formatPrice(overview?.totalProduction, true)}</td>
                </tr>
                <tr>
                  <td>Complete Quote Jobs</td>
                  <td>{overview?.completedQuotesCount}</td>
                </tr>
                <tr>
                  <td>Total Quoted</td>
                  <td>{formatPrice(overview?.totalQuoted, true)}</td>
                </tr>
              </tbody>
            </table>
          </InfoCard>
          <InfoCard title={'Clients Summary'}>
            <table>
              <tbody>
                <tr>
                  <td>New Clients</td>
                  <td>{overview?.totalNewClients}</td>
                </tr>
              </tbody>
            </table>
          </InfoCard>
        </div>
      </div>
    </div>
  );
}

export default Overview;
