import React, { ReactElement } from 'react';
import styles from './DateSelector.module.scss';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import moment from 'moment';
import { dateFormats } from 'lib/date';

interface DateSelectorProps {
  date?: string;
  setDate: (newDate: string) => void;
  type?: string;
  title?: string;
  error?: string;
  hasError?: boolean;
  disabled?: boolean;
  min?: string;
  max?: string;
  includeDaySwitching?: boolean;
}

function DateSelector({
  date,
  setDate,
  type = 'date',
  title,
  error,
  hasError,
  disabled,
  min,
  max,
  includeDaySwitching,
}: DateSelectorProps): ReactElement {
  const dateRight = () => {
    const newDate = moment(date).add(1, 'day').format(dateFormats.sqlDateOnly);
    setDate(newDate);
  };

  const dateLeft = () => {
    const newDate = moment(date).subtract(1, 'day').format(dateFormats.sqlDateOnly);
    setDate(newDate);
  };
  return (
    <>
      <div>{title}</div>
      <div className={styles.dateSelector}>
        {includeDaySwitching && (
          <div className={styles.dateChangeBtn} onClick={dateLeft}>
            <ArrowLeft className={styles.dateChangeIcon} />
          </div>
        )}
        <input
          className={styles.selector}
          type={type}
          value={date || ''}
          min={min}
          max={max}
          disabled={disabled}
          onChange={(event) => setDate(event.target.value)}
        />
        {includeDaySwitching && (
          <div className={styles.dateChangeBtn} onClick={dateRight}>
            <ArrowRight className={styles.dateChangeIcon} />
          </div>
        )}
      </div>

      {hasError && <p className={styles.errorMessage}>{error}</p>}
    </>
  );
}

export default DateSelector;
