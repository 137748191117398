import { BaseApi } from 'api';
import { JOBS, QUOTES } from 'lib/routes';
import {
  JobType,
  Job,
  JobSubType,
  TaskType,
  Quote,
  CreateJob,
  CreateRequestJobChanges,
  RequestJobChanges,
  FetchRequestJobChangesParams,
  ConfirmRequestChanges,
  JobStatus,
  CreateQuote,
  SubmitQuote,
  PaymentType,
  RecordPayment,
  AddressJobDetails,
  FetchJobsParams,
  JobDailySummary,
  FetchQuotesParams,
  FetchMonthlyJobSchedulesParams,
  FetchAddressJobsParams,
  CreatedJob,
  ChangeStatusParams,
  Communication,
} from 'types';

export const fetchJobs = async (params: FetchJobsParams): Promise<Array<Job>> => {
  return await BaseApi.get(JOBS, params);
};

export const fetchQuotes = async ({ searchFilter, clientId }: FetchQuotesParams): Promise<Array<Quote>> => {
  return await BaseApi.get(QUOTES, { searchFilter, clientId });
};

export const fetchJob = async (id: number): Promise<Job> => {
  return await BaseApi.get(`${JOBS}/${id}`);
};

export const fetchMonthJobSchedules = async (
  params: FetchMonthlyJobSchedulesParams
): Promise<Array<JobDailySummary>> => {
  return await BaseApi.get(`${JOBS}/monthSchedules`, params);
};

export const fetchTaskTypes = async (): Promise<Array<TaskType>> => {
  return await BaseApi.get(`${JOBS}/taskTypes`);
};

export const fetchChangeRequests = async (params: FetchRequestJobChangesParams): Promise<Array<RequestJobChanges>> => {
  return await BaseApi.get(`${JOBS}/requestChanges`, params);
};

export const confirmRequestChanges = async (id: number, data: ConfirmRequestChanges): Promise<void> => {
  return await BaseApi.put(`${JOBS}/requestChanges/${id}`, data);
};

export const changeStatus = async (data: ChangeStatusParams, token?: string): Promise<void> => {
  return await BaseApi.post(`${JOBS}/statuses/`, data, token);
};

export const fetchJobTypes = async (): Promise<Array<JobType>> => {
  return await BaseApi.get(`${JOBS}/jobTypes`);
};

export const fetchPaymentTypes = async (): Promise<Array<PaymentType>> => {
  return await BaseApi.get(`${JOBS}/paymentTypes`);
};

export const fetchCommunications = async (id: number): Promise<Array<Communication>> => {
  return await BaseApi.get(`${JOBS}/communications/${id}`);
};

export const fetchAddressJobDetails = async (data: FetchAddressJobsParams): Promise<AddressJobDetails> => {
  return await BaseApi.get(`${JOBS}/addressJobDetails`, data);
};

export const fetchJobSubTypes = async (): Promise<Array<JobSubType>> => {
  return await BaseApi.get(`${JOBS}/jobSubTypes`);
};

export const fetchStatuses = async (): Promise<Array<JobStatus>> => {
  return await BaseApi.get(`${JOBS}/statuses`);
};

export const createJob = async (job: CreateJob): Promise<CreatedJob> => {
  return await BaseApi.post(`${JOBS}`, job);
};

export const updateJob = async (id: number, job: CreateJob): Promise<void> => {
  return await BaseApi.put(`${JOBS}/${id}`, job);
};

export const createQuote = async (data: CreateQuote): Promise<void> => {
  return await BaseApi.post(`${JOBS}/createQuote`, data);
};

export const submitQuote = async (data: SubmitQuote): Promise<void> => {
  return await BaseApi.post(`${JOBS}/submitQuote`, data);
};

export const completeJob = async (id: number): Promise<void> => {
  return await BaseApi.put(`${JOBS}/completeJob/${id}`);
};

export const requestChanges = async (data: CreateRequestJobChanges): Promise<void> => {
  return await BaseApi.post(`${JOBS}/requestChanges`, data);
};

export const recordPayment = async (data: RecordPayment): Promise<void> => {
  return await BaseApi.post(`${JOBS}/recordPayment`, data);
};

export const del = async (id: number): Promise<void> => {
  return await BaseApi.del(`${JOBS}/${id}`);
};
