import { getMonthBase0 } from 'lib/date';
import React, { ReactElement, useEffect } from 'react';
import JobSummaries from './components/JobSummaries';
import styles from './Monthly.module.scss';
import useJobSchedules from 'hooks/useJobSchedules.hook';

interface MonthlyProps {
  date: string;
  switchFormat: (date: string) => void;
  showProductionRemaining: boolean;
  refreshData: boolean;
  resetRefresh: () => void;
}

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function Monthly({
  date,
  switchFormat,
  resetRefresh,
  showProductionRemaining,
  refreshData,
}: MonthlyProps): ReactElement {
  const monthNumber = getMonthBase0(date);

  const { fetchMonthJobSchedules, monthJobSchedules } = useJobSchedules();

  useEffect(() => {
    if (refreshData) {
      fetchMonthJobSchedules({ date });
    }
    resetRefresh();
  }, [refreshData]);
  useEffect(() => {
    fetchMonthJobSchedules({ date });
  }, [date]);

  return (
    <div className={styles.calendar}>
      <div className={styles.dayOfWeek}>
        {daysOfWeek.map((day) => [
          <label className={styles.item} key={day}>
            {day}
          </label>,
        ])}
      </div>
      <div className={styles.dateGrid}>
        {monthJobSchedules.map((day) => {
          return (
            <JobSummaries
              key={day.date}
              date={day.date}
              jobSummaries={day.schedules}
              month={monthNumber}
              showProductionRemaining={showProductionRemaining}
              onClick={() => {
                switchFormat(day.date);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Monthly;
