import { BaseApi } from 'api';
import { CreateQuote, QuoteSettings, UpdateQuoteSettings } from 'types';

import { QUOTES } from 'lib/routes';

export const createQuote = async (data: CreateQuote): Promise<void> => {
  return await BaseApi.post(`${QUOTES}`, data);
};

export const fetchQuoteSignedUrl = async (id: number): Promise<string> => {
  return await BaseApi.get(`${QUOTES}/signedUrl/${id}`);
};

export const fetchSettings = async (): Promise<QuoteSettings> => {
  return await BaseApi.get(`${QUOTES}/settings`);
};

export const updateSettings = async (data: UpdateQuoteSettings): Promise<void> => {
  return await BaseApi.put(`${QUOTES}/settings`, data);
};

export const preview = async (data?: CreateQuote): Promise<ArrayBuffer> => {
  return await BaseApi.getPDF(`${QUOTES}/preview`, data);
};

export const sendQuote = async (id: number, emailIds: Array<number>): Promise<void> => {
  return await BaseApi.post(`${QUOTES}/send/${id}`, { emailIds });
};
