import Layout from 'Base';
import classNames from 'classnames';
import InfoCard from 'components/InfoCard';
import AddEditJobModal from 'components/Modals/AddEditJobModal';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useJobs from 'hooks/useJobs.hook';
import { dateFormats, formatDate } from 'lib/date';
import { formatPrice } from 'lib/formatting';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Job.module.scss';
import useJobSchedules from 'hooks/useJobSchedules.hook';
import useInvoices from 'hooks/useInvoices.hook';
import useQuotes from 'hooks/useQuotes.hook';
import CreateInvoiceModal from 'components/Modals/CreateInvoiceModal';
import EmailSelectionModal from 'components/Modals/EmailSelectionModal';
import { Invoice, QuoteDocument, JobSchedule } from 'types';
import useReactOperations from 'hooks/useReactOperations.hook';
import ChangeStatusModal from 'components/Modals/ChangeStatusModal';
import CreateQuoteModal from 'components/Modals/CreateQuoteModal';
import RecordPaymentModal from 'components/Modals/RecordPayment';
import JobScheduleSummaryModal from 'components/Modals/JobScheduleSummaryModal';

function JobDetails(): ReactElement {
  const { jobId } = useParams();
  const id = jobId ? +jobId : undefined;

  const [openEditJob, setOpenEditJob] = useState<boolean>(false);
  const [openCreateInvoiceModal, setOpenCreateInvoiceModal] = useState<boolean>(false);
  const [openCreateQuoteModal, setOpenCreateQuoteModal] = useState<boolean>(false);
  const [openEmailSelectionModal, setOpenEmailSelectionModal] = useState<boolean>(false);
  const [openQuoteEmailSelectionModal, setOpenQuoteEmailSelectionModal] = useState<boolean>(false);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState<boolean>(false);
  const [openRecordPaymentModal, setOpenRecordPaymentModal] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();
  const [selectedQuote, setSelectedQuote] = useState<QuoteDocument>();
  const [selectedJobSchedule, setSelectedJobSchedule] = useState<JobSchedule>();

  const {
    job,
    fetchJob,
    jobErrorMessage,
    jobErrorTitle,
    resetJobError,
    jobsLoading,
    fetchCommunication,
    communications,
  } = useJobs();

  const { viewInvoice, sendInvoice } = useInvoices();
  const { viewQuote, sendQuote } = useQuotes();

  const { fetchJobSchedules, jobSchedules } = useJobSchedules();

  const { navigateToAddressPage, navigateToClientPage, navigateToDailyCalendarPage } = useReactOperations();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError }],
  });

  const onSave = () => {
    if (!id) return;
    fetchJob(id);
    fetchCommunication(id);
    fetchJobSchedules({ jobId: id });
    setOpenEditJob(false);
    setOpenCreateInvoiceModal(false);
    setOpenCreateQuoteModal(false);
    setOpenEmailSelectionModal(false);
    setOpenQuoteEmailSelectionModal(false);
    setOpenChangeStatusModal(false);
    setOpenRecordPaymentModal(false);
    setSelectedJobSchedule(undefined);
  };

  const localSendInvoice = async (ids: Array<number>) => {
    if (!selectedInvoice || !ids.length) return;
    await sendInvoice(selectedInvoice.id, ids);
    onSave();
  };

  const localSendQuote = async (ids: Array<number>) => {
    if (!selectedQuote || !ids.length) return;
    await sendQuote(selectedQuote.id, ids);
    onSave();
  };

  useEffect(() => {
    if (!id) return;
    fetchJob(id);
    fetchJobSchedules({ jobId: id });
    fetchCommunication(id);
  }, [jobId]);

  const invoiceActions = [{ type: 'invoice', onClick: () => setOpenCreateInvoiceModal(true) }];
  const quoteActions = [{ type: 'quote', onClick: () => setOpenCreateQuoteModal(true) }];

  return (
    <Layout
      title={job?.description || ''}
      alertMessage={alertMessage}
      openAlert={openAlert}
      alertTitle={alertTitle}
      onAlertClose={closeAlertModal}
    >
      <div className={styles.container}>
        <InfoCard title={'Summary'} onEdit={() => setOpenEditJob(true)} loading={jobsLoading}>
          <table>
            <thead>
              <tr>
                <th>Data</th>
                <th>Office Info</th>
              </tr>
            </thead>
            <tbody>
              {!!(!jobsLoading && job) && (
                <tr className={styles.row}>
                  <td className={classNames(styles.left, styles.data)}>
                    <div>{`ID: ${job?.id}`}</div>
                    <div onClick={() => setOpenChangeStatusModal(true)}>
                      Status: <span className={styles.clickable}>{job?.status?.status}</span>
                    </div>
                    <div onClick={() => navigateToClientPage(job.client.id)}>
                      Client: <span className={styles.clickable}>{`${job.client.fullName}`}</span>
                    </div>
                    <div onClick={() => navigateToAddressPage(job.address.id)}>
                      {`Address: `}
                      <span className={styles.clickable}>{`${job?.address.fullAddress}`}</span>
                    </div>
                    <div>{`Started: ${formatDate(job?.startDate, dateFormats.fullPretty)}`}</div>
                    <div>{`${job?.completed ? 'Completed' : 'Projected Finish'}: ${formatDate(
                      job?.endDate,
                      dateFormats.fullPretty
                    )}`}</div>
                    {job?.hasWork && <div>{`Total: ${formatPrice(job.subtotal, true)}`}</div>}
                    <div className={styles.clickable} onClick={() => setOpenRecordPaymentModal(true)}>{`Paid: ${
                      job?.fullyPaid ? 'YES' : 'NO'
                    }`}</div>
                    <div
                      className={styles.clickable}
                      onClick={() => setOpenRecordPaymentModal(true)}
                    >{`Payment Remaining: ${formatPrice(job.paymentRemaining, true)}`}</div>
                    <div className={styles.clickable}>{`Method of Payment: ${
                      job?.payments ? job?.payments.map((payment) => payment.type).join(', ') : ''
                    }`}</div>
                    <div>{`Office Notes: ${job?.officeNotes}`}</div>
                  </td>
                  <td className={classNames(styles.center, styles.data)}>
                    <div>{`Created By: ${job?.createdBy}`}</div>
                    <div>{`Created At: ${formatDate(job?.createdAt, dateFormats.fullPretty)}`}</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </InfoCard>
        <InfoCard title={'Schedules'}>
          <table>
            <thead>
              <tr>
                <th>Team</th>
                <th>Date</th>
                <th>Scheduled Time</th>
                <th>Cleaning Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {jobSchedules.map((schedule) => {
                return (
                  <tr key={schedule.id} className={styles.row}>
                    <td className={classNames(styles.center, styles.data)}>{schedule.team.name}</td>
                    <td className={classNames(styles.date, styles.data)}>{schedule.date}</td>
                    <td className={classNames(styles.date, styles.data)}>
                      {schedule.startTime} - {schedule.endTime}
                    </td>
                    <td className={classNames(styles.date, styles.data)}>
                      {schedule.timeStarted} - {schedule.timeFinished}
                    </td>
                    <td>
                      <div className={styles.clickable} onClick={() => setSelectedJobSchedule(schedule)}>
                        View
                      </div>
                      <div className={styles.clickable} onClick={() => navigateToDailyCalendarPage(schedule.date)}>
                        Go To
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfoCard>
        {job?.hasWork && (
          <InfoCard title={'Invoices'} actions={invoiceActions}>
            <table>
              <thead>
                <th>Date</th>
                <th>Invoice Id</th>
                <th></th>

                <th></th>
              </thead>
              <tbody>
                {job?.invoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td>{formatDate(invoice.createdAt, dateFormats.abbreviatedPretty)}</td>
                    <td>{invoice.id}</td>
                    <td
                      onClick={() => {
                        setOpenEmailSelectionModal(true);
                        setSelectedInvoice(invoice);
                      }}
                    >
                      Send
                    </td>
                    <td onClick={() => viewInvoice(invoice.id)}>View</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfoCard>
        )}
        {job?.hasQuotes && (
          <InfoCard title={'Quotes'} actions={quoteActions}>
            <table>
              <thead>
                <th>Date</th>
                <th>Quote Id</th>
                <th></th>
                <th></th>
              </thead>
              <tbody>
                {job?.quotes.map((quote) => (
                  <tr key={quote.id}>
                    <td>{formatDate(quote.createdAt, dateFormats.abbreviatedPretty)}</td>
                    <td>{quote.id}</td>
                    <td
                      onClick={() => {
                        setOpenQuoteEmailSelectionModal(true);
                        setSelectedQuote(quote);
                      }}
                    >
                      Send
                    </td>
                    <td className={styles.view} onClick={() => viewQuote(quote.id)}>
                      View
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfoCard>
        )}

        <InfoCard title={'Communication'}>
          <table>
            <thead>
              <th>Sent At</th>
              <th>Communication</th>
              <th>File Id</th>
              <th>Sent To</th>
              <th>Sent By</th>
            </thead>
            <tbody>
              {communications.map((communication) => (
                <tr key={communication.sentAt}>
                  <td>{formatDate(communication.sentAt, dateFormats.fullPretty)}</td>
                  <td>{communication.name}</td>
                  <td>{communication.fileId}</td>
                  <td>{communication.email}</td>
                  <td>{communication.sentBy}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfoCard>
      </div>
      <AddEditJobModal open={openEditJob} jobId={job?.id} onClose={() => setOpenEditJob(false)} onSave={onSave} />
      <CreateInvoiceModal
        jobId={job?.id}
        open={openCreateInvoiceModal}
        onClose={() => setOpenCreateInvoiceModal(false)}
        onSave={onSave}
      />
      <CreateQuoteModal
        jobId={job?.id}
        open={openCreateQuoteModal}
        onClose={() => setOpenCreateQuoteModal(false)}
        onSave={onSave}
      />
      <EmailSelectionModal
        open={openEmailSelectionModal}
        emails={job?.client.emails}
        onClose={() => setOpenEmailSelectionModal(false)}
        onSave={(ids: Array<number>) => localSendInvoice(ids)}
      />
      <EmailSelectionModal
        open={openQuoteEmailSelectionModal}
        emails={job?.client.emails}
        onClose={() => setOpenQuoteEmailSelectionModal(false)}
        onSave={(ids: Array<number>) => localSendQuote(ids)}
      />
      <ChangeStatusModal
        open={openChangeStatusModal}
        onClose={() => setOpenChangeStatusModal(false)}
        onSave={onSave}
        jobId={job?.id}
      />
      <RecordPaymentModal
        open={openRecordPaymentModal}
        jobId={job?.id}
        onSave={onSave}
        onClose={() => setOpenRecordPaymentModal(false)}
      />
      <JobScheduleSummaryModal
        open={!!selectedJobSchedule}
        onClose={() => setSelectedJobSchedule(undefined)}
        onEditJobScheduleSave={onSave}
        jobScheduleId={selectedJobSchedule?.id}
      />
    </Layout>
  );
}

export default JobDetails;
