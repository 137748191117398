import classNames from 'classnames';
import { formatPrice } from 'lib/formatting';
import { changeStyleProperty } from 'lib/reactOperations';
import React, { ReactElement, useEffect } from 'react';
import styles from './JobScheduleSummary.module.scss';

interface JobScheduleSummaryProps {
  total: number;
  teamId: number;
  date: string;
  teamColour: string;
}
function JobScheduleSummary({ total, date, teamId, teamColour }: JobScheduleSummaryProps): ReactElement {
  const scheduleId = `${teamId}-${date}`;
  useEffect(() => {
    changeStyleProperty(scheduleId, '--colorHex', teamColour);
  }, [total, teamId, date, teamColour]);
  return (
    <div id={scheduleId} className={classNames(styles.summary, total < 0 && styles.negitive)}>
      {`$${formatPrice(total)}`}
    </div>
  );
}

export default JobScheduleSummary;
