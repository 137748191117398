import { getTimesArray } from 'lib/calendar';
import React, { ReactElement, useEffect, useState } from 'react';
import { CreateJobScheduleFromDrag, Job } from 'types';
import useTeams from 'hooks/useTeams.hook';
import styles from './Daily.module.scss';
import useDebounceVariable from 'hooks/useDebounce.hooks';
import TeamColumn from './components/TeamColumn';
import HandleChangeRequestsModal from 'components/Modals/HandleChangeRequestsModal';
import ChangeStatusModal from 'components/Modals/ChangeStatusModal';
import CreateInvoiceModal from 'components/Modals/CreateInvoiceModal';
import CreateQuoteModal from 'components/Modals/CreateQuoteModal';
import RecordPayment from 'components/Modals/RecordPayment';
import useJobSchedules from 'hooks/useJobSchedules.hook';
import MultiChoiceModal from 'components/Modals/MultiChoiceModal';

interface DailyCalendarProps {
  setSelectedJobScheduleId: (id: number | undefined) => void;
  date: string;
  refreshData: boolean;
  resetRefresh: () => void;
  setCustomJobScheduleDetails: (time: string, teamId: number) => void;
}

function Daily({
  setSelectedJobScheduleId,
  date,
  refreshData,
  resetRefresh,
  setCustomJobScheduleDetails,
}: DailyCalendarProps): ReactElement {
  const { jobSchedules, fetchJobSchedules, updateJobSchedule, createJobSchedule } = useJobSchedules();

  const { dailyTeamSchedules, fetchDailyTeamSchedules } = useTeams();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [times, setTimes] = useState<Array<string>>([]);
  const [changeRequestJob, setChangeRequestJob] = useState<Job>();
  const [invoiceJob, setInvoiceJob] = useState<Job>();
  const [quoteJob, setQuoteJob] = useState<Job>();
  const [changeStatusJobId, setChangeStatusJobId] = useState<number>();
  const [recordPaymentJobId, setRecordPaymentJobId] = useState<number>();
  const [createJobScheduleFromDrag, setCreateJobScheduleFromDrag] = useState<CreateJobScheduleFromDrag>();

  const refreshScreen = useDebounceVariable(refresh, 300);

  const onTimeSelectionClick = (time: string, teamId: number) => {
    setCustomJobScheduleDetails(time, teamId);
  };

  const localUpdateJobSchedule = async () => {
    if (!createJobScheduleFromDrag) return;
    await updateJobSchedule(createJobScheduleFromDrag.id, createJobScheduleFromDrag.createJobSchedule);
    fetchJobSchedules({ date });
    setCreateJobScheduleFromDrag(undefined);
  };

  const localCreateJobSchedule = async () => {
    if (!createJobScheduleFromDrag) return;
    await createJobSchedule(createJobScheduleFromDrag?.createJobSchedule);
    fetchJobSchedules({ date });
    setCreateJobScheduleFromDrag(undefined);
  };

  useEffect(() => {
    if (!refreshScreen) return;

    setRefresh(false);
  }, [refreshScreen]);

  useEffect(() => {
    if (!date) return;
    fetchJobSchedules({ date });
    fetchDailyTeamSchedules({ date });
  }, [date]);

  useEffect(() => {
    if (!date || !refreshData) return;
    fetchJobSchedules({ date });
    fetchDailyTeamSchedules({ date });
    resetRefresh();
  }, [refreshData]);

  useEffect(() => {
    let startTime = '23:59';
    let endTime = '00:00';

    dailyTeamSchedules.forEach((teamSchedule) => {
      teamSchedule.schedules.forEach((schedule) => {
        if (schedule.startTime < startTime) startTime = schedule.startTime;
        if (schedule.endTime > endTime) endTime = schedule.endTime;
      });
    });
    setTimes(getTimesArray(startTime, endTime));
  }, [dailyTeamSchedules]);

  return (
    <div className={styles.daily}>
      {!!dailyTeamSchedules.length && (
        <div className={styles.times}>
          <div className={styles.title} />
          {times.map((time) => {
            return (
              <div key={time} className={styles.timeContainer}>
                {time}
              </div>
            );
          })}
        </div>
      )}

      <div className={styles.schedules}>
        {dailyTeamSchedules.length ? (
          dailyTeamSchedules.map((teamSchedule, index) => {
            return (
              <TeamColumn
                key={index}
                onTimeSelectionClick={onTimeSelectionClick}
                times={times}
                teamSchedule={teamSchedule}
                jobSchedules={jobSchedules.filter((schedule) => schedule.teamId === teamSchedule.teamId)}
                setSelectedJobScheduleId={setSelectedJobScheduleId}
                startTime={times[0]}
                onChangeRequestClick={(job: Job) => setChangeRequestJob(job)}
                onInvoiceClick={(job: Job) => setInvoiceJob(job)}
                onQuoteClick={(job: Job) => setQuoteJob(job)}
                openChangeStatusModal={(jobId: number) => {
                  setChangeStatusJobId(jobId);
                }}
                openRecordPaymentModal={(jobId: number) => {
                  setRecordPaymentJobId(jobId);
                }}
                onDragStop={(data: CreateJobScheduleFromDrag) => setCreateJobScheduleFromDrag(data)}
                refreshPage={() => fetchJobSchedules({ date })}
              />
            );
          })
        ) : (
          <div className={styles.noTeams}>No Teams Scheduled For Today</div>
        )}
      </div>
      <HandleChangeRequestsModal
        open={!!changeRequestJob}
        onClose={() => setChangeRequestJob(undefined)}
        onSave={() => {
          fetchJobSchedules({ date });
          setChangeRequestJob(undefined);
        }}
        job={changeRequestJob}
      />
      <RecordPayment
        open={!!recordPaymentJobId}
        onClose={() => setRecordPaymentJobId(undefined)}
        onSave={() => {
          fetchJobSchedules({ date });
          setRecordPaymentJobId(undefined);
        }}
        jobId={recordPaymentJobId}
      />
      <ChangeStatusModal
        open={!!changeStatusJobId}
        onClose={() => setChangeStatusJobId(undefined)}
        onSave={() => {
          fetchJobSchedules({ date });
          setChangeStatusJobId(undefined);
        }}
        jobId={changeStatusJobId}
      />
      <CreateInvoiceModal
        open={!!invoiceJob}
        onClose={() => setInvoiceJob(undefined)}
        jobId={invoiceJob?.id}
        onSave={() => {
          fetchJobSchedules({ date });
          setInvoiceJob(undefined);
        }}
      />
      <CreateQuoteModal
        open={!!quoteJob}
        onClose={() => setQuoteJob(undefined)}
        jobId={quoteJob?.id}
        onSave={() => {
          fetchJobSchedules({ date });
          setQuoteJob(undefined);
        }}
      />
      <MultiChoiceModal
        open={!!createJobScheduleFromDrag}
        onClose={() => {
          setCreateJobScheduleFromDrag(undefined);
          fetchJobSchedules({ date });
        }}
        buttons={[
          { label: 'Move', onClick: localUpdateJobSchedule },
          { label: 'Copy', onClick: localCreateJobSchedule },
        ]}
        title={'Job Schedule Change'}
        message={'Pick an option'}
      />
    </div>
  );
}

export default Daily;
