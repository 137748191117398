import Layout from 'Base';
import React, { ReactElement } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './Summary.module.scss';
import useReactOperations from 'hooks/useReactOperations.hook';
import classNames from 'classnames';

function Summary(): ReactElement {
  const { navigateToSummaryOverviewPage, navigateToSummaryTeamsPage, navigateToSummaryEmployeesPage } =
    useReactOperations();

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Layout title="Summary">
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <div
            className={classNames(styles.item, currentPath.includes('overview') && styles.selected)}
            onClick={() => navigateToSummaryOverviewPage()}
          >
            Overview
          </div>
          <div
            className={classNames(styles.item, currentPath.includes('teams') && styles.selected)}
            onClick={() => navigateToSummaryTeamsPage()}
          >
            Teams
          </div>
          {false && (
            <div
              className={classNames(styles.item, currentPath.includes('employees') && styles.selected)}
              onClick={() => navigateToSummaryEmployeesPage()}
            >
              Employees
            </div>
          )}
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </Layout>
  );
}

export default Summary;
