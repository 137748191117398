import BaseModal from 'components/Base/BaseModal';
import Checkbox from 'components/Base/Checkbox';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import TextArea from 'components/Base/TextArea';
import TextField from 'components/Base/TextField';
import useJobs from 'hooks/useJobs.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  CreateCustomChanges,
  CreateEavesChanges,
  Job,
  CreateRequestJobChanges,
  RequestJobChangeValidation,
  CreateWindowChanges,
} from 'types';
import AddRemoveCheckboxes from './components/AddRemoveCheckboxes';
import styles from './RequestJobChangeModal.module.scss';

interface RequestJobChangeModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  job: Job;
}

function RequestJobChangeModal({ open, job, onClose, onSave }: RequestJobChangeModalProps): ReactElement {
  const [changeWindow, setChangeWindow] = useState<boolean>(false);
  const [changeEaves, setChangeEaves] = useState<boolean>(false);
  const [changeOther, setChangeOther] = useState<boolean>(false);
  const [windowChanges, setWindowChanges] = useState<CreateWindowChanges>();
  const [eavesChanges, setEavesChanges] = useState<CreateEavesChanges>();
  const [customChanges, setCustomChanges] = useState<CreateCustomChanges>();
  const [reason, setReason] = useState<string>();
  const [errors, setErrors] = useState<RequestJobChangeValidation>();
  const [validated, setValidated] = useState<boolean>(false);
  const [addIncludeTax, setAddIncludeTax] = useState<boolean>();
  const [removeIncludeTax, setRemoveIncludeTax] = useState<boolean>();

  const {
    windowSubTypes,
    eavesSubTypes,
    fetchJobSubTypes,
    requestChanges,
    jobErrorMessage,
    jobErrorTitle,
    resetJobError,
  } = useJobs();
  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError }],
  });

  const windowDetails = job.windowDetails;
  const eavesDetails = job.eavesDetails;

  const validate = () => {
    const errors: RequestJobChangeValidation = {};
    let validated = true;
    if (!reason) {
      validated = false;
      errors.reason = 'Please provide a reason';
    }
    if (!windowChanges && !eavesChanges && !customChanges && !addIncludeTax && !removeIncludeTax) {
      validated = false;
      errors.changes = 'Please make changes';
    }

    setErrors(errors);
    setValidated(true);
    return validated;
  };

  const changeWindowDetails = (key: keyof CreateWindowChanges, value: boolean | number | string) => {
    setWindowChanges((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const changeEavesDetails = (key: keyof CreateEavesChanges, value: boolean | number | string) => {
    setEavesChanges((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const changeCustomDetails = (key: keyof CreateCustomChanges, value: number) => {
    setCustomChanges((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const addRemoveWindows = (value: boolean) => {
    if (!value) setWindowChanges(undefined);
    setChangeWindow(value);
  };

  const addRemoveEaves = (value: boolean) => {
    if (!value) setEavesChanges(undefined);
    setChangeEaves(value);
  };

  const addRemoveCustom = (value: boolean) => {
    if (!value) setCustomChanges(undefined);
    setChangeOther(value);
  };

  const onRequest = async () => {
    if (!validate() || !reason) return;

    const data: CreateRequestJobChanges = {
      jobId: job.id,
      reason,
      windowChanges,
      eavesChanges,
      customChanges,
      addIncludeTax,
      removeIncludeTax,
    };
    try {
      await requestChanges(data);
    } catch (error) {
      console.error(error);
      return;
    }
    onSave();
  };

  useEffect(() => {
    fetchJobSubTypes();
  }, []);

  useEffect(() => {
    if (!validated) return;
    validate();
  }, [reason, windowChanges, eavesChanges, customChanges, windowChanges, addIncludeTax, removeIncludeTax]);

  return (
    <BaseModal
      open={open}
      title="Request Job Change"
      onClose={onClose}
      confirmTitle={'Request'}
      onConfirm={onRequest}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
    >
      <div>
        <Checkbox
          checked={changeWindow}
          onClick={(value: boolean) => addRemoveWindows(value)}
          label={'Change Window Job'}
        />
        <Checkbox
          checked={changeEaves}
          onClick={(value: boolean) => addRemoveEaves(value)}
          label={'Change Eavestrough Job'}
        />
        <Checkbox
          checked={changeOther}
          onClick={(value: boolean) => addRemoveCustom(value)}
          label={'Change Other Job'}
        />
      </div>
      {errors?.changes && <div className={styles.errorMessage}>{errors.changes}</div>}
      <AddRemoveCheckboxes
        add={!job?.includeTax}
        checked={!job.includeTax ? !!addIncludeTax : !!removeIncludeTax}
        title={'Include Tax'}
        changeCheckbox={(value: boolean) => (job.includeTax ? setRemoveIncludeTax(value) : setAddIncludeTax(value))}
      />

      {(changeEaves || changeWindow || changeOther || !addIncludeTax || !removeIncludeTax) && (
        <div className={styles.section}>
          <div className={styles.title}>Reason</div>
          <div className={styles.textarea}>
            <TextArea value={reason} setValue={setReason} name={'reason'} error={errors?.reason} hasError={true} />
          </div>
        </div>
      )}

      {changeWindow && (
        <div className={styles.section}>
          <div className={styles.title}>Window Changes</div>
          <Dropdown
            hint={'Select a Cleaning Type'}
            objectKey={'title'}
            items={windowSubTypes
              .filter((type) => type !== job.windowDetails?.subType)
              .map((type) => {
                return (
                  <DropdownItem
                    key={type.id}
                    id={type.id}
                    value={type.title}
                    onSelect={() => changeWindowDetails('subTypeId', type.id)}
                  />
                );
              })}
            selectedValue={windowSubTypes.find((type) => type.id === windowChanges?.subTypeId)}
          />
          <TextField
            name={'windowPrice'}
            value={windowChanges?.price}
            setValue={(value: string) => changeWindowDetails('price', value)}
            hint={'Change Price'}
            label={'Change Price'}
            type={'price'}
          />
          <AddRemoveCheckboxes
            add={!windowDetails?.railings}
            checked={windowDetails?.railings ? !!windowChanges?.removeRailings : !!windowChanges?.addRailings}
            title={'Railings'}
            changeCheckbox={(value: boolean) =>
              windowDetails?.railings
                ? changeWindowDetails('removeRailings', value)
                : changeWindowDetails('addRailings', value)
            }
          />
          <AddRemoveCheckboxes
            add={!windowDetails?.basement}
            checked={windowDetails?.basement ? !!windowChanges?.removeBasement : !!windowChanges?.addBasement}
            title={'Basement'}
            changeCheckbox={(value: boolean) =>
              windowDetails?.basement
                ? changeWindowDetails('removeBasement', value)
                : changeWindowDetails('addBasement', value)
            }
          />
          <AddRemoveCheckboxes
            add={!windowDetails?.skylights}
            checked={windowDetails?.skylights ? !!windowChanges?.removeSkylights : !!windowChanges?.addSkylights}
            title={'Skylights'}
            changeCheckbox={(value: boolean) =>
              windowDetails?.skylights
                ? changeWindowDetails('removeSkylights', value)
                : changeWindowDetails('addSkylights', value)
            }
          />
          <AddRemoveCheckboxes
            add={!windowDetails?.cranks}
            checked={windowDetails?.cranks ? !!windowChanges?.removeCranks : !!windowChanges?.addCranks}
            title={'Cranks'}
            changeCheckbox={(value: boolean) =>
              windowDetails?.cranks
                ? changeWindowDetails('removeCranks', value)
                : changeWindowDetails('addCranks', value)
            }
          />
          <AddRemoveCheckboxes
            add={!windowDetails?.sills}
            checked={windowDetails?.sills ? !!windowChanges?.removeSills : !!windowChanges?.addSills}
            title={'Sills'}
            changeCheckbox={(value: boolean) =>
              windowDetails?.sills ? changeWindowDetails('removeSills', value) : changeWindowDetails('addSills', value)
            }
          />
          <AddRemoveCheckboxes
            add={!windowDetails?.tracks}
            checked={windowDetails?.tracks ? !!windowChanges?.removeTracks : !!windowChanges?.addTracks}
            title={'Tracks'}
            changeCheckbox={(value: boolean) =>
              windowDetails?.tracks
                ? changeWindowDetails('removeTracks', value)
                : changeWindowDetails('addTracks', value)
            }
          />
          <AddRemoveCheckboxes
            add={!windowDetails?.thirdFloor}
            checked={windowDetails?.thirdFloor ? !!windowChanges?.removeThirdFloor : !!windowChanges?.addThirdFloor}
            title={'Third Floor'}
            changeCheckbox={(value: boolean) =>
              windowDetails?.thirdFloor
                ? changeWindowDetails('removeThirdFloor', value)
                : changeWindowDetails('addThirdFloor', value)
            }
          />
          <AddRemoveCheckboxes
            add={!windowDetails?.doors}
            checked={windowDetails?.doors ? !!windowChanges?.removeDoors : !!windowChanges?.addDoors}
            title={'Doors'}
            changeCheckbox={(value: boolean) =>
              windowDetails?.doors ? changeWindowDetails('removeDoors', value) : changeWindowDetails('addDoors', value)
            }
          />
          <AddRemoveCheckboxes
            add={!windowDetails?.screens}
            checked={windowDetails?.screens ? !!windowChanges?.removeScreens : !!windowChanges?.addScreens}
            title={'Screens'}
            changeCheckbox={(value: boolean) =>
              windowDetails?.screens
                ? changeWindowDetails('removeScreens', value)
                : changeWindowDetails('addScreens', value)
            }
          />
          <AddRemoveCheckboxes
            add={!windowDetails?.garage}
            checked={windowDetails?.garage ? !!windowChanges?.removeGarage : !!windowChanges?.addGarage}
            title={'Garage'}
            changeCheckbox={(value: boolean) =>
              windowDetails?.garage
                ? changeWindowDetails('removeGarage', value)
                : changeWindowDetails('addGarage', value)
            }
          />
          <AddRemoveCheckboxes
            add={!windowDetails?.solarPanels}
            checked={windowDetails?.solarPanels ? !!windowChanges?.removeSolarPanels : !!windowChanges?.addSolarPanels}
            title={'Solar Panels'}
            changeCheckbox={(value: boolean) =>
              windowDetails?.solarPanels
                ? changeWindowDetails('removeSolarPanels', value)
                : changeWindowDetails('addSolarPanels', value)
            }
          />
        </div>
      )}
      {changeEaves && (
        <div className={styles.section}>
          <div className={styles.title}>Eavestrough Changes</div>
          <Dropdown
            hint={'Select a Cleaning Type'}
            objectKey={'title'}
            items={eavesSubTypes
              .filter((type) => type !== eavesDetails?.subType)
              .map((type) => {
                return (
                  <DropdownItem
                    key={type.id}
                    id={type.id}
                    value={type.title}
                    onSelect={() => changeEavesDetails('subTypeId', type.id)}
                  />
                );
              })}
            selectedValue={eavesSubTypes.find((type) => type.id === eavesChanges?.subTypeId)}
          />
          <TextField
            name={'eavesPrice'}
            value={eavesChanges?.price}
            setValue={(value: string) => changeEavesDetails('price', value)}
            hint={'Change Price'}
            label={'Change Price'}
            type={'price'}
          />
          <AddRemoveCheckboxes
            add={!eavesDetails?.flatRoofs}
            checked={eavesDetails?.flatRoofs ? !!eavesChanges?.removeFlatRoofs : !!eavesChanges?.addFlatRoofs}
            title={'Flat Roofs'}
            changeCheckbox={(value: boolean) =>
              eavesDetails?.flatRoofs
                ? changeEavesDetails('removeFlatRoofs', value)
                : changeEavesDetails('addFlatRoofs', value)
            }
          />
          <AddRemoveCheckboxes
            add={!eavesDetails?.valleys}
            checked={eavesDetails?.valleys ? !!eavesChanges?.removeValleys : !!eavesChanges?.addValleys}
            title={'Valleys'}
            changeCheckbox={(value: boolean) =>
              eavesDetails?.valleys
                ? changeEavesDetails('removeValleys', value)
                : changeEavesDetails('addValleys', value)
            }
          />
          <AddRemoveCheckboxes
            add={!eavesDetails?.secondBuilding}
            checked={
              eavesDetails?.secondBuilding ? !!eavesChanges?.removeSecondBuilding : !!eavesChanges?.addSecondBuilding
            }
            title={'SecondBuilding'}
            changeCheckbox={(value: boolean) =>
              eavesDetails?.secondBuilding
                ? changeEavesDetails('removeSecondBuilding', value)
                : changeEavesDetails('addSecondBuilding', value)
            }
          />
        </div>
      )}
      {changeOther && (
        <div className={styles.section}>
          <div className={styles.title}>Custom Changes</div>
          <TextField
            name={'customPrice'}
            value={customChanges?.price}
            setValue={(value: number) => changeCustomDetails('price', value)}
            hint={'Change Price'}
            label={'Change Price'}
            type={'price'}
          />
        </div>
      )}
    </BaseModal>
  );
}

export default RequestJobChangeModal;
