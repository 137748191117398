import React, { ReactElement, ReactNode } from 'react';
import styles from './ColumnAddEdit.module.scss';

interface ColumnAddEditProps {
  children?: ReactNode;
}
function ColumnAddEdit({ children }: ColumnAddEditProps): ReactElement {
  return <div className={styles.column}>{children}</div>;
}

export default ColumnAddEdit;
