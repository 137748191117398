import React, { ReactElement, useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Clients from 'pages/Clients';
import Addresses from 'pages/Addresses/Addresses';
import Calendar from 'pages/Calendar';
import Teams from 'pages/Teams';
import Client from 'pages/Client';
import Users from 'pages/Users';
import Jobs from 'pages/Jobs';
import Job from 'pages/Job';
import DailyJobs from 'pages/DailyJobs';
import TeamsCalendar from 'pages/TeamsCalendar';
import Team from 'pages/Team';
import User from 'pages/User';
import Address from 'pages/Address/Address';
import ClockInOut from 'pages/ClockInOut';
import Login from 'pages/Login';
import JobSchedule from 'pages/JobSchedule';
import { UserContext } from 'context/userProfile';
import useScreenOperations from 'hooks/useScreenOperations.hook';
import SubmitQuote from 'pages/SubmitQuote';
import Summary from 'pages/Summary';
import Overview from 'pages/Summary/components/Overview';
import TeamsSummary from 'pages/Summary/components/Teams';
import EmployeesSummary from 'pages/Summary/components/Employees';
import Unsubscribe from 'pages/Unsubscribe';
import Subscribe from 'pages/Subscribe';
import Settings from 'pages/Settings';
import SettingsGeneral from 'pages/Settings/pages/General';
import SettingsCommunication from 'pages/Settings/pages/Communication';
import SettingsInvoiceQuote from 'pages/Settings/pages/InvoiceQuote';
import Home from 'pages/Home';
import NewAccount from 'pages/NewAccount';
import ConfirmJob from 'pages/ConfirmJob';

function Routing(): ReactElement {
  const { user } = useContext(UserContext);

  const { isPhone } = useScreenOperations();

  const goToClients = (): ReactElement => (user?.permissions.pages.clients && !isPhone ? <Clients /> : <Login />);
  const goToClient = (): ReactElement => (user?.permissions.pages.clients && !isPhone ? <Client /> : <Login />);
  const goToAddresses = (): ReactElement => (user?.permissions.pages.addresses && !isPhone ? <Addresses /> : <Login />);
  const goToAddress = (): ReactElement => (user?.permissions.pages.address && !isPhone ? <Address /> : <Login />);
  const goToTeams = (): ReactElement => (user?.permissions.pages.teams && !isPhone ? <Teams /> : <Login />);
  const goToTeam = (): ReactElement => (user?.permissions.pages.team && !isPhone ? <Team /> : <Login />);
  const goToTeamsCalendar = (): ReactElement =>
    user?.permissions.pages.teamsCalendar && !isPhone ? <TeamsCalendar /> : <Login />;
  const goToUsers = (): ReactElement => (user?.permissions.pages.users && !isPhone ? <Users /> : <Login />);
  const goToUser = (): ReactElement => (user?.permissions.pages.user && !isPhone ? <User /> : <Login />);
  const goToDailyCalendar = (monthly: boolean): ReactElement =>
    user?.permissions.pages.dailyCalendar && !isPhone ? <Calendar monthly={monthly} /> : <Login />;
  const goToJobs = (): ReactElement => (user?.permissions.pages.jobs && !isPhone ? <Jobs /> : <Login />);
  const goToJob = (): ReactElement => (user?.permissions.pages.job && !isPhone ? <Job /> : <Login />);
  const goToJobSchedule = (): ReactElement => (user?.permissions.pages.jobSchedule ? <JobSchedule /> : <Login />);
  const goToDailyJobs = (): ReactElement => (user?.permissions.pages.dailyJobs ? <DailyJobs /> : <Login />);
  const goToClockInOut = (): ReactElement => (user?.permissions.pages.clockInOut ? <ClockInOut /> : <Login />);
  const goToSubmitQuote = (): ReactElement => (user?.permissions.pages.clockInOut ? <SubmitQuote /> : <Login />);
  const goToSummaryPage = (): ReactElement => (user?.permissions.pages.summary ? <Summary /> : <Login />);
  const goToSettingsPage = (): ReactElement =>
    user?.permissions.pages.settings && !isPhone ? <Settings /> : <Login />;
  const goToUnsubscribePage = (): ReactElement => <Unsubscribe />;
  const goToSubscribePage = (): ReactElement => <Subscribe />;
  const goToConfirmPage = (): ReactElement => <ConfirmJob />;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="createAccount" element={<NewAccount />} />
        <Route path="/clients" element={goToClients()} />
        <Route path="/addresses" element={goToAddresses()} />
        <Route path="/dayCalendar/:date/" element={goToDailyCalendar(false)} />
        <Route path="/monthCalendar/:date/" element={goToDailyCalendar(true)} />
        <Route path="/teams" element={goToTeams()} />
        <Route path="/teamsCalendar/:date" element={goToTeamsCalendar()} />
        <Route path="/users" element={goToUsers()} />
        <Route path="/jobs" element={goToJobs()} />
        <Route path="/client/:clientId" element={goToClient()} />
        <Route path="/team/:teamId" element={goToTeam()} />
        <Route path="/user/:userId" element={goToUser()} />
        <Route path="/address/:addressId" element={goToAddress()} />
        <Route path="/job/:jobId" element={goToJob()} />
        <Route path="/jobSchedule/:jobScheduleId" element={goToJobSchedule()} />
        <Route path="/dailyJobs/:date" element={goToDailyJobs()} />
        <Route path="/clockInOut/:date" element={goToClockInOut()} />
        <Route path="/submitQuote/:jobId" element={goToSubmitQuote()} />
        <Route path="/unsubscribe" element={goToUnsubscribePage()} />
        <Route path="/subscribe" element={goToSubscribePage()} />
        <Route path="/confirm" element={goToConfirmPage()} />
        <Route path="/summary/*" element={goToSummaryPage()}>
          <Route path="overview" element={<Overview />} />
          <Route path="teams" element={<TeamsSummary />} />
          <Route path="employees" element={<EmployeesSummary />} />
          <Route path="*" element={<Navigate to="/summary/overview" replace />} />
        </Route>
        <Route path="/settings/*" element={goToSettingsPage()}>
          <Route path="general" element={<SettingsGeneral />} />
          <Route path="invoiceQuote" element={<SettingsInvoiceQuote />} />
          <Route path="communication" element={<SettingsCommunication />} />
          <Route path="*" element={<Navigate to="/settings/general" replace />} />
        </Route>
        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Routing;
