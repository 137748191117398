import React, { ReactElement } from 'react';
import { DailyAllJobSchedule } from 'types';
import styles from './JobSummaries.module.scss';
import classNames from 'classnames';
import {
  compareEqualDate,
  compareFormats,
  dateFormats,
  formatDate,
  formatFromMonth,
  isPast,
  isSameDay,
} from 'lib/date';
import JobScheduleSummary from '../JobScheduleSummary';
import moment from 'moment';

interface JobSummariesProps {
  jobSummaries: Array<DailyAllJobSchedule>;
  date: string;
  month: number;
  onClick: () => void;
  showProductionRemaining: boolean;
}

function JobSummaries({
  jobSummaries,
  date,
  month,
  onClick,
  showProductionRemaining,
}: JobSummariesProps): ReactElement {
  const sameMonth = compareEqualDate(date, formatFromMonth(month, moment(date).year()), compareFormats.month);

  const getPrice = (summary: DailyAllJobSchedule) => {
    if (showProductionRemaining) {
      return isPast(date) ? 0 : summary.remainingProduction;
    }
    return summary.totalProduction;
  };

  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.item,
        !sameMonth && styles.nonMonth,
        sameMonth && isSameDay(date) && styles.currentDay
      )}
    >
      <div className={styles.dayContainer}>
        <div className={styles.day}>{formatDate(date, dateFormats.dayOnly)}</div>
      </div>
      {jobSummaries.map((summary, index) => {
        return (
          <JobScheduleSummary
            key={index}
            date={date}
            teamId={summary.teamId}
            teamColour={summary.teamColour}
            total={getPrice(summary)}
          />
        );
      })}
    </div>
  );
}

export default JobSummaries;
