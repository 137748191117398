import Layout from 'Base';
import React, { ReactElement, useState } from 'react';
import styles from './Settings.module.scss';
import useReactOperations from 'hooks/useReactOperations.hook';
import classNames from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';

type NavigationItem = {
  id: number;
  title: string;
  onClick: () => void;
  path: string;
};

function Settings(): ReactElement {
  const location = useLocation();
  const currentPath = location.pathname;

  const { navigateToSettingsGeneralPage, navigateToSettingsCommunicationPage, navigateToSettingsInvoiceQuotePage } =
    useReactOperations();

  const navigation: Array<NavigationItem> = [
    { id: 0, title: 'General', onClick: navigateToSettingsGeneralPage, path: 'general' },
    { id: 1, title: 'Invoice / Quote', onClick: navigateToSettingsInvoiceQuotePage, path: 'invoiceQuote' },
    { id: 2, title: 'Communication', onClick: navigateToSettingsCommunicationPage, path: 'communication' },
  ];

  const startingPage = navigation.find((p) => currentPath.includes(p.path));
  const [page, setPage] = useState<NavigationItem>(startingPage || navigation[0]);

  const navigationClick = (page: NavigationItem) => {
    setPage(page);
    page.onClick();
  };
  return (
    <Layout title="Settings">
      <div className={styles.container}>
        <div className={styles.navigation}>
          {navigation.map((p) => {
            return (
              <div
                key={p.id}
                className={classNames(styles.item, p.id === page.id && styles.selected)}
                onClick={() => navigationClick(p)}
              >
                {p.title}
              </div>
            );
          })}
        </div>
        <div className={styles.settings}>
          <Outlet />
        </div>
      </div>
    </Layout>
  );
}

export default Settings;
