import BaseModal from 'components/Base/BaseModal';
import Checkbox from 'components/Base/Checkbox';
import { UserContext } from 'context/userProfile';
import useJobSchedules from 'hooks/useJobSchedules.hook';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styles from './ConfirmJobScheduleMembersResetModal.module.scss';

interface ConfirmJobScheduleMembersResetModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  id?: number;
}

function ConfirmJobScheduleMembersResetModal({
  open,
  id,
  onClose,
  onSave,
}: ConfirmJobScheduleMembersResetModalProps): ReactElement {
  const [jobCompleted, setJobCompleted] = useState<boolean>(false);
  const [selectedMemberIds, setSelectedMemberIds] = useState<Array<number>>([]);
  const { user } = useContext(UserContext);

  const { fetchJobSchedule, jobSchedule, reset } = useJobSchedules();

  const onClick = (id: number, value: boolean) => {
    const members = [...selectedMemberIds];
    if (value) {
      members.push(id);
    } else {
      const membersIndex = members.findIndex((memberId) => memberId === id);
      members.splice(membersIndex, 1);
    }
    setSelectedMemberIds(members);
  };

  const resetJobSchedule = async () => {
    if (!id) return;
    await reset(id, selectedMemberIds, jobCompleted);
    onSave();
  };

  useEffect(() => {
    if (!jobSchedule) return;
    const memberIds = jobSchedule?.team.members.map((member) => member.id);
    setSelectedMemberIds(memberIds);
  }, [jobSchedule]);

  useEffect(() => {
    if (!id || !open) return;
    fetchJobSchedule(id);
  }, [open]);

  return (
    <BaseModal title={'Reset'} open={open} onClose={onClose} onConfirm={resetJobSchedule}>
      <div className={styles.container}>
        <Checkbox label="Job Completed" onClick={setJobCompleted} checked={jobCompleted} />
        {jobSchedule?.team.members.map((member) => {
          return (
            <Checkbox
              key={member.id}
              label={member.fullName}
              onClick={(value) => onClick(member.id, value)}
              checked={selectedMemberIds.includes(member.id)}
              disabled={member.id === user?.id}
            />
          );
        })}
        <p className={styles.warning}>
          Warning! This will cause the loss of all tracked times for all selected staff members
        </p>
      </div>
    </BaseModal>
  );
}

export default ConfirmJobScheduleMembersResetModal;
