import Layout from 'Base';
import React, { ReactElement, useEffect, useState } from 'react';
import styles from './Calendar.module.scss';
import Monthly from './components/Monthly';
import Daily from './components/Daily';
import AddEditJobModal from 'components/Modals/AddEditJobModal';
import AddEditJobScheduleModal from 'components/Modals/AddEditJobScheduleModal';
import JobSummaryModal from 'components/Modals/JobScheduleSummaryModal';
import { CustomAddJobScheduleDetails, MonthlyCalendarProps } from 'types';
import Button from 'components/Base/Button/Button.view';
import DateSelector from 'components/Base/DateSelector';
import { dateFormats, sqlToday } from 'lib/date';
import ToggleButton from 'components/Base/ToggleButton';
import { useLocation, useParams } from 'react-router-dom';
import useReactOperations from 'hooks/useReactOperations.hook';
import moment from 'moment';
import FindJobScheduleOpeningsModal from 'components/Modals/FindJobScheduleOpeningsModal';
import { SnackBarType } from 'components/SnackBar/SnackBar';

interface CalendarProps {
  monthly: boolean;
  jobId?: number;
}

function Calendar({ monthly }: CalendarProps): ReactElement {
  const location = useLocation();
  const props = location.state as MonthlyCalendarProps;

  const { date } = useParams();

  const [openAddEditJobModal, setOpenAddEditJobModal] = useState<boolean>(false);
  const [openAddEditJobScheduleModal, setOpenAddEditScheduleJobModal] = useState<boolean>(false);
  const [selectedJobScheduleId, setSelectedJobScheduleId] = useState<number>();
  const [localDate, setLocalDate] = useState<string>(date || sqlToday());
  const [showProductionRemaining, setShowProductionRemaining] = useState<boolean>(false);
  const [refreshDaily, setRefreshDaily] = useState<boolean>(false);
  const [refreshMonthly, setRefreshMonthly] = useState<boolean>(false);
  const [openFindAvailabilty, setOpenFindAvailability] = useState<boolean>(false);
  const [customJobScheduleDetails, setCustomJobScheduleDetails] = useState<CustomAddJobScheduleDetails>();
  const [snackBarMessage, setSnackBarMessage] = useState<string>();
  const [jobId, setJobId] = useState<number | undefined>(props ? props.jobId : undefined);

  const { navigateToDailyCalendarPage, navigateToMonthCalendarPage } = useReactOperations();

  const onSaveSuccess = () => {
    if (!monthly) {
      setRefreshDaily(true);
    } else {
      setRefreshMonthly(true);
    }
    setOpenAddEditScheduleJobModal(false);
    setCustomJobScheduleDetails(undefined);
    setOpenAddEditJobModal(false);
    setOpenFindAvailability(false);
    setJobId(undefined);
  };

  const onJobScheduleCreation = (date?: string) => {
    onSaveSuccess();
    setSnackBarMessage(`Job Schedule Created On ${moment(date).format(dateFormats.abbreviatedPretty)}`);
  };

  const customAddJobScheduleSettings = (startTime: string, teamId: number) => {
    setCustomJobScheduleDetails({ startTime, teamId });
    setOpenAddEditScheduleJobModal(true);
  };

  const changeMonthTypes = () => {
    if (monthly) {
      navigateToDailyCalendarPage(localDate);
    } else {
      navigateToMonthCalendarPage(localDate);
    }
  };

  useEffect(() => {
    if (monthly) {
      navigateToMonthCalendarPage(localDate);
    } else {
      navigateToDailyCalendarPage(localDate);
    }
  }, [localDate]);

  useEffect(() => {
    if (!jobId) return;
    setOpenFindAvailability(true);
  }, [jobId]);

  return (
    <Layout
      title={moment(localDate).format(monthly ? dateFormats.monthFullYearFull : dateFormats.abbreviatedPretty)}
      snackBarMessage={snackBarMessage}
      showSnackBar={!!snackBarMessage}
      closeSnackBar={() => setSnackBarMessage(undefined)}
      snackBarType={SnackBarType.SUCCESS}
    >
      <div className={styles.container}>
        <section id="toolbar" className={styles.toolbar}>
          <DateSelector date={localDate} setDate={setLocalDate} includeDaySwitching={!monthly} />
          <Button title={'New Job'} onClick={() => setOpenAddEditJobModal(true)} />
          <Button title={'Schedule Job'} onClick={() => setOpenAddEditScheduleJobModal(true)} />
          <Button title={monthly ? 'Daily' : 'Monthly'} onClick={changeMonthTypes} />
          <Button title={'Find Openings'} onClick={() => setOpenFindAvailability(true)} />
          {monthly && (
            <>
              <ToggleButton
                leftTitle={'Production'}
                rightTitle={'Remaining'}
                setRightSelected={setShowProductionRemaining}
                value={showProductionRemaining}
              />
            </>
          )}
        </section>

        <div className={styles.calendar}>
          {!monthly ? (
            <Daily
              setSelectedJobScheduleId={setSelectedJobScheduleId}
              date={localDate}
              resetRefresh={() => setRefreshDaily(false)}
              refreshData={refreshDaily}
              setCustomJobScheduleDetails={customAddJobScheduleSettings}
            />
          ) : (
            <Monthly
              date={localDate}
              switchFormat={(date: string) => navigateToDailyCalendarPage(date)}
              resetRefresh={() => setRefreshMonthly(false)}
              showProductionRemaining={showProductionRemaining}
              refreshData={refreshMonthly}
            />
          )}
        </div>

        <JobSummaryModal
          open={!!selectedJobScheduleId}
          onClose={() => setSelectedJobScheduleId(undefined)}
          jobScheduleId={selectedJobScheduleId}
          onEditJobScheduleSave={onSaveSuccess}
        />
        <AddEditJobModal
          open={openAddEditJobModal}
          onClose={() => {
            setOpenAddEditJobModal(false);
          }}
          onSave={onSaveSuccess}
        />
        <AddEditJobScheduleModal
          open={openAddEditJobScheduleModal}
          onClose={() => {
            setCustomJobScheduleDetails(undefined);
            setOpenAddEditScheduleJobModal(false);
          }}
          onSave={onJobScheduleCreation}
          date={localDate}
          customDetails={customJobScheduleDetails}
          onDelete={() => {
            setOpenAddEditScheduleJobModal(false);
            setCustomJobScheduleDetails(undefined);
          }}
        />
        <FindJobScheduleOpeningsModal
          open={openFindAvailabilty}
          onClose={() => {
            setOpenFindAvailability(false);
            setJobId(undefined);
          }}
          onSave={onJobScheduleCreation}
          jobId={jobId}
        />
      </div>
    </Layout>
  );
}

export default Calendar;
