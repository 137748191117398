import React, { ReactElement, ReactNode } from 'react';
import styles from './InfoCard.module.scss';
import Edit from '@material-ui/icons/Edit';
import LoadingSpinner from 'components/LoadingSpinner';
import Invoice from '@material-ui/icons/Receipt';
import FormatQuote from '@material-ui/icons/FormatQuote';

type Action = {
  type: string;
  onClick: () => void;
};

interface InfoCardProps {
  title?: string;
  children?: ReactNode;
  includeEdit?: boolean;
  onEdit?: () => void;
  loading?: boolean;
  actions?: Array<Action>;
}

function InfoCard({ children, title, loading, onEdit, actions }: InfoCardProps): ReactElement {
  const getActions = () => {
    return actions?.map((action) => {
      switch (action.type) {
        case 'invoice':
          return <Invoice className={styles.edit} onClick={action.onClick} key={'invoice'} />;
        case 'quote':
          return <FormatQuote className={styles.edit} onClick={action.onClick} key={'quote'} />;
        default:
          return <></>;
      }
    });
  };
  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
      <div className={styles.title}>{title}</div>
      <div className={styles.info}>{children}</div>
      {actions && getActions()}
      {!!onEdit && <Edit className={styles.edit} onClick={onEdit} />}
    </div>
  );
}

export default InfoCard;
