import TypeTitleBar from 'components/AddEditSetup/components/TypeTitleBar';
import BaseModal from 'components/Base/BaseModal';
import TextField from 'components/Base/TextField';
import useJobs from 'hooks/useJobs.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import { dateFormats, formatDate } from 'lib/date';
import { formatPhone, formatPrice } from 'lib/formatting';
import { getEavesExtras, getWindowExtras } from 'lib/jobs';
import React, { ReactElement, useEffect, useState } from 'react';
import { CreateQuote, CreateQuoteValidation, EmailContact, PhoneContact } from 'types';
import styles from './CreateQuoteModal.module.scss';
import MultiChoiceModal from '../MultiChoiceModal';
import { MultiChoiceButton } from '../MultiChoiceModal/MultiChoiceModal';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import Checkbox from 'components/Base/Checkbox';
import useQuotes from 'hooks/useQuotes.hook';
import Button from 'components/Base/Button';

interface CreateQuoteModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  jobId?: number;
}

function CreateQuoteModal({ open, onClose, jobId, onSave }: CreateQuoteModalProps): ReactElement {
  const [showClientDetails, setShowClientDetails] = useState<boolean>(true);
  const [clientName, setClientName] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [emails, setEmails] = useState<Array<EmailContact>>([]);
  const [displayEmail, setDisplayEmail] = useState<EmailContact>();
  const [phone, setPhone] = useState<PhoneContact>();
  const [errors, setErrors] = useState<CreateQuoteValidation>();
  const [validated, setValidated] = useState<boolean>(false);
  const [openCreateQuoteConfirmation, setOpenCreateQuoteConfirmation] = useState<boolean>(false);

  const { job, fetchJob, jobErrorMessage, jobErrorTitle, resetJobError } = useJobs();
  const { createQuote, previewQuote } = useQuotes();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError }],
  });

  const validate = () => {
    let success = true;
    const errors: CreateQuoteValidation = {};

    setValidated(true);

    if (!clientName) {
      success = false;
      errors.clientName = 'Please provide a name';
    }
    if (!address) {
      success = false;
      errors.address = 'Please provide an address';
    }

    setErrors(success ? undefined : errors);
    return success;
  };

  const onCreateQuote = async (emailQuote: boolean) => {
    if (!validate() || !job) return;

    const data: CreateQuote = {
      jobId: job?.id,
      client: {
        name: clientName,
        address,
        emailId: displayEmail?.id,
        phoneId: phone?.id,
      },
      emailIds: emailQuote && emails.length ? emails.map((e) => e.id) : undefined,
    };

    try {
      await createQuote(data);
      onSave();
    } catch (error) {
      console.error(error);
    }
  };

  const resetData = () => {
    setShowClientDetails(true);
    setClientName(undefined);
    setAddress(undefined);
    setEmails([]);
    setPhone(undefined);
    setErrors(undefined);
    setValidated(false);
    setOpenCreateQuoteConfirmation(false);
  };

  const getMultiButtons = (): Array<MultiChoiceButton> => {
    const buttons: Array<MultiChoiceButton> = [{ label: 'Create Quote', onClick: () => onCreateQuote(false) }];

    if (emails.length) buttons.push({ label: 'Create Quote And Send', onClick: () => onCreateQuote(true) });
    return buttons;
  };

  const handleEmails = (email: EmailContact) => {
    const existingEmailIndex = emails.findIndex((e) => (e.id = email.id));
    const temp = [...emails];
    if (existingEmailIndex === -1) {
      temp.push(email);
    } else {
      temp.splice(existingEmailIndex, 1);
    }
    setEmails(temp);
  };

  const preview = async () => {
    if (!validate() || !job) return;

    const data: CreateQuote = {
      jobId: job?.id,
      client: {
        name: clientName,
        address,
        emailId: displayEmail?.id,
        phoneId: phone?.id,
      },
    };

    try {
      await previewQuote(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!open || !jobId) {
      resetData();
      return;
    }
    fetchJob(jobId);
  }, [jobId, open]);

  useEffect(() => {
    if (!job || !job.id) return;
    setAddress(job?.address?.fullAddress);
    setClientName(job?.client?.fullName);
    setDisplayEmail(job?.client?.emails.find((email) => email.main));
    setPhone(job?.client?.phones.find((phone) => phone.main));
  }, [job]);

  useEffect(() => {
    if (!validated) return;
    validate();
  }, [clientName, address]);

  return (
    <BaseModal
      open={open}
      title="Create Quote"
      onClose={onClose}
      fullScreen={true}
      onConfirm={() => setOpenCreateQuoteConfirmation(true)}
      confirmTitle={'Create Quote'}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
    >
      <div className={styles.container}>
        <div className={styles.details}>
          <div className={styles.clientDetails}>
            <TypeTitleBar
              onExpandedCollaspe={() => setShowClientDetails((prev) => !prev)}
              expanded={showClientDetails}
              title={'Client Details'}
            />
            {showClientDetails && (
              <div className={styles.divisionContent}>
                <div className={styles.titledRow}>
                  <div className={styles.rowTitle}>Client Name:</div>
                  <div className={styles.content}>
                    <TextField
                      name={'name'}
                      value={clientName}
                      setValue={(value: string) => setClientName(value)}
                      hasError={true}
                      error={errors?.clientName}
                    />
                  </div>
                </div>
                <div className={styles.titledRow}>
                  <div className={styles.rowTitle}>Address:</div>
                  <div className={styles.content}>
                    <TextField
                      name={'address'}
                      value={address}
                      setValue={(value: string) => setAddress(value)}
                      hasError={true}
                      error={errors?.address}
                    />
                  </div>
                </div>
                <div className={styles.titledRow}>
                  <div className={styles.rowTitle}>Email:</div>
                  <div className={styles.rowTitle}>Display Email:</div>
                  {job?.client.emails && (
                    <Dropdown
                      hint={'Select an email'}
                      items={job.client.emails.map((email) => {
                        return (
                          <DropdownItem
                            key={email.id}
                            id={email.id}
                            value={email.email}
                            onSelect={() => setDisplayEmail(email)}
                          />
                        );
                      })}
                      selectedValue={displayEmail?.email}
                    />
                  )}
                </div>
                <div className={styles.titledRow}>
                  <div className={styles.rowTitle}>Phone:</div>
                  {job?.client.phones && (
                    <Dropdown
                      hint={'Select an phone number'}
                      items={job.client.phones.map((phone) => {
                        return (
                          <DropdownItem
                            key={phone.id}
                            id={phone.id}
                            value={phone.number}
                            onSelect={() => setPhone(phone)}
                          />
                        );
                      })}
                      selectedValue={
                        phone ? formatPhone(job.client.phones.find((p) => p.id === phone?.id)?.number) : undefined
                      }
                    />
                  )}
                  <div>Send To:</div>
                  {job?.client.emails.map((email) => {
                    return (
                      <Checkbox
                        key={email.id}
                        checked={!!emails.find((e) => e.id === email.id)}
                        onClick={() => handleEmails(email)}
                        label={email.email}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={styles.summary}>
          <div className={styles.clientDetails}>
            <div className={styles.mainDivision}>
              <div className={styles.sectionTitle}>Quote Summary</div>
            </div>

            <div className={styles.divisionContent}>
              {showClientDetails && (
                <div className={styles.divisionContent}>
                  <div className={styles.titledRow}>
                    <div className={styles.rowTitle}>Client Name:</div>
                    <div className={styles.rowItem}>{clientName}</div>
                  </div>
                  <div className={styles.titledRow}>
                    <div className={styles.rowTitle}>Email:</div>
                    <div className={styles.rowItem}>{displayEmail?.email}</div>
                  </div>
                  <div className={styles.titledRow}>
                    <div className={styles.rowTitle}>Address:</div>
                    <div className={styles.rowItem}>{address}</div>
                  </div>
                  <div className={styles.titledRow}>
                    <div className={styles.rowTitle}>Phone:</div>
                    <div className={styles.rowItem}>{formatPhone(phone?.number)}</div>
                  </div>

                  <div className={styles.titledRow}>
                    <div className={styles.rowTitle}>Start Date:</div>
                    <div className={styles.rowItem}>{formatDate(job?.startDate || '', dateFormats.fullPretty)}</div>
                  </div>
                  <div className={styles.titledRow}>
                    <div className={styles.rowTitle}>End Date:</div>
                    <div className={styles.rowItem}>{formatDate(job?.endDate || '', dateFormats.fullPretty)}</div>
                  </div>
                  <div className={styles.titledRow}>
                    <div className={styles.rowTitle}>Cleaners:</div>
                    <p className={styles.rowItem}>{job?.members?.map((member) => member.fullName).join(', ')}</p>
                  </div>
                </div>
              )}
              {job?.windowQuotes && (
                <>
                  <div className={styles.summarySpacer} />
                  <div className={styles.summaryTitle}>Windows</div>
                  {job?.windowQuotes.map((data) => {
                    return (
                      <>
                        <div className={styles.titledRow}>
                          <div className={styles.rowTitle}>Description:</div>
                          <p className={styles.rowItem}>{data.description}</p>
                        </div>
                        <div className={styles.titledRow}>
                          <div className={styles.rowTitle}>Extra:</div>
                          <p className={styles.rowItem}>{getWindowExtras(data)}</p>
                        </div>

                        <div className={styles.titledRow}>
                          <div className={styles.rowTitle}>Price:</div>
                          <p className={styles.rowItem}>{formatPrice(data.price, true)}</p>
                        </div>
                      </>
                    );
                  })}
                </>
              )}

              {job?.eavesQuotes && (
                <>
                  <div className={styles.summarySpacer} />
                  <div className={styles.summaryTitle}>Eaves</div>
                  {job?.eavesQuotes.map((data) => {
                    return (
                      <>
                        <div className={styles.titledRow}>
                          <div className={styles.rowTitle}>Description:</div>
                          <p className={styles.rowItem}>{data.description}</p>
                        </div>
                        <div className={styles.titledRow}>
                          <div className={styles.rowTitle}>Extra:</div>
                          <p className={styles.rowItem}>{getEavesExtras(data)}</p>
                        </div>
                        <div className={styles.titledRow}>
                          <div className={styles.rowTitle}>Price:</div>
                          <p className={styles.rowItem}>{formatPrice(data.price, true)}</p>
                        </div>
                      </>
                    );
                  })}
                </>
              )}

              {job?.otherQuotes && (
                <>
                  <div className={styles.summarySpacer} />
                  <div className={styles.summaryTitle}>Other</div>
                  {job?.otherQuotes.map((data) => {
                    return (
                      <>
                        <div className={styles.titledRow}>
                          <div className={styles.rowTitle}>Description:</div>
                          <p className={styles.rowItem}>{data.description}</p>
                        </div>
                        <div className={styles.titledRow}>
                          <div className={styles.rowTitle}>Price:</div>
                          <p className={styles.rowItem}>{formatPrice(data.price, true)}</p>
                        </div>
                      </>
                    );
                  })}
                </>
              )}

              <div className={styles.summarySpacer} />
              <div className={styles.notesSummary}>
                <div className={styles.rowTitle}>Notes:</div>
              </div>

              <div className={styles.summarySpacer} />
              <div className={styles.titledRow}>
                <div className={styles.rowTitle}>Sub Total:</div>
                <p className={styles.rowItem}>{formatPrice(job?.quoteSubtotal, true)}</p>
              </div>

              <div className={styles.titledRow}>
                <div className={styles.rowTitle}>Tax:</div>
                <p className={styles.rowItem}>{formatPrice(job?.quoteTax, true)}</p>
              </div>
              <div className={styles.titledRow}>
                <div className={styles.rowTitle}>Total:</div>
                <p className={styles.rowItem}>{formatPrice(job?.quoteTotal, true)}</p>
              </div>
            </div>
          </div>
          <div className={styles.preview}>
            <Button title="Preview" onClick={preview} border={true} />
          </div>
        </div>
      </div>
      <MultiChoiceModal
        open={openCreateQuoteConfirmation}
        title={'Send Quote'}
        message={
          emails.length ? `Confirm sending quote to  ${emails.map((e) => e.email).join(',')}` : 'No email provided'
        }
        onClose={() => setOpenCreateQuoteConfirmation(false)}
        buttons={getMultiButtons()}
      />
    </BaseModal>
  );
}

export default CreateQuoteModal;
