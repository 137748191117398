import React, { MouseEvent, ReactElement, useEffect, useState } from 'react';
import styles from './Dropdown.module.scss';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';

interface DropdownProps {
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedValue: any;
  objectKey?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  noSelectValue?: any;
  disabled?: boolean;
  error?: string;
  hint?: string;
  onRemove?: () => void;
}

function Dropdown({
  items,
  label,
  selectedValue,
  noSelectValue,
  onRemove,
  error,
  objectKey,
  hint,
  disabled,
}: DropdownProps): ReactElement {
  const [isActive, setIsActive] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [values, setValues] = useState<Array<any>>([]);
  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsActive(!isActive);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pageClickEvent = (e: any) => {
      if (e.target.id !== 'menu') setIsActive(false);
    };

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  useEffect(() => {
    let localValues = [...items];
    if (noSelectValue) localValues = [noSelectValue, ...items];
    setValues(localValues);
  }, [items]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getValue = (selectedValue: any): string => {
    if (selectedValue) {
      if (objectKey) return selectedValue[objectKey];
      return selectedValue;
    }
    return hint || '';
  };

  return (
    <div className={styles.container}>
      {label && <label>{label}</label>}
      <div className={styles.details}>
        <div className={styles.menuContainer}>
          <div className={styles.removeable}>
            <button
              disabled={disabled}
              placeholder={hint}
              onClick={onClick}
              className={classNames(styles.menuTrigger, !!error && styles.btnError)}
              id={'menu'}
            >
              <span className={classNames(!selectedValue && styles.hint)} id={'menu'}>
                {getValue(selectedValue)}
              </span>
            </button>
            {onRemove && (
              <div onClick={onRemove}>
                <CloseIcon />
              </div>
            )}
          </div>

          {<p className={styles.errorMessage}>{error}</p>}
          <div className={classNames(styles.menu, isActive && styles.active, error && styles.error)}>
            <ul>{values}</ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
