import Button from 'components/Base/Button';
import Checkbox from 'components/Base/Checkbox';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import TextField from 'components/Base/TextField';
import TextAreaCountdown from 'components/TextAreaCountdown';
import TextFieldCountdown from 'components/TextFieldCountdown';
import { dateFormats, formatDate } from 'lib/date';
import { formatPrice } from 'lib/formatting';
import { MAX_DESCRIPTION_SIZE, MAX_NOTES_SIZE } from 'lib/jobs';
import React, { ReactElement, useEffect, useState } from 'react';
import { CreateEavestrough, CreateEavesValidation, EavestroughDetails, JobSubType } from 'types';
import styles from '../TypeSetup.module.scss';

interface EavesSetupProps {
  subTypes: Array<JobSubType>;
  details?: CreateEavestrough;
  addTitle?: string;
  changeDetails: (key: keyof CreateEavestrough, value: boolean | JobSubType | number | string | undefined) => void;
  setSubType: (type: JobSubType) => void;
  onAdd?: () => void;
  isQuote?: boolean;
  previousJobs?: Array<EavestroughDetails>;
  previousQuotes?: Array<EavestroughDetails>;
  errors?: CreateEavesValidation;
  includePreviousWork?: boolean;
}

function EavesSetup({
  subTypes,
  details,
  addTitle,
  changeDetails,
  setSubType,
  onAdd,
  isQuote,
  errors,
  previousJobs,
  previousQuotes,
  includePreviousWork,
}: EavesSetupProps): ReactElement {
  const [selectedPreviousJob, setSelectedPreviousJob] = useState<EavestroughDetails>();
  const [selectedQuote, setSelectedQuote] = useState<EavestroughDetails>();

  const previousSummary = (data?: EavestroughDetails) => {
    return (
      data && (
        <div>
          {`${formatDate(data?.createdAt, dateFormats.abbreviatedPretty)}: ${data?.subType.title} - ${formatPrice(
            data?.price,
            true
          )}`}
        </div>
      )
    );
  };

  const getQuoteValue = () => {
    if (previousQuotes && !previousQuotes.length) return 'No Quotes Found';
    return previousSummary(selectedQuote);
  };

  const getPreviousJobValue = () => {
    if (previousJobs && !previousJobs.length) return 'No Previous Jobs Found';
    return previousSummary(selectedPreviousJob);
  };

  useEffect(() => {
    if (!includePreviousWork) return;

    const quote = selectedPreviousJob || selectedQuote;
    changeDetails('subType', quote?.subType);
    changeDetails('description', quote?.description);
    changeDetails('secondBuilding', quote?.secondBuilding);
    changeDetails('valleys', quote?.valleys);
    changeDetails('flatRoofs', quote?.flatRoofs);

    changeDetails('notes', quote?.notes);
    changeDetails('price', quote?.price);
  }, [selectedPreviousJob, selectedQuote]);

  return (
    <div className={styles.divisionContent}>
      {!!(!isQuote && includePreviousWork) && (
        <div className={styles.preset}>
          {previousJobs && (
            <Dropdown
              hint={'Previous Eavestrough Jobs'}
              items={previousJobs.map((job) => {
                return (
                  <DropdownItem
                    key={job.id}
                    id={job.id}
                    value={<div>{previousSummary(job)}</div>}
                    onSelect={() => {
                      setSelectedPreviousJob(job);
                      setSelectedQuote(undefined);
                    }}
                  />
                );
              })}
              selectedValue={getPreviousJobValue()}
            />
          )}
          {previousQuotes && (
            <Dropdown
              hint={'Quotes'}
              items={previousQuotes.map((job) => {
                return (
                  <DropdownItem
                    key={job.id}
                    id={job.id}
                    value={<div>{previousSummary(job)}</div>}
                    onSelect={() => {
                      setSelectedPreviousJob(undefined);
                      setSelectedQuote(job);
                    }}
                  />
                );
              })}
              selectedValue={getQuoteValue()}
            />
          )}
        </div>
      )}
      <div className={styles.subTypes}>
        <Dropdown
          error={errors?.subType}
          hint={'Select a Cleaning Type'}
          objectKey={'title'}
          items={subTypes.map((type) => {
            return <DropdownItem key={type.id} id={type.id} value={type.title} onSelect={() => setSubType(type)} />;
          })}
          selectedValue={details?.subType}
        />
        {isQuote && (
          <div className={styles.includeInQuote}>
            <Checkbox
              label={'Include In Quote'}
              checked={!!details?.showToClient}
              onClick={(value: boolean) => changeDetails('showToClient', value)}
            />
          </div>
        )}
      </div>
      <div className={styles.description}>
        <TextFieldCountdown
          maxLength={MAX_DESCRIPTION_SIZE}
          hint={'Description'}
          value={details?.description}
          error={errors?.description}
          setValue={(value) => changeDetails('description', value)}
        />
      </div>
      <div className={styles.checkboxContainer}>
        <div>
          <Checkbox
            label={'Second Building'}
            checked={!!details?.secondBuilding}
            onClick={(value: boolean) => changeDetails('secondBuilding', value)}
          />
        </div>
        <div>
          <Checkbox
            label={'Flat Roofs'}
            checked={!!details?.flatRoofs}
            onClick={(value: boolean) => changeDetails('flatRoofs', value)}
          />
        </div>
        <div>
          <Checkbox
            label={'Valleys'}
            checked={!!details?.valleys}
            onClick={(value: boolean) => changeDetails('valleys', value)}
          />
        </div>
      </div>

      <div className={styles.notes}>
        <TextAreaCountdown
          hint={'Notes'}
          value={details?.notes}
          setValue={(value: string) => changeDetails('notes', value)}
          maxLength={MAX_NOTES_SIZE}
        />
      </div>
      <div className={styles.priceContainer}>
        <div className={styles.price}>
          <TextField
            type={'price'}
            name={'eavesPrice'}
            hint={'Price'}
            value={details?.price}
            hasError={true}
            error={!isQuote ? errors?.price : undefined}
            setValue={(value) => {
              if (value) value = parseFloat(value);
              changeDetails('price', value === '' ? undefined : +value);
            }}
          />
        </div>
      </div>
      {addTitle && (
        <div className={styles.addBtnContainer}>
          <div className={styles.addBtn}>
            <Button title={addTitle} onClick={onAdd} />
          </div>
        </div>
      )}
    </div>
  );
}

export default EavesSetup;
