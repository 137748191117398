import Layout from 'Base';
import React, { ReactElement, useEffect, useState } from 'react';
import styles from './Jobs.module.scss';
import Table from 'components/Base/Table';
import TableRow from 'components/Base/Table/components/TableRow';
import TableData from 'components/Base/Table/components/TableData';
import useJobs from 'hooks/useJobs.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import AddEditJobModal from 'components/Modals/AddEditJobModal';
import useDebounce from 'hooks/useDebounce.hooks';
import useReactOperations from 'hooks/useReactOperations.hook';
import { formatPrice } from 'lib/formatting';
import Button from 'components/Base/Button/Button.view';
import TextSearch from 'components/TextSearch';
import JobTypeIndicators from 'components/JobTypeIndicators';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';
import { Job } from 'types';
import moment from 'moment';
import { dateFormats } from 'lib/date';
import DateRange from 'components/Base/DateRange';

type Filter = {
  id: number;
  name: string;
};

const invoicedFilters = [
  { id: 0, name: '-- Invoiced or Quoted --' },
  { id: 1, name: 'Yes' },
  { id: 2, name: 'No' },
];

const jobTypeFilters = [
  { id: 0, name: '-- Filter Job Category --' },
  { id: 1, name: 'Work Only' },
  { id: 2, name: 'Quotes Only' },
];

const paidFilters = [
  { id: 0, name: '-- Filter Paid Status --' },
  { id: 1, name: 'Fully Paid' },
  { id: 2, name: 'Not Fully Paid' },
];

const completedFilters = [
  { id: 0, name: '-- Filter Completed Status --' },
  { id: 1, name: 'Completed' },
  { id: 2, name: 'Not Completed' },
];

export interface JobsProps {
  minDate?: string;
  maxDate?: string;
  completed?: number;
  paid?: number;
  type?: number;
  search?: string;
}

function Jobs(): ReactElement {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [openAddEditJobModal, setOpenAddEditJobModal] = useState<boolean>(false);

  const [jobTypeFilter, setJobTypeFilter] = useState<Filter>(jobTypeFilters[0]);
  const [paidFilter, setPaidFilter] = useState<Filter>(paidFilters[0]);
  const [completedFilter, setCompletedFilter] = useState<Filter>(completedFilters[0]);
  const [snackBarMessage, setSnackBarMessage] = useState<string>();
  const [minDate, setMinDate] = useState<string | undefined>();
  const [maxDate, setMaxDate] = useState<string | undefined>();
  const [invoicedOrQuoted, setInvoicedOrQuoted] = useState<Filter>(invoicedFilters[0]);

  const columnSizes = [5, 10, 20, 28, 10, 10, 10, 7];

  const getHeader = () => {
    return [
      <TableData key={1} data={'id'} id={'id-header'} widthPercent={columnSizes[0]} />,
      <TableData key={2} data={'Date'} id={'date-header'} widthPercent={columnSizes[1]} />,
      <TableData key={3} data={'Client'} id={'client-header'} widthPercent={columnSizes[2]} />,
      <TableData key={4} data={'Address'} id={'address-header'} widthPercent={columnSizes[3]} />,
      <TableData key={5} data={'Total'} id={'total-header'} widthPercent={columnSizes[4]} />,
      <TableData key={6} data={'Types'} id={'types-header'} widthPercent={columnSizes[5]} />,
      <TableData key={7} data={'Paid'} id={'paid-header'} widthPercent={columnSizes[6]} />,
      <TableData key={8} data={'Done'} id={'completed-header'} widthPercent={columnSizes[7]} />,
    ];
  };

  const { jobs, fetchJobs, jobErrorMessage, jobErrorTitle, resetJobError } = useJobs();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError }],
  });

  const { navigateToJobPage } = useReactOperations();
  const debounceSearch = useDebounce(searchFilter, 300);

  const localFetchJobs = () => {
    fetchJobs({
      searchFilter: debounceSearch,
      completed: completedFilter.id === 1,
      notCompleted: completedFilter.id === 2,
      paid: paidFilter.id === 1,
      notPaid: paidFilter.id === 2,
      workOnly: jobTypeFilter.id === 1,
      quoteOnly: jobTypeFilter.id === 2,
      invoiced: jobTypeFilter.id === 1 && invoicedOrQuoted.id === 1,
      quoted: jobTypeFilter.id === 2 && invoicedOrQuoted.id === 1,
      notInvoiced: jobTypeFilter.id === 1 && invoicedOrQuoted.id === 2,
      notQuoted: jobTypeFilter.id === 2 && invoicedOrQuoted.id === 2,
      minDate: minDate,
      maxDate: maxDate,
      limit: 30,
    });
  };

  const onSaveSuccess = async (jobId?: number) => {
    await localFetchJobs();
    setOpenAddEditJobModal(false);
  };

  const getDateRange = (job: Job) => {
    if (!job.scheduledStartDate || !job.scheduledEndDate) {
      return 'Unscheduled';
    }
    const startDateMoment = moment(job.scheduledStartDate);
    const endDateMoment = moment(job.scheduledEndDate);
    if (startDateMoment.isSame(endDateMoment, 'day')) {
      return startDateMoment.format(dateFormats.abbreviatedPretty);
    }
    return `${startDateMoment.format(dateFormats.abbreviatedPretty)}-${endDateMoment.format(
      dateFormats.abbreviatedPretty
    )}`;
  };

  useEffect(() => {
    if (paidFilter.id !== 0 && jobTypeFilter.id === 2) {
      setPaidFilter(paidFilters[0]);
      return;
    }
    localFetchJobs();
  }, [debounceSearch, paidFilter, completedFilter, jobTypeFilter, minDate, maxDate, invoicedOrQuoted]);

  return (
    <Layout
      title={'Jobs'}
      openAlert={openAlert}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      onAlertClose={closeAlertModal}
      snackBarMessage={snackBarMessage}
      showSnackBar={!!snackBarMessage}
      closeSnackBar={() => setSnackBarMessage(undefined)}
    >
      <div className={styles.container}>
        <section id="toolbar" className={styles.toolbar}>
          <div className={styles.row}>
            <div>
              <TextSearch value={searchFilter} onChange={setSearchFilter} />
            </div>

            <div className={styles.dateRange}>
              <DateRange
                minDate={minDate}
                maxDate={maxDate}
                setMaxDate={setMaxDate}
                setMinDate={setMinDate}
                highestMinDate={maxDate}
                lowestMaxDate={minDate}
              />
            </div>
            <Button title="New Job" onClick={() => setOpenAddEditJobModal(true)} />
          </div>
          <div className={styles.row}>
            <div className={styles.jobTypeFilter}>
              <Dropdown
                objectKey={'name'}
                items={jobTypeFilters.map((filter) => (
                  <DropdownItem
                    key={filter.id}
                    id={filter.id}
                    value={filter.name}
                    onSelect={() => setJobTypeFilter(filter)}
                  />
                ))}
                selectedValue={jobTypeFilter}
              />
            </div>
            {!!jobTypeFilter.id && (
              <div className={styles.jobTypeFilter}>
                <Dropdown
                  objectKey={'name'}
                  items={invoicedFilters.map((filter) => (
                    <DropdownItem
                      key={filter.id}
                      id={filter.id}
                      value={filter.name}
                      onSelect={() => setInvoicedOrQuoted(filter)}
                    />
                  ))}
                  selectedValue={invoicedOrQuoted}
                />
              </div>
            )}
            <div className={styles.completedFilter}>
              <Dropdown
                objectKey={'name'}
                items={completedFilters.map((filter) => (
                  <DropdownItem
                    key={filter.id}
                    id={filter.id}
                    value={filter.name}
                    onSelect={() => setCompletedFilter(filter)}
                  />
                ))}
                selectedValue={completedFilter}
              />
            </div>

            <div className={styles.paidFilter}>
              <Dropdown
                objectKey={'name'}
                items={paidFilters.map((filter) => (
                  <DropdownItem
                    key={filter.id}
                    id={filter.id}
                    value={filter.name}
                    onSelect={() => setPaidFilter(filter)}
                  />
                ))}
                selectedValue={paidFilter}
                disabled={jobTypeFilter.id === 2}
              />
            </div>
          </div>
        </section>
        <div></div>
        <div className={styles.listContainer}>
          <Table header={getHeader()}>
            {jobs.map((job, index) => {
              return (
                <TableRow key={index} onClick={() => navigateToJobPage(job.id)}>
                  <TableData data={job.id} id={`id-${job.id}`} widthPercent={columnSizes[0]} />
                  <TableData data={getDateRange(job)} id={`date-${job.id}`} widthPercent={columnSizes[1]} />
                  <TableData data={job.client.fullName} id={`client-${job.id}`} widthPercent={columnSizes[2]} />
                  <TableData data={job.address.fullAddress} id={`address-${job.id}`} widthPercent={columnSizes[3]} />
                  <TableData
                    data={`${formatPrice(job.subtotal, true)}`}
                    id={`total-${job.id}`}
                    widthPercent={columnSizes[4]}
                  />
                  <TableData
                    data={<JobTypeIndicators job={job} />}
                    id={`types-${job.id}`}
                    widthPercent={columnSizes[5]}
                  />
                  {job.hasWork && jobTypeFilter.id !== 2 ? (
                    <TableData
                      data={job.fullyPaid ? 'YES' : 'NO'}
                      id={`paid-${job.id}`}
                      widthPercent={columnSizes[6]}
                    />
                  ) : (
                    <TableData data={'---'} id={`paid-${job.id}`} widthPercent={columnSizes[6]} />
                  )}
                  <TableData
                    data={job.completed ? 'YES' : 'NO'}
                    id={`completed-${job.id}`}
                    widthPercent={columnSizes[7]}
                  />
                </TableRow>
              );
            })}
          </Table>
        </div>
      </div>

      <AddEditJobModal
        open={openAddEditJobModal}
        onClose={() => setOpenAddEditJobModal(false)}
        onSave={onSaveSuccess}
      />
    </Layout>
  );
}

export default Jobs;
