import Autocomplete from 'components/Base/Autocomplete';
import DateRange from 'components/Base/DateRange';
import InfoCard from 'components/InfoCard';
import useDebounce from 'hooks/useDebounce.hooks';
import useSummary from 'hooks/useSummary.hook';
import useTeams from 'hooks/useTeams.hook';
import { formatPrice } from 'lib/formatting';
import React, { ReactElement, useEffect, useState } from 'react';
import { Team } from 'types';
import styles from '../Components.module.scss';

function Teams(): ReactElement {
  const [minDate, setMinDate] = useState<string>();
  const [maxDate, setMaxDate] = useState<string>();
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [searchTeams, setSearchTeams] = useState<string>('');

  const { fetchTeamsSummary, teamsSummary } = useSummary();
  const { teams, getTeams } = useTeams();
  const debounceSearchTeams = useDebounce(searchTeams);

  useEffect(() => {
    if (!selectedTeam) return;
    const params = {
      minDate: minDate ? minDate : undefined,
      maxDate: maxDate ? maxDate : undefined,
      teamId: selectedTeam.id,
    };
    fetchTeamsSummary(params);
  }, [minDate, maxDate, selectedTeam]);

  useEffect(() => {
    getTeams({ searchFilter: debounceSearchTeams });
  }, [debounceSearchTeams]);

  return (
    <div className={styles.container}>
      <div className={styles.itemSelection}>
        <Autocomplete
          values={teams.map((team) => {
            return {
              object: team,
              rowDisplay: <div>{team.name}</div>,
            };
          })}
          onSelect={(team) => {
            setSelectedTeam(team);
            setSearchTeams('');
          }}
          searchFilter={searchTeams}
          setSearchFilter={setSearchTeams}
          hint={'Search Teams'}
          onClearField={() => setSearchTeams('')}
        />
      </div>
      <DateRange
        minDate={minDate}
        setMinDate={setMinDate}
        maxDate={maxDate}
        setMaxDate={setMaxDate}
        highestMinDate={maxDate}
        lowestMaxDate={minDate}
      />

      {!!selectedTeam && (
        <div className={styles.data}>
          <div className={styles.selectedItem}>{selectedTeam?.name}</div>
          <div className={styles.summaries}>
            <InfoCard title={'Needs Attention'}>
              <table>
                <tbody>
                  <tr>
                    <td>Past Incomplete Jobs</td>
                    <td>{teamsSummary?.notCompletedJobsCount}</td>
                  </tr>
                  <tr>
                    <td>Outstanding Invoices</td>
                    <td>{teamsSummary?.outstandingInvoicesCount}</td>
                  </tr>
                  <tr>
                    <td>Past Incomplete Quote Jobs</td>
                    <td>{teamsSummary?.notCompletedQuotesCount}</td>
                  </tr>
                  <tr>
                    <td>Outstanding Quotes</td>
                    <td>{teamsSummary?.outstandingQuotesCount}</td>
                  </tr>
                </tbody>
              </table>
            </InfoCard>
            <InfoCard title={'Job Summary'}>
              <table>
                <tbody>
                  <tr>
                    <td>Total Jobs And Quotes</td>
                    <td>{teamsSummary?.totalJobsCount}</td>
                  </tr>
                  <tr>
                    <td>Complete Jobs</td>
                    <td>{teamsSummary?.completedJobsCount}</td>
                  </tr>
                  <tr>
                    <td>Total Production</td>
                    <td>{formatPrice(teamsSummary?.totalProduction, true)}</td>
                  </tr>
                  <tr>
                    <td>Cash Production</td>
                    <td>{formatPrice(teamsSummary?.cashProduction, true)}</td>
                  </tr>
                  <tr>
                    <td>Complete Quote Jobs</td>
                    <td>{teamsSummary?.completedQuotesCount}</td>
                  </tr>
                  <tr>
                    <td>Total Quoted</td>
                    <td>{formatPrice(teamsSummary?.totalQuoted, true)}</td>
                  </tr>
                </tbody>
              </table>
            </InfoCard>
            <InfoCard title={'Time Summary'}>
              <table>
                <tbody>
                  <tr>
                    <td>Hours Worked</td>
                    <td>{teamsSummary?.totalWorkedHours}</td>
                  </tr>
                  <tr>
                    <td>Hours Scheduled</td>
                    <td>{teamsSummary?.totalScheduledWorkHours}</td>
                  </tr>
                  <tr>
                    <td>Hourly Production</td>
                    <td>{`${formatPrice(teamsSummary?.hourlyProduction, true)}/hour`}</td>
                  </tr>
                </tbody>
              </table>
            </InfoCard>
          </div>
        </div>
      )}
    </div>
  );
}

export default Teams;
