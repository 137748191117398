import React, { ReactElement, useEffect, useState } from 'react';
import styles from '../Components.module.scss';
import useAccounts from 'hooks/useAccounts.hook';
import { CreateAccount } from 'types';
import EmailVerification from '../../components/EmailVerification';

function Communication(): ReactElement {
  const [outboundEmail, setOutboundEmail] = useState<string>();
  const { fetchAccount, account, update } = useAccounts();

  const onSave = async () => {
    const data: CreateAccount = {
      outboundEmail,
    };

    await update(data);
    fetchAccount();
  };

  useEffect(() => {
    setOutboundEmail(account?.outboundEmail);
  }, [account]);

  useEffect(() => {
    fetchAccount();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.title}>Communication</div>

      <div className={styles.content}>
        <EmailVerification
          value={outboundEmail}
          onChange={(value) => setOutboundEmail(value)}
          field={'Invoice / Quote Email:'}
          onSave={onSave}
        />
      </div>
    </div>
  );
}

export default Communication;
