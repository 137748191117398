import AddEditBase from 'components/AddEditSetup/AddEditBase';
import styles from './AddEditJobScheduleModal.module.scss';
import Autocomplete from 'components/Base/Autocomplete';
import BaseModal from 'components/Base/BaseModal';
import HorizontalAddEditChild from 'components/AddEditSetup/HorizontalAddEditChild';
import HorizontalAddEditRow from 'components/AddEditSetup/HorizontalAddEditRow';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Job,
  Team,
  CreateJobSchedule,
  CreateEditJobScheduleValidation,
  CustomAddJobScheduleDetails,
  JobScheduleType,
} from 'types';
import useDebounce from 'hooks/useDebounce.hooks';
import useJobs from 'hooks/useJobs.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useTeams from 'hooks/useTeams.hook';
import DateSelector from 'components/Base/DateSelector';
import TimeSelector from 'components/Base/TimeSelector';
import { dateFormats, formatDate, formatTime } from 'lib/date';
import Checkbox from 'components/Base/Checkbox';
import useJobSchedules from 'hooks/useJobSchedules.hook';
import Dropdown from 'components/Base/Dropdown';
import DropdownItem from 'components/Base/Dropdown/components/DropdownItem';

interface AddEditJobScheduleModalProps {
  open: boolean;
  id?: number;
  onClose: () => void;
  onSave: (date: string) => void;
  customDetails?: CustomAddJobScheduleDetails;
  endTime?: string;
  date?: string;
  onDelete: () => void;
}

function AddEditJobScheduleModal({
  open,
  id,
  customDetails,
  onClose,
  onSave,
  onDelete,
  date,
}: AddEditJobScheduleModalProps): ReactElement {
  const [searchJobFilter, setSearchJobFilter] = useState<string>('');
  const [searchTeamFilter, setSearchTeamFilter] = useState<string>('');
  const [selectedJob, setSelectedJob] = useState<Job>();
  const [selectedTeam, setSelectedTeam] = useState<Team>();

  const [localStartTime, setLocalStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();
  const [validations, setValidations] = useState<CreateEditJobScheduleValidation>();
  const [validated, setValidated] = useState<boolean>(false);
  const [includeJobsScheduled, setIncludeJobsScheduled] = useState<boolean>(false);
  const [type, setType] = useState<JobScheduleType>();

  const [manditoryArriveAfter, setManditoryArriveAfter] = useState<string>();
  const [manditoryArriveBefore, setManditoryArriveBefore] = useState<string>();
  const [preferredArriveAfter, setPreferredArriveAfter] = useState<string>();
  const [preferredArriveBefore, setPreferredArriveBefore] = useState<string>();

  const [localDate, setLocalDate] = useState<string>();

  const debounceJobSearch = useDebounce(searchJobFilter, 300);
  const debounceTeamSearch = useDebounce(searchTeamFilter, 300);

  const { jobs, fetchJobs, jobErrorMessage, jobErrorTitle, resetJobError } = useJobs();

  const {
    createJobSchedule,
    updateJobSchedule,
    fetchJobSchedule,
    jobSchedule,
    deleteJobSchedule,
    fetchJobScheduleTypes,
    jobScheduleTypes,
  } = useJobSchedules();

  const { teams, getTeams, fetchTeamSchedules, teamSchedules, teamErrorMessage, teamErrorTitle, resetTeamError } =
    useTeams();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [
      { title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError },
      { title: teamErrorTitle, message: teamErrorMessage, reset: resetTeamError },
    ],
  });

  const validate = (showErrors = true): boolean => {
    const errors: CreateEditJobScheduleValidation = {};
    let success = true;
    if (!localStartTime) {
      errors.startTime = 'Provide a start time';
      success = false;
    }
    if (!endTime) {
      errors.endTime = 'Provide an end time';
      success = false;
    }
    if (!selectedTeam) {
      errors.team = 'Please select a team';
      success = false;
    }
    if (!localDate) {
      errors.date = 'Pleasee select a date';
      success = false;
    }
    if (!selectedJob) {
      errors.job = 'Please select a job';
      success = false;
    }

    if (showErrors || validations) setValidations(errors);
    setValidated(true);
    return success;
  };
  const getJobs = () => {
    fetchJobs({ searchFilter: debounceJobSearch, notCompleted: true, jobsWithoutSchedules: !includeJobsScheduled });
  };

  const onLocalSave = async () => {
    try {
      if (!validate() || !selectedJob?.id || !selectedTeam?.id || !type) return;

      const schedule: CreateJobSchedule = {
        jobId: selectedJob.id,
        teamId: selectedTeam.id,
        startTime: localStartTime,
        endTime,
        date: localDate,
        manditoryArriveAfter,
        manditoryArriveBefore,
        preferredArriveAfter,
        preferredArriveBefore,
        typeId: type.id,
      };
      if (id && jobSchedule) {
        await updateJobSchedule(jobSchedule.id, schedule);
      } else {
        await createJobSchedule(schedule);
      }

      onSave(localDate || '');
    } catch (error) {
      console.error(error);
    }
  };

  const onLocalDelete = async () => {
    try {
      if (!id) return;
      await deleteJobSchedule(id);
      onDelete();
    } catch (error) {
      console.error(error);
    }
  };

  const resetData = () => {
    setSearchJobFilter('');
    setSearchTeamFilter('');
    setSelectedJob(undefined);
    setSelectedTeam(undefined);
    setManditoryArriveAfter(undefined);
    setManditoryArriveBefore(undefined);
    setPreferredArriveAfter(undefined);
    setPreferredArriveBefore(undefined);
    setLocalStartTime(undefined);
    setEndTime(undefined);
    setValidations(undefined);
    setValidated(false);
    setType(jobScheduleTypes[0] || undefined);
    setIncludeJobsScheduled(false);
    setLocalDate(undefined);
  };

  useEffect(() => {
    if (customDetails?.startTime) setLocalStartTime(customDetails.startTime);
    if (customDetails?.teamId) {
      const team = teams.find((team) => team.id === customDetails?.teamId);
      setSelectedTeam(team);
    }
  }, [customDetails]);

  useEffect(() => {
    if (!open || !id) return;
    fetchJobSchedule(id);
  }, [id, open]);

  useEffect(() => {
    setLocalStartTime(jobSchedule?.startTime);
    setEndTime(jobSchedule?.endTime);
    setLocalDate(jobSchedule?.date);
    setSelectedTeam(jobSchedule?.team);
    setSelectedJob(jobSchedule?.job);
    setManditoryArriveAfter(jobSchedule?.manditoryArriveAfter);
    setManditoryArriveBefore(jobSchedule?.manditoryArriveBefore);
    setPreferredArriveAfter(jobSchedule?.preferredArriveAfter);
    setPreferredArriveBefore(jobSchedule?.preferredArriveBefore);
    setSearchTeamFilter(jobSchedule?.team.name || '');
    setType(jobSchedule?.type);
  }, [jobSchedule]);
  useEffect(() => {
    if (!selectedTeam || !localDate) return;
    fetchTeamSchedules({ date: localDate, teamId: selectedTeam.id });
  }, [selectedTeam, localDate]);

  useEffect(() => {
    if (!open) return;
    getJobs();
  }, [debounceJobSearch, includeJobsScheduled]);

  useEffect(() => {
    if (!open) return;
    getTeams({ searchFilter: debounceTeamSearch, scheduleDate: date });
  }, [debounceTeamSearch]);

  useEffect(() => {
    if (!open) {
      resetData();
      return;
    }
    setLocalDate(date);
    getTeams({ searchFilter: debounceTeamSearch, scheduleDate: date });
    getJobs();
    fetchJobScheduleTypes();
  }, [open]);

  useEffect(() => {
    if (!jobScheduleTypes.length) return;
    setType(jobScheduleTypes[0]);
  }, [jobScheduleTypes]);

  useEffect(() => {
    if (!open || !localDate) return;
    getTeams({ searchFilter: debounceTeamSearch, scheduleDate: localDate });
  }, [localDate]);

  useEffect(() => {
    if (validated) validate(validated);
  }, [selectedJob, selectedTeam, localStartTime, endTime, localDate]);

  useEffect(() => {
    if (customDetails?.teamId) {
      const team = teams.find((team) => team.id === customDetails?.teamId);
      setSelectedTeam(team);
    }
  }, [teams]);

  return (
    <BaseModal
      open={open}
      title={'Create Job Schedule'}
      onConfirm={(id && jobSchedule) || !id ? onLocalSave : undefined}
      onClose={onClose}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
      onDelete={id ? onLocalDelete : undefined}
      deletePopupMessage={`Are you sure you would like to delete job schedule for ${
        jobSchedule?.team.name
      } on ${formatDate(jobSchedule?.date, dateFormats.abbreviatedPretty)} at ${formatTime(
        jobSchedule?.startTime,
        dateFormats.prettyTime
      )}`}
      deletePopupTitle={'Delete Job Schedule'}
    >
      <AddEditBase>
        <HorizontalAddEditRow>
          <HorizontalAddEditChild>
            <DateSelector
              title={'Date'}
              date={localDate}
              setDate={setLocalDate}
              error={validations?.date}
              hasError={true}
            />
            <Autocomplete
              values={teams.map((team) => {
                return {
                  object: team,
                  rowDisplay: <div>{team.name}</div>,
                };
              })}
              onSelect={(team: Team) => {
                setSearchTeamFilter(team.name);
                setSelectedTeam(team);
              }}
              searchFilter={searchTeamFilter}
              setSearchFilter={setSearchTeamFilter}
              onClearField={() => setSearchTeamFilter('')}
              hint={'Search Team'}
              error={validations?.team}
              hasError={true}
            />
          </HorizontalAddEditChild>
        </HorizontalAddEditRow>
        {!!(selectedTeam && !id) && (
          <HorizontalAddEditRow>
            <HorizontalAddEditChild fullWidth>
              <Checkbox
                checked={includeJobsScheduled}
                label={'Include Jobs With Schedules'}
                onClick={setIncludeJobsScheduled}
              />
              <Autocomplete
                values={jobs.map((job) => {
                  return {
                    object: job,
                    rowDisplay: (
                      <div className={styles.jobItem}>
                        <div>{job.id}</div>
                        <div className={styles.description}>{job.description}</div>
                      </div>
                    ),
                  };
                })}
                onSelect={(job: Job) => setSelectedJob(job)}
                searchFilter={searchJobFilter}
                setSearchFilter={setSearchJobFilter}
                onClearField={() => setSearchJobFilter('')}
                hint={'Search Jobs'}
                error={validations?.job}
                hasError={true}
              />
            </HorizontalAddEditChild>
          </HorizontalAddEditRow>
        )}

        <div className={styles.border} />

        <div className={styles.team}>{selectedTeam?.name}</div>
        <div className={styles.team}>{selectedJob?.description}</div>
        <div className={styles.date}>{formatDate(localDate, dateFormats.abbreviatedPretty)}</div>
        {!!(selectedJob && jobScheduleTypes.length) && (
          <>
            <HorizontalAddEditRow>
              <HorizontalAddEditChild>
                <Dropdown
                  items={jobScheduleTypes.map((type) => {
                    return <DropdownItem key={type.id} id={type.id} value={type.type} onSelect={() => setType(type)} />;
                  })}
                  selectedValue={jobScheduleTypes?.find((t) => t.id === type?.id) ?? jobScheduleTypes[0]}
                  objectKey="type"
                />
              </HorizontalAddEditChild>
            </HorizontalAddEditRow>
            <HorizontalAddEditRow>
              <HorizontalAddEditChild>
                <TimeSelector
                  title={'Start Time'}
                  time={localStartTime}
                  setTime={setLocalStartTime}
                  max={endTime}
                  highlightRanges={teamSchedules.map((schedule) => {
                    return { start: schedule.startTime, end: schedule.endTime };
                  })}
                  step={15}
                  error={validations?.startTime}
                  hasError={true}
                />
              </HorizontalAddEditChild>
              <HorizontalAddEditChild>
                <TimeSelector
                  title={'End Time'}
                  time={endTime}
                  setTime={setEndTime}
                  min={localStartTime}
                  step={15}
                  highlightRanges={teamSchedules.map((schedule) => {
                    return { start: schedule.startTime, end: schedule.endTime };
                  })}
                  error={validations?.endTime}
                  hasError={true}
                />
              </HorizontalAddEditChild>
            </HorizontalAddEditRow>
            <HorizontalAddEditRow>
              <HorizontalAddEditChild>
                <TimeSelector
                  title={'Manditory Start After'}
                  time={manditoryArriveAfter}
                  setTime={setManditoryArriveAfter}
                  max={manditoryArriveBefore}
                  highlightRanges={teamSchedules.map((schedule) => {
                    return { start: schedule.startTime, end: schedule.endTime };
                  })}
                  step={15}
                />
              </HorizontalAddEditChild>
              <HorizontalAddEditChild>
                <TimeSelector
                  title={'Manditory Start Before'}
                  time={manditoryArriveBefore}
                  setTime={setManditoryArriveBefore}
                  min={manditoryArriveAfter}
                  step={15}
                  highlightRanges={teamSchedules.map((schedule) => {
                    return { start: schedule.startTime, end: schedule.endTime };
                  })}
                />
              </HorizontalAddEditChild>
            </HorizontalAddEditRow>
            <HorizontalAddEditRow>
              <HorizontalAddEditChild>
                <TimeSelector
                  title={'Preferred Start After'}
                  time={preferredArriveAfter}
                  setTime={setManditoryArriveAfter}
                  max={preferredArriveBefore}
                  highlightRanges={teamSchedules.map((schedule) => {
                    return { start: schedule.startTime, end: schedule.endTime };
                  })}
                  step={15}
                />
              </HorizontalAddEditChild>
              <HorizontalAddEditChild>
                <TimeSelector
                  title={'Preferred Start Before'}
                  time={preferredArriveBefore}
                  setTime={setManditoryArriveBefore}
                  min={preferredArriveAfter}
                  step={15}
                  highlightRanges={teamSchedules.map((schedule) => {
                    return { start: schedule.startTime, end: schedule.endTime };
                  })}
                />
              </HorizontalAddEditChild>
            </HorizontalAddEditRow>
          </>
        )}
      </AddEditBase>
    </BaseModal>
  );
}

export default AddEditJobScheduleModal;
