import AddEditAddressSetup from 'components/AddEditSetup/AddEditAddressSetup';
import Autocomplete from 'components/Base/Autocomplete';
import AddEditBase from 'components/AddEditSetup/AddEditBase';
import ColumnAddEdit from 'components/AddEditSetup/ColumnAddEdit';
import HorizontalAddEditChild from 'components/AddEditSetup/HorizontalAddEditChild';
import HorizontalAddEditRow from 'components/AddEditSetup/HorizontalAddEditRow';
import BaseModal from 'components/Base/BaseModal';
import useAddresses from 'hooks/useAddresses.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useClients from 'hooks/useClients.hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { Client, CreateAddress, CreateEditAddressValidation } from 'types';
import styles from './AddEditAddressModal.module.scss';
import useDebounce from 'hooks/useDebounce.hooks';
import useReactOperations from 'hooks/useReactOperations.hook';

interface AddEditAddressModalProps {
  open: boolean;
  onClose: () => void;
  id?: number;
  onSave: (addressId?: number) => void;
}

function AddEditAddressModal({ open, id, onClose, onSave }: AddEditAddressModalProps): ReactElement {
  const [validationErrors, setValidationErrors] = useState<CreateEditAddressValidation>();
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [createEditAddress, setCreateEditAddress] = useState<CreateAddress>();
  const [hasValidated, setHasValidated] = useState<boolean>(false);

  const debounceSearch = useDebounce(searchFilter, 350);

  const { navigateToAddressesPage } = useReactOperations();
  const {
    createAddress,
    addressErrorMessage,
    addressErrorTitle,
    address,
    fetchCountries,
    fetchRegions,
    regions,
    countries,
    updateAddress,
    resetAddressError,
    fetchAddress,
    deleteAddress,
  } = useAddresses();

  const { fetchClients, clearClients, clients, clientsErrorMessage, clientsErrorTitle, resetClientError } =
    useClients();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [
      { title: addressErrorTitle, message: addressErrorMessage, reset: resetAddressError },
      { title: clientsErrorTitle, message: clientsErrorMessage, reset: resetClientError },
    ],
  });

  const validate = (): boolean => {
    const errors: CreateEditAddressValidation = {};

    let success = true;

    if (!selectedClient) {
      errors.client = 'Select a client';
      success = false;
    }

    if (!createEditAddress?.streetName) {
      errors.name = 'Provide a street name';
      success = false;
    }
    if (!createEditAddress?.latitude) {
      errors.latitude = 'Provide provide a latitude';
      success = false;
    }
    if (!createEditAddress?.longitude) {
      errors.longitude = 'Provide provide a longitude';
      success = false;
    }
    if (!createEditAddress?.postalCode) {
      errors.postalCode = 'Provide a postal code';
      success = false;
    }
    if (!createEditAddress?.streetNumber) {
      errors.number = 'Provide a street number';
      success = false;
    }
    if (!createEditAddress?.city) {
      errors.city = 'Provide a city';
      success = false;
    }
    if (!createEditAddress?.countryId) {
      errors.country = 'Provide a country';
      success = false;
    }

    if (!createEditAddress?.regionId) {
      errors.region = 'Provide a region';
      success = false;
    }
    if (!createEditAddress?.latitude) {
      errors.latitude = 'Provide a latitude';
      success = false;
    }
    if (!createEditAddress?.longitude) {
      errors.longitude = 'Provide a latitude';
      success = false;
    }

    setValidationErrors(errors);
    setHasValidated(true);
    return success;
  };

  const resetValues = () => {
    setValidationErrors(undefined);
    setSelectedClient(undefined);
    setCreateEditAddress(undefined);
    setHasValidated(false);
    setSearchFilter('');
  };
  const onLocalClose = () => {
    onClose();
    resetValues();
  };

  const onLocalSave = async () => {
    if (!validate() || !createEditAddress) return;
    const data = {
      ...createEditAddress,
      clientId: selectedClient?.id,
    };
    try {
      let addressId = address?.id;
      if (id) {
        await updateAddress(id, data);
      } else {
        addressId = await createAddress(data);
      }
      if (onSave) await onSave(addressId);
    } catch (error) {
      console.error(error);
    }
  };

  const onClientSelect = (client: Client) => {
    clearClients();
    setSelectedClient(client);
    setSearchFilter(client.fullName);
  };

  const onDelete = async () => {
    try {
      if (!id) return;
      await deleteAddress(id);
      navigateToAddressesPage();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!hasValidated) return;
    validate();
  }, [createEditAddress, selectedClient]);

  useEffect(() => {
    if (!address) return;
    setSelectedClient(address.client);
    const country = countries.find((country) => country.name === address.country);
    const region = regions.find((region) => region.name === address.region);
    setCreateEditAddress({
      id: address.id,
      unit: address.unit,
      city: address.city,
      countryId: country?.id,
      regionId: region?.id,
      streetNumber: address.streetNumber,
      streetName: address.streetName,
      postalCode: address.postalCode,
      latitude: address.latitude,
      longitude: address.longitude,
    });
  }, [address, countries, regions]);

  useEffect(() => {
    if (!open) {
      resetValues();
      return;
    }
    fetchCountries();
    if (!id) return;
    fetchAddress(id);
  }, [open, id]);

  useEffect(() => {
    if (!open || !debounceSearch) {
      clearClients();
      return;
    }
    fetchClients({ searchFilter: debounceSearch });
  }, [debounceSearch]);

  return (
    <BaseModal
      open={open}
      onClose={onLocalClose}
      title={'Create Address'}
      onConfirm={onLocalSave}
      onDelete={id ? onDelete : undefined}
      alertTitle={alertTitle}
      alertMessage={alertMessage}
      openAlert={openAlert}
      deletePopupMessage={`Are you sure you would like to delete ${address?.fullAddress}?`}
      deletePopupTitle={'Delete Address'}
      onAlertClose={closeAlertModal}
    >
      <AddEditBase>
        <ColumnAddEdit>
          {!address?.id && (
            <HorizontalAddEditRow>
              <HorizontalAddEditChild>
                <Autocomplete
                  values={clients.map((client) => {
                    return {
                      object: client,
                      rowDisplay: <div>{client.fullName}</div>,
                    };
                  })}
                  onSelect={onClientSelect}
                  searchFilter={searchFilter}
                  setSearchFilter={setSearchFilter}
                  hint={'Search Clients'}
                  onClearField={() => setSearchFilter('')}
                  error={validationErrors?.client}
                  hasError={true}
                />
              </HorizontalAddEditChild>
            </HorizontalAddEditRow>
          )}
          <HorizontalAddEditRow>
            <div className={styles.client}>
              <span>{selectedClient?.fullName}</span>
            </div>
          </HorizontalAddEditRow>
          <AddEditAddressSetup
            setAddress={setCreateEditAddress}
            validation={validationErrors}
            address={createEditAddress}
            countries={countries}
            regions={regions}
            fetchRegions={fetchRegions}
          />
        </ColumnAddEdit>
      </AddEditBase>
    </BaseModal>
  );
}

export default AddEditAddressModal;
