import React, { ReactElement } from 'react';
import styles from './Button.module.scss';

interface ButtonProps {
  title?: string;
  onClick?: () => void;
  border?: boolean;
  disabled?: boolean;
}

function Button({ title, onClick, border = true, disabled }: ButtonProps): ReactElement {
  const getStyle = () => {
    return border ? styles.button : styles.noBorder;
  };
  return (
    <div className={getStyle()} onClick={onClick}>
      {title}
    </div>
  );
}

export default Button;
