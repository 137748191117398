import Layout from 'Base';
import React, { ReactElement, useEffect, useState } from 'react';
import styles from './TeamsCalendar.module.scss';
import useTeams from 'hooks/useTeams.hook';
import { daysOfWeek, sqlToday } from 'lib/date';
import TeamSummaries from './components/TeamSummaries';
import AddEditTeamSchedule from 'components/Modals/AddEditTeamSchedule';
import DateSelector from 'components/Base/DateSelector';
import Button from 'components/Base/Button/Button.view';
import { TeamSchedule } from 'types';
import { SnackBarType } from 'components/SnackBar/SnackBar';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import { useParams } from 'react-router-dom';

function TeamsCalendar(): ReactElement {
  const params = useParams();
  const [openAddEditTeamModal, setOpenAddEditTeamModal] = useState<boolean>(false);
  const [date, setDate] = useState<string>(params.date || sqlToday());
  const [selectedSchedule, setSelectedSchedule] = useState<TeamSchedule>();
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarType, setSnackBarType] = useState<SnackBarType>();
  const [snackBarMessage, setSnackBarMessage] = useState<string>();

  const { monthTeamsSchedules, fetchMonthlyTeamSchedules, resetTeamError, teamErrorMessage, teamErrorTitle } =
    useTeams();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [{ title: teamErrorTitle, message: teamErrorMessage, reset: resetTeamError }],
  });

  const onSave = () => {
    fetchMonthlyTeamSchedules({ date });
    setOpenAddEditTeamModal(false);
    setSelectedSchedule(undefined);
    setSnackBarMessage('Team Schedule Created');
    setSnackBarType(SnackBarType.SUCCESS);
    setShowSnackBar(true);
  };

  const onDelete = () => {
    fetchMonthlyTeamSchedules({ date });
    setOpenAddEditTeamModal(false);
    setSelectedSchedule(undefined);
    setSnackBarMessage('Team Schedule Deleted');
    setSnackBarType(SnackBarType.SUCCESS);
    setShowSnackBar(true);
  };

  useEffect(() => {
    fetchMonthlyTeamSchedules({ date });
  }, [date]);

  return (
    <Layout
      title={'Team Schedules'}
      alertMessage={alertMessage}
      alertTitle={alertTitle}
      openAlert={openAlert}
      onAlertClose={closeAlertModal}
      showSnackBar={showSnackBar}
      snackBarType={snackBarType}
      snackBarMessage={snackBarMessage}
      closeSnackBar={() => setShowSnackBar(false)}
    >
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <DateSelector date={date} setDate={setDate} includeDaySwitching />
          <div className={styles.button}>
            <Button title={'New Schedule'} onClick={() => setOpenAddEditTeamModal(true)} />
          </div>
        </div>

        <div className={styles.calendar}>
          <div className={styles.month}></div>
          <div className={styles.dayOfWeek}>
            {daysOfWeek.map((day) => [
              <label className={styles.item} key={day}>
                {day}
              </label>,
            ])}
          </div>
          <div className={styles.dateGrid}>
            {monthTeamsSchedules.map((day) => {
              return (
                <TeamSummaries key={day.date} teamSummaries={day} date={date} editSchedule={setSelectedSchedule} />
              );
            })}
          </div>
        </div>
      </div>

      <AddEditTeamSchedule
        id={selectedSchedule?.id}
        open={openAddEditTeamModal || !!selectedSchedule}
        onClose={() => {
          setOpenAddEditTeamModal(false);
          setSelectedSchedule(undefined);
        }}
        onSave={onSave}
        date={selectedSchedule?.day}
        onDelete={onDelete}
      />
    </Layout>
  );
}

export default TeamsCalendar;
