import React, { ReactElement } from 'react';
import { DailyAllTeamsSchedule, TeamSchedule } from 'types';
import styles from './TeamSummaries.module.scss';
import classNames from 'classnames';
import { compareEqualDate, compareFormats, dateFormats, formatDate, isSameDay } from 'lib/date';
import TeamScheduleSummary from '../TeamScheduleSummary';

interface TeamSummariesProps {
  teamSummaries: DailyAllTeamsSchedule;
  date: string;
  editSchedule: (schedule: TeamSchedule) => void;
}

function TeamSummaries({ teamSummaries, date, editSchedule }: TeamSummariesProps): ReactElement {
  const sameMonth = compareEqualDate(teamSummaries.date, date, compareFormats.month);

  return (
    <div
      className={classNames(
        styles.item,
        !sameMonth && styles.nonMonth,
        sameMonth && isSameDay(teamSummaries.date) && styles.currentDay
      )}
    >
      <div className={styles.dayContainer}>
        <div className={styles.day}>{formatDate(teamSummaries.date, dateFormats.dayOnly)}</div>
      </div>
      {teamSummaries.schedules.map((schedule, index) => {
        return <TeamScheduleSummary editSchedule={() => editSchedule(schedule)} key={index} schedule={schedule} />;
      })}
    </div>
  );
}

export default TeamSummaries;
