import { BaseApi } from 'api';
import {
  FetchEmployeesSummaryParams,
  FetchOverviewParams,
  FetchTeamsSummaryParams,
  SummaryEmployees,
  SummaryOverview,
  SummaryTeam,
} from 'types';

import { SUMMARIES } from 'lib/routes';

export const fetchOverview = async (params?: FetchOverviewParams): Promise<SummaryOverview> => {
  return await BaseApi.get(`${SUMMARIES}/overview`, params);
};

export const fetchTeamsSummary = async (params?: FetchTeamsSummaryParams): Promise<SummaryTeam> => {
  return await BaseApi.get(`${SUMMARIES}/teams`, params);
};

export const fetchEmployeesSummary = async (params?: FetchEmployeesSummaryParams): Promise<SummaryEmployees> => {
  return await BaseApi.get(`${SUMMARIES}/employees`, params);
};
