import React, { ReactElement } from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from 'lib/config';

export type GoogleMarker = {
  lat: number;
  lng: number;
};

interface GoogleMapProps {
  width: number;
  height: number;
  markers?: Array<GoogleMarker>;
  setMarker?: (marker: GoogleMarker) => void;
}

function GoogleMapView({ width, height, markers, setMarker }: GoogleMapProps): ReactElement {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY || '',
  });

  if (!isLoaded) return <>Loading</>;

  return (
    <GoogleMap
      zoom={10}
      center={markers?.[0] || { lat: 44, lng: -80 }}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
      }}
      mapContainerStyle={{ width: `${width}px`, height: `${height}px` }}
      onClick={(event) => {
        if (!event.latLng || !setMarker) return;
        setMarker({ lat: event.latLng?.lat(), lng: event.latLng?.lng() });
      }}
    >
      {markers?.map((marker, index) => {
        return <Marker key={`marker-${index}`} position={marker} label={'1'} />;
      })}
    </GoogleMap>
  );
}

export default GoogleMapView;
