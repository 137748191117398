import Layout from 'Base';
import InfoCard from 'components/InfoCard';
import JobTypeIndicators from 'components/JobTypeIndicators';
import AddEditClientModal from 'components/Modals/AddEditClientModal';
import useAddresses from 'hooks/useAddresses.hook';
import useAlertDetails from 'hooks/useAlertDetails.hook';
import useClients from 'hooks/useClients.hooks';
import useJobs from 'hooks/useJobs.hook';
import { dateFormats, formatDate } from 'lib/date';
import { formatPhone, formatPrice } from 'lib/formatting';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Client.module.scss';

import Invoice from '@material-ui/icons/Receipt';
import Copy from '@material-ui/icons/FileCopyOutlined';
import Default from '@material-ui/icons/VerifiedUser';
import useReactOperations from 'hooks/useReactOperations.hook';
import classNames from 'classnames';
import { copyToClipboard } from 'lib/reactOperations';
import { SnackBarType } from 'components/SnackBar/SnackBar';
import useInvoices from 'hooks/useInvoices.hook';
import { Job } from 'types';
import moment from 'moment';

function Client(): ReactElement {
  const [openEditClient, setOpenEditClient] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>();
  const [snackBarType, setSnackBarType] = useState<SnackBarType>();
  const [sortedJobs, setSortedJobs] = useState<Array<Job>>([]);

  const { clientId } = useParams();

  const { client, getClient, clientsErrorMessage, clientsErrorTitle, resetClientError, clientsLoading } = useClients();

  const { addressLoading, addressErrorMessage, addressErrorTitle, addresses, resetAddressError, fetchAddresses } =
    useAddresses();

  const { jobsLoading, jobs, jobErrorMessage, jobErrorTitle, resetJobError, fetchJobs } = useJobs();
  const { viewInvoice } = useInvoices();

  const { openAlert, alertMessage, alertTitle, closeAlertModal } = useAlertDetails({
    alerts: [
      { title: jobErrorTitle, message: jobErrorMessage, reset: resetJobError },
      { title: addressErrorTitle, message: addressErrorMessage, reset: resetAddressError },
      { title: clientsErrorTitle, message: clientsErrorMessage, reset: resetClientError },
    ],
  });

  const { navigateToJobPage, navigateToAddressPage } = useReactOperations();

  const onEditSave = async (): Promise<void> => {
    setOpenEditClient(false);
    getClient(client?.id);
  };

  const copyData = async (data: string) => {
    try {
      await copyToClipboard(data);
      setSnackBarMessage('Copied To Clipboard');
      setSnackBarType(SnackBarType.SUCCESS);
    } catch (error) {
      setSnackBarMessage('Failed To Copy');
      setSnackBarType(SnackBarType.ERROR);
      console.error(error);
    }
  };

  useEffect(() => {
    const temp = jobs.sort((a, b) => {
      if (!a.startDate && !b.startDate) {
        return 0; // Keep them in their original order
      } else if (!a.startDate) {
        return -1; // a has no startDate, so it should come before b
      } else if (!b.startDate) {
        return 1; // b has no startDate, so it should come before a
      }
      // Compare by startDate (newest to oldest)
      return moment(b.startDate).diff(a.startDate);
    });

    setSortedJobs(temp);
  }, [jobs]);

  useEffect(() => {
    if (!clientId) return;
    const id = +clientId;
    getClient(id);
    fetchAddresses({ clientId: id });
    fetchJobs({ clientId: id });
  }, []);

  return (
    <Layout
      title={client?.fullName || ''}
      alertMessage={alertMessage}
      openAlert={openAlert}
      alertTitle={alertTitle}
      onAlertClose={closeAlertModal}
      snackBarMessage={snackBarMessage}
      showSnackBar={!!snackBarMessage}
      snackBarType={snackBarType}
      closeSnackBar={() => setSnackBarMessage(undefined)}
    >
      <div className={styles.container}>
        <div className={styles.summaries}>
          <InfoCard title={'Client Info '} onEdit={() => setOpenEditClient(true)} loading={clientsLoading}>
            <table>
              <thead>
                <tr>
                  <th>Emails</th>
                  <th>Phone Numbers</th>
                  <th>Data</th>
                  <th>Office Info</th>
                </tr>
              </thead>
              <tbody>
                {!clientsLoading && (
                  <tr className={styles.row}>
                    <td className={classNames(styles.center, styles.data)}>
                      {client?.emails?.map((email) => (
                        <div key={email.id}>
                          {!!email.main && (
                            <span title="Preferred Email">
                              <Default className={styles.default} />
                            </span>
                          )}
                          {email.email}
                          <span>
                            <Copy className={styles.copy} onClick={() => copyData(email.email)} />
                          </span>
                        </div>
                      ))}
                    </td>
                    <td className={classNames(styles.center, styles.data)}>
                      {client?.phones?.map((phone) => (
                        <div key={phone.id}>
                          {!!phone.main && (
                            <span title="Preferred Phone">
                              <Default className={styles.default} />
                            </span>
                          )}

                          {`${phone.type}: ${formatPhone(phone.number)}`}
                          <span>
                            <Copy className={styles.copy} onClick={() => copyData(phone.number)} />
                          </span>
                        </div>
                      ))}
                    </td>
                    <td className={classNames(styles.center, styles.data)}>
                      <div>{`Coorperate: ${client?.coorperate ? 'YES' : 'NO'}`}</div>
                      <div>{`Owing: ${client?.owing}`}</div>
                      <div>{`Total Jobs: ${client?.jobCount}`}</div>
                    </td>
                    <td className={classNames(styles.center, styles.data)}>
                      <div>{`Created By: ${client?.createdBy}`}</div>
                      <div>{`Created At: ${formatDate(client?.createdAt, dateFormats.fullPretty)}`}</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </InfoCard>
          <InfoCard title={'Addresses'} loading={addressLoading}>
            <table>
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Created At</th>
                  <th>Created By</th>
                </tr>
              </thead>
              <tbody>
                {addresses.map((address) => {
                  return (
                    <tr key={address.id}>
                      <td
                        className={classNames(styles.center, styles.clickable)}
                        onClick={() => navigateToAddressPage(address.id)}
                      >
                        {address.fullAddress}
                      </td>
                      <td className={styles.center}>{formatDate(address.createdAt, dateFormats.fullPretty)}</td>
                      <td className={styles.center}>{address.createdBy}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </InfoCard>
          <InfoCard title={'Jobs Summary'} loading={jobsLoading}>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Address</th>
                  <th>Types</th>
                  <th>Price</th>
                  <th>Paid</th>
                  <th>Documents</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {sortedJobs.map((job) => {
                  return (
                    <tr key={job.id}>
                      <td className={styles.date}>
                        {!job.scheduledStartDate && 'Not Scheduled'}
                        {job.scheduledStartDate &&
                          formatDate(job.scheduledStartDate || '', dateFormats.abbreviatedPretty)}
                        {!!(job.scheduledStartDate && job.scheduledEndDate !== job.scheduledStartDate) &&
                          ` - ${formatDate(job.scheduledEndDate || '', dateFormats.abbreviatedPretty)}`}
                      </td>
                      <td
                        className={classNames(styles.center, styles.clickable)}
                        onClick={() => navigateToAddressPage(job.address.id)}
                      >
                        {job.address?.shortAddress}
                      </td>
                      <td className={styles.center}>
                        <JobTypeIndicators job={job} />
                      </td>
                      <td className={styles.center}>{job.hasWork ? formatPrice(job.subtotal, true) : ''}</td>
                      <td className={styles.center}>{!!(job.completed && job.fullyPaid) && 'PAID'}</td>
                      <td className={styles.center}>
                        {job.invoices.map((invoice) => {
                          return <Invoice key={invoice.id} onClick={() => viewInvoice(invoice.id)} />;
                        })}
                      </td>
                      <td
                        className={classNames(styles.center, styles.clickable)}
                        onClick={() => navigateToJobPage(job.id)}
                      >
                        View
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </InfoCard>
        </div>
      </div>
      <AddEditClientModal
        id={client?.id}
        open={openEditClient}
        onClose={() => setOpenEditClient(false)}
        onSave={onEditSave}
      />
    </Layout>
  );
}

export default Client;
