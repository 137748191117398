import Button from 'components/Base/Button/Button.view';
import TextField from 'components/Base/TextField';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styles from './Login.module.scss';
import useLogin from 'action/useLogin';
import { UserContext } from 'context/userProfile';
import useReactOperations from 'hooks/useReactOperations.hook';
import useScreenOperations from 'hooks/useScreenOperations.hook';

function Login(): ReactElement {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const { navigateToDailyCalendarPage, navigateToDailyJobsPage } = useReactOperations();

  const { isPhone } = useScreenOperations();

  const userContext = useContext(UserContext);

  const { login, getRefreshToken, loginErrorMessage } = useLogin();
  const localLogin = async () => {
    if (!email || !password) return;
    await login(email, password);
  };

  useEffect(() => {
    const user = userContext.user;
    if (!user) return;
    if (isPhone || user.permissions.isCleaner) {
      navigateToDailyJobsPage();
    } else if (user.permissions.isAdmin || user.permissions.isReceptionist) {
      navigateToDailyCalendarPage();
    }
  }, [userContext.user]);

  useEffect(() => {
    getRefreshToken();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <h1>Login</h1>
        <TextField setValue={setEmail} value={email} label={'Email'} name={'Email'} hint={'Email'} />
        <TextField
          setValue={setPassword}
          value={password}
          label={'Password'}
          name={'Password'}
          hint={'Password'}
          type={'password'}
        />

        <div className={styles.submitButton}>
          <Button title={'Login'} border={true} onClick={localLogin} />
        </div>
        <div className={styles.error}>{loginErrorMessage}</div>
      </div>
    </div>
  );
}

export default Login;
