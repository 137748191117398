import { useState } from 'react';
import { AccountsApi } from 'api';
import { Account, CreateAccount } from 'types';
import { handleApiError } from 'lib/errors';
import useErrorsDetails from './useErrors.hook';

interface AccountsData {
  fetchCompanySquareLogo: (token?: string) => void;
  fetchCompanyInvoiceQuoteLogo: (token?: string) => void;
  fetchAccount: () => void;
  resetAccountError: () => void;
  create: (data: CreateAccount) => Promise<void>;
  account?: Account;
  accountsLoading: boolean;
  accountsErrorMessage: string;
  accountsErrorTitle: string;
  companySquareLogoSignedUrl?: string;
  companyInvoiceQuoteLogoSignedUrl?: string;
  update: (data: CreateAccount) => Promise<void>;
}

function useAccounts(): AccountsData {
  const [companySquareLogoSignedUrl, setCompanySquareLogoSignedUrl] = useState<string>();
  const [companyInvoiceQuoteLogoSignedUrl, setCompanyInvoiceQuoteLogoSignedUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<Account>();
  const { handleError, errorMessage, errorTitle, resetError } = useErrorsDetails();

  const fetchAccount = async () => {
    try {
      setLoading(true);
      const account = await AccountsApi.fetchAccount();
      setAccount(account);
      setLoading(false);
    } catch (error) {
      handleError('Error', handleApiError(error));
      setLoading(false);
      throw error;
    }
  };

  const fetchCompanySquareLogo = async (token?: string) => {
    try {
      const signedUrl = await AccountsApi.fetchCompanySquareLogo(token);
      setCompanySquareLogoSignedUrl(signedUrl);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const fetchCompanyInvoiceQuoteLogo = async () => {
    try {
      const signedUrl = await AccountsApi.fetchInvoiceQuoteLogoSignedUrl();
      setCompanyInvoiceQuoteLogoSignedUrl(signedUrl);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const create = async (data: CreateAccount) => {
    try {
      await AccountsApi.create(data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  const update = async (data: CreateAccount): Promise<void> => {
    try {
      await AccountsApi.update(data);
    } catch (error) {
      handleError('Error', handleApiError(error));
      throw error;
    }
  };

  return {
    companySquareLogoSignedUrl,
    account,
    accountsLoading: loading,
    accountsErrorMessage: errorMessage,
    accountsErrorTitle: errorTitle,
    companyInvoiceQuoteLogoSignedUrl,
    create,
    fetchCompanyInvoiceQuoteLogo,
    fetchCompanySquareLogo,
    fetchAccount,
    update,
    resetAccountError: resetError,
  };
}

export default useAccounts;
