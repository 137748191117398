import React, { ReactElement } from 'react';
import DateSelector from '../DateSelector';
import styles from './DateRange.module.scss';

interface DateRangeProps {
  setMinDate: (newDate: string) => void;
  setMaxDate: (newDate: string) => void;
  minDate?: string;
  maxDate?: string;
  highestMinDate?: string;
  lowestMaxDate?: string;
}

function DateRange({
  minDate,
  maxDate,
  highestMinDate,
  lowestMaxDate,
  setMinDate,
  setMaxDate,
}: DateRangeProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.date}>
        <DateSelector
          date={minDate}
          setDate={setMinDate}
          type="date"
          includeDaySwitching={false}
          max={highestMinDate}
        />
      </div>
      <div className={styles.date}>
        <DateSelector date={maxDate} setDate={setMaxDate} type="date" includeDaySwitching={false} min={lowestMaxDate} />
      </div>
    </div>
  );
}

export default DateRange;
